/*
 * File: CompanyAddPage.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 11th March 2020 1:44:40 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Monday, 16th March 2020 7:39:32 pm
 * -----
 * Copyright 2020 - 2020 Allshore, https://allshorestaffing.com
 */

import React from "react";
import { useHistory } from "react-router-dom";
import { BaseAuth } from "../../../Components/BaseAuth";
import { PageHeader } from "../../../Components/PageHeader";
import { CompanyForm } from "./CompanyForm";
import { companyLinks } from "./CompaniesListPage";

export const CompanyAddPage: React.FC = () => {
  const history = useHistory();

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  return (
    <BaseAuth>
      <PageHeader heading="Add Company" links={companyLinks} />
      <CompanyForm />
    </BaseAuth>
  );
};
