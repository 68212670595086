/*
 * File: ReferPage.tsx
 * Project: Dyme Jobs Web
 * File Created: Monday, 30th November 2020 10:58:31 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 22nd November 2021 9:22:42 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import "react-phone-number-input/style.css";
import { images } from "../../../assets/images/images";
import { log } from "../../../Lib/helpers";
import { ReferUser } from "./ReferUser";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import {
  EUserType,
  ILoginResponse,
  IUser,
} from "../../../Services/Interfaces/AuthInterface";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { JobApi } from "../../../Services/API/JobApi";
import { IJob } from "../../../Services/Interfaces/JobInterface";
import Loader from "../../../Components/Loader";
import { UserApi } from "../../../Services/API/UsersApi";
import { EmployeesApi } from "../../../Services/API/EmployeesApi";
import {
  EEmployeeRole,
  IEmployee,
} from "../../../Services/Interfaces/EmployeeInterface";
import { GoogleLogin } from "./GoogleLogin";
import FacebookLogin from "./FacebookLogin";
import { IApiResponse } from "../../../Services/api-client";
import { AuthApi } from "../../../Services/API/AuthApi";
import { ENotifyType } from "../../../Components/Notify";
import { Config } from "../../../Config";
import { toastify } from "../../../Services/toast.service";
import { AppContext } from "../../../Services/AppContext";
import { ErrorMessage } from "@hookform/error-message";
import { LoaderComponent } from "../../../Components/common/loader.component";

/**
 * - User Refer Other User
 * - Company Refer their employee
 * - User Refer a business / company
 */
export type TRefer = "business" | "friend" | "employee" | "";

const schema = yup.object().shape({
  fullName: yup.string().required("Full Name required"),
  email: yup.string().email("Invalid email address").required("Email Required"),
  password: yup.string().required("Password required"),
});

interface IReferPage {
  refer?: TRefer;
}

const ReferPage = (props: IReferPage) => {
  const referBy = props.refer;

  //@ts-ignore
  const { id, jobId, employeeId } = useParams();

  const history = useHistory();

  const { updateUser } = useContext(AppContext);

  const [_errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState<IJob | null>(null);
  const [success, setSuccess] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const [user, setUser] = useState<IUser | null>(null);
  const [employee, setEmployee] = useState<IEmployee | null>(null);

  const _fetchData = async () => {
    setLoading(true);

    await Promise.all([_getJobData(), _getReferrerData(), _getEmployeeData()]);

    setLoading(false);
  };
  useEffect(() => {
    _fetchData();
  }, [id, jobId]);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const _getJobData = async () => {
    if (!jobId) return false;
    const response = await JobApi.GetJobNoAuth(jobId);
    try {
      if (response.hasError) {
        handleApiError(response, "job-info");
      } else {
        // ? Get Job success
        log("Job Info SUCCESS: ", response);
        setJob(response.data);

        document.title = job ? job.title : "Dyme Jobs and Rewards";
        document
          .querySelector('meta[name="description"]')
          ?.setAttribute(
            "content",
            document.title +
              ". Create account and earn rewards by referring your friends."
          );
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  const _getEmployeeData = async () => {
    if (!employeeId) return false;
    const response = await EmployeesApi.GetEmployeeNoAuth(employeeId);
    try {
      if (response.hasError) {
        handleApiError(response, "employee-info");
        _navigate(RouteKeys.SignIn);
      } else {
        // ? Get Employee success
        log("Employee Info SUCCESS: ", response);
        setEmployee(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  const _getReferrerData = async () => {
    if (!id) return false;
    const response = await UserApi.GetUserNoAuth(id);
    try {
      if (response.hasError) {
        handleApiError(response, "User Info: ");
      } else {
        // ? Get User Info success
        log("User Info SUCCESS: ", response);
        setUser(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  const _signup = async (
    v: any,
    socialLoginData: IApiResponse | null = null
  ) => {
    // alertObject(v);
    // return;
    // if (!phoneVerified)
    //   return toastify("error", "Phone number is not verified.");

    setLoading(true);

    let response: IApiResponse;

    if (socialLoginData) {
      response = socialLoginData;
    } else {
      //   v.phone = phone;
      const { fullName, email, password } = v;

      response = await AuthApi.Register(
        email,
        "",
        email, // username
        fullName,
        password,
        referBy === "business", // isBusiness if business register as admin
        "", // photo
        id || 0, //referredBy user id
        jobId,
        employeeId
      );
    }

    let message: string = "";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        handleApiError(response, "SIGN UP SCREEN: ");

        message = response.data;

        if (response.data) {
          message = response.data.message;
          setErrors(response.data);
        }
      } else {
        // ? Account creation success
        log("REGISTER SUCCESS: ", response);
        message = "Account created!";
        errorType = ENotifyType.Success;

        // IF REFER BY BUSINESS, LOGIN AND NAVIGATE TO SETTINGS PAGE.

        // IF REFER TO FRIEND, NAVIGATE TO DOWNLOAD APP PAGE..

        // _navigate(RouteKeys.SignIn);

        // login if business

        let newUser: ILoginResponse = response.data;
        let allowLogin = true;
        if (referBy === "business") {
          newUser.ttl =
            new Date().getTime() + Config.LOGOUT_AFTER_MINUTES * 60 * 1000; // newUser.ttl

          updateUser(newUser);
        } else {
          message = "Account created! Download Dyme Jobs to continue.";
        }

        if (newUser.user.role === EUserType.Noob) {
          allowLogin = false;

          const { employee } = newUser;

          if (employee && employee.role !== EEmployeeRole.Noob) {
            allowLogin = true;
          }
        }

        if (allowLogin) {
          newUser.ttl =
            new Date().getTime() + Config.LOGOUT_AFTER_MINUTES * 60 * 1000; // newUser.ttl

          updateUser(newUser);

          // toastify(ENotifyType.Success, "Logged In!");
        } else {
          // don't allow login and redirect to apps page.

          toastify(errorType, message);

          _navigate(RouteKeys.MobileApps);
        }

        setSuccess(true);
      }
    } catch (e) {
      handleCatchError(e);
    }

    // toastify(errorType, "stuff");
    // alert(message)
    setLoading(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      //   phone: "",
      fullName: "",
      password: "",
      // confirmPassword: "",
    },
  });

  if (success && referBy === "friend") {
    return (
      <h1>
        Thank you for registration. Please download our app to apply for jobs.
      </h1>
    );
  }

  if (success && referBy === "employee") {
    return (
      <h1>
        Thank you for registration. Please download our app to refer your
        friends and earn rewards.
      </h1>
    );
  }

  const referData = {
    employeeId,
    isBusiness: referBy === "business",
    jobId,
    referredBy: referBy || 0,
  };

  return (
    <div
      className="Auth_Page Auth_Page_v2 auth_page_wrapper referal_page"
      data-testid="AuthPage"
    >
      {/* <header className="auth_header">
                <div className="fluid-container login_header">
                    <div className="row">
                        <div className="col-md-12">
                            <img src={images.DymeBlueLogo} alt="Dyme Jobs Logo" />
                        </div>
                    </div>
                </div>
            </header> */}
      <div className="container">
        <div className="row">
          <div className="col-md-5 login_form_section2">
            <div className="login_form_wrapper">
              <div className="container login_action_wrapper">
                <div className="row">
                  <div className="col-md-12 text-center">
                    {/* <strong className="rt-logo"> */}
                    
                      <img
                        src={images.DymeLogo}
                        onClick={() => history.replace(RouteKeys.Home)}
                        alt="Dyme Logo"
                        style={{ cursor: "pointer" }}
                        className="logo"
                      />
                    
                    
                    {/* </strong> */}

                    <p className="logo_text">
                      Find your job ~ Help a Friend ~<br />
                      Redeem Your Rewards
                    </p>

                    {referBy === "friend" || referBy === "employee" ? (
                      <p className="greating_text">
                        <span>
                          Hi {employee ? employee.fullName : "There"}, Welcome
                          to Dyme!
                        </span>
                        {employee ? (
                          <span>
                            Refer your friends and earn enhanced rewards from{" "}
                            {employee.__branch__.name}
                          </span>
                        ) : (
                          <span>
                            Your new jobs and rewards are waiting for you.
                          </span>
                        )}
                        {user && (
                          <span style={{ marginTop: 10 }}>
                            {user?.fullName} referred you.
                            {job
                              ? " " + job.title + " from " + job.branch?.name
                              : null}
                          </span>
                        )}
                      </p>
                    ) : (
                      <p className="logo_text">
                        <span>Your source for Call Center Applicants -</span>
                        <span>Faster, More Flexible, Better</span>
                      </p>
                    )}

                    {/* <p>
                      <a className="how_it_work">How it Works</a>
                    </p> */}
                  </div>
                </div>
                <div className="row align-items-center">
                  <GoogleLogin afterLogin={_signup} referData={referData} />
                </div>
                <div className="row text-center">
                  <FacebookLogin afterLogin={_signup} referData={referData} />
                  {/* <div className="col-md-12">
                                        <a className="login_button facebook"><span>Sign up with Facebook</span></a>
                                    </div> */}
                </div>
                {/* <div className="row">
                                    <div className="col-md-12">
                                        <a className="login_button apple"><span>Sign up with Apple</span></a>
                                    </div>
                                </div> */}
                <div className="row">
                  <div className="col-md-12 text-center">
                    <p className="or_text">
                      <span>or</span>
                    </p>
                  </div>
                </div>
                {/* <br />
                                <div className="row">
                                    <GoogleLogin afterLogin={_tryLogin} />
                                </div> */}
              </div>

              <div id="form_wrapper">
                <form
                  className="rt-formtheme rt-formsignin"
                  onSubmit={handleSubmit((data) => _signup(data))}
                >
                  <fieldset>
                    <div className="form-group">
                      <div className="rt-inputiconholder">
                        <input
                          type="text"
                          className="form-control username_field text_field"
                          placeholder="Full Name"
                          {...register("fullName", {
                            required: "Full name is require",
                          })}
                        />
                        <span className="text-danger">
                          <ErrorMessage errors={errors} name="fullName" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="rt-inputiconholder">
                        <input
                          type="email"
                          className="form-control username_field email_field text_field"
                          placeholder="Email Address"
                          {...register("email")}
                        />
                        <span className="text-danger">
                          <ErrorMessage errors={errors} name="email" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="rt-inputiconholder password_wrapper">
                        <input
                          type={showPass ? "text" : "password"}
                          className="form-control password_field text_field"
                          placeholder="Password"
                          {...register("password", {
                            required: "Password is required",
                            min: "Password minimum length must be 8 characters",
                            minLength: 8,
                          })}
                        />
                        {showPass ? (
                          <i
                            onClick={() => setShowPass(!showPass)}
                            className="icon-eye show_hide_password"
                          ></i>
                        ) : (
                          <i
                            onClick={() => setShowPass(!showPass)}
                            className="icon-eye-blocked1 show_hide_password"
                          ></i>
                        )}
                        <span className="text-danger">
                          <ErrorMessage errors={errors} name="password" />
                        </span>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="blue_button yellow_button"
                        disabled={loading}
                      >
                        {loading ? <LoaderComponent /> : ""}
                        <span>Sign Up</span>
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>

              <div className="rt-btnforgotpass">
                <Link style={{ color: "whitesmoke" }} to={RouteKeys.SignIn}>
                  Already have an account? <span> Let’s Sign In</span>
                </Link>
              </div>
            </div>




            <div className="col-md-12">
              {referBy === "friend" ? (
                <ul className="points">
                  <li>
                    Easily search for jobs by location, pay, full time/part time
                    and the apply with the single click.
                  </li>
                  <li>
                    Track your application every step of the way as an employer
                    view you application, accept your application, schedules an
                    interview and then hires you.
                  </li>
                  <li>
                    Refer you friends to Dyme. Earn Dyme rewards for 3 layers of
                    you friends as you friends refer Dyme to other friends.
                  </li>
                  <li>
                    Redeem you Dyme with over 200 gift card to choose from.
                  </li>
                </ul>
              ) : (
                <ul className="points">
                  <li>Easily post jobs to Dyme</li>
                  <li>Pay per application model. Only pay what you need.</li>
                  <li>Eliminate wasted HR marketing dollars.</li>
                </ul>
              )}
            </div>


          </div>
        </div>
      </div>
      <footer className="auth_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                Having trouble? Call 1 8444-992-DATA (3282) or{" "}
                <a href="">Contact Support</a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>
                &copy; {new Date().getFullYear()} Dyme |{" "}
                <a href={Config.TERMS_LINK}>Terms & Conditions</a> |{" "}
                <a href={Config.PRIVACY_LINK}>Privacy</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

ReferPage.defaultProps = {
  refer: "",
};

export default ReferPage;
