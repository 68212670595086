/*
 * File: JobsListPage.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 8th December 2020 6:53:15 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:34:11 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./JobsListPage.module.scss";
import Dropdown from "react-bootstrap/Dropdown";
import moment from "moment";
import ShowMoreText from "react-show-more-text";

import {
  EJobStatus,
  IJob,
} from "../../../../Services/Interfaces/JobInterface";
import {
  IPaginate,
  defaultPaginatedData,
} from "../../../../Services/Interfaces";
import { toastify } from "../../../../Services/toast.service";
import SearchingComponent from "../../../../Components/common/searching.component";
import { PaginationComponent } from "../../../../Components/common/Pagination/Pagination.component";
import { ConfirmModal } from "../../../../Components/modals/confirm.modal";
import { JobDetailComponent } from "../job-detail.component";
import { JobApi } from "../../../../Services/API/JobApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { log, _cloneDeep } from "../../../../Lib/helpers";
import Loader from "../../../../Components/Loader";
import { RouteKeys } from "../../../../Routes/RouteKeys";
import { EApplicationStatus } from "../../../../Services/Interfaces/ApplicationInterface";

let keyword: string = "";
const JobsListPage: React.FC = (props: any) => {
  const [openJobDetail, setJobDetailStatus] = useState(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [jobToDel, setJobToDel] = useState<IJob | null>(null);
  const [jobs, setJobs] = useState<IPaginate<IJob>>(defaultPaginatedData);
  // const [jobs, setJobs] = useState<IPaginate<IJob>>([]);

  const [loading, setLoading] = useState(false);

  const [selectedJob, setSelectedJob] = useState<IJob>();

  const statuses: EJobStatus[] = Object.values(EJobStatus);

  const history = useHistory();

  let page: number = 1;

  useEffect(() => {
    keyword = "";
    getData();
  }, []);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const getData = async (page = 1) => {
    setLoading(true);

    const response = await JobApi.JobsList(page);
    try {
      if (response.hasError) {
        handleApiError(response, "JOB List: ");
      } else {
        // ? Get Jobs success
        log("Jobs List SUCCESS: ", response);
        setJobs(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  const viewJob = (v: IJob) => {
    setSelectedJob(v);
    setJobDetailStatus(true);
    if (v.location) {
      showMap(`${v.location}`);
    }
  };

  const showMap = (location: string): void => {
    const [lat, lng]: any = location.split(",");

    const map = new google.maps.Map(
      document.getElementById("showLocationOnMap"),
      {
        zoom: 13,
        center: { lat: Number(lat), lng: Number(lng) },
        disableDefaultUI: true,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        zoomControl: false,
        overviewMapControl: false,
        panControl: false,
				scrollwheel: false
      }
    );
    new google.maps.Circle({
      strokeColor: "#FF0000",
      strokeOpacity: 0.5,
      strokeWeight: 1,
      fillColor: "#FF0000",
      fillOpacity: 0.2,
      map,
      center: { lat: Number(lat), lng: Number(lng) },
      radius: Math.sqrt(50) * 100,
    });
  };

  const deleteJob = (jobToDel: IJob | undefined | null) => {
    if (!jobToDel) return false;

    if (jobToDel.applications?.length) {
      toastify(
        "info",
        "Cannot delete job, because applicants have applied to it."
      );
      return false;
    }
    setJobToDel(jobToDel);
    setShowConfirm(true);
  };

  const confirmDelete = async () => {
    if (!jobToDel || !jobToDel.id) return false;

    setDeleting(true);

    let success = false;

    // setLoading(true);
    try {
      const response = await JobApi.JobDelete(jobToDel.id);
      if (response.hasError) {
        handleApiError(response, "JOB Delete: ");
      } else {
        // ? Delete success
        log("Jobs Delete SUCCESS: ", response);
        updateJobsList(jobToDel, true);
        success = true;
      }
    } catch (e) {
      handleCatchError(e);
    }

    hideDeletingModal(success);

    if (success) {
      setSelectedJob(undefined);
      setJobDetailStatus(false);
    }

    // setLoading(false);

    // deleteRequest(endpoints.job.delete(jobToDel)).then((response: AxiosResponse<IJob>) => {
    //     hideDeletingModal(true)
    //     const rows = jobs.rows.map((j: IJob) => (j.id === response.data.id) ? response.data : j);
    //     setJobs({ ...jobs, rows })
    // })
  };

  const hideDeletingModal = (status = false) => {
    setDeleting(false);
    setShowConfirm(false);
    if (status) toastify("success", "Job deleted successfully");
    setJobToDel(null);
  };

  const updateJobsList = (updatedJob: IJob, deleteJob = false) => {
    let newJobs: IPaginate<IJob> = _cloneDeep(jobs);

    const i = newJobs.data.findIndex((j) => j.id === updatedJob.id);

    if (i > -1) {
      if (deleteJob) {
        newJobs.data.splice(i, 1);
      } else {
        newJobs.data[i] = updatedJob;
      }
    }

    setJobs(newJobs);
  };

  const updateStatus = async (job: IJob, _s: EJobStatus) => {
    setLoading(true);
    let newJob = _cloneDeep(job);
    newJob.status = _s;
    try {
      const response = await JobApi.EditJob(newJob);
      if (response.hasError) {
        handleApiError(response, "JOB FORM: ");
      } else {
        log("JOB EDIT: ", response);

        updateJobsList(newJob);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  return (
    <div className={styles.JobsListPage} data-testid="JobsListPage">
      <div className="rt-wrapper">
        <div className="rt-joblistpage">
          <div className="rt-pagetitle">
            <h1>Job List</h1>
            <Link className="rt-btnpostajob" to={RouteKeys.AddJob}>
              <span>Create A Job</span>
            </Link>
          </div>
          <div
            className={`rt-jobslistholder ${
              openJobDetail ? "show-jobdetail" : ""
            }`}
          >
            <div className="rt-jobslistsarea">
              <div className="at-jobareahead">
                <h3>Newest to Oldest ({jobs.total} Total Jobs)</h3>
                {/* <SearchingComponent
									getData={(v: string) => {
										keyword = v;
										page = 1;
										getData();
									}}
									/> */}
              </div>
              <div className="rt-themetableholder" id="job_list_wrapper">
                <table className="rt-themetable table rt-jobstable">
                  <thead>
                    <tr>
                      <th>
                        <span>Status</span>
                      </th>
                      <th>
                        <span>Job Title</span>
                      </th>
                      {/* <th>
												<span>Address</span>
											</th> */}
                      <th>
                        <ul>
                          <li>
                            <span>Applied</span>
                          </li>
                          <li>
                            <span>Hired</span>
                          </li>
                        </ul>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {jobs.data.map((job: IJob) => {
                      const _onEditClick = () =>
                        _navigate(`${RouteKeys.EditJob}/${job?.id}`, { job });
                      const _onViewClick = () => viewJob(job);
                      return (
                        <tr key={job.id}>
                          <td colSpan={6} className="job_item_col">
                            <div className="job_item_wrapper">
                              <div className="arrow_dropdown">
                                <Dropdown className="rt-themedropdown">
                                  <Dropdown.Toggle id="dropdown-basic">
                                    <div className="rt-statusbuttonholder">
                                      <div className="job_down_arrow_wrapper">
                                        <i className="icon-down-arrow-1"></i>
                                      </div>
                                    </div>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="rt-themedropdownmenu">
                                    {statuses.map((_s: EJobStatus) => (
                                      <Dropdown.Item
                                        key={_s}
                                        onClick={() => updateStatus(job, _s)}
                                      >
                                        {_s}
                                      </Dropdown.Item>
                                    ))}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <table>
                                <tbody>
                                  <tr onClick={_onViewClick}>
                                    <td data-title="Status">
                                      <div className="rt-statusbuttonholder">
                                        <div
                                          className={`rt-staturresult rt-bgactive rt-status-bg-${job.status}`}
                                        >
                                          <i
                                            className={`icon-status-active rt-status-c-${job.status}`}
                                          ></i>
                                          <em>{job.status}</em>
                                        </div>
                                      </div>
                                    </td>
                                    <td data-title="Job Title/Date">
                                      <h3 style={{ cursor: "pointer" }}>
                                        {job.title} <i>(${job.salary})</i>
                                      </h3>
                                      <time>
                                        created{" "}
                                        {moment(job.createdAt).fromNow()} and
                                        updated{" "}
                                        {moment(job.updatedAt).fromNow()}
                                      </time>
                                    </td>
                                    {/* <td data-title="Address">
																		<ShowMoreText
																			lines={1}
																			more=""
																			less=""
																			className="content-css"
																			anchorClass="my-anchor-css-class"
																			expanded={false}
																			width={400}
																		>
																			{job.branch?.address.address || "N/A"}
																		</ShowMoreText>
																		</td> */}
                                    <td data-title="Applied/Hired">
                                      <div className="rt-countdropdown">
                                        <ul>
                                          <li>
                                            <i className="icon-suitcase"></i>
                                            <span>
                                              {job?.applications?.length}
                                            </span>
                                          </li>
                                          <li>
                                            <i className="icon-suitcase"></i>
                                            <span>
                                              {
                                                job?.applications?.filter(
                                                  (j) =>
                                                    j.status ===
                                                    EApplicationStatus.Hired
                                                ).length
                                              }
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <div className="dropdown">
                                <Dropdown className="rt-themedropdown">
                                  <Dropdown.Toggle
                                    variant="success"
                                    id="dropdown-basic"
                                  >
                                    <i className="icon-more"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="rt-themedropdownmenu">
                                    <Dropdown.Item onClick={_onEditClick}>
                                      <i className="icon-edit"></i>
                                      <span>Edit Job</span>
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={_onViewClick}>
                                      <i className="icon-eye1"></i>
                                      <span>View Job</span>
                                    </Dropdown.Item>

                                    <Dropdown.Item
                                      className="rt-bgdanger"
                                      onClick={() => deleteJob(job)}
                                    >
                                      <i className="icon-delete"></i>
                                      <span>Delete Job</span>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <PaginationComponent
                  {...jobs}
                  onClick={(v: number) => {
                    getData(v);
                  }}
                />
              </div>
            </div>
            <JobDetailComponent
              selectedJob={selectedJob as IJob}
              close={() => setJobDetailStatus(false)}
              onDelete={deleteJob}
            />
            <ConfirmModal
              loading={deleting}
              onClose={hideDeletingModal}
              onConfirm={confirmDelete}
              show={showConfirm}
              message="Are you sure you want to delete this job?"
              confirmTitle="Delete Job"
            />
            {loading && <Loader />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobsListPage;
