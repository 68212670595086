/*
 * File: App.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 21st January 2020 12:39:53 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useContext } from "react";
import { ThemeProvider, Theme, StyledEngineProvider, adaptV4Theme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/App.scss";
import "./assets/styles/social_buttons.scss";
import Routes from "./Routes";
import { createTheme } from "./Themes/theme";
import { AppContext } from "./Services/AppContext";
import { Config } from "./Config";


declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}


// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Config.STRIPE_KEY);

const App: React.FC = () => {
  const { darkMode } = useContext(AppContext);

  const theme = React.useMemo(() => createTheme(darkMode), [darkMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Elements stripe={stripePromise}>
          <Routes />
          <ToastContainer closeOnClick autoClose={5000} />
        </Elements>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
