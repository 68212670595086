/*
 * File: AppContext.tsx
 * Project: Dyme Jobs Web
 * File Created: Saturday, 8th February 2020 12:33:28 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 30th November 2021 11:51:08 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { createContext, useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import {
  Notify,
  ENotifyType,
  INotify,
  INotification
} from "../Components/Notify";
import { log, storageUpdate, storageGet } from "../Lib/helpers";
import { IUser, ILoginResponse, EUserType } from "./Interfaces/AuthInterface";
import { AuthApi } from "./API/AuthApi";
import { toastify } from "./toast.service";

interface IAppContext {
  darkMode: boolean;
  toggleTheme: () => void;
  notify(data: INotification): void;
  logout: () => void;
  logoutIfTimeout: () => void;
  user: ILoginResponse | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  updateUser(newUser: ILoginResponse | null): void;
}

export const AppContext = createContext<IAppContext>({
  darkMode: false,
  toggleTheme: () => {},
  notify: (data: INotification) => {},
  logout: () => {},
  logoutIfTimeout: () => {},
  user: null,
  isAdmin: false,
  isSuperAdmin: false,
  updateUser: (newUser: ILoginResponse | null) => null
});

export const AppProvider: React.FC = props => {
  const dMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [darkMode, setDarkMode] = useState(dMode);
  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  let localUser:ILoginResponse|null = storageGet("user");

  log("LOCAL USER IN CONTEXT: ", localUser);

  const [user, setUser] = useState<ILoginResponse | null>(localUser);

  // Update Data in User Context and Storage
  const updateUser = (newUser: ILoginResponse | null, updateStorage = true) => {
    log("updating new user...", newUser);

    if (updateStorage) {
      storageUpdate("user", newUser);
    }

    setUser(newUser);

    // return newUser;
  };

  /**
   * Logout User and clear Storage
   */
  const logout = () => {
    log("LOGGING OUT IN CONTEXT");

    AuthApi.Logout();

    // updateContextLoading(true);
    updateUser(null);

    // setGlobalUser(null);

    window.localStorage.clear();

    // updateContextLoading(false);
  };

  const logoutIfTimeout = () => {
    // auto logout user if token expired based on ttl that was set on login screen.
    if (user && (new Date().getTime() > user.ttl)) {
      logout();
    }
  }

  useEffect(() => {
    //? this is also being called in routes
    logoutIfTimeout()
  }, [user])


  // const [notifier, setNotifier] = useState<INotify>({
  //   show: false,
  //   title: "",
  //   type: ENotifyType.Success
  // });
  const notify = (data: INotification = {title: "Something went wrong", type: ENotifyType.Error}) => {
    toastify(data.type, data.title)
    
    // setNotifier({
    //   ...data,
    //   show: true
    // });
  };

  const isAdmin = user?.user?.role === EUserType.Admin
  const isSuperAdmin = user?.user?.role === EUserType.SuperAdmin

  return (
    <AppContext.Provider
      value={{
        darkMode,
        toggleTheme,
        notify,
        logout,
        isAdmin,
        isSuperAdmin,
        user,
        updateUser,
        logoutIfTimeout
      }}
    >
      {props.children}
      {/* <Notify
        show={notifier.show}
        type={notifier.type}
        title={notifier.title}
        onClose={() => setNotifier({ ...notifier, title: "", show: false })}
      /> */}
    </AppContext.Provider>
  );
};
