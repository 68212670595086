/*
 * File: Thankyou.tsx
 * Project: Dyme-Web
 * File Created: Friday, 20th August 2021 8:40:32 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 6th October 2021 1:17:06 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { EChargeVia } from "../../../../Services/API/PaymentApi";

const useStyles = makeStyles((theme: Theme) => ({
  modalContainer:{
    borderRadius: 10,
  },
  checkContainer: {
    background: "green",
    width: 50,
    height: 50,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  iconCheck: {
    color: "#fff",
  },
  iconClose: {
    position: "absolute",
    right: 0,
  },
  bodyContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 50,
  },
  textThanks: {
    fontSize: 30,
    fontFamily: 'proxima_novabold',
  },
  subHeader: {
    color: "#515151",
    marginTop:10,
    fontFamily: "proxima_novaregular",
  },
  amountContainer:{
    display:"flex",
    flexDirection:"row",
    width: "100%",
    justifyContent:"space-between",
    fontFamily: "proxima_novaregular",
    marginTop:30,
  },
  paymentContainer:{
    display:"flex",
    flexDirection:"row",
    width: "100%",
    justifyContent:"space-between",
    marginTop:20,
    fontFamily: "proxima_novaregular",
  },
  divider:{
    width:"100%",
    border:"1px solid #F5F5F5",
  },
}));

interface IThankyouBox {
  toggle: boolean;
  setToggle: (toggle: boolean) => void;
  amount: number;
  source: EChargeVia
}
const ThankyouBox = (props: IThankyouBox) => {
  const {toggle , setToggle, amount } = props
  const classes = useStyles();
 
  const handleClose = () => {
    setToggle(false);
  };
  console.log(props, "qwertyuio")
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={toggle}
        PaperProps={{
          style: { borderRadius: 15, overflow:"visible" }
        }}
        className={classes.modalContainer}
      >
        <div>
          <div className={classes.checkContainer}>
            <CheckIcon className={classes.iconCheck} />
          </div>
          <IconButton
            aria-label="close"
            // className={classes.closeButton}
            onClick={handleClose}
            className={classes.iconClose}
            size="large">
            <CloseIcon />
          </IconButton>
          <div className={classes.bodyContainer}>
            <Typography className={classes.textThanks}>Thank you!</Typography>
            <Typography className={classes.subHeader}>
              Check your wallet for updated balance
            </Typography>
            <div className={classes.amountContainer}>
              <Typography>Amount</Typography>
              <Typography>${amount}</Typography>
            </div>
            <hr className={classes.divider}/>
            <div className={classes.paymentContainer}>
              <Typography>Pay from</Typography>
              <Typography>{props.source === EChargeVia.Bank ? "Bank Transfer" : "Credit / Debit Card"}</Typography>
            </div>
            <hr className={classes.divider}/>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
export default ThankyouBox;