import { grey } from "@mui/material/colors";

/*
 * File: Colors.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 4th February 2020 8:53:42 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

export const AppColors = {
  black: '#000000',
  blueMain: "#0062FF",
  darkBlue: "#3475ba",
  green: '#BCE338',
  grey: '#212121',
  greyLight: grey[400],
  white: '#FFFFFF',
  yellow: "#fbc542"
}
