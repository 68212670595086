/*
 * File: ImportEmployees.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 6th July 2021 1:35:51 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 20th September 2021 11:49:47 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { ParseResult } from "papaparse";
import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVReader } from "react-papaparse";
import Loader from "../../../Components/Loader";
import { ENotifyType } from "../../../Components/Notify";
import { log } from "../../../Lib/helpers";
import { EmployeesApi } from "../../../Services/API/EmployeesApi";
import { AppContext } from "../../../Services/AppContext";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { EEmployeeRole, IEmployeeBase } from "../../../Services/Interfaces/EmployeeInterface";

const INITIAL_COLUMNS = ["id", "name", "title", "mobile", "email"];

type TLogType = "error" | "warning" | "info";
interface ILog {
  type: TLogType;
  message: string;
}

interface IImportEmployees {
  onFinish: () => void;
}
export const ImportEmployees = ({ onFinish }: IImportEmployees) => {
  const buttonRef = useRef();

  const { notify } = useContext(AppContext);

  const [csvData, setCsvData] = useState<ParseResult<any>[]>([]);
  const [employees, setEmployees] = useState<IEmployeeBase[]>([]);

  const [logList, setLogList] = useState<ILog[]>([]);

  const [loading, setLoading] = useState(false);

  // const [columns, setColumns] = useState([...INITIAL_COLUMNS]);

  //@ts-ignore
  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      //@ts-ignore
      buttonRef.current.open(e);
    }
  };

  const _resetData = () => {
    setEmployees([]);
    setCsvData([]);
    setLogList([]);
  };

  //@ts-ignore
  const handleOnError = (err, file, inputElem, reason) => {
    log(err);
    notify({
      title: reason,
      type: ENotifyType.Error,
    });
    _resetData();
  };
  //@ts-ignore
  const handleOnRemoveFile = (data) => {
    log("--------------------------- on file remove");
    _resetData();
    log("---------------------------");
  };

  // upload employees to server
  const _addEmployeesInBulk = async (empList: IEmployeeBase[]) => {
    let message: string = "Something went wrong";
    let errorType = ENotifyType.Error;
    setLoading(true);
    try {
      const response = await EmployeesApi.BulkAddEmployees(empList);
      if (response.hasError) {
        message = handleApiError(response, "bulk-emp-add");
      } else {
        log("Add Employees Bulk SUCCESS: ", response);
        message = `Employees added!`;
        errorType = ENotifyType.Success;

        setEmployees(response.data);

        let logs: ILog[] = [];
        let type: TLogType = "error";

        // list of errors. In case employee already exists.
        response.data.map((res: any) => {
          if (typeof res === "string") {
            type = "error";
            message = res;
          } else {
            type = "info";
            message = res.fullName + " added successfully";
          }

          logs.push({
            type,
            message,
          });

          return res;
        });

        // if (logs.length) {
        //   errorType = ENotifyType.Info;
        //   message = "Some of the employees already exist.";
        // }

        setLogList(logs);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);

    // notify({
    //   title: message,
    //   type: errorType,
    // });

    onFinish(); // fetch employees
  };

  const _handleImport = () => {
    let newEmpList: IEmployeeBase[] = [];

    if (csvData && csvData.length) {
      const header = csvData[0].data;

      // TODO: if numOfCols is 5 use name as index [1] otherwise 0
      let nameIndex = header.findIndex((c) => c.includes("name"));
      let titleIndex = header.findIndex((c) => c.includes("title"));
      let mobileIndex = header.findIndex(
        (c) => c.includes("mobile") || c.includes("phone")
      );
      let emailIndex = header.findIndex((c) => c.includes("email"));

      if (
        nameIndex < 0 ||
        // titleIndex < 0 ||
        mobileIndex < 0
        // emailIndex < 0
      ) {
        // if any of these index not found throw error

        let initialIndex = 0;
        if (header.length < 4) {
          alert("Please make sure you have name, and mobile in your CSV file.");

          return;
        } else if (header.length === 4) {
          // if there are only 4 columns they will be selected as name,title,mobile,email.
          initialIndex = 0;
        } else if (header.length > 4) {
          // if columns are greater than 4, it is assumed first column to be id, hence next 4 will be selected.
          initialIndex = 1;
        }

        nameIndex = initialIndex++;
        titleIndex = initialIndex++;
        mobileIndex = initialIndex++;
        emailIndex = initialIndex++;
      }

      csvData.map((row, i) => {
        if (i === 0) {
        } else if (row.data) {
          const empArr = row.data;

          if (!empArr[nameIndex]) {
            return row;
          }

          try {
            const newEmp: IEmployeeBase = {
              fullName: empArr[nameIndex],
              title: empArr[titleIndex] || "",
              phone: empArr[mobileIndex],
              email: empArr[emailIndex] || "",
              role: EEmployeeRole.Noob
            };
            newEmpList.push(newEmp);
          } catch (error) {}
        }
        return row;
      });

      _addEmployeesInBulk(newEmpList);
      setEmployees(newEmpList);
    }
  };

  useEffect(() => {
    _handleImport();
  }, [csvData]);

  //@ts-ignore
  const handleOnDrop = (rowsData: ParseResult<any>[]) => {
    log("--------------------------- on file drop");
    log(rowsData);
    log("---------------------------");

    if (rowsData && rowsData.length) {
      setCsvData(rowsData);
    }
  };

  return (
    <div>
      <CSVReader
        onDrop={handleOnDrop}
        onError={handleOnError}
        addRemoveButton
        onRemoveFile={handleOnRemoveFile}
      >
        <span>Drop CSV file here or click to Import Employees.</span>
        <span>CSV header should contain name*, title, mobile* and email.</span>
        {/* <span>* required.</span> */}
      </CSVReader>

      <p style={{ marginTop: 20 }}>
        {employees.length ? (
          <span>
            Employees in CSV: <b>{employees.length}</b>
          </span>
        ) : null}
      </p>
      <div style={{ height: logList.length ? 300 : 0, overflow: "scroll" }}>
        {logList.map((err) => (
          <p style={{ margin: 0 }}>
            <span style={{ color: err.type === "error" ? "red" : "" }}>
              {err.message}
            </span>
          </p>
        ))}
      </div>

      {loading && <Loader />}
    </div>
  );
};
