/*
 * File: edit-company.component.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 28th December 2020 7:49:16 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 21st January 2022 1:09:29 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import _ from "lodash";
import { ErrorMessage } from "@hookform/error-message";
import Compressor from "compressorjs";
import { LoaderComponent } from "../../../../Components/common/loader.component";
import { AuthApi } from "../../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { latLngToString, log, _cloneDeep } from "../../../../Lib/helpers";
import { toastify } from "../../../../Services/toast.service";
import {
  defaultAddress,
  IAddress,
  IBranch,
} from "../../../../Services/Interfaces/CompanyInterface";
import { ApiResponse } from "../../../../Services/api-client";
import { EUserType } from "../../../../Services/Interfaces/AuthInterface";
import { AppContext } from "../../../../Services/AppContext";
import { GoogleMap } from "../../../../Components/GoogleMap";
import { Config } from "../../../../Config";
import { AppColors } from "../../../../Themes/AppColors";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
// import Button from "../../../Components/common/ButtonComponent/ButtonComponent";
import Button from "../../../../Components/common/ButtonComponent/ButtonComponent";

const schema = yup.object().shape({
  name: yup.string().required(),
  // address: yup.string().required(),
});
const useStyles = makeStyles((theme: Theme) => ({
  formText: {
    fontFamily: "proxima_novaregular",
  },
  companyName: {
    border: "1px solid gray",
    borderRadius: "4px",
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "0.8rem 2.5rem",
    borderRadius: "7px",
    // marginRight: "10px",
    float: "right",
    marginBottom: "40px",
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  Locations: {
    fontFamily: "proxima_novabold",
    fontSize: "17px",
    marginBottom: "12px",
    color: "#666",
  },
  company: {
    fontFamily: "proxima_novabold",
    fontSize: "17px",
  },
}));

export const EditCompanyComponent = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [tempImg, setImage] = useState<any>(null);
  const [companyImage, setCompanyImage] = useState<any>(null);

  const { user, updateUser } = useContext(AppContext);

  const [company, setCompany] = useState<IBranch>({
    addresses: [defaultAddress],
    name: "",
  });
  const [companyAddrList, setCompanyAddrList] = useState<IAddress[]>([
    defaultAddress,
  ]);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      address: "",
      city: "",
      state: "",
      img: "",
    },
  });

  useEffect(() => {
    setImage(null);
    _fetchOrUpdate();
    // getRequest(endpoints.company.get()).then((response: AxiosResponse<any>) => {
    //     setCompany(response.data)
    //     Object.keys(response.data).forEach((key:string) => setValue(key, response.data[key]))
    // })
  }, []);

  const selectImage = (e: any) => {
    const [first] = e.target.files;
    setCompanyImage(first);
    setImage(URL.createObjectURL(first));
  };

  const _uploadImage = async (type: "logo" | "dyme" = "logo") => {
    setLoading(true);

    let data = new FormData();

    if (companyImage) {
      // compress image before upload
      const result: any = await new Promise((resolve, reject) => {
        new Compressor(companyImage, {
          quality: 0.6,
          success: resolve,
          error: reject,
        });
      });

      data.append(type, result, result.name);

      try {
        const response = await AuthApi.UploadImage(data);
        if (response.hasError) {
          handleApiError(response, "_upload image company profile: ");
        } else {
          // ? Get User success
          log("Upload Company Logo SUCCESS: ", response);

          toastify("success", "Company Profile and Logo updated successfully");

          setCompanyImage(null);

          setCompany(response.data);
        }
      } catch (e) {
        handleCatchError(e);
      }
    }
    setLoading(false);
  };

  const _fetchOrUpdate = async (newData: any = null) => {
    setLoading(true);
    let uploading = false;
    try {
      let response: ApiResponse;

      if (newData) {
        response = await AuthApi.UpdateCompanyProfile({
          ...newData,
          addresses: companyAddrList,
          // address: {
          //   address: newData.address,
          //   state: newData.state,
          //   city: newData.city,
          // },
        });
      } else {
        response = await AuthApi.GetCompanyProfile();
      }
      if (response.hasError) {
        handleApiError(response, "_fetchOrUpdate company profile: ");
      } else {
        // ? Get / Update Company success
        log("Get / Update Company Branch SUCCESS: ", response);

        const res: IBranch = response.data;
        const { name, addresses, logo } = res;
        // setting new address values.
        reset({
          name,
          // address: address.address,
          // state: address.state,
          // city: address.city,
        });

        setCompanyAddrList(addresses);

        // if (addresses && addresses.length) {
        //   const addr = addresses[0] // TODO : USE SELECTED ADDRESS
        //   setCompanyAddr({
        //     address: addr.address,
        //     city: addr.city,
        //     latitude: addr.latitude,
        //     longitude: addr.longitude,
        //     state: addr.state,
        //     country: addr.country,
        //   });
        // }

        // IF USER IS NOOB MAKE THEM ADMIN
        if (user?.user.role === EUserType.Noob) {
          updateUser({
            ...user,
            user: {
              ...user.user,
              role: EUserType.Admin,
            },
          });
        }

        if (!_.isEmpty(newData)) {
          if (companyImage) {
            uploading = true;
            _uploadImage();
          } else {
            toastify("success", "Company Profile updated successfully");
          }
        }
        setCompany(res);
      }
    } catch (e) {
      handleCatchError(e);
    }
    if (!uploading) {
      setLoading(false);
    }
  };

  const submit = (v: any) => {
    _fetchOrUpdate(v);
  };

  const _addNewAddress = () => {
    const addrList = _cloneDeep(companyAddrList);

    addrList.push(defaultAddress);

    setCompanyAddrList(addrList);
  };

  const _deleteLocation = (i: number) => {
    const addrList = _cloneDeep(companyAddrList);

    addrList.filter((addr) => addr);

    if (addrList[i]) {
      addrList.splice(i, 1);

      setCompanyAddrList(addrList);
    }
  };

  const _updateAddress = (newAddr: IAddress, index: number) => {
    /// check if already exits.

    if (companyAddrList[index]) {
      const addrList = _cloneDeep(companyAddrList);

      newAddr.latitude = Number(newAddr.latitude).toFixed(7);
      newAddr.longitude = Number(newAddr.longitude).toFixed(7);

      addrList[index] = newAddr;

      setCompanyAddrList(addrList);
    }
  };

  return (
    <div className="rt-profilebox rt-updateprofile">
      <div className="rt-profileboxhead">
        <h3>Update Company Profile</h3>
      </div>
      <div className="rt-formtheme rt-formprofile">
        <form>
          <fieldset>
            <label className={classes.company}>Company Name</label>
            <div className="form-group">
              <i className="icon-user1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="text"
                  {...register("name")}
                  className="form-control"
                  // placeholder="Google Inc."
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="name" />
                </span>
              </div>
            </div>
            {/* <Typography className={classes.formText}>Company Name</Typography> 
                <TextField
                  variant="outlined"
                  className={`${classes.companyName} mt-2`}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: false,
                  }}
                /> */}
            {loading ? (
              <LoaderComponent />
            ) : (
              <>
                <div>
                  <Typography className={`${classes.Locations} w-100`}>
                    Locations
                  </Typography>
                </div>
                {companyAddrList?.map((addr, i) => (
                  <div key={i}>
                    <label className={classes.formText}>Location {i + 1}</label>
                    <div className="d-flex">
                      <GoogleMap
                        addr={addr.address}
                        coords={latLngToString(addr.latitude, addr.longitude)}
                        useUserLocation={false}
                        onNewAddr={_updateAddress}
                        id={i}
                      />

                      <i
                        onClick={() => _deleteLocation(i)}
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontWeight: "bold",
                          marginTop: "17px",
                          marginLeft: "3px",
                        }}
                      >
                        x
                      </i>
                    </div>
                  </div>
                ))}
              </>
            )}

            <div className="rt-changeprofileimage">
              <figure className="rt-userprofileimg">
                <img
                  src={tempImg ? tempImg : company?.logo}
                  alt="Company Logo"
                />
              </figure>
              <span className="rt-changeimage">
                <input
                  type="file"
                  name="company-pic"
                  id="rt-companyimg"
                  onChange={selectImage}
                  accept="image/x-png,image/gif,image/jpeg"
                />
                <label htmlFor="rt-companyimg">Change Logo</label>
              </span>
            </div>

            {/* <div className="rt-updatebtnholder">
              <button type="button" onClick={_addNewAddress} disabled={loading}>
                Add Location
              </button>
            </div> */}
            <div className="d-flex justify-content-between">
              <Button
                className={`${classes.bottomBtn} ${classes.bgBlue}`}
                onClick={handleSubmit(submit)}
                disabled={loading}
                type="button"
              >
                Save
                {loading && <LoaderComponent />}
              </Button>
              <Button
                className={`${classes.bottomBtn} ${classes.bgBlue}`}
                onClick={_addNewAddress}
                disabled={loading}
              >
                Add Location
              </Button>
            </div>
          </fieldset>
        </form>
        {/* <div className="rt-updatebtnholder">
          <button
            type="button"
            onClick={handleSubmit(submit)}
            className="rt-btn"
            disabled={loading}
          >
            Save
            {loading && <LoaderComponent />}
          </button>
        </div> */}
      </div>
    </div>
  );
};
