/*
 * File: TemplateApi.ts
 * Project: JobApp-Admin
 * File Created: Friday, 28th May 2021 11:21 am
 * Author: Rahul (rahul.excel2011@gmail.com)
 * -----
 * Last Modified: Tuesday, 13th July 2021 3:15:16 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { log } from "../../Lib/helpers";
import { ApiPost, ApiGet, ApiPatch, ApiPut, ApiDelete } from "../api-client";

import { ITemplateRequest ,ISms,ITemplate, ISmsEmps} from "../Interfaces/TemplateInterface";

/**
 * Create a Template
 */
const CreateTemplate = async (template: ITemplate) => {
  const data = await ApiPost(`sms/template/create`, template);

  log("☁️ TEMPLATE CREATE RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Template
 */
const GetTemplate = async (branchId?:string|number) => {
  let url=branchId?`sms/template/get/?branchId=${branchId}`:`sms/template/get`
  const data = await ApiGet(url);

  log("☁️ TEMPLATE GET RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Edit Template
 */
const EditTemplate = async (template: ITemplate) => {
  
  
  const data = await ApiPatch(`sms/template/edit`, template);

  log("☁️ TEMPLATE EDIT RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};


/**
 * Delete Template
 */
const TemplateDelete = async (id: number | string,branchId?:string|number) => {
  const url =branchId?`sms/template/delete/${id}?${branchId}`:`sms/template/delete/${id}`
  const data = await ApiDelete(`${url}`);

  log("☁️ TEMPLATE DELETE RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};
/**
 * Send SMS
 */
const SendSms = async (smsData:ISms) => {
    const data = await ApiPost(`sms/send`,smsData);
  
    log("☁️ SEND SMS RESPONSE: ", smsData);
  
    if (data.ok) {
    }
  
    return data;
  };


/**
 * Send SMS to Employees
 */
const SendSmsToEmp = async (smsData:ISmsEmps) => {
    const data = await ApiPost(`sms/emp-invite`, smsData);
  
    log("☁️ SEND SMS TO Employee RESPONSE: ", smsData);
  
    if (data.ok) {
    }
  
    return data;
  };


const SendEmailToEmp = async (emailData:ISmsEmps) => {

  const data = await ApiPost(`email/emp-invite`, emailData);

  log("☁️ SEND EMAIL TO Employee RESPONSE: ", emailData);

  if (data.ok) {
  }

  return data;

}

export const TemplateApi = {
    CreateTemplate,
    GetTemplate,
    EditTemplate,
    TemplateDelete,
    SendSms,
    SendSmsToEmp,
    SendEmailToEmp

  };
  

