/*
 * File: CompaniesListPage.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 11th March 2020 1:46:23 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { BaseAuth } from "../../../Components/BaseAuth";
import { PageHeader } from "../../../Components/PageHeader";
import MaterialTable from "material-table";
import { colors, Chip, Typography } from "@mui/material";
import { ICompany } from "../../../Services/Interfaces/CompanyInterface";
import { ENotifyType } from "../../../Components/Notify";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log, _cloneDeep } from "../../../Lib/helpers";
import { CompanyApi } from "../../../Services/API/CompanyApi";
import { AppContext } from "../../../Services/AppContext";
import { BranchTable } from "../Branches/BranchTable";

export const companyLinks = [
  {
    title: "Companies",
    route: RouteKeys.Companies,
  },
  {
    title: "Add Company",
    route: RouteKeys.CompanyCreate,
  },
  {
    title: "Branches",
    route: RouteKeys.Branches,
  },
  // {
  //   title: "Add Branch",
  //   route: RouteKeys.BranchCreate,
  // },
];

const defaultInfo = {
  address: {
    address: "",
    city: "",
    latitude: "",
    longitude: "",
  },
  isActive: true,
  description: "",
  logo: "",
};

export const CompaniesListPage: React.FC = () => {
  const history = useHistory();

  const { notify } = useContext(AppContext);

  const [companies, setCompanies] = useState<ICompany[]>([
    // {
    //   name: "DatumSquare IT Services",
    //   ...defaultInfo
    // },
    // {
    //   name: "Allshore Staffing",
    //   ...defaultInfo
    // },
  ]);
  const [loading, setLoading] = useState(false);

  const _navigate = (route: string, data?: any) => {
    log('Data to pass', data)
    history.push({ pathname: route, state: data });
  };

  useEffect(() => {
    __fetchCompanies();
  }, []);

  const __fetchCompanies = async () => {
    setLoading(true);

    const response = await CompanyApi.GetCompanies();

    let message: string = "Something went wrong";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        message = handleApiError(response, "Companies List: ");
      } else {
        log("Company List: ", response);

        errorType = ENotifyType.Success;
        message = "Companies Fetched";

        setCompanies(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    if (errorType === ENotifyType.Error) {
      notify({
        title: message,
        type: errorType,
      });
    }

    setLoading(false);
  };

  const _onDelete = async (id: string | number) => {
    setLoading(true);

    const response = await CompanyApi.DeleteCompany(id);

    let message: string = "Something went wrong";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        message = handleApiError(response, "Companies Delete Error: ");
      } else {
        __fetchCompanies();
        errorType = ENotifyType.Success
      }
    } catch (e) {
      handleCatchError(e);
    }

    if (errorType === ENotifyType.Error) {
      notify({
        title: message,
        type: errorType,
      });
    }

    setLoading(false);
  };

  return (
    <BaseAuth>
      <PageHeader
        heading="Companies"
        btnTitle="Add Company"
        onBtnPress={() => _navigate(RouteKeys.CompanyCreate)}
        links={companyLinks}
      />

      <MaterialTable
        isLoading={loading}
        columns={[
          { title: "ID", field: "id" },
          { title: "Name", field: "name" },
          {
            title: "Status",
            field: "status",
            render: (rowData) => {
              const { isActive } = rowData;
              let backgroundColor: string = colors.grey[500];
              switch (isActive) {
                case false:
                  break;
                case true:
                  backgroundColor = colors.green[500];
                  break;
              }
              return (
                <Chip
                  style={{
                    backgroundColor,
                    color: colors.grey[50],
                    fontWeight: "bold",
                  }}
                  label={isActive ? "Enabled" : "Disabled"}
                />
              );
            },
          },
          {
            title: "Branches",
            field: "branches",
            render: (rowData) => {
              const {branches} = rowData
              return (
                <Typography>{branches?.length}</Typography>
              )
            }
          }
        ]}
        data={companies}
        title="Companies List"
        actions={[
          {
            icon: "add",
            tooltip: "Add Branch",
            onClick: (event, rowData: any) =>
              _navigate(`${RouteKeys.BranchCreate}/${rowData.id}`),
          },
          {
            icon: "edit",
            tooltip: "Edit Company",
            onClick: (event, rowData: any) => {
              // return false;
              let companyData:ICompany = _cloneDeep(rowData)
              delete companyData.branches;
              // @ts-ignore
              delete companyData.tableData;
              log('row data on edit is: ', companyData)
              
              _navigate(`${RouteKeys.Companies}/${rowData.id}`, {
                company: companyData,
              })
            }
          },
          {
            icon: "delete",
            tooltip: "Delete Company",
            onClick: (event, rowData: any) => {
              _onDelete(rowData.id);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
        detailPanel={(rowData) =>
          rowData.branches?.length ? (
            <BranchTable onDelete={__fetchCompanies} branches={rowData.branches} nested />
          ) : <Typography style={{paddingLeft: 30}} variant="subtitle2">No Branches Yet</Typography>
        }
      />
    </BaseAuth>
  );
};
