/*
 * File: Plaid.tsx
 * Project: Dyme-Web
 * File Created: Thursday, 9th September 2021 10:41:04 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 7th October 2021 1:52:36 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useCallback, useState, FunctionComponent, useContext } from "react";
import {
  usePlaidLink,
  PlaidLinkOptions,
  PlaidLinkOnSuccess,
} from "react-plaid-link";
import { log } from "../../../../Lib/helpers";

import { create } from "apisauce";
import { PaymentApi } from "../../../../Services/API/PaymentApi";
import { AppContext } from "../../../../Services/AppContext";
import { ENotifyType } from "../../../../Components/Notify";
import { makeStyles, Theme } from "@mui/material";

const baseURL = "";
// define the api
const api = create({
  baseURL,
  headers: { Accept: "application/vnd.github.v3+json" },
});

interface Props {
  token: string;
}


const styles = {
  
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  bottomBtn: {
    color: "#fff",
    // textTransform: ,
    padding: "0.8rem ",
    borderRadius: "10px",
    marginRight: "10px",
    width: "100%",
    marginBottom: "20px",
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  
};

interface IPlaidLink {
  token: string;
  afterComplete: () => void;
}
const PlaidLink = ({ token, afterComplete }: IPlaidLink) => {

  

  const { notify } = useContext(AppContext);


  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (public_token, metadata) => {
      // send public_token to server

      //user_good and pass_good test plaid credentials

      try {
        const accountId = metadata.accounts[0].id;

        const res = await PaymentApi.SaveUserBank(
          accountId,
          public_token
        );

        log("Plaid saved: ", res.data);

        if (res.ok) {
          notify({
            title: "Bank account connected",
            type: ENotifyType.Success
          })
          afterComplete()
        } else {
          notify({
            title: res.data.message,
            type: ENotifyType.Error
          })
        }

        // send this account id and public token to server.
      } catch (error) {
        log("plaid save error: ", error);
        
        notify({
          title: "Something went wrong while trying to connect with bank.",
          type: ENotifyType.Error
        })
      }

      log("PLAID SUCCESS", public_token, metadata);
    },
    []
  );

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready, error } = usePlaidLink(config);

  return (
    <div className="mb-2 mt-4">
    <button onClick={() => open()} disabled={!ready} style={styles.bottomBtn}>
      Connect a bank account
    </button>
    </div>
  );
};

const App = (props:any) => {
  const [token, setToken] = useState<string | null>(null);

  // generate a link_token
  React.useEffect(() => {
    async function createLinkToken() {
      try {
        let response = await PaymentApi.GetPlaidToken();

        log("GET PLAID TOKEN RESPONSE: ", response.data);
        const { link_token } = response.data;

        setToken(link_token);
      } catch (error) {
        //@ts-ignore
        log("TOKEN ERROR: ", error?.message);
      }
    }
    createLinkToken();
  }, []);

  // only initialize Link once our token exists
  return token === null ? (
    // insert your loading animation here
    <div className="loader">Loading...</div>
  ) : (
    <PlaidLink token={token} afterComplete={props.afterComplete} />
  );
};



export default App;
