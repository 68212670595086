/*
 * File: index.ts
 * Project: dyme-web
 * File Created: Friday, 31st January 2020 12:19:19 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 11th January 2022 1:45:10 am
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */

import packageInfo from '../../package.json';
import { RouteKeys } from '../Routes/RouteKeys';

// import { version, apiLink, appLink } = packageInfo;


const PRIVACY_LINK = 'http://mydyme.io/privacy-policy/';
const TERMS_LINK = 'http://mydyme.io/terms-of-service/'
const APP_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.buddyrise';
const APP_LINK_IOS = 'https://apps.apple.com/us/app/dyme-jobs-and-rewards/id1526557944';

const LIVE_API = "https://api.mydyme.io/";
// const TESTING_API = "http://176.9.137.77:3045";
const TESTING_API = "https://apitest.mydyme.io/";
const LOCAL_API = "http://localhost:3002";

const SELECTED_API = process.env.REACT_APP_API_URL || TESTING_API;

const IS_PRODUCTION = process.env.NODE_ENV === "production";

// ! PLUM_API_URL is not in use
const PLUM_API_URL=process.env.REACT_APP_PLUM_PRO_API||'https://stagingaccount.xoxoday.com/chef/'

const lat = 39.419220;
const lng = -111.950684;
export let Config = {

  APP_VERSION: `v${packageInfo.version} | API Support: ${packageInfo.apiLink} | APP Support: ${packageInfo.appLink} | ${!IS_PRODUCTION ? "(LOCAL)" : ""}`,
  SYSTEM_VERSION: `Dyme Jobs and Rewards 2022 [v${packageInfo.version}]`,

  API_URL: SELECTED_API, // !API,

  DEMO_EMAIL: IS_PRODUCTION ? "" : "umardatum@gmail.com",
  DEMO_PASSWORD: IS_PRODUCTION ? "" : "@Password1",

  FIRST_ROUTE_LOGIN: RouteKeys.AdminDashboard,
  FIRST_ROUTE: RouteKeys.Login,

  IS_PRODUCTION,

  LOGOUT_AFTER_MINUTES: 480,//8 hrs

  // add google api key in index.html
  GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY || '',

  DEFAULT_COORDINATES: {
    lat,
    lng
  },

  DEFAULT_COORDS_STR: `${lat},${lng}`,
  PLUM_API_URL,
  PRIVACY_LINK,
  TERMS_LINK,
  APP_LINK_ANDROID,
  APP_LINK_IOS,
}