/*
 * File: BranchEditPage.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 13th July 2020 7:25:08 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 1:28:43 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { BaseAuth } from "../../../Components/BaseAuth";
import { PageHeader } from "../../../Components/PageHeader";
import Loader from "../../../Components/Loader";
import { BranchForm } from "./BranchForm";
import { log } from "../../../Lib/helpers";
import { IBranch } from "../../../Services/Interfaces/CompanyInterface";
import { BranchApi } from "../../../Services/API/BranchApi";
import { ENotifyType } from "../../../Components/Notify";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { AppContext } from "../../../Services/AppContext";
import { companyLinks } from "../Companies/CompaniesListPage";

interface LocationState {
  branch?: IBranch;
}

export const BranchEditPage = () => {
  const history = useHistory();
  const { notify } = useContext(AppContext);

  //@ts-ignore
  const { id } = useParams();

  // const [branch, setBranch] = useState({id})

  const location = useLocation<LocationState>();
  const [loading, setLoading] = useState(false);
  const [branch, setBranch] = useState(location.state?.branch);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  useEffect(() => {
    log("got branch: ", branch);
    _getBranch();
  }, []);

  const _getBranch = async () => {
    if (!id) {
      return false;
    }
    setLoading(true);

    const response = await BranchApi.GetBranch(id);

    let message: string = "Something went wrong";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        message = handleApiError(response, "Branch Get: ");
      } else {
        log("Branch Data: ", response);

        errorType = ENotifyType.Success;
        message = "Branch Fetched";

        setBranch(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    notify({
      title: message,
      type: errorType,
    });

    setLoading(false);
  };

  const _editBranch = () => {};

  return (
    <BaseAuth>
      <PageHeader heading="Edit Branch" links={companyLinks} />
      {loading && branch ? (
        <Loader />
      ) : (
        <BranchForm branch={branch} onSubmit={_editBranch} />
      )}
    </BaseAuth>
  );
};
