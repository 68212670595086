/*
 * File: index.tsx
 * Project: jobapp-admin
 * File Created: Friday, 31st January 2020 12:25:41 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Sun Feb 13 2022
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";
import { RouteKeys } from "./RouteKeys";
import { AppContext } from "../Services/AppContext";
import { log } from "../Lib/helpers";
import { NoMatch } from "../Pages/Home/NoMatch";
import { Config } from "../Config";
import { ChangePassPage } from "../Pages/Admin/Settings/ChangePassPage";
import { CompanyProfilePage } from "../Pages/Admin/Settings/CompanyProfilePage";
import { CompaniesListPage } from "../Pages/Admin/Companies/CompaniesListPage";
import { CompanyEditPage } from "../Pages/Admin/Companies/CompanyEditPage";
import { CompanyAddPage } from "../Pages/Admin/Companies/CompanyAddPage";
import { BranchesListPage } from "../Pages/Admin/Branches/BranchesListPage";
import { BranchAddPage } from "../Pages/Admin/Branches/BranchAddPage";
import { BranchEditPage } from "../Pages/Admin/Branches/BranchEditPage";
import { CategoryListPage } from "../Pages/Admin/Categories/CategoryListPage";
import AuthPage from "../Pages/Auth/AuthPage/AuthPage";
import ReferPage from "../Pages/Auth/AuthPage/ReferPage";
import ForgotPasswordPage from "../Pages/Auth/ForgotPasswordPage/ForgotPasswordPage";
import DashboardPage from "../Pages/Admin/DashboardPage";
import { SideNav } from "./admin-menu/SideNav";
import JobsListPage from "../Pages/Admin/Jobs/JobsListPage/JobsListPage";
import NewJobPage from "../Pages/Admin/Jobs/NewJobPage/NewJobPage";
import { EUserType } from "../Services/Interfaces/AuthInterface";
import ApplicantsListPage from "../Pages/Admin/Applicants/ApplicantsListPage";
import EditProfilePage from "../Pages/Admin/Settings/EditProfilePage/EditProfilePage";
import EmployeesListPage from "../Pages/Admin/Employees/EmployeesListPage";
import ReferralProgram from "../Pages/Admin/ReferralProgram/ReferralProgram";
import EmployeesPage from "../Pages/Admin/ReferralProgram/Employees";
import RewardsGiven from "../Pages/Admin/ReferralProgram/RewardsGiven";
import ChatPage from "../Pages/Admin/Chat/ChatPage";
import Plum from "../Pages/Plum/Plum";
import MobileApps from "../Pages/MobileApps/MobileApps";
import BillingPage from "../Pages/Admin/Settings/EditProfilePage/BillingPage";
import { ReactHooksDemo } from "../Pages/Admin/ReactDemo/ReactHooksDemo";
interface IAppRoute extends RouteProps {
  type: "normal" | "auth" | "no_auth" | "superAdmin" | "admin";
}
/**
 * Modified Router
 * @param type - Type
 * normal: can be accessed by both logged in and logged out users
 * auth: only logged in users can access this path
 * no_auth: only logged out users can access this path
 */
export const GuRoute: React.FC<IAppRoute> = ({ children, ...rest }) => {
  const { user, logoutIfTimeout } = useContext(AppContext);

  log("USER IN ROUTE: ", user);

  let pathname = RouteKeys.Home; // redirect to this route if condition fails
  let allow = false;

  //?: CHECK USER TOKEN EXPIRATION.
  logoutIfTimeout();

  switch (rest.type) {
    case "auth":
      pathname = RouteKeys.SignIn;
      allow = !!user;
      break;
    case "superAdmin":
      pathname = RouteKeys.SignIn;
      allow = user && user.user.role === EUserType.SuperAdmin ? true : false;
      break;
    case "admin":
      pathname = RouteKeys.SignIn;
      allow = user && user.user.role === EUserType.Admin ? true : false;
      break;
    case "no_auth": // when user is logged in
      pathname = Config.FIRST_ROUTE_LOGIN;
      allow = !user;
      break;
    case "normal":
      pathname = Config.FIRST_ROUTE;
      allow = true;
      break;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        allow ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              // state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default function Routes() {
  const { user } = useContext(AppContext);
  return (
    <BrowserRouter>
      {user && <SideNav />}
      <Switch>
        {/* AUTH ROUTES */}
        <GuRoute type="no_auth" path={RouteKeys.SignIn} exact>
          <AuthPage />
        </GuRoute>
        <GuRoute type="no_auth" path={RouteKeys.Home} exact>
          <AuthPage />
          {/* <Home /> */}
        </GuRoute>
        <GuRoute type="no_auth" path={RouteKeys.SignUp} exact>
          <AuthPage />
        </GuRoute>
        <GuRoute type="no_auth" path={RouteKeys.Forgot} exact>
          <ForgotPasswordPage />
        </GuRoute>

        {/* Employee Refer Friend */}
        <GuRoute
          type="no_auth"
          path={`${RouteKeys.ReferFriend}/:id/:jobId`}
          exact
        >
          <ReferPage refer="friend" />
        </GuRoute>
        <GuRoute type="no_auth" path={`${RouteKeys.ReferFriend}/:id/`} exact>
          <ReferPage refer="friend" />
        </GuRoute>
        <GuRoute type="no_auth" path={`${RouteKeys.ReferFriend}`} exact>
          <ReferPage refer="friend" />
        </GuRoute>

        {/* Business Refer Employee */}
        <GuRoute
          type="no_auth"
          path={`${RouteKeys.ReferEmployee}/:employeeId`}
          exact
        >
          <ReferPage refer="employee" />
        </GuRoute>

        {/* User Refer Business */}
        <GuRoute type="no_auth" path={`${RouteKeys.ReferBusiness}/:id`} exact>
          <ReferPage refer="business" />
        </GuRoute>
        <GuRoute type="no_auth" path={`${RouteKeys.ReferBusiness}`} exact>
          <ReferPage refer="business" />
        </GuRoute>

        <Route
          path={RouteKeys.ValidatePass}
          exact
          component={ForgotPasswordPage}
        />

        <GuRoute
          type="normal"
          path={RouteKeys.MobileApps}
          exact
          component={MobileApps}
        />

        <GuRoute type="auth" path={RouteKeys.AdminDashboard} exact>
          <DashboardPage />
        </GuRoute>
        <GuRoute type="auth" path={RouteKeys.Dashboard} exact>
          <DashboardPage />
        </GuRoute>

        {/* JOBS SECTION */}
        <GuRoute type="auth" path={RouteKeys.JobsList} exact>
          <JobsListPage />
        </GuRoute>
        <GuRoute type="auth" path={RouteKeys.AddJob} exact>
          <NewJobPage />
        </GuRoute>
        <GuRoute type="auth" path={`${RouteKeys.EditJob}/:id`} exact>
          <NewJobPage />
        </GuRoute>

        {/* CATEGORIES SECTION */}
        <GuRoute type="superAdmin" path={RouteKeys.CategoryList} exact>
          <CategoryListPage />
        </GuRoute>
        {/* <GuRoute type="auth" path={RouteKeys.AddJob} exact>
          <JobAddPage />
        </GuRoute>
        <GuRoute type="auth" path={`${RouteKeys.EditJob}/:id`} exact>
          <JobEditPage />
        </GuRoute> */}

        {/* APPLICATIONS SECTION */}
        <GuRoute type="auth" path={RouteKeys.ApplicantsList} exact>
          <ApplicantsListPage />
        </GuRoute>

        {/* EMPLOYEES SECTION */}
        <GuRoute type="auth" path={RouteKeys.Employees} exact>
          <EmployeesListPage />
        </GuRoute>
        {/* REFERRAL PROGRAM */}
        <GuRoute type="auth" path={RouteKeys.ReferralProgram} exact>
          <ReferralProgram />
        </GuRoute>
        <GuRoute type="auth" path={RouteKeys.EmployeesPageNew} exact>
          <EmployeesPage />
        </GuRoute>

        <GuRoute type="auth" path={RouteKeys.RewardsGiven} exact>
          <RewardsGiven />
        </GuRoute>
        {/* ! not in use anymore due to plum API update */}
        {/* <GuRoute type="auth" path={RouteKeys.Plum}>
          <Plum />
        </GuRoute> */}

        {/* USER SETTINGS */}
        <GuRoute type="auth" path={RouteKeys.AdminSettings} exact>
          <EditProfilePage />
        </GuRoute>
        <GuRoute
          type="auth"
          path={`${RouteKeys.AdminSettings}/:firstPage`}
          exact
        >
          <EditProfilePage />
        </GuRoute>

        {/* COMPANY SETTINGS */}
        <GuRoute type="auth" path={RouteKeys.AdminCompany} exact>
          <BillingPage />
        </GuRoute>
        <GuRoute
          type="auth"
          path={`${RouteKeys.AdminCompany}/:firstPage`}
          exact
        >
          <BillingPage />
        </GuRoute>

        <GuRoute type="auth" path={RouteKeys.AdminSettings + "/old"} exact>
          <CompanyProfilePage />
        </GuRoute>
        <GuRoute type="auth" path={RouteKeys.ChangePass} exact>
          <ChangePassPage />
        </GuRoute>
        <GuRoute type="auth" path={RouteKeys.UpdateCompanyProfile} exact>
          <CompanyProfilePage />
        </GuRoute>

        {/* Chat Page */}
        <GuRoute type="auth" path={RouteKeys.ChatPage} exact>
          <ChatPage />
        </GuRoute>

        {/* Call Centers / Companies / Businesses */}
        <GuRoute type="superAdmin" path={RouteKeys.Companies} exact>
          <CompaniesListPage />
        </GuRoute>
        <GuRoute type="superAdmin" path={RouteKeys.CompanyCreate} exact>
          <CompanyAddPage />
        </GuRoute>
        <GuRoute type="superAdmin" path={`${RouteKeys.Companies}/:id`} exact>
          <CompanyEditPage />
        </GuRoute>

        {/* Call Center Branches */}
        <GuRoute type="superAdmin" path={RouteKeys.Branches} exact>
          <BranchesListPage />
        </GuRoute>
        <GuRoute
          type="superAdmin"
          path={`${RouteKeys.BranchCreate}/:companyId`}
          exact
        >
          <BranchAddPage />
        </GuRoute>
        {/* <GuRoute type="auth" path={`${RouteKeys.BranchCreate}`} exact>
          <BranchAddPage />
        </GuRoute> */}
        <GuRoute type="superAdmin" path={`${RouteKeys.Branches}/:id`} exact>
          <BranchEditPage />
        </GuRoute>

        {/* Demo Components */}
        <GuRoute type="auth" path={RouteKeys.ReactHooksDemo} exact>
          <ReactHooksDemo />
        </GuRoute>

        <Route component={NoMatch} />
      </Switch>
      <h6 style={{ marginLeft: 150 }}>{Config.SYSTEM_VERSION}</h6>
    </BrowserRouter>
  );
}
