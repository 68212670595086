/*
 * File: Template.tsx
 * Project: Dyme-Web
 * File Created: Tuesday, 10th August 2021 12:14:25 am
 * Author: Rahul Khera (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 2nd December 2021 1:49:13 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
// ! shit code
import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Typography,
  Grid,
  FormControl,
  Select,
} from "@mui/material";
import MuiDialog from "../../../Components/modals/modalLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TemplateApi } from "../../../Services/API/TemplateApi";
import { IApiResponse } from "../../../Services/api-client";
import { Dropdown } from "react-bootstrap";

import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { toastify } from "../../../Services/toast.service";
import { log, _cloneDeep, compareArrays } from "../../../Lib/helpers";
import MenuItem from "@mui/material/MenuItem";
import {
  ITemplateRequest,
  ITemplate,
  ISmsEmps,
  IEmailEmps,
} from "../../../Services/Interfaces/TemplateInterface";
import { toast } from "react-toastify";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { AppContext } from "../../../Services/AppContext";
import AlertMessage from "./AlertMessage";
import SuccessPopup from "./SuccessPopup";
import { ConfirmModal } from "../../../Components/modals/confirm.modal";
import { IBranch } from "../../../Services/Interfaces/CompanyInterface";

// import { global } from "yargs";

// import FormHelperText from '@mui/material/FormHelperText';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
      borderBottom: "none",
    },
    dialogPaper: {
      width: "100%",
      maxWidth: "700px",
    },
    heading: {
      color: "#0d66fc",
      fontWeight: 500,
      fontFamily: "proxima_nova",
    },
    owner: {
      fontWeight: 500,
      fontFamily: "proxima_nova",
      // marginLeft:"50%",
    },
    ownerName: {
      fontFamily: "proxima_nova",
    },
    forFontfamily: {
      fontFamily: "proxima_nova",
    },
    formControl: {
      fontFamily: "proxima_nova",
      "&>div": {
        width: "100%",
        "&:before": {
          content: "none",
        },
      },
    },
    subjectHeading: {
      color: "#0d66fc",
      fontWeight: 500,
      fontFamily: "proxima_nova",
    },
    underlineRemove: {
      "&>div": {
        "&:before": {
          border: "none",
        },
      },
    },
    inputBase: {
      "&>div": {
        borderRadius: "0",
      },
    },
    bottomBtn: {
      color: "#fff",
      textTransform: "capitalize",
      padding: "0.8rem 2.5rem",
      borderRadius: "10px",
      marginRight: "10px",
      backgroundColor: "blue",
    },
    table: {
      minWidth: 650,
      minHeight: 200,
      border: "1px solid gainsboro",
    },
    headerRow: {
      background: "#eaece9",
      textAlign: "left",
      fontFamily: "proxima_novabold",
    },
    topRow: {
      fontFamily: "proxima_novabold",
      fontSize: "15px",
      padding: "7px",
    },
    tableBodyRow: {
      borderBottom: "1px solid gainsboro",
    },
    tableBodyCell: {
      textAlign: "left",
      padding: "7px",
      color: "black",
      fontSize: "15px",
      fontFamily: "proxima_novaregular",
    },
    actionbtn: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    edit: {
      "& > button": {
        background: "white !important",
        color: "black",
        borderColor: "white",
        "&::after": {
          content: " ",
          borderTop: "0px solid white",
          display: "none",
          marginTop: "20px",
        },
      },
    },
    templatename: {
      cursor: "pointer",
    },
    nameTextField: {
      width: "58%",
    },
  })
);

interface ITemplateBox {
  companyInfo?: IBranch;
  updateTemplate: boolean;
}

const Template = (props: ITemplateBox) => {
  const {
    companyInfo,
    updateTemplate,
  } = props;
  const [templates, setTemplate] = useState<any>({});
  const [allvalues, setAllvalues] = useState<any>([]);
  const { user } = useContext(AppContext);
  const [show, setShowConfirm] = useState(false);
  const [open, setOpenConfirm] = useState(false);
  const [globle, setGloble] = useState(false);
  const [edit, setEdit] = useState<any>({
    name: "",
    medium: "",
    title: "",
    subject: "",
    id: "",
    text: "",
  });
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<any>({
    name: false,
    medium: false,
    title: false,
    subject: false,
    text: false,
  });
  const classes = useStyles();
  const _fetchAllTemplates = async () => {
    try {
      let response: IApiResponse;
      if (companyInfo?.id && user?.user.role === "admin") {
        response = await TemplateApi.GetTemplate(companyInfo.id);
      } else {
        response = await TemplateApi.GetTemplate();
      }
      if (response.hasError) {
        console.log(response, "TEMPLATE List ALL: ");
      } else {
        console.log("Templates List ALL SUCCESS: ", response.data);
        setTemplate(response.data);
      }
    } catch (e) {
      console.log(e, "TEMPLATE List ALL: ");
    }
  };
  useEffect(() => {
    _fetchAllTemplates();
  }, [updateTemplate]);

  useEffect(() => {
    if (templates) {
      _templatefunc();
    }
  }, [templates]);

  const _templatefunc = () => {
    let temp = new Array();
    Object.values(templates).forEach((val: any) => {
      val.forEach((value: any) => {
        temp.push(value);
      });
    });
    setAllvalues(temp);
  };

  const _deleteTem = async (id: any) => {
    if (id) {
      try {
        let response: IApiResponse;
        if (id) {
          response = await TemplateApi.TemplateDelete(id);
        } else {
          response = await TemplateApi.TemplateDelete(id);
        }
        if (response.hasError) {
          handleApiError(response, "TEMPLATE DELETE: ");
        } else {
          log("TEMPLATE DELETE SUCCESS: ", response);
          _fetchAllTemplates();
          // toastify("success", "Template deleted successfully");
          // toast.success("Template deleted successfully", {
          //   position: toast.POSITION.TOP_CENTER
          // });
        }
      } catch (e) {
        handleCatchError(e);
      }
    } else {
      toast.warn("Warning Notification !", {
        position: toast.POSITION.TOP_CENTER,
      });
      // toastify("info", "You do not have permission to delete Template");
    }
  };

  const _editTemplates = (row: any) => {
    setShowConfirm(!show);
    setEdit(row);
  };
  const _deleteTemplate = (row: any) => {
    if (user?.user?.role === "superAdmin" || row.branch !== null) {
      setOpenConfirm(row.id);
    }
  };
  const handleDeleteConfirmation = () => {
    _deleteTem(open);
    setOpenConfirm(false);
  };
  const _editTemplate = async () => {
    if (edit) {
      const payload: ITemplate = {
        id: edit?.id,
        title: edit.title,
        text: edit.text,
        //   edit.checked === "email"
        //     ? edit.email.toString("html")
        //     : edit.message,
        // text: state.textEditorMessage? state.textEditorMessage :state.message,
        name: edit.name,
        subject: edit.subject,
        medium: edit.medium,
      };
      if (companyInfo?.id && user?.user.role === "admin") {
        payload.branchId = companyInfo.id;
      }

      try {
        const response = await TemplateApi.EditTemplate(payload);
        if (response.hasError) {
          handleApiError(response, "TEMPLATE EDIT: ");
        } else {
          log("TEMPLATE EDIT SUCCESS: ", response);
          _fetchAllTemplates();
          setShowConfirm(false);
          setSuccess(true);
          // toastify("success", "Template edit successfully");
          // toast.success("You have Successfully edited template!", {
          //   position: toast.POSITION.TOP_CENTER
          // });
        }
      } catch (e) {
        handleCatchError(e);
      }
    } else {
      toast.warn("Warning Notification !", {
        position: toast.POSITION.TOP_CENTER,
      });

      // toastify("info", "You do not have permission to edit Template");
    }
  };
  const handleClose = () => {
    setGloble(false);
    setShowConfirm(false);
  };
  const handleOpen = (row: any) => {
    setShowConfirm(true);
    setEdit(row);
  };
  const onRequestCloseMessage = () => {
    setShowConfirm(false);
  };


  const handleValidate = () => {
    const errors: any = {};
    let validated = true;
    if (!edit.name) {
      errors["name"] = true;
      validated = false;
    }
    if (!edit.medium || edit.medium === "Add New Group") {
      errors["medium"] = true;
      validated = false;
    }
    if (!edit.title) {
      errors["title"] = true;
      validated = false;
    }
    if (!edit.subject) {
      errors["subject"] = true;
      validated = false;
    }
    if (!edit.text) {
      errors["message"] = true;
      validated = false;
    }

    if (validated === true) {
      handleSubmit();
      setError({
        name: false,
        medium: false,
        title: false,
        subject: false,
        text: false,
      });
    }
    setError({ ...errors });
  };

  const handleSubmit = () => {
    if (user?.user?.role === "superAdmin" || edit.branch !== null) {
      _editTemplate();
    } else {
      setGloble(true);
    }
  };

  return (
    <>
      <div className={classes.root}>
        {success === true ? (
          <SuccessPopup
            success={success}
            setSuccess={setSuccess}
            msg={`${edit.name} template successfully saved`}
          />
        ) : null}
        {show === true ? (
          <ClickAwayListener onClickAway={handleClose}>
            <MuiDialog
              open={show}
              dialogClasses={{
                paper: classes.dialogPaper,
              }}
              title={"Edit Template"}
              onRequestClose={onRequestCloseMessage}
              dialogActions={
                <div className="d-flex justify-content-around">
                  <Grid container className="my-3 d-flex">
                    <div>
                      <Grid item>
                        <button
                          className={`${classes.bottomBtn} text-light`}
                          // type="submit"
                          // btncolor="bgBlue"
                          // disabled={!state.name.value}
                          onClick={handleValidate}
                        >
                          Update existing template
                        </button>
                      </Grid>
                    </div>
                  </Grid>
                </div>
              }
            >
              {globle === true ? (
                <AlertMessage globle={globle} setGloble={setGloble} />
              ) : null}
              <form onSubmit={handleSubmit}>
                <div className="d-flex">
                  <Grid
                    container
                    alignItems="center"
                    className="border py-2 px-3 justify-content-between"
                  >
                    <div className={`${classes.nameTextField} d-flex`}>
                      <Typography className={`${classes.heading} mr-2 pt-1`}>
                        Name:
                      </Typography>
                      <TextField
                        required
                        fullWidth
                        value={edit.name}
                        name="name"
                        id="filled-secondary"
                        variant="standard"
                        InputLabelProps={{ shrink: false }}
                        placeholder="Name"
                        error={error.name}
                        helperText={error.name && "required"}
                        onChange={(e) =>
                          setEdit({ ...edit, name: e.target.value })
                        }
                      />
                    </div>
                    <div className={`${classes.ownerName} d-flex`}>
                      <Typography className={`${classes.owner} ml-4 mr-2`}>
                        Owner:
                      </Typography>
                      {edit?.user?.fullName}
                    </div>
                  </Grid>
                </div>

                <Grid
                  container
                  alignItems="center"
                  className="border py-2 px-3"
                >
                  <Grid item container sm={2} alignItems="center">
                    <Grid item sm={5}>
                      <Typography className={`${classes.forFontfamily} mr-2`}>
                        Type:
                      </Typography>
                    </Grid>
                    <Grid item sm={7} className="pr-2">
                      <FormControl
                        variant="outlined"
                        className={`${classes.formControl} w-50`}
                      >
                        {edit?.branch === null ? "Global" : "Custom"}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container sm={5} alignItems="center">
                    <Grid item sm={3}>
                      <Typography className={`${classes.forFontfamily}`}>
                        Medium:
                      </Typography>
                    </Grid>
                    <Grid item sm={9}>
                      <FormControl
                        variant="outlined"
                        className={`${classes.formControl} w-100`}
                      >
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          name="medium"
                          value={edit.medium}
                          // error={error.medium}
                          // helperText={error.name && "required"}
                          onChange={(e: any) =>
                            setEdit({ ...edit, medium: e.target.value })
                          }
                        >
                          <MenuItem value={"sms"}>SMS</MenuItem>
                          <MenuItem value={"email"}>Email</MenuItem>
                          {/* {error.name? <FormHelperText>required</FormHelperText>:null} */}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container sm={5} alignItems="center">
                    <Grid item sm={4}>
                      <Typography className={`${classes.forFontfamily} pl-3`}>
                        Category:
                      </Typography>
                    </Grid>
                    <Grid item sm={8}>
                      <FormControl
                        variant="outlined"
                        className={`${classes.formControl}
                        d-flex flex-row align-items-center`}
                      >
                        <Select
                          required
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={edit.title}
                          name="title"
                          onChange={(e: any) =>
                            setEdit({ ...edit, title: e.target.value })
                          }
                        >
                          <MenuItem value={"message"}>message</MenuItem>
                          <MenuItem value={"invite"}>invite</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>

                <div className="d-flex align-items-center border py-2 px-3">
                  <Typography className={`${classes.subjectHeading} mr-3`}>
                    Subject:
                  </Typography>

                  <TextField
                    required
                    fullWidth
                    name="subject"
                    value={edit.subject}
                    id="filled-secondary"
                    error={error.subject}
                    helperText={error.name && "required"}
                    variant="standard"
                    InputLabelProps={{ shrink: false }}
                    placeholder="Message"
                    className={classes.underlineRemove}
                    onChange={(e) =>
                      setEdit({ ...edit, subject: e.target.value })
                    }
                  />
                </div>
                <TextField
                  required
                  fullWidth
                  value={edit.text}
                  error={error.text}
                  helperText={error.name && "required"}
                  id="filled-secondary"
                  name="message"
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                  placeholder="Write SMS here – Max 140 characters per SMS"
                  multiline
                  rows={8}
                  className={classes.inputBase}
                  onChange={(e) => setEdit({ ...edit, text: e.target.value })}
                />
              </form>
            </MuiDialog>
          </ClickAwayListener>
        ) : null}

        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell align="left" className={classes.topRow}>
                NAME
              </TableCell>
              <TableCell align="left" className={classes.topRow}>
                TYPE
              </TableCell>
              <TableCell align="left" className={classes.topRow}>
                MEDIUM
              </TableCell>
              <TableCell align="left" className={classes.topRow}>
                CATEGORY
              </TableCell>
              <TableCell align="left" className={classes.topRow}>
                OWNER
              </TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allvalues.map((row: any, i: any) => {
              const isDisabled =
                !row?.branch && user?.user?.role !== "superAdmin";
              return (
                <TableRow key={i} className={classes.tableBodyRow}>
                  <td
                    className={classes.tableBodyCell}
                    onClick={(e: any) => handleOpen(row)}
                  >
                    <u className={classes.templatename}>{row.name}</u>
                  </td>
                  <td className={classes.tableBodyCell}>
                    {row?.branch === null ? "Global" : "Custom"}
                  </td>
                  <td className={`${classes.tableBodyCell}`}>{row.medium}</td>
                  <td className={`${classes.tableBodyCell}`}>{row.title}</td>
                  <td className={classes.tableBodyCell}>
                    {row?.user?.fullName}
                  </td>
                  <td className={classes.actionbtn}>
                    <Dropdown className={classes.edit}>
                      <Dropdown.Toggle
                        id="dropdown-basic"
                        className="btn btn-light"
                      >
                        <i className="icon-more pt-1"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="rt-themedropdownmenu">
                        <Dropdown.Item
                          disabled={isDisabled}
                          onClick={(e: any) => _editTemplates(row)}
                        >
                          <i className="icon-edit pt-1"></i>
                          <span>Edit Template</span>
                        </Dropdown.Item>
                        <Dropdown.Item
                          disabled={isDisabled}
                          onClick={(e: any) => _deleteTemplate(row)}
                        >
                          <i className="icon-delete"></i>
                          <span>Delete Template</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      <ConfirmModal
        message={"Are you sure you want to delete Template?"}
        onConfirm={handleDeleteConfirmation}
        show={open}
        onClose={() => setOpenConfirm(false)}
      />
    </>
  );
};

export default Template;
