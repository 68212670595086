/*
 * File: BaseAuth.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 6th February 2020 9:35:45 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 11th January 2022 12:55:16 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import Card from "@mui/material/Card";
import { inputLabelClasses } from "@mui/material/InputLabel";

import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Button from "../../../../Components/common/ButtonComponent/ButtonComponent";
import Logo from "../../../../../src/assets/images/dymed-blue.png";
import { Modal } from "react-bootstrap";
import {
  CardCvcElement,
  CardElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { toastify } from "../../../../Services/toast.service";
import { log } from "../../../../Lib/helpers";
import { AuthApi } from "../../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { useState } from "react";
import { toast } from "react-toastify";
import Plaid from "./Plaid";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";
import AddBalance from "./AddBalance";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { PaymentApi } from "../../../../Services/API/PaymentApi";
import StripeInput from "./StripeInput";
import {
  IStripeBank,
  IStripeCard,
  IUser,
} from "../../../../Services/Interfaces/AuthInterface";

// ? includes new add card popup

interface IWalletSection {
  card: IStripeCard | null;
  bank: IStripeBank | null;
  getPaymentInfo: () => void;
  user?: IUser;
}
export const WalletSection = (props: IWalletSection) => {
  const { card, bank, getPaymentInfo, user } = props;

  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();

  const [paymentForm, setPaymentForm] = React.useState(false);
  const [loadingPayment, setLoadingPayment] = React.useState(false);

  const [showAddMoney, setShowAddMoney] = useState(false);

  const [value, setValue] = useState("1");
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    // _getBankDetails()
    _getBalance();
  }, [showAddMoney, card, bank]);

  const _getBalance = async () => {
    setLoadingPayment(true);
    try {
      const response = await PaymentApi.GetRemainingBalance();
      if (response.hasError) {
        handleApiError(response, "Get balance: ");
      } else {
        log("Get Balance SUCCESS: ", response.data);

        setBalance(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoadingPayment(false);
  };

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
  };

  const handleAccountDetailAdd = (e: any, key: string) => {
    if (key === "bank") {
      setValue("2");
      setPaymentForm(true);
    } else {
      setValue("1");
      setPaymentForm(true);
    }
  };

  const _uploadPaymentInfo = async (payment_method: string) => {
    setLoadingPayment(true);
    try {
      const response = await PaymentApi.SaveUserCard(payment_method);
      if (response.hasError) {
        handleApiError(response, "Save Stripe Card");
      } else {
        // ? Customer Payment Info saved successfully
        log("Customer Card Payment Info saved SUCCESS: ", response);

        toastify(
          "success",
          "Payment method saved successfully. Now you can accept and view applicants details."
        );

        getPaymentInfo();
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoadingPayment(false);
    handleHide();
  };

  const _saveStripeCardDetails = async (e: any) => {
    e.preventDefault();

    let name = "";
    let postal_code = "";
    try {
      name = e.currentTarget.cardHolderName.value;
      postal_code = e.currentTarget.postal_code.value;
    } catch (error) {}

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      return false;
    }

    setLoadingPayment(true);

    // Use your card Element with other Stripe.js APIs

    // TODO: UPDATE CARD IF ALREADY EXISTS.

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardNumberElement,
      billing_details: {
        name,
        address: {
          postal_code,
        },
        email: user?.email,
      },
    });
    if (error) {
      log("[error]", error);
      toastify("error", error.message || "Unable to update payment info");
      setLoadingPayment(false);
    } else {
      log("[PaymentMethod]", paymentMethod);
      // send paymentMethod.id to server
      _uploadPaymentInfo(paymentMethod?.id || "");
    }
  };

  const handleHide = () => {
    setPaymentForm(false);
  };
  const handleAddPay = () => {
    if (!card?.card && !bank) {
      toastify("info", "Please add payment method first");
      _openPaymentPopup();
    } else {
      setShowAddMoney(true);
    }
  };

  const _openPaymentPopup = (e: any = null) => {
    setPaymentForm(true);
  };

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  const _afterBankConnect = () => {
    getPaymentInfo();
    handleHide();
  };

  // connect card / bank

  const _renderConnectPaymentPopup = () => {
    return (
      <Modal
        size="lg"
        show={paymentForm}
        onHide={handleHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={classes.modalBox}
      >
        {value === "1" ? (
          <Modal.Header
            className={`mx-2 ${classes.modalHeaderClass}`}
            closeButton
          >
            <h5 
              // className={`modal-title ${classes.titleClass} mx-2`}
              style = {{
                fontFamily : "'Monsterrat', sans-serif",
                fontWeight : 700,
                marginLeft : 38, 
                marginTop : 10, 
                color : "#2C2C2C"
              }}
            >
              Add Payment Method
            </h5>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton className="mx-5">
            <Modal.Title id="contained-modal-title-vcenter">
              Add Bank
            </Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          <TabContext value={value}>
            <TabList
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="add payment method"
              TabIndicatorProps={{ style: { background: "#005EFF" } }}
            >
              <Tab className={classes.tabName} label="Credit Card" value="1" />
              <Tab className={classes.tabName} label="Bank" value="2" />
            </TabList>
            <TabPanel value={`1`}>
              <div className="mx-4 mt-4">
                <form onSubmit={_saveStripeCardDetails}>
                  <TextField
                    label="Name on Card" 
                    variant="outlined"
                    fullWidth
                    className=""
                    
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                      sx: {
                        fontWeight : 600,
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#005EFF",
                          fontFamily : "'Monsterrat', sans-serif"
                        }, 
                        fontFamily : "'Monsterrat', sans-serif"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      sx: {
                        fontSize : 15, 
                        color : "#4B6B73",
                        fontWeight : 600,
                      }
                    }}
                    placeholder="Enter Name"

                  />

                  {/* <Typography className={`${classes.formText} mt-2`}>
                    Card Number
                  </Typography> */}

                  {/* card number */}
                  <TextField
                    className="mt-4"
                    label="Card Number" 
                    variant="outlined"
                    fullWidth
                    required = {true}
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                      sx: {
                        fontWeight : 600,
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#005EFF",
                          fontFamily : "'Monsterrat', sans-serif"
                        }, 
                        fontFamily : "'Monsterrat', sans-serif"
                      }
                    }}
                    InputProps={{
                      //@ts-ignore
                      inputComponent: StripeInput,
                      inputProps: {
                        Component: CardNumberElement,
                        fontFamily : "'Monsterrat', sans-serif",
                        
                      },
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      sx: {
                        fontSize : 15, 
                        color : "#4B6B73",
                        fontWeight : 600,
                      }
                    }}
                    //placeholder="Enter Name"
                  />

                  <div className={classes.flexRowClass}>
                    <div className={`${classes.flexColumnClass} mt-4`}>
                      <TextField
                        className={`${classes.textFieldSmall}`}
                        label="Expiry Date" 
                        variant="outlined"
                        fullWidth
                        required={true}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                          sx: {
                            fontWeight : 600,
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#005EFF",
                              fontFamily : "'Monsterrat', sans-serif"
                            }, 
                            fontFamily : "'Monsterrat', sans-serif"
                          }
                        }}
                        InputProps={{
                          //@ts-ignore
                          inputComponent: StripeInput,
                          inputProps: {
                            Component: CardExpiryElement,
                            fontFamily : "'Monsterrat', sans-serif",
                          },
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                          sx: {
                            fontSize : 15, 
                            color : "#4B6B73",
                            fontWeight : 600,
                          }
                        }}
                      />
                    </div>

                    <div className={`${classes.flexColumnClass} mt-4`}>
                      <TextField
                        className={`${classes.textFieldSmall}`}
                        label="CVV" 
                        variant="outlined"
                        fullWidth
                        required={true}
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                          sx: {
                            fontWeight : 600,
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#005EFF",
                              fontFamily : "'Monsterrat', sans-serif"
                            }, 
                            fontFamily : "'Monsterrat', sans-serif"
                          }
                        }}
                        InputProps={{
                          //@ts-ignore
                          inputComponent: StripeInput,
                          inputProps: {
                            Component: CardCvcElement,
                            fontFamily : "'Monsterrat', sans-serif",
                          },
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                          sx: {
                            fontSize : 15, 
                            color : "#4B6B73",
                            fontWeight : 600,
                          }
                        }}
                      />
                    </div>
                    <div className={`${classes.flexColumnClass} mt-4`}>
                      {/* <Typography className={classes.formText}>
                        Zip Code
                      </Typography> */}
                      <TextField
                        label="Zip Code" 
                        variant="outlined"
                        fullWidth
                        className=""
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                          classes: {
                            root: classes.cssLabel,
                            focused: classes.cssFocused,
                          },
                          sx: {
                            fontWeight : 600,
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#005EFF",
                              fontFamily : "'Monsterrat', sans-serif"
                            }, 
                            fontFamily : "'Monsterrat', sans-serif"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.cssOutlinedInput,
                            focused: classes.cssFocused,
                            notchedOutline: classes.notchedOutline,
                          },
                          sx: {
                            fontSize : 15, 
                            color : "#4B6B73",
                            fontWeight : 600,
                          }
                        }}
                        placeholder="Zip Code"
                      />
                    </div>
                  </div>

                  <div className="mb-2 mt-4">
                    <Button
                      // className={`${classes.submitButton} ${classes.Addsubmitbtn}`}
                      type="submit"
                      // onClick={_saveStripeCardDetails}
                      loading={loadingPayment}
                      style = {{
                        backgroundColor : "#005EFF", 
                        fontFamily : "'Monsterrat', sans-serif"
                      }}
                    >
                      Add Card
                    </Button>
                  </div>
                  <div className="outcome">
                    <div className="error"></div>
                    <div className="success">
                      <span className="token"></span>
                    </div>
                  </div>
                </form>
              </div>
            </TabPanel>

            <TabPanel value={"2"}>
              <Plaid afterComplete={_afterBankConnect} />
            </TabPanel>
          </TabContext>
        </Modal.Body>
      </Modal>
    );
  };

  let brand,
    exp_month,
    exp_year,
    last4 = "";
  if (card && card.card) {
    const cardInfo = card.card;

    brand = cardInfo.brand;
    exp_month = cardInfo.exp_month;
    exp_year = cardInfo.exp_year;
    last4 = cardInfo.last4;
  }

  let bankLast4,
    bankName = "";
  if (bank) {
    bankLast4 = bank.last4;
    bankName = bank.bank_name;
  }

  return (
    <>
      {showAddMoney ? (
        <AddBalance open={showAddMoney} setOpen={setShowAddMoney} />
      ) : null}
      {_renderConnectPaymentPopup()}
      <Grid container direction="row">
        <Grid className={classes.borderRight}>
          <Grid container direction="row">
            <Grid
              className={classes.paper}
              onClick={(e) => handleAccountDetailAdd(e, "card")}
            >
              <Grid item xs={12} container direction="column">
                <Card style={{ boxShadow: "none" }}>
                  <CardContent className={classes.content}>
                    <div
                      className={`${classes.Method} d-flex justify-content-center`}
                    >
                      <CreditCardIcon
                        color="secondary"
                        style={{ fontSize: 50, fill: "#2574ff" }}
                      />
                    </div>
                    <Typography
                      variant="body2"
                      gutterBottom
                      className={classes.Text}
                    >
                      Add a Card
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid
              className={classes.paper}
              onClick={(e) => handleAccountDetailAdd(e, "bank")}
            >
              <Grid item xs={12} container direction="column">
                <Card style={{ boxShadow: "none" }}>
                  <CardContent className={classes.content}>
                    <div
                      className={`${classes.Method} d-flex justify-content-center`}
                    >
                      <AccountBalanceIcon
                        style={{ fontSize: 50, fill: "#2574ff" }}
                      />
                    </div>
                    <Typography gutterBottom className={classes.Text}>
                      Add a Bank
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          {/* {data.map((item:any, i:any) => ( */}
          <div style={{ paddingTop: "2%" }}>
            <Card style={{ borderTop: "1px solid #e8e8e8", boxShadow: "none" }}>
              <CardContent className={classes.content}>
                <Grid xs={12} container direction="row" spacing={2}>
                  <Grid item justifyContent="center">
                    <Box>
                      <CreditCardIcon
                        color="secondary"
                        className={classes.Icon}
                        style={{ fontSize: 50, fill: "#2574ff" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm container justifyContent="center">
                    <Grid item xs container direction="column">
                      {!card?.card ? (
                        <Typography
                          gutterBottom
                          onClick={_openPaymentPopup}
                          style={{ fontSize: 14, cursor: "pointer" }}
                        >
                          No Card Connected
                        </Typography>
                      ) : (
                        <Grid>
                          {/* show credit card details */}
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            style={{ fontSize: 15, color: "#2574ff" }}
                          >
                            {brand}
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{ fontSize: 14 }}
                          >
                            **** **** ****{last4}
                          </Typography>
                          <Typography
                            variant="caption"
                            gutterBottom
                            style={{ fontSize: 13 }}
                          >
                            {exp_month}/{exp_year}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <hr />
                <Grid xs={12} container direction="row" spacing={2}>
                  <Grid item justifyContent="center">
                    <Box>
                      <AccountBalanceIcon
                        color="secondary"
                        className={classes.Icon}
                        style={{ fontSize: 50, fill: "#2574ff" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item sm container justifyContent="center">
                    <Grid item xs container direction="column">
                      {!bank ? (
                        <Typography
                          gutterBottom
                          onClick={_openPaymentPopup}
                          style={{ fontSize: 14, cursor: "pointer" }}
                        >
                          No Bank Account Connected
                        </Typography>
                      ) : (
                        <Grid>
                          {/* show credit card details */}
                          <Typography
                            gutterBottom
                            variant="subtitle1"
                            style={{ fontSize: 15, color: "#2574ff" }}
                          >
                            {bankName}
                          </Typography>
                          <Typography
                            variant="body2"
                            gutterBottom
                            style={{ fontSize: 14 }}
                          >
                            **** **** ****{bankLast4}
                          </Typography>
                          {/* <Typography
                            variant="caption"
                            gutterBottom
                            style={{ fontSize: 13 }}
                          >
                            {exp_month}/{exp_year}
                          </Typography> */}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
          {/* ))} */}
        </Grid>

        <div className="mx-auto text-center " style={{ marginTop: "5%" }}>
          <Box
            boxShadow={1}
            className="rounded-circle mx-auto my-2"
            style={{ width: "50px", height: "50px" }}
          >
            <img
              src={Logo}
              alt="hello"
              style={{ width: "30px", height: "30px", marginTop: "20%" }}
            />
          </Box>

          <Typography variant="h6" gutterBottom>
            Dyme Balance
          </Typography>
          <Typography variant="h2" gutterBottom>
            ${balance || 0}
          </Typography>
          <Typography gutterBottom style={{ marginTop: "-5%" }}>
            Available
          </Typography>
          <div className="mx-auto">
            <button
              onClick={handleAddPay}
              className={`${classes.bottomBtn} ${classes.bgBlue}`}
            >
              Add Dyme Balance
            </button>

            {/* <button className={`${classes.bottomBtn} ${classes.bgTorqtoise}`}>
              Transfer Money
            </button> */}
          </div>
        </div>
      </Grid>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: "36px",
  },

  paper: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  bgTorqtoise: {
    backgroundColor: "#63d699",
    "&:hover": {
      backgroundColor: "#63d699",
    },
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "0.8rem ",
    borderRadius: "10px",
    marginRight: "10px",
    width: "100%",
    marginBottom: "20px",
  },
  borderRight: {
    borderRight: "1px solid #e8e8e8",
    marginLeft: theme.spacing(-3),
  },
  content: {
    padding: "40px",
  },
  Text: {
    paddingTop: "15px",
    fontFamily: "proxima_novaregular",
    fontSize: "18px",
  },
  Method: {},
  Icon: {
    fill: "#2574ff",
    fontSize: "50",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tabtop: {
    fontFamily: "proxima_novabold",
    fontSize: "16px",
  },
  submitButton: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "0.9rem ",
    borderRadius: "35px",
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
    fontSize: "17px",
    fontFamily: "proxima_novaregular",
  },
  textFieldSmall: {
    width: "120px",
  },
  flexColumnClass: {
    display: "flex",
    flexDirection: "column",
  },
  flexRowClass: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titleClass: {
    fontSize: "24px",
    fontFamily: "proxima_novabold",
  },
  modalHeaderClass: {
    borderBottom: "none",
    paddingBottom: "0px",
  },
  formText: {
    fontFamily: "proxima_novaregular",
  },
  tabName: {
    fontFamily: "proxima_novabold",
    color: "black",
    "&>span": {
      position: "unset",
    },
  },
  modalBox: {
    "&>div": {
      "&>div": {
        borderRadius: "15px",
        width: "100%",
      },
    },
  },
  Addsubmitbtn: {
    backgroundColor: "#009900",
    "&:hover": {
      backgroundColor: "#009900",
    },
  },

  cssLabel: {
    color : '#4B6B73', 
    fontSize : 15,
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#005EFF !important`,
      borderWidth : 1, 
    },
    color : "#4B6B73",
    fontFamily : "'Monsterrat', sans-serif"
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#ddd !important',
    fontFamily : "'Monsterrat', sans-serif"
  },
}));
