/*
 * File: useDollarValue.tsx
 * Project: CallJob App
 * File Created: Thursday, 8th July 2021 2:45:54 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 8:48:55 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect, useState } from 'react';
import { log } from '../Lib/helpers';
import { SettingsApi } from './API/SettingsApi';
import { handleApiError, handleCatchError } from './error-handling';
import { ESettingType, ISettings } from './Interfaces/SettingInterface';

// number of coins for 1 dollar. like $1 = 1000 dyme coins
export const useDollarValue = () => {

  const [dollarValue, setDollarValue] = useState(0);
  const [loading, setLoading] = useState(true)


  useEffect(() => {
   _fetchCoinSettings()
  }, [])

  const _fetchCoinSettings = async () => {
    setLoading(true)
    try {
      const response = await SettingsApi.GetCoinSettings();
      if (response.hasError) {
        handleApiError(response, "coin-settings");
      } else {
        // ? success
        log("COINS SETTINGS GET SUCCESS: ", response);

        let dollar: ISettings = response.data.find(
          (cs:ISettings) => cs.key === ESettingType.DOLLAR
        );

        setDollarValue(dollar.values[0]);

        // setCoinSettings(response.data);
      }
    } catch (e) {
      handleCatchError(e)
    }
    setLoading(false)
  };

  return {
    getCoinSettings: _fetchCoinSettings,
    dollarValue,
    dollarLoading: loading
  }
};

