/*
 * File: SideNav.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 4th December 2020 1:09:52 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Saturday, 5th March 2022 12:16:31 am
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */

import React, { MouseEvent, useContext, useState } from "react";

import styles from "./SideNav.module.scss";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { images } from "../../assets/images/images";
import { alertObject, log } from "../../Lib/helpers";
import { RouteKeys } from "../RouteKeys";
import { AppContext } from "../../Services/AppContext";
import { ImageComponent } from "../../Components/common/image.component";
import { Config } from "../../Config";
import { EUserType } from "../../Services/Interfaces/AuthInterface";
import { Hidden, ClickAwayListener, Grow, Typography } from "@mui/material";
import clsx from "clsx";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "@mui/material/Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import BadgeIcon from "@mui/icons-material/Badge";
import WorkIcon from "@mui/icons-material/Work";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import GroupIcon from "@mui/icons-material/Group";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";

// this is side bar
const useStyles = makeStyles((theme: Theme) => ({
  dropdown: {
    position: "absolute",
    zIndex: 1078,
    // minWidth: "20%",
    maxWidth: "21%",
    float: "right",
    marginTop: "71px !important",
    left: "82%",
    top: "-2%",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  company: {
    fontFamily: "proxima_novaregular",
    fontSize: "13px",
  },
  signOut: {
    fontFamily: "proxima_novabold",
    fontSize: "15px",
    color: "#007bff",
    cursor: "pointer",
  },
  fullName: {
    fontSize: "24px",
    fontFamily: "proxima_novabold",
  },
  adminSettingsLink: {
    cursor: "pointer",
  },
}));

export const SideNav: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { logout, user } = useContext(AppContext);
  const [openSidebar, setSidebarStatus] = useState(false);
  const [drop, setDrop] = React.useState(false);

  const [openMenu1, setOpenMenu1] = useState(false);
  const [openMenu2, setOpenMenu2] = useState(false);

  const handleMenu1 = () => {
    setOpenMenu1(!openMenu1);
  };

  const handleMenu2 = () => {
    setOpenMenu2(!openMenu2);
  };

  const location = useLocation();

  const { pathname } = location;

  const handleDrop = (type: any) => {
    if (type === "open") {
      setDrop(true);
    }
    if (type === "none") {
      setDrop(false);
    }
  };
  const handleRoute = () => {
    history.push(`${RouteKeys.AdminSettings}`);
    setDrop(false);
  };
  const handleCompanyRoute = () => {
    history.push(`${RouteKeys.AdminCompany}`);
    setDrop(false);
  };

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  let breadCrumbPath = pathname.split("/");

  let isSuperAdmin = user?.user.role === EUserType.SuperAdmin;

  return (
    <div className={styles.SideNav} data-testid="SideNav">
      {drop ? (
        <>
          <ClickAwayListener onClickAway={() => handleDrop("none")}>
            <Grow in={true}>
              <div
                className={clsx(
                  classes.dropdown,
                  "bg-white shadow-box rounded-sm mt-4 p-4"
                )}
              >
                <div
                  className="row d-flex mx-1"
                  onClick={() => history.push(RouteKeys.UserProfile)}
                  style={{ cursor: "pointer" }}
                >
                  <div className="col-3 p-0 pl-1">
                    {user?.user.photo ? (
                      <img
                        src={user?.user.photo}
                        style={{
                          borderRadius: "50%",
                          border: "1px solid #92929d",
                        }}
                        alt="avatar"
                      />
                    ) : (
                      <Avatar
                        src="/broken-image.jpg"
                        style={{
                          borderRadius: "50%",
                          width: "42px",
                          height: "42px",
                        }}
                      />
                    )}
                  </div>
                  <div className="col-9 text-wrap">
                    <span
                      style={{
                        fontFamily: "proxima_novabold",
                        fontSize: "14px",
                        wordBreak: "break-all",
                      }}
                    >
                      {user?.user?.fullName}
                    </span>
                    <br />
                    <span
                      style={{
                        fontFamily: "proxima_novaregular",
                        fontSize: "13px",
                        color: "gray",
                        // wordBreak: "break-all",
                      }}
                    >
                      {user?.user?.email}
                    </span>
                  </div>
                </div>
                <hr />
                <div className={`${classes.company} mt-2 mx-2`}>
                  <span
                    className={classes.adminSettingsLink}
                    onClick={handleRoute}
                  >
                    User Setting
                  </span>
                </div>
                <div className={`${classes.company} mt-2 mb-2 mx-2`}>
                  <span
                    className={classes.adminSettingsLink}
                    onClick={handleCompanyRoute}
                  >
                    Company Settings
                  </span>
                </div>
                <hr />
                <div className={`${classes.signOut} mt-3 mb-1 mx-2`}>
                  <span onClick={logout}>Sign out</span>
                </div>
              </div>
            </Grow>
          </ClickAwayListener>
        </>
      ) : null}
      <div
        id="rt-header"
        className={` rt-header ${openSidebar ? "show-sidebar" : ""}`}
      >
        <div className="rt-logobuttonholder">
          <div
            //className = "flex flex-row"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <strong className="rt-headerlogo">
              <a>
                <img src={images.DymeBlueLogo} alt="Logo" />{" "}
              </a>
            </strong>
            <button
              type="button"
              className="rt-btntogglesidebar"
              onClick={() => setSidebarStatus(!openSidebar)}
            >
              <i className="icon-menu6"></i>
            </button>
            <div
              //className = "ml-5 mt-2 mr-2 text-gray-400 text-xs font-extralight"
              style={{
                marginLeft: 28,
                marginRight: 8,
                marginTop: 8,
                fontFamily: "'Montserrat', sans-serif",
                color: "#a1aeab",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              {breadCrumbPath[1].toUpperCase()}
            </div>
            <div
              style={{
                //marginLeft : 28,
                marginRight: 8,
                marginTop: 8,
                fontFamily: "'Montserrat', sans-serif",
                color: "#047CFF",
                fontWeight: 400,
                fontSize: 14,
              }}
            >
              <FontAwesomeIcon
                icon={faAngleRight}
                style={{ fontWeight: 200 }}
              />
            </div>
            {breadCrumbPath.length > 2 && (
              <div
                className="mt-2 ml-2 text-xs font-extralight"
                style={{
                  marginRight: 8,
                  marginTop: 8,
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#047CFF",
                }}
              >
                {breadCrumbPath[2].toUpperCase().replace(/-/g, " ")}
              </div>
            )}
          </div>
        </div>

        <ul className="rt-usernotification">
          {/* <li style={{ marginTop: 5 }}>
            <a>
              <em className="rt-notidot"></em>
              <i className="icon-notification1"></i>
            </a>
          </li> */}
          <li
            //className = "flex flex-row cursor-pointer"
            style={{ display: "flex", flexDirection: "row", cursor: "pointer" }}
            onClick={() => handleDrop("open")}
          >
            <figure className="rt-userimg">
              {/* <Link to={RouteKeys.AdminSettings}> */}
              <ImageComponent
                src={user?.user.photo}
                title={user?.user.fullName}
                //onClick={() => handleDrop("open")}
              />

              {/* </Link> */}
            </figure>
            <div
              //className = "inline mt-2 ml-2"
              style={{
                display: "inline",
                marginTop: 8,
                marginLeft: 8,
                fontFamily: "'Montserrat', sans-serif",
              }}
            >
              <p
                //className = "text-sm font-semibold"
                style={{
                  fontSize: 12,
                  fontWeight: 600,
                }}
              >
                {user?.user?.fullName}{" "}
                {isSuperAdmin && <b style={{ color: "red" }}>[ADMIN]</b>}
              </p>
            </div>
          </li>
        </ul>
        <div className="rt-sidebarholder">
          {/* <div className="rt-searchholder">
            <a>
              <i className="icon-search1"></i>
            </a>
            <form className="rt-sidebarsearch">
              <fieldset>
                <div className="form-group">
                  <input
                    type="search"
                    name="search"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </fieldset>
            </form>
          </div> */}
          <nav className="rt-navigation">
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              component="nav"
              aria-labelledby="nested-list-subheader"
              subheader={null}
            >
              {/* Employee Rewards */}
              <ListItemButton onClick={handleMenu1}>
                <ListItemIcon>
                  <EmojiEventsIcon />
                </ListItemIcon>
                <ListItemText primary="Employee Rewards" />
                {openMenu1 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              {/* Employee Rewards */}
              <Collapse in={openMenu1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => _navigate(RouteKeys.EmployeesPageNew)}
                  >
                    <ListItemIcon>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Employees" />
                  </ListItemButton>

                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => _navigate(RouteKeys.BillingPage)}
                  >
                    <ListItemIcon>
                      <AccountBalanceWalletIcon />
                    </ListItemIcon>
                    <ListItemText primary="Wallet" />
                  </ListItemButton>

                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => _navigate(RouteKeys.RewardsGiven)}
                  >
                    <ListItemIcon>
                      {/* <MilitaryTechIcon /> */}
                      <CardGiftcardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Rewards Given" />
                  </ListItemButton>
                </List>
              </Collapse>

              {/* Employee Referral Program */}
              <ListItemButton onClick={handleMenu2}>
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary="Job Referral Program" />
                {openMenu2 ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>

              <Collapse in={openMenu2} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => _navigate(RouteKeys.AdminDashboard)}
                  >
                    <ListItemIcon>
                      <i className="icon-dashboard rt-active"></i>
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>

                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => _navigate(RouteKeys.JobsList)}
                  >
                    <ListItemIcon>
                      <i className="icon-jobs"></i>
                    </ListItemIcon>
                    <ListItemText primary="Jobs" />
                  </ListItemButton>

                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => _navigate(RouteKeys.ApplicantsList)}
                  >
                    <ListItemIcon>
                      <i className="icon-applicant-list"></i>
                    </ListItemIcon>
                    <ListItemText primary="Applications" />
                  </ListItemButton>

                  <ListItemButton
                    sx={{ pl: 4 }}
                    onClick={() => _navigate(RouteKeys.ReferralProgram)}
                  >
                    <ListItemIcon>
                      <i className="icon-user-tie"></i>
                    </ListItemIcon>
                    <ListItemText primary="Referral Program" />
                  </ListItemButton>
                </List>
              </Collapse>
            </List>

            {/* ! below code not in use */}
            <ul style={{ opacity: 0 }}>
              <li
                className={
                  pathname === RouteKeys.AdminDashboard || pathname === "/"
                    ? "rt-active"
                    : ""
                }
              >
                <Link to={RouteKeys.AdminDashboard}>
                  <i className="icon-dashboard"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={pathname === RouteKeys.JobsList ? "rt-active" : ""}
              >
                <Link to={RouteKeys.JobsList}>
                  <i className="icon-jobs"></i>
                  <span>Jobs</span>
                </Link>
              </li>
              <li
                className={
                  pathname === RouteKeys.ApplicantsList ? "rt-active" : ""
                }
              >
                <Link to={RouteKeys.ApplicantsList}>
                  <i className="icon-applicant-list"></i>
                  <span>Applications</span>
                </Link>
              </li>
              {/* <li
                className={pathname === RouteKeys.ChatPage ? "rt-active" : ""}
              >
                <Link to={RouteKeys.ChatPage}>
                  <i className="icon-chat"></i>
                  <span>Chat/Messages</span>
                </Link>
              </li> */}
              {/* <li className={pathname === RouteKeys.ApplicantsList ? "rt-active" : ""}>
                <Link to={RouteKeys.ApplicantsList}>
                  <i className="icon-explore-candidates"></i>
                  <span>Candidates</span>
                </Link>
              </li> */}
              {/* <li
                className={pathname === RouteKeys.Employees ? "rt-active" : ""}
              >
                <Link to={RouteKeys.Employees}>
                  <i className="icon-users"></i>
                  <span>Employees</span>
                </Link>
              </li> */}
              <li
                className={
                  pathname === RouteKeys.ReferralProgram ? "rt-active" : ""
                }
              >
                <Link to={RouteKeys.ReferralProgram}>
                  <i className="icon-user-tie"></i>
                  <span>Employee Referral Program</span>
                </Link>
              </li>

              <li
                className={
                  pathname === RouteKeys.EmployeesPageNew ? "rt-active" : ""
                }
              >
                <Link to={RouteKeys.EmployeesPageNew}>
                  <i className="icon-users"></i>
                  <span>Employees</span>
                </Link>
              </li>
              {/* <li
                className={
                  pathname === RouteKeys.AdminSettings ? "rt-active" : ""
                }
              >
                <Link to={RouteKeys.AdminSettings}>
                  <i className="icon-settings"></i>
                  <span>Setting</span>
                </Link>
              </li>
              <li>
                <button className="rt-btnlogout" onClick={logout}>
                  <i className="icon-logout"></i>
                  <span>Logout</span>
                </button>
              </li> */}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};
