/*
 * File: CompanyForm.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 11th March 2020 1:43:59 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import {
  Typography,
  Paper,
  Grid,
  TextField,
  Avatar,
  Input,
  Box,
  Button,
  FormGroup,
  Switch,
  FormControlLabel
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppContext } from "../../../Services/AppContext";
import {
  ICompany,
  TCompany,
  IAddress
} from "../../../Services/Interfaces/CompanyInterface";
import { Config } from "../../../Config";
import { ENotifyType } from "../../../Components/Notify";
import MapContainer from "../../../Components/MapContainer";
import { log } from "../../../Lib/helpers";
import { CompanyApi } from "../../../Services/API/CompanyApi";
import { handleApiError, handleCatchError } from "../../../Services/error-handling";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { ApiResponse } from "../../../Services/api-client";

const USERS = [
  {
    email: "umar.u@allshorestaffing.com",
    name: "Umar Aamer"
  },
  {
    email: "umardatum@gmail.com",
    name: "Datum"
  },
  {
    email: "umar.u@allshorestaffing.com",
    name: "Allshore"
  }
];

interface ICompanyData {
  name: string;
  description: string;
  logo: string|null;
  img: string|undefined;
  address: string;
  city: string;
  latitude: string|number;
  longitude: string|number;
  isActive: boolean;

}

const DEFAULT_DATA:ICompanyData = {
  name: "",
  description: "",
  logo: "",
  img: "",
  address: "",
  city: "",
  latitude: Config.DEFAULT_COORDINATES.lat,
  longitude: Config.DEFAULT_COORDINATES.lng,
  isActive: true
};
type dataType = keyof typeof DEFAULT_DATA;

interface ICompanyForm {
  company?: TCompany;
  onSubmit?: Function;
}
export const CompanyForm: React.FC<ICompanyForm> = props => {
  const history = useHistory();
  const { company } = props;

  const { notify, user, isAdmin } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ...DEFAULT_DATA
  });

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  useEffect(() => {
    
    if(company) {
      const {address, userId, ...others} = company
      // others.
      
      setData({
        ...others,
        ...address,
        // city: "",
        // latitude: 324,
        // longitude: 324,
        logo: '',
        img: '',
      })
    } else {
      setData({
      ...data,
      // name: company ? company.name : 'umarss'
    })
    }
    
  }, [company])

  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      name: Yup.string().required("Call Center Name required"),
      address: Yup.string().required("Address required"),
      description: Yup.string().required("Description required")
      // logo: Yup.string().required("Logo required")
      // coordinates: Yup. .string().required("Address required"),
    }),
    enableReinitialize: true,

    onSubmit: async values => {
      log("company profile values: ", values);

      alert(JSON.stringify(values))

      // TODO: CALL ADMIN UPDATE API IF LOGGED IN AS SUPER-ADMIN
      // TODO: CALL BUSINESS UPDATE API IF BUSINESS USER

      setLoading(true);

      const { name, description, address, city, isActive } = values;
      let response:ApiResponse;
      const addressObj = {
        address,
        city,
        latitude: data.latitude,
        longitude: data.longitude
      }
      const updatedCompany = {
        name,
        description,
        address:addressObj,
        isActive
      }
      if (company && company.id) {
        //edit
        response = await CompanyApi.EditCompany(company.id, {
          ...updatedCompany
        })
      } else {
        //create new company
        response = await CompanyApi.CreateCompany(
          user?.user.id,
          name,
          description,
          addressObj,
          isActive
        );
      }
      

      let message: string = "Something went wrong";
      let errorType = ENotifyType.Error;

      try {
        if (response.hasError) {
          message = handleApiError(response, "Company Form SCREEN: ");

        } else {
          log("Create Company SUCCESS: ", response);
          message = `Company ${company ? "Updated" : "Created"}!`;
          errorType = ENotifyType.Success;

          if (!company) {
          _navigate(RouteKeys.Companies)
          }
          
          // response.data;
        }
      } catch (e) {
        handleCatchError(e);
      }

      notify({
        title: message,
        type: errorType
      });

      setLoading(false);
    }
  });

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue
  } = formik;

  const _onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const _updateLatLng = (latLng = { lat: 0, lng: 0 }) => {
    log("updating marker: ", latLng);
    
    setData({
      ...values,
      latitude: latLng.lat, longitude: latLng.lng 
    });
  };
  

  const _handleFile = (e: any) => {
    const file = e.currentTarget.files[0];

    const reader = new FileReader();

    const url = reader.readAsDataURL(file);

    reader.onloadend = () => {
      log("file: ", reader.result, url);
      let imgData = reader.result;
      setData({
        ...values,
        img: imgData ? imgData.toString() : ""
      });
    };
  };

  // Default input props for each text input
  const _getInputProps = (key: dataType): any => {
    return {
      value: values[key],
      onChange: handleChange,
      error: touched[key] && !!errors[key],
      onBlur: handleBlur,
      variant: "outlined",
      margin: "none",
      required: true,
      fullWidth: true,
      name: `${key}`,
      helpertext: errors[key]
    };
  };

  return (
    <Paper style={{ padding: 20 }}>
      <form noValidate autoComplete="off" onSubmit={_onSubmit}>
        <Typography variant="h6">Company Profile</Typography>

        {/* name, address, description, logo, coordinates */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="Name" {..._getInputProps("name")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Address" {..._getInputProps("address")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="City" {..._getInputProps("city")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Avatar src={data.img} style={{ width: 150, height: 150 }}>
              LOGO
            </Avatar>
            <Input
              {..._getInputProps("logo")}
              type="file"
              accept="image/*"
              onChange={_handleFile}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              label="Description"
              multiline
              rows={4}
              {..._getInputProps("description")}
            />
          </Grid>


          <Grid item xs={12} sm={6}>
            <Box height={300}>
              <MapContainer
                onMarkerDrag={_updateLatLng}
                coordinates={{latitude: data.latitude, longitude: data.longitude}}
                style={{ height: 300, width: 350 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: 30 }}>
            <FormGroup row>
              <FormControlLabel
                disabled={!isAdmin}
                control={
                  <Switch
                    onChange={() => setFieldValue("isActive", !values.isActive)}
                    checked={!!values.isActive}
                    // inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label={`Company ${values.isActive ? "Enabled" : "Disabled"}`}
              />
            </FormGroup>

            {isAdmin && (
              <Grid item xs={12} sm={6} style={{ marginTop: 30 }}>
                <Autocomplete
                  options={USERS}
                  // freeSolo
                  getOptionLabel={option => option.email}
                  style={{ width: 300 }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Business Manager Email"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} style={{ marginTop: 30 }}>
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                color="primary"
                variant="contained"
              >
                {props.company ? "Update" : "Create"}
                &nbsp;Company
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

CompanyForm.defaultProps = {
  
};
