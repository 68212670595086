/*
 * File: Rewards.tsx
 * Project: Dyme-Web
 * File Created: Thursday, 29th July 2021 8:01:00 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 9th September 2021 11:38:00 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect } from "react";
import {
  Typography,
  List,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import CommomPopOver from "../../../Components/common/PopOver";
import InfoIcon from "@mui/icons-material/Info";
import Button from "../../../Components/common/ButtonComponent/ButtonComponent";
import {
  IPaginate,
  ISelect,
  postHire,
  beforeHire,
} from "../../../Services/Interfaces";
import { IStatusReward } from "../../../Services/Interfaces/EmployeeInterface";
import { TReferSaveType } from "./ReferralProgram";

const useStyles = makeStyles((theme) => ({
  mainDivClass:{
    maxHeight:"300px",
    overflowY:'auto',
    overflowX:'hidden'
  },
  inputBox: {
    maxWidth: 50,
    "& input": {
      padding: "6px 10px",
    },
  },
  input: {
    padding: 10,
  },
  rewardsHead: {
    color: "#65656f",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  beforeHireText: {
    color: "#2574ff",
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "0.8rem 2.5rem",
    borderRadius: "10px",
    marginRight: "10px",
  },
  bgTorqtoise: {
    backgroundColor: "#63d699",
    "&:hover": {
      backgroundColor: "#63d699",
    },
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  iconWrapper: {
    pointerEvents: "none",
  },
  checkbox: {
    color: "#93939e !important",
  },
  checked: {
    color: "#63d699 !important",
  },
}));

interface RewardsPropsData {
  selectedJob: ISelect;
  _updateReferralSettings: (type?: TReferSaveType) => void;
  beforeHireData: Array<beforeHire>;
  postHireData: Array<postHire>;
  setBeforeHireData: (data: Array<beforeHire>) => void;
  setPostHireData: (data: Array<postHire>) => void;
  saveLoading: boolean;
  statusReward: Array<IStatusReward>;
}

const RewardsData = (props: RewardsPropsData) => {
  const {
    selectedJob,
    _updateReferralSettings,
    beforeHireData,
    postHireData,
    setBeforeHireData,
    setPostHireData,
    saveLoading,
    statusReward,
  } = props;
  const [checked, setChecked] = React.useState<number[]>([]);
  const [totalBeforeHire, setTotalBeforeHire] = useState(0);
  const [totalPostHire, setTotalPostHire] = useState(0);

  
  
  const classes = useStyles();
  useEffect(() => {
    const selectedOptions: Array<number> = [];
    statusReward?.length &&
      statusReward.forEach((val, i) => {
        if (val.active) {
          selectedOptions.push(val.status);
        }
      });
    setChecked(selectedOptions);
  }, [statusReward]);

  const handleToggle = (value: any, type: string) => () => {
    if (type === "beforeHire") {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      setBeforeHireData(
        beforeHireData.map((val, i) => {
          if (val.status === value) {
            if (currentIndex == -1) {
              return { ...val, active: true };
            } else {
              return { ...val, active: false };
            }
          } else {
            return val;
          }
        })
      );
      setChecked(newChecked);
    }
    else if (type === "postHire") {
      setPostHireData(
        postHireData.map((val, i) => {
          if (i === value) {
            return { ...val, active: !val.active };
          } else {
            return val;
          }
        })
      );
    }
  };
  const [anchorEl, setAnchorEl] = React.useState({
    beforeHire: null,
    postHire: null,
  });

  const handlePopoverOpen = (event: any, key: string) => {
    setAnchorEl({
      ...anchorEl,
      [key]: event.currentTarget,
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl({
      beforeHire: null,
      postHire: null,
    });
  };
  useEffect(() => {
    let beforeHireTotal = 0;
    beforeHireData.filter(val => val.active === true).forEach((val, i) => {
      beforeHireTotal = beforeHireTotal + Number(val.reward);
    });
    setTotalBeforeHire(beforeHireTotal);
  }, [beforeHireData, checked]);

  useEffect(() => {
    let postHireTotal = 0;
    postHireData.filter(val => val.active === true).forEach((val, i) => {
      postHireTotal = postHireTotal + Number(val.reward);
    });
    setTotalPostHire(postHireTotal);
  }, [postHireData, checked]);

  const handleChange = (e: any, type: string, index: number) => {
    if (type === "beforeHire") {
      let newBeforeHireData = [...beforeHireData];
      newBeforeHireData[index].reward = Number(e.target.value);
      setBeforeHireData(newBeforeHireData);
    } else if (type === "postHire") {
      let newPostHireData = [...postHireData];
      let postHireTotal = 0;
      newPostHireData[index].reward = Number(e.target.value);
      setPostHireData(newPostHireData);
      postHireData.forEach((val, i) => {
        if (index == i) {
          postHireTotal = postHireTotal + Number(e.target.value);
        } else {
          postHireTotal = postHireTotal + Number(val.reward);
        }
      });
      setTotalPostHire(postHireTotal);
    }
  };
  const handleChangeDays=(e: any, type: string, index: number)=>{
    if(type==="postHire"){
      let newPostHireData = [...postHireData];
      newPostHireData[index].daysAfterHire = Number(e.target.value);
      setPostHireData(newPostHireData)
    }
  }

  return (
    <>
    <div className={classes.mainDivClass}>
      <div className="hireSection mb-4 mt-4">
        <div className="row">
          <div className="col-sm-3">
            <span
              className={`${classes.rewardsHead} d-inline`}
              aria-owns={
                anchorEl.beforeHire
                  ? "mouse-over-popover-beforeHire"
                  : undefined
              }
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, "beforeHire")}
              onMouseOut={handlePopoverClose}
            >
              Before Hire{" "}
              <span className={classes.iconWrapper}>
                {" "}
                <InfoIcon className="mx-1" />
              </span>
            </span>
            <List>
              {beforeHireData.map((value, index) => {
                const labelId = `checkbox-list-label-${value.status}`;
                return (
                  <ListItem role={undefined} dense className="pl-0">
                    <Checkbox
                      edge="start"
                      // checked={checked.indexOf(value.status) !== -1}
                      checked={value.active}
                      onClick={handleToggle(value.status, "beforeHire")}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked,
                      }}
                    />
                    <ListItemText id={labelId} primary={value.label} />{" "}
                    <span className="mx-2">$</span>
                    <TextField
                      classes={{
                        root: classes.inputBox,
                      }}
                      disabled={
                        checked.indexOf(value.status) === -1 ||
                        !selectedJob.value
                      }
                      value={value.reward}
                      onChange={(e) => handleChange(e, "beforeHire", index)}
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                    />
                  </ListItem>
                );
              })}
              <ListItem className="d-flex justify-content-between pl-0">
                <Typography className="font-weight-bold">
                  Total Before Hired Rewards
                </Typography>
                <Typography className="font-weight-bold">
                  ${totalBeforeHire}
                </Typography>
              </ListItem>
            </List>
          </div>
          <div className="col-sm-1"></div>
          <div className="col-sm-5">
            <span
              className={`${classes.rewardsHead} d-inline`}
              aria-owns={
                anchorEl.postHire ? `mouse-over-popover-postHire` : undefined
              }
              aria-haspopup="true"
              onMouseEnter={(e) => handlePopoverOpen(e, "postHire")}
              onMouseOut={handlePopoverClose}
            >
              Post Hire{" "}
              <span className={classes.iconWrapper}>
                {" "}
                <InfoIcon className="mx-1" />
              </span>
            </span>
            <List>
              {}
              {postHireData.map((value, index) => {
                const labelId = `checkbox-list-label-${value.id}`;
                return (
                  <ListItem
                    role={undefined}
                    dense
                    className="pl-0"
                  >
                    <Checkbox
                      edge="start"
                      checked={value.active}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ "aria-labelledby": labelId }}
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked,
                      }}
                      onClick={handleToggle(index, "postHire")}
                    />
                    <span className="mx-2"></span>
                    <TextField
                      classes={{
                        root: classes.inputBox,
                      }}
                      value={value.daysAfterHire}
                      variant="outlined"
                      disabled={!value.active }
                      onChange={(e) => handleChangeDays(e, "postHire", index)}
                      InputLabelProps={{ shrink: false }}
                    />
                    <span className="ml-3">Days after Hire</span>
                    <span className="mx-2">$</span>
                    <TextField
                      classes={{
                        root: classes.inputBox,
                      }}
                      onChange={(e) => handleChange(e, "postHire", index)}
                      value={value.reward}
                      disabled={!value.active }
                      variant="outlined"
                      InputLabelProps={{ shrink: false }}
                    
                    />
                  </ListItem>
                );
              })}
              <ListItem className="d-flex pl-0" role={undefined} dense button>
                <Typography className="font-weight-bold col-sm-8 px-0">
                  Total Post Hired Rewards
                </Typography>
                <Typography className="font-weight-bold">
                  ${totalPostHire}
                </Typography>
              </ListItem>
              <ListItem className="d-flex pl-0" role={undefined} dense button>
                <Typography className="font-weight-bold col-sm-8 px-0">
                  Total Rewards
                </Typography>
                <Typography className="font-weight-bold">
                  ${totalBeforeHire + totalPostHire}
                </Typography>
              </ListItem>
            </List>
          </div>
        </div>
        <CommomPopOver
          anchorEl={anchorEl.beforeHire}
          handlePopoverClose={handlePopoverClose}
          id="beforeHire"
        >
          Before Hire Rewards allow you to reward employees to refer friends to
          apply for jobs.
        </CommomPopOver>
        <CommomPopOver
          anchorEl={anchorEl.postHire}
          handlePopoverClose={handlePopoverClose}
          id="postHire"
          maxWidth="850px"
        >
          Post Hire Rewards allow you to reward employees to refer friends to
          apply for jobs.
        </CommomPopOver>
      </div>
      <div className="networkSection">
        <Typography className={classes.rewardsHead}> Network Awards</Typography>
        <div className="section1 col-sm-5 mb-3">
          <div
            className="d-flex align-items-center"
          >
            <Checkbox
              edge="start"
              tabIndex={-1}
              disableRipple
              classes={{
                root: classes.checkbox,
                checked: classes.checked,
              }}
            />
            <Typography>2nd Level Awards(friends of friends)</Typography>
          </div>
          <List className="pt-0">
            <ListItem>
              <Typography className={classes.beforeHireText}>
                Before Hire
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TextField
                  classes={{
                    root: classes.inputBox,
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                />
              </ListItemIcon>
              <ListItemText>% of Rewards for Employee</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TextField
                  classes={{
                    root: classes.inputBox,
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                />
              </ListItemIcon>
              <ListItemText>% of Rewards for Employee Friend</ListItemText>
            </ListItem>
            {/* <ListItem className="d-flex align-items-center justify-content-between">
              <Typography className="font-weight-bold">
                Total 2nd Level Reward{" "}
              </Typography>
              <Typography className="font-weight-bold">$300</Typography>
            </ListItem> */}
          </List>
        </div>
        <div className="section2 col-sm-5">
          <div
            className="d-flex align-items-center"
          // onClick={handleToggle("networkAwards2")}
          >
            <Checkbox
              edge="start"
              // checked={checked.indexOf("networkAwards2") !== -1}
              tabIndex={-1}
              disableRipple
              classes={{
                root: classes.checkbox,
                checked: classes.checked,
              }}
            />
            <Typography>3rd Level Awards</Typography>
          </div>
          <List className="pt-0">
            <ListItem>
              <Typography className={classes.beforeHireText}>
                Before Hire
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TextField
                  classes={{
                    root: classes.inputBox,
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                />
              </ListItemIcon>
              <ListItemText>% of Rewards for Employee</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TextField
                  classes={{
                    root: classes.inputBox,
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                />
              </ListItemIcon>
              <ListItemText>% of Rewards for Employee Friend</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TextField
                  classes={{
                    root: classes.inputBox,
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: false }}
                />
              </ListItemIcon>
              <ListItemText>
                % of Rewards for Employee Friend of Friends
              </ListItemText>
            </ListItem>
            {/* <ListItem className="d-flex align-items-center justify-content-between">
              <Typography className="font-weight-bold">
                Total 3rd Level Reward{" "}
              </Typography>
              <Typography className="font-weight-bold">$300</Typography>
            </ListItem> */}
          </List>
        </div>
      </div>
      </div>
      <div className="py-3">
        <Button
          className={`${classes.bgTorqtoise} ${classes.bottomBtn}`}
          disabled={!selectedJob.value}
          onClick={() => _updateReferralSettings("save")}
          loading={saveLoading}
        >
          Save
        </Button>
        {/* <Button className={`${classes.bgBlue} ${classes.bottomBtn}`}>
          Exit
        </Button> */}
      </div>
    </>
  );
};

export default RewardsData;
