/*
 * File: ManagerForm.tsx
 * Project: Dyme-Web
 * File Created: Friday, 24th September 2021 1:51:45 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Saturday, 25th September 2021 12:55:24 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState, useEffect, useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { LoaderComponent } from "../../../../../Components/common/loader.component";
import { AppContext } from "../../../../../Services/AppContext";
import { log } from "../../../../../Lib/helpers";
import { ApiResponse } from "../../../../../Services/api-client";
import { EmployeesApi } from "../../../../../Services/API/EmployeesApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../../Services/error-handling";
import { TextField, TextFieldProps } from "@mui/material";
import { IEmployee } from "../../../../../Services/Interfaces/EmployeeInterface";

const DEFAULT_DATA = {
  email: "",
  fullName: "",
};
type dataType = keyof typeof DEFAULT_DATA;

interface IManagerForm {
  show: boolean;
  onClose: () => void;
  employee: IEmployee|null;
}
export const ManagerForm = (props: IManagerForm) => {
  const history = useHistory();
  const { notify } = useContext(AppContext);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(DEFAULT_DATA);

  const _resetForm = () => {
    setData(DEFAULT_DATA)
    resetForm()
  }

  useEffect(() => {
    const {employee} = props;

    if(employee) {
      setData({
        email: employee.email,
        fullName: employee.fullName
      })
    } else {
      _resetForm()
    }
  }, [props.employee])

  const [show, setShow] = useState(props.show);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      fullName: Yup.string().required("Employee Name required"),
      email: Yup.string().email().required("Email required"),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      _saveEmployee(values);
    },
  });

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = formik;

  const _onClose = () => {
    props.onClose();
    _resetForm();
  };

  useEffect(() => {
    setShow(props.show);
  }, [props.show]);

  const _saveEmployee = async (values = DEFAULT_DATA) => {
    log("emp values: ", values);
    const newValues = { ...values };

    setLoading(loading);

    let response: ApiResponse;

    if (props.employee) {
      //edit employee
      response = await EmployeesApi.EditAdminEmployee(props.employee.id, {
        ...newValues,
      });
    } else {
      //create new employee
      response = await EmployeesApi.CreateAdminEmployee({
        ...newValues,
      });
    }

    try {
      if (response.hasError) {
        handleApiError(response, "Employee Manager Form: ");
      } else {
        log("Create Employee SUCCESS: ", response);

        _onClose(); // fetch employees
        // clear form or hide

        // response.data;
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  const defaultInputProps = (key: dataType): TextFieldProps => {
    return {
      value: values[key],
      onChange: handleChange,
      error: touched[key] && !!errors[key],
      onBlur: handleBlur,
      variant: "standard",
      margin: "normal",
      required: true,
      fullWidth: true,
      name: `${key}`,
      helperText: errors[key],
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      },
    };
  };

  let btnTitle = 'Save and Invite';

  // {props.employee ? "Update" : "Invite"}{props.employee?.user ? "" : ""} Admin

  const {employee} = props;

  if (employee) {
    btnTitle = "Update"
    if (!employee.user) {
      btnTitle += " and Invite"
    }
  }

  return (
    <Modal show={show} onHide={_onClose} className="rt-thememodal" centered>
      <Modal.Header closeButton className="rt-modalheader">
        <Modal.Title>{employee ? "Update" : "Invite"} Admin Manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <div className="rt-userinfoinput">
            <div className="form-group">
              <label>Full Name</label>
              <i className="icon-user1"></i>
              <div className="rt-inputiconholder">
                <TextField
                  className="form-control"
                  placeholder="John Doe"
                  {...defaultInputProps("fullName")}
                />
              </div>
            </div>
            <div className="form-group">
              <label>Manager Email</label>
              <i className="icon-email"></i>
              <div className="rt-inputiconholder">
                <TextField
                  {...defaultInputProps("email")}
                  type="email"
                  className="form-control"
                  placeholder="john@doe.com"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Button
        disabled={loading}
        variant="primary"
        type="submit"
        className="rt-btndelete"
        onClick={() => handleSubmit()}
      >
        {btnTitle} Admin
        {loading && <LoaderComponent />}
      </Button>
    </Modal>
  );
};
