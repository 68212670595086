/*
 * File: GoogleLogin.tsx
 * Project: Dyme-Web
 * File Created: Wednesday, 17th November 2021 1:22:08 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 22nd November 2021 7:55:57 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useState } from "react";
import { GoogleLogin as RGLogin } from "react-google-login";
import { LoaderComponent } from "../../../Components/common/loader.component";
import { log } from "../../../Lib/helpers";
import { IApiResponse } from "../../../Services/api-client";
import { AuthApi } from "../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { IReferUserDto } from "../../../Services/Interfaces/AuthInterface";

interface IGoogleLogin {
  referData?: IReferUserDto;
  title?: string;
  afterLogin: (data: any, googleLoginResponse: IApiResponse) => void;
}

export function GoogleLogin(props: IGoogleLogin) {
  const [loading, setLoading] = useState(false);

  const _onGoogleResponse = async (gResponse: any) => {
    setLoading(true);
    log("Google Response: ", gResponse);

    if (gResponse && gResponse.tokenId) {
      const { tokenId, googleId } = gResponse;

      try {
        const response = await AuthApi.GoogleLogin({
          googleId,
          idToken: tokenId,
          ...props.referData,
        });
        if (response.hasError) {
          handleApiError(response, "google-login");

          // message = response.data.message;
        } else {
          // ? Google Login success
          log("Google LOGIN SUCCESS: ", response);

          props.afterLogin(null, response);
        }
      } catch (e) {
        handleCatchError(e);
      }
    }
    setLoading(false);
  };

  return (
    <>
      <RGLogin
        // clientId="1062980516072-dfbv3fqt8jj115fp8o22su6vco5nhj0b.apps.googleusercontent.com"
        clientId="261863546217-bpfb1kuqg2b210lg1h41jcebnql9fopc.apps.googleusercontent.com"
        buttonText="Login with Google"
        onSuccess={_onGoogleResponse}
        onFailure={_onGoogleResponse}
        cookiePolicy={"single_host_origin"}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="login_button google"
          >
            <span>{props.title}</span>
          </button>
        )}
      />
      {loading ? <LoaderComponent /> : null}
    </>
  );
}

GoogleLogin.defaultProps = {
  title: "Sign up with Google"
}