/*
 * File: format.helper.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 8th December 2020 7:46:04 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 8th December 2020 7:46:06 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */
export const currencyFormat = (currency: 'USD' | 'EUR' | 'PKR', number: any) => new Intl.NumberFormat('en-EN', { style: 'currency', currency }).format(number)