/*
 * File: EmployeesListPage.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 18th December 2020 6:41:36 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 18th January 2022 10:05:54 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

// ! OVER RIDDEN WITH ReferralProgram

import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import styles from "./EmployeesListPage.module.scss";
import { ImageComponent } from "../../../Components/common/image.component";
import { PaginationComponent } from "../../../Components/common/Pagination/Pagination.component";
import {
  IPaginate,
  ISelect,
  defaultPaginatedData,
} from "../../../Services/Interfaces";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { alertObject, log, _cloneDeep } from "../../../Lib/helpers";
import { EmployeesApi } from "../../../Services/API/EmployeesApi";
import Loader from "../../../Components/Loader";
import { images } from "../../../assets/images/images";
import { useHistory, useParams } from "react-router-dom";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { AppColors } from "../../../Themes/AppColors";
import {
  EEmployeeRole,
  IEmployee,
  IEmployeeBase,
} from "../../../Services/Interfaces/EmployeeInterface";
import { Button, TextField, TextFieldProps } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import { useFormik } from "formik";
import { AppContext } from "../../../Services/AppContext";
import { ApiResponse } from "../../../Services/api-client";
import { ENotifyType } from "../../../Components/Notify";
import SearchingComponent from "../../../Components/common/searching.component";

type TOrderBy = "id" | "status" | "createdAt";
let keyword = "";

const DEFAULT_DATA: IEmployeeBase = {
  fullName: "",
  title: "",
  email: "",
  phone: "",
  role: EEmployeeRole.Noob,
};
type dataType = keyof typeof DEFAULT_DATA;

const EmployeesListPage = () => {
  const [employees, setEmployees] =
    useState<IPaginate<IEmployee>>(defaultPaginatedData);
  const { notify } = useContext(AppContext);

  const [editableEmpId, setEditableEmpId] = useState(0);

  const [showNewRow, setShowNewRow] = useState(false);

  const [selectAll, setSelectAll] = useState(false);

  const [selectedEmps, setSelectedEmps] = useState<number[]>([]);

  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState<TOrderBy>("id");
  const [data, setData] = useState({
    ...DEFAULT_DATA,
  });

  useEffect(() => {
    _fetchAllEmployees();
  }, [orderBy]);

  const _fetchAllEmployees = async (page = 1, keyword = "") => {
    setLoading(true);
    try {
      const response = await EmployeesApi.GetEmployees({
        page,
        orderBy,
        keyword,
      });
      if (response.hasError) {
        handleApiError(response, "Employees List: ");
      } else {
        // ? Get Employees success
        log("Employees List ALL SUCCESS: ", response);

        setEmployees(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  const history = useHistory();
  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      fullName: Yup.string().required("Employee Name required"),
      // title: Yup.string().required("Job Title required"),
      email: Yup.string().required("Employee Email required"),
      // phone: Yup.string().required("Phone number required"),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      log("emp values: ", values);

      /**
       * After employee Created, remove that row, pull all employees again
       */

      setLoading(true);

      // const { name, address, city, isActive } = values;
      let response: ApiResponse;

      if (editableEmpId) {
        //edit employee
        response = await EmployeesApi.EditEmployee(editableEmpId, {
          ...values,
        });
      } else {
        //create new employee
        response = await EmployeesApi.CreateEmployee({
          ...values,
        });
      }

      let message: string = "Something went wrong";
      let errorType = ENotifyType.Error;

      try {
        if (response.hasError) {
          message = handleApiError(response, "Employee Form SCREEN: ");
        } else {
          log("Create Employee SUCCESS: ", response);
          message = `Employee ${editableEmpId ? "Updated" : "Created"}!`;
          errorType = ENotifyType.Success;

          _fetchAllEmployees(); // fetch employees

          _hideFormRow();
          // clear form or hide

          // response.data;
        }
      } catch (e) {
        handleCatchError(e);
      }

      notify({
        title: message,
        type: errorType,
      });

      setLoading(false);
    },
  });

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = formik;

  useEffect(() => {
    if (editableEmpId) {
      // find the employee by selected id
      // update data form

      try {
        const index = employees.data.findIndex((e) => e.id === editableEmpId);

        const { email, fullName, title, phone } = employees.data[index];

        setData({
          ...data,
          email,
          fullName,
          title,
          phone,
        });
      } catch (e) {}
    } else {
      _resetForm();
    }
  }, [editableEmpId]);

  const defaultInputProps = (key: dataType): TextFieldProps => {
    return {
      value: values[key],
      onChange: handleChange,
      error: touched[key] && !!errors[key],
      onBlur: handleBlur,
      variant: "standard",
      margin: "dense",
      required: true,
      fullWidth: true,
      name: `${key}`,
      helperText: errors[key],
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      },
    };
  };
  const renderFormRow = () => {
    return (
      <tr>
        <td colSpan={6} className="application_item_col">
          <div className={`application_item_outer_wrapper`}>
            <div className="employee_item_wrapper">
              <table>
                <tbody>
                  <tr>
                    <td data-title="Name">
                      <div className="rt-applicantimgname">
                        <TextField
                          label="Full Name"
                          {...defaultInputProps("fullName")}
                        />
                      </div>
                    </td>
                    <td data-title="Title">
                      <TextField
                        label="Job Title"
                        {...defaultInputProps("title")}
                        required={false}
                      />
                    </td>
                    <td data-title="Phone">
                      <TextField
                        label="Mobile Number"
                        {...defaultInputProps("phone")}
                      />
                    </td>
                    <td data-title="Email">
                      <TextField
                        label="Email"
                        {...defaultInputProps("email")}
                        required={false}
                      />
                    </td>
                    <td /> {/* status is not required in form */}
                    <td>
                      <span
                        style={{ cursor: "pointer", marginRight: 20 }}
                        onClick={_hideFormRow}
                      >
                        <i className="icon-cross" style={{ fontSize: 20 }}></i>
                      </span>
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => handleSubmit()}
                      >
                        <i
                          className="icon-checkmark"
                          style={{ color: AppColors.blueMain, fontSize: 22 }}
                        ></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const _showNewRow = () => {
    _resetForm();
    setEditableEmpId(0);
    setShowNewRow(true);
  };
  const _hideFormRow = () => {
    _resetForm();
    setEditableEmpId(0);
    setShowNewRow(false);
  };

  const _resetForm = () => {
    setData({ ...DEFAULT_DATA });
  };

  const _editEmployee = (id = 0) => {
    setEditableEmpId(id);
  };

  const _deleteEmployee = async (id = 0) => {
    setLoading(true);
    try {
      const response = await EmployeesApi.DeleteEmployee(id);
      if (response.hasError) {
        handleApiError(response, "Employee Delete: ");
      } else {
        // ? Delete Employee success
        log("Employee Delete SUCCESS: ", response);

        _fetchAllEmployees();
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  const _onSearch = (keyword: string) => {
    _fetchAllEmployees(1, keyword);
  };

  const _onSelectEmp = (id: number) => {
    const emps: number[] = _cloneDeep(selectedEmps);

    const index = emps.findIndex((e) => e === id);
    if (index < 0) {
      // not selected before
      emps.push(id);
    } else {
      emps.splice(index, 1);
    }

    setSelectedEmps(emps);

    // if (employees.data.length === selectedEmps.length && (selectedEmps.length > 0)) {
    //   // if all employees are selected check select all
    //   setSelectAll(true)
    // } else {
    //   setSelectAll(false)
    // }
  };

  const _selectAll = () => {
    let emps: number[] = [];
    if (selectAll) {
      employees.data.map((e) => {
        return emps.push(e.id);
      });
    } else {
    }

    setSelectedEmps(emps);
  };

  useEffect(() => {
    _selectAll();
  }, [selectAll]);

  return (
    <div className={styles.EmployeesListPage} data-testid="EmployeesListPage">
      <div className="rt-wrapper">
        {/* <div className={`rt-joblistpage rt-applicationspage ${showDetail ? "show-applicantdetail" : "" }`} > */}
        <div className={`rt-joblistpage`}>
          <div className="rt-pagetitle">
            <h1>Employees List</h1>
            <Button
              style={{ marginLeft: 30, marginRight: 30 }}
              variant="contained"
              onClick={_showNewRow}
            >
              Add New Employee
            </Button>
          </div>
          <div>{/* {!loading && <Loader hideModal />} */}</div>
          <div className="rt-jobslistholder">
            <div className="rt-jobslistsarea">
              <div className="at-jobareahead">
                <h3>
                  Newest to Oldest ({employees.total} Employees)
                  {selectedEmps.length
                    ? ` | Selected (${selectedEmps.length} Employees)`
                    : null}
                </h3>

                <SearchingComponent
                  getData={(v: string) => {
                    _onSearch(v);
                  }}
                  loading={loading}
                  placeholder={"Search Employee by Name, Title, Email or Phone"}
                />
              </div>
              <div className="rt-themetableholder" id="application_list">
                <table className="rt-themetable table rt-jobstable">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          style={{ marginRight: 20 }}
                          onChange={() => setSelectAll(!selectAll)}
                          checked={selectAll}
                        />
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Title</span>
                      </th>
                      <th>
                        <span>Mobile</span>
                      </th>
                      <th style={{ width: "0%" }}>
                        <span></span>
                      </th>

                      <th>
                        <span>Email</span>
                      </th>

                      <th
                        onClick={() => setOrderBy("status")}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          style={{
                            color:
                              orderBy === "status"
                                ? AppColors.blueMain
                                : "inherit",
                          }}
                        >
                          Status
                        </span>
                      </th>
                    </tr>
                    {showNewRow && renderFormRow()}
                  </thead>
                  <tbody>
                    {employees.data.map((emp, ind) => {
                      let statusColor = emp.user ? "green" : "red";

                      const { user } = emp;

                      if (editableEmpId === emp.id) {
                        return renderFormRow();
                      }

                      const selected = selectedEmps.find((e) => e === emp.id);

                      return (
                        <tr key={emp.id}>
                          <td colSpan={6} className="application_item_col">
                            <div className={`application_item_outer_wrapper`}>
                              <div className="employee_item_wrapper">
                                <table>
                                  <tbody>
                                    <tr>
                                      <td
                                        data-title="Name"
                                        onClick={() => _onSelectEmp(emp.id)}
                                        style={{ cursor: "pointer" }}
                                      >
                                        <div className="rt-applicantimgname">
                                          <input
                                            type="checkbox"
                                            style={{ marginRight: 20 }}
                                            checked={!!selected}
                                            onChange={() =>
                                              _onSelectEmp(emp.id)
                                            }
                                          />
                                          <figure className="rt-applicantimg">
                                            <ImageComponent
                                              src={
                                                user
                                                  ? user.photo
                                                  : images.UserImg
                                              }
                                            />
                                          </figure>

                                          <h4>{emp.fullName}</h4>
                                        </div>
                                      </td>
                                      <td data-title="Title">
                                        <p>{emp.title}</p>
                                      </td>
                                      <td data-title="Phone">
                                        <p>{emp.phone}</p>
                                      </td>
                                      <td style={{ width: "2%" }}>
                                        <Button variant="contained">
                                          Invite
                                        </Button>
                                      </td>
                                      <td data-title="Email">
                                        <span>{emp.email}</span>
                                      </td>

                                      <td data-title="Status">
                                        <div className="rt-statusbuttonholder">
                                          <div
                                            className={`rt-selectresult rt-bgstatus${statusColor}`}
                                          >
                                            <i className="icon-status-active"></i>
                                            <em>
                                              {emp.user ? "Active" : "Pending"}
                                            </em>
                                          </div>

                                          <Dropdown className="rt-themedropdown">
                                            <Dropdown.Toggle id="dropdown-basic">
                                              <i className="icon-more"></i>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="rt-themedropdownmenu">
                                              <Dropdown.Item
                                                onClick={() =>
                                                  _editEmployee(emp.id)
                                                }
                                              >
                                                <i className="icon-edit"></i>
                                                <span>Edit Employee</span>
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  _deleteEmployee(emp.id)
                                                }
                                              >
                                                <i className="icon-delete"></i>
                                                <span>Delete Employee</span>
                                              </Dropdown.Item>
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>

                  <PaginationComponent
                    {...employees}
                    onClick={(v: number) => {
                      _fetchAllEmployees(v);
                    }}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeesListPage;
