/*
 * File: ReferralProgram.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 20th May 2021 6:31:32 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 18th January 2022 10:03:38 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import {
  InputBase,
  FormControl,
  Select,
  IconButton,
  Collapse,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  TextField,
  TextFieldProps,
  Tooltip,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Loader from "../../../Components/Loader";
// import Button from "../../../Components/common/ButtonComponent/ButtonComponent";
// import MessageBox from "./Message";

import SearchIcon from "@mui/icons-material/Search";
import makeStyles from "@mui/styles/makeStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import clsx from "clsx";
import { EmployeesTable } from "./EmployeesTable";
import RewardsData from "./Rewards";
import {
  IPaginate,
  ISelect,
  defaultPaginatedData,
  beforeHire,
  postHire,
} from "../../../Services/Interfaces";
import { useFormik } from "formik";
import { AppContext } from "../../../Services/AppContext";
import { ApiResponse } from "../../../Services/api-client";
import { ENotifyType } from "../../../Components/Notify";
import MessageBox from "./Message";
import {
  EEmployeeRole,
  IEmployee,
  IEmployeeBase,
  IEmployeeRefer,
  IPostHireReward,
  IReferSettings,
  IStatusReward,
} from "../../../Services/Interfaces/EmployeeInterface";
import { EmployeesApi } from "../../../Services/API/EmployeesApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import * as Yup from "yup";

import { IJob } from "../../../Services/Interfaces/JobInterface";

import {
  IBranch,
  ICompany,
} from "../../../Services/Interfaces/CompanyInterface";

import { JobApi } from "../../../Services/API/JobApi";
import { alertObject, log, _cloneDeep } from "../../../Lib/helpers";
import { AuthApi } from "../../../Services/API/AuthApi";
// import { ImportEmployees } from "./ImportEmployees";
import Template from "./Template";
import { TasksList } from "./TasksList";
import { RewardsApi } from "../../../Services/API/RewardApi";
import { TemplateApi } from "../../../Services/API/TemplateApi";
import { IApiResponse } from "../../../Services/api-client";
import Button from "@mui/material/Button";
import { ImportEmployees } from "./ImportEmployees";
import { Modal } from "react-bootstrap";

export type TReferSaveType = "save" | "invite" | "deactivate";

const titleList = [
  { label: "All", id: "title" + 1, checked: false, type: "title" },
  { label: "Call Center Rep", id: "title" + 2, checked: false, type: "title" },
];
const statusList = [
  { label: "Invited", id: "status" + 1, checked: false, type: "status" },
  { label: "Active", id: "status" + 2, checked: false, type: "status" },
  { label: "Not Active", id: "status" + 1, checked: false, type: "status" },
  { label: "Not Invited", id: "status" + 2, checked: false, type: "status" },
];

const headerCellsData = [
  {
    id: "Name",
    label: "Name",
  },
  { id: "Title", label: "Title" },
  { id: "Mobile", label: "Mobile" },
  {
    id: "EmailAddress",
    label: "Email Address",
  },

  { id: "status", label: "Account Status" },
  { id: "Rewards", label: "Rewards" },
  {
    id: "invite",
    label: "",
  },
  {
    id: "extraData",
    label: "",
  },
];
const beforeHireDefaultData = [
  {
    label: "Employee Friend Viewed Job",
    reward: 0,
    id: "before1",
    status: 0,
    active: false,
  },
  {
    label: "Employee Friend Applied",
    reward: 0,
    id: "before2",
    status: 1,
    active: false,
  },
  {
    label: "Employee Friend App Accepted",
    reward: 0,
    id: "before3",
    status: 3,
    active: false,
  },
  {
    label: "Employee Friend Hired",
    reward: 0,
    id: "before4",
    status: 4,
    active: false,
  },
];
const POST_HIRE_DATA: postHire[] = [
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post1",
  },
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post2",
  },
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post3",
  },
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post4",
  },
];

type dataType = keyof typeof DEFAULT_DATA;

const DEFAULT_DATA: IEmployeeBase = {
  fullName: "",
  title: "",
  email: "",
  phone: "",
  role: EEmployeeRole.Noob,
};
let DEFAULT_SELECT = { value: "", label: "" };

type TOrderBy = "id" | "status" | "createdAt";

const ReferralProgramPage = () => {
  const [tabValue, setTabValue] = React.useState(1);
  const [toggle, setToggle] = React.useState(false);

  const [loading, setLoading] = useState({
    addEmployee: false,
    saveData: false,
    inviteAll: false,
    invite: false,
    fetchEmployee: false,
    fetchJobs: false,
  });
  const [updateTemplate, setUpdateTemplate] = useState(false);
  const [orderBy, setOrderBy] = useState<TOrderBy>("id");
  const [inviteStatus, setInviteStatus] = useState(false);
  const [referType, setReferType] = useState("");
  const [companyInfo, setCompanyInfo] = useState<IBranch | undefined>();
  const [employees, setEmployees] =
    useState<IPaginate<IEmployee>>(defaultPaginatedData);

  const [expanded, setExpanded] = React.useState({
    filter: false,
    title: false,
    status: false,
    message: false,
  });
  const [openMessage, setOpenMessage] = useState(false);
  const [checked, setChecked] = useState({
    title: titleList,
    status: statusList,
  }) as any;

  const classes = useStyles();
  const [editableEmpId, setEditableEmpId] = useState(0);
  const [jobs, setJobs] = useState<ISelect[]>([]);
  const [selectedJob, setSelectedJob] = useState<ISelect>(DEFAULT_SELECT);
  const [selectedJobEmployees, setSelectedJobEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = React.useState([]) as any;
  const [beforeHireData, setBeforeHireData] = useState(beforeHireDefaultData);
  const [postHireData, setPostHireData] = useState(POST_HIRE_DATA);
  // const [message, setMessage] = useState("");
  const [create, setCreate] = useState(false);
  const [statusReward, setStatusReward] = useState([]);
  const { user } = useContext(AppContext);
  const [showNewRow, setShowNewRow] = useState(false);
  const [data, setData] = useState({
    ...DEFAULT_DATA,
  });

  const [showEmpImport, setShowEmpImport] = useState(false);

  const _showNewRow = () => {
    _resetForm();
    setEditableEmpId(0);
    setShowNewRow(true);
  };
  const _hideFormRow = () => {
    _resetForm();
    setEditableEmpId(0);
    setShowNewRow(false);
  };
  const { notify } = useContext(AppContext);

  useEffect(() => {
    if (editableEmpId) {
      // find the employee by selected id
      // update data form
      try {
        const index = employees.data.findIndex((e) => e.id === editableEmpId);
        const { email, fullName, title, phone, role } = employees.data[index];
        setData({
          ...data,
          email,
          fullName,
          title,
          phone,
          role,
        });
      } catch (e) {}
    } else {
      _resetForm();
    }
  }, [editableEmpId]);

  useEffect(() => {
    _fetchAllJobs();
    _fetchAllEmployees();
    _fetchCompanyProfile();
  }, []);

  const _resetForm = () => {
    setData({ ...DEFAULT_DATA });
  };

  const _editEmployee = (id = 0) => {
    setEditableEmpId(id);
  };

  const _deleteEmployee = async (id = 0) => {
    // // setLoading(true);
    try {
      const response = await EmployeesApi.DeleteEmployee(id);
      if (response.hasError) {
        handleApiError(response, "Employee Delete: ");
      } else {
        // ? Delete Employee success
        log("Employee Delete SUCCESS: ", response);
        _fetchAllEmployees(employees.page);
      }
    } catch (e) {
      handleCatchError(e);
    }
    // // setLoading(false);
  };

  const _fetchCompanyProfile = async () => {
    try {
      const response = await AuthApi.GetCompanyProfile();
      if (response.hasError) {
        handleApiError(response, "COMPANY PROFILE: ");
      } else {
        log("COMPANY PROFILE SUCCESS: ", response);
        setCompanyInfo(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  const _fetchAllEmployees = async (page = 1, keyword = "") => {
    setLoading({ ...loading, fetchEmployee: true });
    try {
      const response = await EmployeesApi.GetEmployees({
        page,
        orderBy,
        keyword,
      });
      if (response.hasError) {
        handleApiError(response, "Employees List: ");
      } else {
        // ? Get Employees success
        log("Employees List ALL SUCCESS: ", response);

        setEmployees(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading({ ...loading, fetchEmployee: false });
  };

  const handleExpandClick = (key: string, value: Boolean) => {
    setExpanded({
      ...expanded,
      [key]: value,
    });
  };

  const handleToggle = (i: number, type: string) => () => {
    let newChecked = { ...checked };
    newChecked[type][i].checked = !newChecked[type][i].checked;
    setChecked(newChecked);
  };
  const handleJobChange = (e: any, val: ISelect) => {
    if (val) {
      log("got in handle job change: ", val);
      _fetchReferralSettings(val.value);
      setSelectedJob(val);
      setTabValue(0);
    } else {
      setSelectedJob({
        label: "",
        value: 0,
      });
      setTabValue(1);
    }
  };
  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      fullName: Yup.string().required("Employee Name required"),
      // title: Yup.string().required("Job Title required"),
      email: Yup.string().email().required("Email required"),
      // phone: Yup.string().required("Phone number required"),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      log("emp values: ", values);
      // let phoneVal = values.phone?.replace(/\s/g, "");
      let phoneVal = values.phone?.split("-").join("");
      const newValues = { ...values, phone: phoneVal };

      /**
       * After employee Created, remove that row, pull all employees again
       */

      setLoading({ ...loading, fetchEmployee: true });

      // const { name, address, city, isActive } = values;
      let response: ApiResponse;

      if (editableEmpId) {
        //edit employee
        response = await EmployeesApi.EditEmployee(editableEmpId, {
          ...newValues,
        });
      } else {
        //create new employee
        response = await EmployeesApi.CreateEmployee({
          ...newValues,
        });
      }

      let message: string = "Something went wrong";
      let errorType = ENotifyType.Error;

      try {
        if (response.hasError) {
          message = handleApiError(response, "Employee Form SCREEN: ");
        } else {
          log("Create Employee SUCCESS: ", response);
          message = `Employee ${editableEmpId ? "Updated" : "Created"}!`;
          errorType = ENotifyType.Success;

          _fetchAllEmployees(); // fetch employees

          _hideFormRow();
          // clear form or hide

          // response.data;
        }
      } catch (e) {
        handleCatchError(e);
      }

      notify({
        title: message,
        type: errorType,
      });

      setLoading({ ...loading, fetchEmployee: false });
    },
  });

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    handleSubmit,
    resetForm,
  } = formik;

  const defaultInputProps = (key: dataType): TextFieldProps => {
    return {
      value: values[key],
      onChange: handleChange,
      error: touched[key] && !!errors[key],
      onBlur: handleBlur,
      variant: "standard",
      margin: "dense",
      required: true,
      fullWidth: true,
      name: `${key}`,
      helperText: errors[key],
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      },
    };
  };

  const _fetchAllJobs = async () => {
    setLoading({ ...loading, fetchJobs: true });
    try {
      const response = await JobApi.JobsListAll();
      if (response.hasError) {
        handleApiError(response, "JOB List ALL: ");
      } else {
        // ? Get Jobs success
        log("Jobs List ALL SUCCESS: ", response);

        const jobsList: IJob[] = response.data.data;

        let jobSelect: ISelect[] = [];
        jobsList.map((j: IJob) => {
          return jobSelect.push({
            label: `${j.title} ($${j.salary}) (${j.status})`,
            value: j.id,
          });
        });

        // jobSelect.push({
        //   label: "Select Job",
        //   value: "Select Job",
        // })

        // await jobSelect.reverse();

        setJobs(jobSelect);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading({ ...loading, fetchJobs: false });
  };

  const _fetchReferralSettings = async (id: number) => {
    setLoading({ ...loading, fetchJobs: true });

    try {
      const response = await RewardsApi.GetReferSettings(id);
      if (response.hasError) {
        handleApiError(response, "GET REFERRAL SETTINGS: ");
        setSelectedJobEmployees([]);
        setStatusReward([]);
        setBeforeHireData(beforeHireDefaultData);
      } else {
        // ? Get Data success
        log("GET REFERRAL SETTINGS SUCCESS: ", response);
        const settings = response.data.settings;
        const { statusReward, postHireRewards } = settings;
        let newBeforeHireData: Array<beforeHire> = [];
        beforeHireData.forEach((val, i) => {
          for (let i = 0; i < statusReward.length; i++) {
            if (val.status === statusReward[i].status) {
              newBeforeHireData.push({
                ...val,
                active: statusReward[i].active,
                status: statusReward[i].status,
                reward: statusReward[i].reward,
              });
              break;
            }
          }
        });

        let newPostHireData: IPostHireReward[] = [];
        postHireData.forEach((val, i) => {
          for (let i = 0; i < postHireRewards.length; i++) {
            if (val.daysAfterHire === postHireRewards[i].daysAfterHire) {
              newPostHireData.push({
                ...val,
                daysAfterHire: postHireRewards[i].daysAfterHire,
                reward: postHireRewards[i].reward,
                active: postHireRewards[i].active,
              });
              break;
            }
          }
        });

        setStatusReward(statusReward);
        setBeforeHireData(newBeforeHireData);
        setPostHireData(postHireRewards);
        setSelectedJobEmployees(response.data.selectedEmps);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading({ ...loading, fetchJobs: false });
  };

  const _updateReferralSettings = async (
    type?: TReferSaveType,
    employeeId?: number
  ) => {
    const statusReward: Array<IStatusReward> = [];
    beforeHireData.forEach((data, i) => {
      statusReward.push({
        status: data.status,
        reward: data.reward,
        active: data.active,
      });
    });

    const postHireRewards: Array<IPostHireReward> = [];

    postHireData.forEach((data, i) => {
      postHireRewards.push({
        daysAfterHire: data.daysAfterHire,
        reward: data.reward,
        active: data.active,
      });
    });

    let status = false;
    const selectedEmps = employeeId ? [employeeId] : selectedEmployees;
    if (employeeId) {
      setLoading({ ...loading, invite: true });
    } else {
      if (type === "save") {
        setLoading({ ...loading, saveData: true });
      } else if (type === "invite") {
        setLoading({ ...loading, inviteAll: true });
      }
    }
    const referSettings: IEmployeeRefer = {
      jobId: selectedJob.value,
      selectedEmps: selectedEmps,
      deactivateEmps: type === "deactivate" && selectedJob.value ? true : false,
      settings: {
        statusReward,
        postHireRewards,
      },
    };
    try {
      const response = await RewardsApi.UpdateReferSettings(referSettings);
      if (response.hasError) {
        handleApiError(response, "UPDATE REFERRAL SETTINGS: ");
        status = false;
      } else {
        // ? Get Jobs success
        log("UPDATE REFERRAL SETTINGS SUCCESS: ", response);
        _fetchReferralSettings(selectedJob.value);
        setSelectedEmployees([]);
        status = true;
      }
    } catch (e) {
      handleCatchError(e);
      status = false;
    }
    setLoading({
      ...loading,
      saveData: false,
      inviteAll: false,
      invite: false,
    });
    return status;
  };

  const renderEmployeesData = () => {
    return (
      <>
        <div className="">
          <Collapse in={expanded.filter} timeout="auto" unmountOnExit>
            <div className="row">
              <div className="col-sm-3 ">
                <div
                  className={`${classes.tag} d-flex align-items-center justify-content-between`}
                >
                  <Typography>Title</Typography>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded.title,
                    })}
                    onClick={() => handleExpandClick("title", !expanded.title)}
                    aria-label="show more"
                    size="large"
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </div>
                <Collapse in={expanded.title} timeout="auto" unmountOnExit>
                  <List>
                    {checked.title.map((value: any, i: number) => {
                      const labelId = `${value.id}`;

                      return (
                        <ListItem
                          key={value.id}
                          role={undefined}
                          dense
                          button
                          onClick={handleToggle(i, "title")}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{
                                root: classes.checkbox,
                                checked: classes.checked,
                              }}
                              edge="start"
                              checked={value.checked}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value.label} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
              <div className="col-sm-3 ">
                <div
                  className={`${classes.tag} d-flex align-items-center justify-content-between`}
                >
                  <Typography>Status</Typography>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expanded.status,
                    })}
                    onClick={() =>
                      handleExpandClick("status", !expanded.status)
                    }
                    aria-label="show more"
                    size="large"
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </div>
                <Collapse in={expanded.status} timeout="auto" unmountOnExit>
                  <List>
                    {checked.status.map((value: any, i: number) => {
                      const labelId = `${value.id}`;

                      return (
                        <ListItem
                          key={value.id}
                          role={undefined}
                          dense
                          button
                          onClick={handleToggle(i, "status")}
                        >
                          <ListItemIcon>
                            <Checkbox
                              classes={{
                                root: classes.checkbox,
                                checked: classes.checked,
                              }}
                              edge="start"
                              checked={value.checked}
                              tabIndex={-1}
                              disableRipple
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value.label} />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            </div>
          </Collapse>
        </div>

        <EmployeesTable
          headCells={headerCellsData}
          _hideFormRow={_hideFormRow}
          _fetchAllEmployees={_fetchAllEmployees}
          orderBy={orderBy}
          showNewRow={showNewRow}
          handleSubmit={handleSubmit}
          employees={employees}
          editableEmpId={editableEmpId}
          _deleteEmployee={_deleteEmployee}
          _editEmployee={_editEmployee}
          defaultInputProps={defaultInputProps}
          fetchEmployeeloading={loading.fetchEmployee}
          inviteLoading={loading.invite}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          selectedJob={selectedJob}
          selectedJobEmployees={selectedJobEmployees}
          _updateReferralSettings={_updateReferralSettings}
          setOpenMessage={(val: boolean, id?: number) => handleInvite(val, id)}
          _showNewRow={_showNewRow}
          saveLoading={loading.saveData}
        />
      </>
    );
  };
  const handleInvite = (val: boolean, id?: number) => {
    setOpenMessage(val);
    setReferType("invite");
    if (id) {
      setSelectedEmployees([id]);
    }
  };

  const handleMessageClick = (type: any) => {
    setReferType("");
    if (type === "create") {
      log("opening create template box..");
      setCreate(true);
    } else {
      if (selectedEmployees.length) {
        setOpenMessage(true);
      } else {
        alert("Please select Employees first.");
      }
    }
  };

  useEffect(() => {
    if (tabValue === 3) {
      setToggle(true);
    }
  }, [tabValue]);
  const handleEditBox = (val: boolean) => {
    setToggle(val);
  };

  const _toggleEmpImportPopup = () => {
    setShowEmpImport(!showEmpImport);
  };

  return (
    <>
      <div className="rt-wrapper">
        <div className="rt-pagetitle">
          <h1>Employee Referral Program</h1>
          <div className="rt-profilebox rt-updateprofile mt-4">
            <div className="row">
              <div className={`${classes.search} col-sm-12`}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <Autocomplete
                  options={jobs}
                  fullWidth={true}
                  // openOnFocus
                  // open={!selectedJob.value}
                  // getOptionDisabled={(option) => option === jobs[0]}
                  value={selectedJob}
                  onChange={(e: any, val: any) => handleJobChange(e, val)}
                  getOptionLabel={(option: ISelect) => option.label}
                  classes={{
                    input: classes.autocompleteInput,
                  }}
                  style={{
                    border: selectedJob.value ? "0" : "2px solid red",
                    borderRadius: 5,
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      placeholder="Select Job"
                      variant="outlined"
                      fullWidth
                      required
                      size="medium"
                      InputLabelProps={{
                        classes: {
                          root: classes.cssLabel,
                          // focused: classes.cssFocused,
                        },
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-sm-2">
                {/* <FormControl className={`${classes.formControl} w-100`}>
                  <Select
                    native
                    value="Action"
                    classes={{
                      root: classes.select,
                    }}
                    //   onChange={handleChange}
                    inputProps={{
                      name: "age",

                    }}
                  >
                    <option value={1}>Action</option>
                  </Select>
                </FormControl> */}
              </div>
            </div>

            {loading.fetchJobs && (
              <Loader
                hidemodal
                size={20}
                style={{
                  margin: 10,
                  marginBottom: 0,
                  opacity: loading.fetchJobs ? 1 : 0,
                }}
              />
            )}

            <div
              className="d-flex justify-content-between align-items-center"
              style={{ paddingTop: 10 }}
            >
              <div className="tabs mt-1">
                <span
                  className={`mr-3 font-weight-bold pb-1 ${classes.tab} ${
                    tabValue === 0 ? classes.tabActive : classes.tabInactive
                  }`}
                  onClick={() => selectedJob.label !== "" && setTabValue(0)}
                >
                  Rewards
                </span>
                <span
                  className={`mr-3 font-weight-bold pb-1 ${classes.tab} ${
                    tabValue === 1 ? classes.tabActive : classes.tabInactive
                  }`}
                  onClick={() => setTabValue(1)}
                >
                  Employees
                </span>
                <span
                  className={`mr-3 font-weight-bold pb-1 ${classes.tab} ${
                    tabValue === 2 ? classes.tabActive : classes.tabInactive
                  }`}
                  onClick={() => setTabValue(2)}
                >
                  Templates
                </span>
                <span
                  className={`mr-3 font-weight-bold pb-1 ${classes.tab} ${
                    tabValue === 3 ? classes.tabActive : classes.tabInactive
                  }`}
                  onClick={() => setTabValue(3)}
                >
                  Tasks
                </span>
              </div>

              <div className="mt-2">
                {tabValue === 2 ? (
                  <Button
                    className={`${classes.bottomBtn} ${classes.bgTorqtoise}`}
                    onClick={() => handleMessageClick("create")}
                  >
                    Create Template
                  </Button>
                ) : tabValue !== 0 && tabValue !== 3 && tabValue === 1 ? (
                  <>
                    <Tooltip title="Send message via SMS or Email to selected employees">
                      <Button
                        className={`${classes.bottomBtn} ${classes.bgTorqtoise}`}
                        onClick={() => handleMessageClick("message")}
                      >
                        Send Message
                      </Button>
                    </Tooltip>

                    <Tooltip title="Import Employees using a CSV File">
                      <Button
                        className={`${classes.bottomBtn} ${classes.bgTorqtoise} mx-2`}
                        onClick={_toggleEmpImportPopup}
                      >
                        Import Employees
                      </Button>
                    </Tooltip>
                  </>
                ) : null}
              </div>
            </div>

            {tabValue === 1 && <div>{renderEmployeesData()}</div>}
            {selectedJob.label !== "" && tabValue === 0 && (
              <RewardsData
                selectedJob={selectedJob}
                _updateReferralSettings={_updateReferralSettings}
                beforeHireData={beforeHireData}
                postHireData={postHireData}
                setBeforeHireData={setBeforeHireData}
                setPostHireData={setPostHireData}
                saveLoading={loading.saveData}
                statusReward={statusReward}
              />
            )}
            {tabValue === 2 && (
              <Template
                companyInfo={companyInfo}
                updateTemplate={updateTemplate}
              />
            )}
            {tabValue === 3 && <TasksList jobId={selectedJob?.value} />}

            {openMessage || create ? (
              <MessageBox
                openMessage={openMessage}
                setOpenMessage={(val: boolean) => setOpenMessage(val)}
                inviteStatus={inviteStatus}
                employees={employees}
                selectedEmployees={selectedEmployees}
                referType={referType}
                _updateReferralSettings={_updateReferralSettings}
                companyInfo={companyInfo}
                setSelectedEmployees={setSelectedEmployees}
                handleEditBox={handleEditBox}
                setUpdateTemplate={setUpdateTemplate}
                create={create}
                setCreate={setCreate}
              />
            ) : null}

            {showEmpImport ? (
              <Modal
                show={showEmpImport}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Body className="mx-2">
                  <Typography
                    style={{ fontFamily: "proxima_novabold", fontSize: "25px" }}
                    className="mb-5"
                  >
                    Import Employees using CSV
                  </Typography>
                  <ImportEmployees onFinish={_fetchAllEmployees} />
                </Modal.Body>
                <Modal.Footer className="mt-3">
                  <Button
                    onClick={_toggleEmpImportPopup}
                    className={`${classes.bottomBtn} ${classes.bgBlue}`}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferralProgramPage;

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: "2px",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    position: "absolute",
    top: "49%",
    transform: "translate(0, -50%)",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fill: "#666",
    },
  },
  inputRoot: {
    width: "100%",
  },
  inputInput: {
    padding: "20px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: `1.5px solid #f2f5f9 !important`,
    borderRadius: "10px !important",
  },
  select: {
    border: "1px solid #f2f5f9 ",
    padding: "10px",
    borderRadius: "10px",
  },
  formControl: {
    "&>div": {
      "&:before": {
        content: "none",
      },
    },
  },
  tab: {
    color: "black",
    cursor: "pointer",
  },
  tabActive: {
    borderBottom: `3px solid #2574ff`,
  },
  tabInactive: {
    color: "grey",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  tag: {
    backgroundColor: "#fafafb",
    borderRadius: "5px",
    paddingLeft: "12px",
  },
  checkbox: {
    color: "#93939e !important",
  },
  checked: {
    color: "#63d699 !important",
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    // padding: "0.8rem 2.5rem",
    borderRadius: "7px",
    // marginRight: "10px",
    fontSize: "12px",
    padding: "10px 20px",
  },
  bgWarning: {
    backgroundColor: "#fbc542",
    "&:hover": {
      backgroundColor: "#fbc542",
    },
  },
  bgDanger: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  bgTorqtoise: {
    backgroundColor: "#4169E1",
    "&:hover": {
      backgroundColor: "#0000FF",
    },
  },
  autocompleteInput: {
    paddingLeft: "40px !important",
  },
  searchfield: {
    padding: "3px !important",
  },

  statustablehead: {
    textAlign: "center",
  },

  cssLabel: {
    color: "red",
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
}));
