/*
 * File: PopOver.tsx
 * Project: Dyme-Web
 * File Created: Tuesday, 30th November 2021 1:05:04 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:53:56 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react";
import { Typography, Popover } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
    maxWidth: (props: any) => (props.maxWidth ? props.maxWidth : "500px"),
  },
  paper: {
    padding: theme.spacing(2),
    borderRadius: 10,
    pointerEvents: "none",
    overflow:'initial',
    "&:before": {
      content: "close-quote",
      display: "block",
      width: 0,
      height: 0,
      position: "absolute",
      borderTop: "8px solid transparent",
      borderBottom: "8px solid transparent",
      borderRight: "8px solid #fff",
      left: "-8px",
      top: "15px",
    },
  },
}));
const CommomPopOver = (props: any) => {
  const { handlePopoverClose, anchorEl, id, maxWidth } = props;
  const classes = useStyles({ maxWidth: maxWidth });
  return (
    <Popover
      id={`mouse-over-popover-${id}`}
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      // anchorPosition={{
      //   left: 0,
      //   top: 0,
      // }}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      onClose={handlePopoverClose}
      // disableRestoreFocus
    >
      <Typography>{props.children}</Typography>
    </Popover>
  );
};
export default CommomPopOver;
