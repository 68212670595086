/*
 * File: MapContainer.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 12th February 2020 10:35:22 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Thursday, 30th July 2020 12:59:30 am
 * -----
 * Copyright 2020 - 2020 Allshore, https://allshorestaffing.com
 */

//@ts-ignore
import React, { useEffect, useRef, useState } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import { Config } from "../Config";
import { log } from "../Lib/helpers";

const MapContainer = ({ google, style, onMarkerDrag, coordinates }) => {
  const _onMarkerDrag = (e, d, f) => {
    log("on end", f.latLng.toJSON());
    onMarkerDrag(f.latLng.toJSON());
  };

  const coords = React.useMemo(() => ({ ...coordinates }), [coordinates]);

  return (
    <div style={{ position: "relative" }}>
      <Map
        google={google}
        initialCenter={{
          lat: +coords.latitude,
          lng: +coords.longitude,
        }}
        // center={{
        //   lat: +coordinates.latitude,
        //   lng: +coordinates.longitude
        // }}

        zoom={14}
        style={{ ...style }}
      >
        <Marker
          position={{ lat: +coords.latitude, lng: +coords.longitude }}
          draggable
          onDragend={_onMarkerDrag}
          name={"Current location"}
        />

        {/* <InfoWindow onClose={this.onInfoWindowClose}>
    <div>
      <h1>{this.state.selectedPlace.name}</h1>
    </div>
</InfoWindow> */}
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: Config.GOOGLE_API_KEY,
})(MapContainer);
