/*
 * File: TasksList.tsx
 * Project: Dyme-Web
 * File Created: Monday, 2nd August 2021 10:16:58 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 11th January 2022 2:02:56 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import Table from "@mui/material/Table";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TemplateApi } from "../../../Services/API/TemplateApi";
import { IApiResponse } from "../../../Services/api-client";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import clsx from "clsx";
import {
  ERewardStatus,
  IRewardTask,
} from "../../../Services/Interfaces/EmployeeInterface";
import { RewardsApi } from "../../../Services/API/RewardApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log, _cloneDeep } from "../../../Lib/helpers";
import { PaginationComponent } from "../../../Components/common/Pagination/Pagination.component";
import { defaultPaginatedData, IPaginate } from "../../../Services/Interfaces";
import Loader from "../../../Components/Loader";
import { capitalize } from "lodash";
import { AppContext } from "../../../Services/AppContext";
import { ENotifyType } from "../../../Components/Notify";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router";
import { RouteKeys } from "../../../Routes/RouteKeys";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
      borderBottom: "none",
    },
    table: {
      minWidth: 650,
      border:"1px solid gainsboro",
    },
    headerRow: {
      background: "#eaece9",
      fontSize: "0.4em",
    },
    tableBodyRow: {
      padding: "20px",
      cursor: "pointer",
      textAlign: "left",
    },
    tableBodyCell: {
      paddingTop: 15,
      paddingBottom: 15,
    },
    bottomBtn: {
      color: "#fff",
      textTransform: "capitalize",
      padding: "0.8rem 2.5rem",
      borderRadius: "10px",
      marginRight: "10px",
      fontFamily: "proxima_novabold",
      fontSize: "15px",
    },
    bgDanger: {
      backgroundColor: "red",
      "&:hover": {
        backgroundColor: "red",
      },
    },
    bgTorqtoise: {
      backgroundColor: "#63d699",
      "&:hover": {
        backgroundColor: "#63d699",
      },
    },
    PopupText: {
      fontSize: "20px",
      fontFamily: "proxima_novabold",
    },
    popup: {
      minWidth: "50%",
    },
  })
);

interface ITasksList {
  jobId?: number
}
export const TasksList = (props: ITasksList) => {
  const { notify } = useContext(AppContext);

  const history = useHistory()

  const classes = useStyles();
  const [rewardEmpDialog, setRewardEmpDialog] = useState(false);
  const [deleteTaskDialog, setDeleteTaskDialog] = useState(false);
  const [tasks, setTasks] =
    useState<IPaginate<IRewardTask>>(defaultPaginatedData);
  const [loading, setLoading] = useState(false);

  const [selectedTask, setSelectedTask] = useState<IRewardTask | null>(null);

  // const [employeeName, setEmployeeName] = useState("");

  useEffect(() => {
    _fetchTasks();
  }, [props.jobId]);

  const _fetchTasks = async (page = 1) => {
    setLoading(true);
    try {
      const response = await RewardsApi.RewardsList({
        page,
        jobId: props.jobId
      });
      if (response.hasError) {
        handleApiError(response, "Rewards Tasks List: ");
      } else {
        // ? Get Tasks success
        log("Tasks List SUCCESS: ", response);

        setTasks(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  const _handleRewardEmpPopup = (task: IRewardTask) => {
    if (task.coin) {
      notify({
        title: `Employee is already Rewarded`,
        type: ENotifyType.Info,
      });
      closeRewardEmpDialog();
    } else {
      setSelectedTask(task);
      setRewardEmpDialog(true);
    }
  };
  const closeRewardEmpDialog = () => {
    setSelectedTask(null);
    setRewardEmpDialog(false);
  };

  // reward Employee API
  const _rewardEmployee = async () => {
    if (!selectedTask) {
      closeRewardEmpDialog();
      return false;
    }

    setLoading(true);
    try {
      const response = await RewardsApi.RewardEmployee(selectedTask.id);
      if (response.hasError) {
        handleApiError(response, "Reward Employee: ");

        if (response.status === 403) {
          // payment method is not setup or amount is low.
          // navigate to payment page.

          history.push(`${RouteKeys.BillingPage}`);
        }

      } else {
        // ? Reward Employee success
        log("Reward Employee SUCCESS: ", response);

        const updatedTask: IRewardTask = response.data;

        let newTasksList = _cloneDeep(tasks);

        const foundIndex = newTasksList.data.findIndex(
          (t) => t.id === updatedTask.id
        );

        if (foundIndex > -1) {
          log("FOUND INDEX: ", foundIndex);
          newTasksList.data[foundIndex] = updatedTask;
        }

        log("new tasks list: ", newTasksList);

        setTasks(newTasksList);

        notify({
          title: "Employee Rewarded Successfully",
          type: ENotifyType.Success,
        });
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
    closeRewardEmpDialog();
  };

  // delete task API
  const _deleteTask = async () => {
    if (!selectedTask) {
      closeDeleteTaskDialog();
      return false;
    }
    setLoading(true);
    try {
      const response = await RewardsApi.DeleteTask(selectedTask.id);
      if (response.hasError) {
        handleApiError(response, "delete-task: ");
      } else {
        // ? Task Delete success
        log("Task Delete SUCCESS: ", response);

        let newTasksList = _cloneDeep(tasks);

        newTasksList.data = newTasksList.data.filter(
          (t) => t.id !== selectedTask.id
        );

        log("new tasks list after delete: ", newTasksList);

        setTasks(newTasksList);

        notify({
          title: "Reward Task Deleted Successfully",
          type: ENotifyType.Success,
        });
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
    closeDeleteTaskDialog();
  };

  const openDeleteTaskDialog = (e: any, t: IRewardTask) => {
    e.stopPropagation();
    if (t.coinId) {
      notify({
        title: "Employee is already rewarded. You cannot delete this task.",
        type: ENotifyType.Warning,
      });
      closeDeleteTaskDialog();
    } else {
      setSelectedTask(t);
      setDeleteTaskDialog(true);
    }
  };
  const closeDeleteTaskDialog = () => {
    setDeleteTaskDialog(false);
    setSelectedTask(null);
  };

  const _getTaskStatus = (t: IRewardTask) => {
    if (t.coinId) {
      return ERewardStatus.Rewarded;
    } else if (t.dateHired) {
      return ERewardStatus.InProgress;
    } else if (!t.rewardAmount) {
      return ERewardStatus.Declined;
    }
    return ERewardStatus.Pending;
  };

  const _handleStyle = (task: IRewardTask) => {
    const status: ERewardStatus = _getTaskStatus(task);
    if (status === ERewardStatus.Pending) {
      return "text-warning";
    } else if (status === ERewardStatus.InProgress) {
      return "text-primary";
    }
    return "text-success";
  };
  return (
    <>
      <div className={classes.root}>
        {
          // Delete Task
          <Dialog
            open={deleteTaskDialog}
            onClose={closeDeleteTaskDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
          >
            <DialogContent style={{ height: "110px" }}>
              <DialogContentText
                id="alert-dialog-description"
                className={clsx(classes.PopupText)}
              >
                Are you sure you want to delete this task.
              </DialogContentText>
            </DialogContent>
            <div className="d-flex justify-content-between m-4">
              <Button
                className={`${classes.bottomBtn} ${classes.bgTorqtoise} text-light`}
                onClick={_deleteTask}
              >
                Yes
              </Button>
              <Button
                className={`${classes.bottomBtn} ${classes.bgDanger} text-light`}
                onClick={closeDeleteTaskDialog}
              >
                No
              </Button>
            </div>
          </Dialog>
        }
        {
          // Reward Employee Popup
          <Dialog
            open={rewardEmpDialog}
            onClose={closeRewardEmpDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
          >
            <DialogContent style={{ height: "110px" }}>
              <DialogContentText
                id="alert-dialog-description"
                className={clsx(classes.PopupText)}
              >
                {`Do you want to reward ${selectedTask?.employee.fullName} post hire reward?`}
              </DialogContentText>
            </DialogContent>
            <div className="d-flex justify-content-between m-4">
              <Button
                className={`${classes.bottomBtn} ${classes.bgTorqtoise} text-light`}
                onClick={_rewardEmployee}
              >
                Yes
              </Button>
              <Button
                className={`${classes.bottomBtn} ${classes.bgDanger} text-light`}
                onClick={closeRewardEmpDialog}
              >
                No
              </Button>
            </div>
          </Dialog>
        }
        <div>{loading && <Loader />}</div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell>EMPLOYEE</TableCell>
              <TableCell>FRIEND HIRED</TableCell>
              <TableCell>DATE HIRED</TableCell>
              <TableCell>DAYS EMPLOYED</TableCell>
              <TableCell>REWARD TYPE</TableCell>
              <TableCell>REWARD INCENTIVE</TableCell>
              <TableCell>REWARD AMOUNT</TableCell>
              <TableCell>REWARD STATUS</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tasks.data.map((task) => {
              const hireDate = moment(task.dateHired);
              return (
                <TableRow
                  onClick={() => _handleRewardEmpPopup(task)}
                  className={classes.tableBodyRow}
                  key={task.id}
                >
                  <td className={classes.tableBodyRow}>
                    {task.employee.fullName}
                  </td>
                  <td className={classes.tableBodyRow}>
                    {task.application.user.fullName}
                  </td>
                  <td className={classes.tableBodyRow}>
                    {hireDate.format("L")}
                  </td>
                  <td className={classes.tableBodyRow}>{hireDate.fromNow()}</td>
                  <td className={classes.tableBodyRow}>{task.rewardType}</td>
                  <td className={classes.tableBodyRow}>
                    {task.incentiveDays} Days Hired
                  </td>
                  <td className={classes.tableBodyRow}>${task.rewardAmount}</td>
                  <td
                    className={`${_handleStyle(task)} ${classes.tableBodyRow}`}
                    style={{ fontWeight: "bold" }}
                  >
                    {capitalize(_getTaskStatus(task))}
                  </td>
                  <td
                    onClick={(e) => openDeleteTaskDialog(e, task)}
                    className={classes.tableBodyRow}
                  >
                    <DeleteIcon />
                  </td>
                </TableRow>
              );
            })}
          </TableBody>

          <PaginationComponent
            {...tasks}
            onClick={(v: number) => {
              _fetchTasks(v);
            }}
          />
        </Table>
      </div>
    </>
  );
};
