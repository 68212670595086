/*
 * File: SideBar.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 7th February 2020 1:51:32 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

// ! not used in new UI
import React, { ReactElement, useContext } from "react";
import {
  useTheme,
  ListItemTextProps,
  Drawer,
  List,
  ListItem,
  ListItemText,
  colors,
  Icon,
  Avatar,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useDefaultStyles } from "../Themes/defaultStyles";
import { AppColors } from "../Themes/AppColors";
import { useHistory } from "react-router-dom";
import {
  Home,
  Work,
  AssignmentInd,
  Settings,
  ExitToApp,
  Brightness4,
  Business
} from "@mui/icons-material";
import { RouteKeys } from "./RouteKeys";
import { AppContext } from "../Services/AppContext";
import { ENotifyType } from "../Components/Notify";
import { getInitialsOfName } from "../Lib/helpers";

const useStyles = makeStyles(theme => ({
  root: {},
  drawerList: {
    backgroundColor:
      theme.palette.mode === "dark" ? colors.grey[900] : AppColors.grey,
    borderLeft: 0,
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  drawerItem: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center"
  }
}));

interface IItemText extends ListItemTextProps {
  title: string;
  onPress: Function;
  icon?: ReactElement;
}
const ItemText = (props: IItemText) => {
  const theme = useTheme();
  const cStyles = useStyles();

  const fontColor =
    theme.palette.mode === "dark" ? AppColors.greyLight : AppColors.greyLight;

  return (
    <ListItem button onClick={() => props.onPress()}>
      <div className={cStyles.drawerItem}>
        {props.icon}
        <ListItemText
          primary={props.title}
          primaryTypographyProps={{
            style: {
              color: fontColor,
              fontSize: "11px",
              textTransform: "uppercase"
              // fontWeight: '500'
            }
          }}
        />
      </div>
    </ListItem>
  );
};

export const SideBar = () => {
  const theme = useTheme();

  const { toggleTheme, logout, notify, user } = useContext(AppContext);

  const cStyles = useStyles();

  const styles = useDefaultStyles();

  const history = useHistory();

  const drawerWidth = theme.spacing(12) + 1;

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const _logout = () => {
    logout();
    notify({
      title: "Logged Out!",
      type: ENotifyType.Info
    });
  };

  const fontColor = AppColors.greyLight;

  const fullName = `${user?.user.fullName}`;

  return (
    <Drawer
      PaperProps={{
        style: {
          width: drawerWidth,
          // borderRightWidth: 1,
          flex: 0.2
          // borderColor: AppColors.greyLight,
        }
      }}
      variant="permanent"
    >
      <List className={cStyles.drawerList}>
        <div>
          <ItemText
            title={"Home"}
            onPress={() => _navigate(RouteKeys.AdminDashboard)}
            icon={<Home htmlColor={fontColor} />}
          />

          <ItemText
            title={"Companies"}
            onPress={() => _navigate(RouteKeys.Companies)}
            icon={<Business htmlColor={fontColor} />}
          />

          <ItemText
            title={"Jobs"}
            onPress={() => _navigate(RouteKeys.JobsList)}
            icon={<Work htmlColor={fontColor} />}
          />

          <ItemText
            title={"Applicants"}
            onPress={() => _navigate(RouteKeys.ApplicantsList)}
            icon={<AssignmentInd htmlColor={fontColor} />}
          />
        </div>

        <div>
          <ItemText
            title={"Theme"}
            onPress={() => toggleTheme()}
            icon={<Brightness4 htmlColor={fontColor} />}
          />
          <ItemText
            title={"Settings"}
            onPress={() => _navigate(RouteKeys.AdminSettings)}
            icon={
              <Avatar style={{ width: theme.spacing(5) }}>
                {getInitialsOfName(fullName)}
              </Avatar>
            }
          />

          <ItemText
            title={"Logout"}
            onPress={_logout}
            icon={<ExitToApp htmlColor={fontColor} />}
          />
        </div>
      </List>
    </Drawer>
  );
};
