/*
 * File: searching.component.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 6th January 2021 2:30:12 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 5th January 2022 7:36:03 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect } from "react";
import { debounce } from "lodash";
import Loader from "../Loader";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchbox: {
     border: "2px solid",
     borderRadius:"3px !important",
     borderColor: "rgba(224, 224, 224, 1)",
     "&:hover":{
      borderColor:"#666"
    },
    },
    searchEmployees : {
      padding : '3px 40px !important',
     
    }
  })
);

let keyword: string = "";
let searchFunc: Function = () => {};

interface IProps {
  getData(keyword: string): void;
  loading?: boolean;
  placeholder?: string;
}

export default function SearchingComponent(props: IProps) {
  const classes = useStyles();
  const [active, setActive ] = React.useState(false)
  useEffect(() => {
    keyword = "";
    searchFunc = debounce(props.getData, 500);

    return () => {
      searchFunc = () => {};
      keyword = "";
    };
  }, []);

  const onChangeKeyword = (v: any) => {
    setActive(true)
    keyword = v.target.value || "";
    searchFunc(keyword);
  };

  return (
    <form className="rt-formtheme rt-formsearch">
      <fieldset>
        {
          <Loader
            hidemodal
            size={20}
            style={{
              margin: 10,
              marginBottom: 0,
              opacity: props.loading ? 1 : 0,
            }}
          />
        }
        <div className={`${classes.searchbox} form-group p-1`} >
          <i className="icon-search1" style={{paddingLeft: 10}}></i>
          <input
            type="search"
            name="search"
            className={`form-control ${classes.searchEmployees}`}
            onChange={onChangeKeyword}
            placeholder={props.placeholder}
          />
        </div>
      </fieldset>
    </form>
  );
}

SearchingComponent.defaultProps = {
  placeholder: "Search by Name, Status, and Location…",
};
