/*
 * File: PaymentApi.ts
 * Project: Dyme-Web
 * File Created: Thursday, 19th August 2021 12:51:36 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Sunday, 27th February 2022 11:35:43 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { log } from "../../Lib/helpers";
import { ApiGet, ApiPost } from "../api-client";

/**
 * Get Payments Record of business when amount is charged or deducted.
 *
 * @param page Page
 * @param limit Limit
 * @returns
 */
const GetPaymentsRecord = async (page = 1, limit = 10, jobId = null) => {
  let query = `payment/transactions?page=${page}&limit=${limit}`;

  // if (jobId) {
  //   query = `${query}`
  // }

  const data = await ApiGet(query);

  log("☁️ Payments Record RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

const GetRemainingBalance = async () => {
  const data = await ApiGet(`payment/balance`);

  log("☁️ Account Balance RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

export enum EChargeVia {
  Card = "via-card",
  Bank = "via-bank",
}

/**
 * Charge Business Account
 */
const ChargeAccount = async (amount: number, via: EChargeVia) => {
  const data = await ApiPost(`payment/${via}`, {
    amount,
  });

  log("☁️ Charge Business RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Plaid Token
 *
 */
const GetPlaidToken = async () => {
  const data = await ApiGet(`payment/plaid/token`);

  log("☁️ Get Plaid Token RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Store User Plaid Bank account Token
 *
 */
const SaveUserBank = async (account_id: string, public_token: string) => {
  const data = await ApiPost(`payment/plaid/token`, {
    account_id,
    public_token,
  });

  log("☁️ Save user Plaid Bank Token RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Store User Stripe Credit / Debit Card Token
 *
 */
const SaveUserCard = async (payment_method: string) => {
  const data = await ApiPost(`payment/stripe/attach-card`, { payment_method });

  log("☁️ Save user Stripe Card Token RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Connected Bank accounts of this user.
 * @returns
 */
const GetConnectedBanks = async () => {
  const data = await ApiGet(`payment/stripe/my-banks`);

  log("☁️ Get My Banks RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Connected Cards of this user.
 */
const GetConnectedCards = async () => {
  const data = await ApiGet(`payment/stripe/my-cards`);

  log("☁️ Get My Cards RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

export const PaymentApi = {
  GetPaymentsRecord,
  GetRemainingBalance,
  ChargeAccount,

  GetPlaidToken,

  SaveUserBank,
  SaveUserCard,

  GetConnectedBanks,
  GetConnectedCards,
};
