/*
 * File: index.ts
 * Project: jobapp-admin
 * File Created: Friday, 31st January 2020 12:34:30 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Friday, 26th November 2021 1:00:31 am
 * -----
 * Copyright 2020 - 2020 Allshore, https://allshorestaffing.com
 */

import { create } from "apisauce";
import { Config } from "../Config";
import { log, storageGet } from "../Lib/helpers";
import Reactotron from "reactotron-react-js";
import { ILoginResponse } from "./Interfaces/AuthInterface";

export interface IApiData {
  data?: object;
  errors?: Array<string>;
}

export interface IApiResponse {
  data: IApiData | any;
  status: number;
  ok: boolean;
  hasError: boolean;
}

/**
 * GU Api Response Class
 */
export class ApiResponse implements IApiResponse {
  hasError: boolean;
  data: IApiData | any;
  status: number;
  ok: boolean;

  constructor(hasError: boolean = false, response: IApiResponse) {
    log(":100: response in const:  ", response);
    // const errorsList = getErrorsList(response);
    const { data, status } = response;
    this.hasError = hasError;
    this.data = data;
    this.ok = !hasError;
    // this.errors = errorsList[0];
    // this.errorCodes = errorsList[1];
    this.status = status;
  }

  static DummyResponse() {
    return new ApiResponse(false, {
      data: null,
      hasError: false,
      ok: false,
      status: 200
    });
  }
}

/**
 * APP API Client
 */
const ApiClient = (isFile = false) => {
  // const gUser = getGlobalUser();
  // let userToken = '';
  // if (gUser) {
  //   userToken = gUser.access_token;

  //   //? CHECK IF TOKEN EXPIRED OR NOT

  //   AuthService.isTokenExpired()
  // }

  let user: ILoginResponse | null = storageGet("user");

  let userToken = user ? user.accessToken : "";

  let api =create({
        baseURL: Config.API_URL,
        headers: {
          "Content-Type": isFile ? "multipart/form-data" : "application/json",
          Authorization: `Bearer ${userToken}`
        },
        timeout: 60000
      });

  //@ts-ignore
  api.addMonitor(Reactotron.apisauce);

  // api.addRequestTransform(request => {
  //   if (request.data) {
  //     request.data = qs.stringify(request.data)
  //   }
  //   log('API CLIENT is: ', request)
  // })

  return api;
};
/**
 * API POST
 */
export const ApiPost = async (url = "", data = {}): Promise<IApiResponse> => {
  log("api post: ", url);

  // data = {...data, access_token}

  let api = ApiClient();

  const response = await api.post(url, data);

  log("POST Response before parse: ", response);

  _logoutOnError(response.status);

  // return validateResponse(response, url, data, "post");
  return parseResponse(response);
};

/**
 * API PUT
 */
export const ApiPut = async (url = "", data = {}): Promise<IApiResponse> => {
  log("api put: ", url);

  let api = ApiClient();

  const response = await api.put(url, data);

  log("PUT Response before parse: ", response);

  _logoutOnError(response.status);

  return parseResponse(response);
};

//? LOGOUT if any of these status codes
const LOGOUT_STATUS_LIST: number[] = [401];

const _logoutOnError = (errorCode = 0) => {
  if (LOGOUT_STATUS_LIST.includes(errorCode)) {
    log(`LOGGING OUT GLOBAL due to ${errorCode} GET 🚪`);
    // logoutGlobal();
  }
};

/**
 * GU API Get Request
 *
 * @typedef {ApiResponse}
 *
 */
export const ApiGet = async (url = "", data = {}) => {
  log("api get: ", url);

  const response = await ApiClient().get(url, data);

  log("GET Response before parse: ", response);

  _logoutOnError(response.status);

  return parseResponse(response);
};

/**
 * GU API DELETE Request
 *
 */
export const ApiDelete = async (url = "", data = {}) => {
  log("api delete: ", url, data);

  const response = await ApiClient().delete(url, data, { data });

  log("DELETE Response before parse: ", response);

  return parseResponse(response);
};

/**
 * GU API PATCH Request
 *
 */
export const ApiPatch = async (url = "", data = {}) => {
  log("api patch: ", url, data);

  // const {access_token} = getGlobalUser();

  const response = await ApiClient().patch(url, { ...data });

  log("PATCH Response before parse: ", response);

  return parseResponse(response);
};

/**
 * GU API UPLOAD Request
 *
 */
export const ApiUploadPost = async (url = "", data = {}) => {
  log("api upload post: ", url, data);

  const response = await ApiClient(true).post(url, data);

  log("Upload POST Response before parse: ", response);

  return parseResponse(response);
};


/**
 * Parse each API response in ApiResponse format
 *
 * @param {Object} response
 */
const parseResponse = (response: IApiResponse | any): Promise<IApiResponse> => {
  if (response.ok) {
    log("response ok: ", response);
    return Promise.resolve(new ApiResponse(false, response));
  }
  // error response
  return new Promise(resolve => {
    // if connected
    // _status
    // _errors
    // if no connection
    // problem
    // NETWORK_ERROR
    resolve(new ApiResponse(true, response));
  });
};

export const returnIfLoggedIn = (response: any) => {
  const user = true; //getGlobalUser()
  if (user) {
    return response;
  }

  throw new Error("440"); //"Please login again");
};
