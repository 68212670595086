/*
 * File: ResetPasswordPage.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 3rd December 2020 8:01:26 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 8:40:40 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect, useState } from "react";
import { images } from "../../../assets/images/images";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import queryString from "query-string";
import { LoaderComponent } from "../../../Components/common/loader.component";
import { toastify } from "../../../Services/toast.service";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log } from "../../../Lib/helpers";
import { AuthApi } from "../../../Services/API/AuthApi";
import { stat } from "fs";

const resetSchema = yup.object().shape({
  password: yup.string().required(),
  confirm: yup.string().required(),
});

interface ResetPasswordProps {
  email: string;
}

const ResetPasswordPage = (props: ResetPasswordProps) => {
  const { email } = props;
  const [loading, setLoading] = useState(false);
  const [_errors, setErrors] = useState<any>({});
  const history = useHistory();
  const location = useLocation();
  const queries = queryString.parse(location.search);

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(resetSchema),
    defaultValues: {
      code: "",
      password: "",
      confirm: "",
    },
  });
  const [showPass, setShowPass] = useState({ pass: false, confirmPass: false });

  useEffect(() => {
    verifyLink();
  }, []);

  const verifyLink = async () => {
    // return await postRequest(endpoints.auth.verify(), queries).then((response: AxiosResponse<any>) => {
    //     if (response.data.status !== 'success') {
    //         toastify('error', 'Invalid link, reset password again.');
    //         history.push('/auth/forgot')
    //     }
    //     return response.data;
    // })
  };

  const submit = async (v: any) => {
    const payload = {
      ...v,
      email: email,
    };
    const response = await AuthApi.ResetWebPassword(payload);
    try {
      if (response.hasError) {
        toastify(
          "error",
          typeof response?.data?.message === "object"
            ? response?.data?.message[0]
            : response.data.message
        );
      } else {
        log("RESET PASSWORD: ", response);
        history.push("/");
        toastify("success", response.data.message);
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  return (
    <div className="ForgotPasswordPage" data-testid="ForgotPasswordPage">
      <figure className="rt-loginimg">
        <img src={images.loginBG} alt="Login Image" />
      </figure>
      <div className="rt-loginarea">
        <div className="rt-logincontent">
          <div className="rt-logoarea">
            <strong className="rt-logo">
              <img src={images.Logo} alt="Logo Image" />
            </strong>
          </div>
          <div className="rt-loginsignupholder">
            <div className="rt-formtextarea">
              <div className="rt-forheading">
                <h3>Reset Password</h3>
              </div>
              <div className="rt-description">
                <p>Enter your new password</p>
              </div>
            </div>
            <form
              className="rt-formtheme rt-formsignin"
              onSubmit={handleSubmit(submit)}
            >
              <fieldset>
                <div className="form-group">
                  <label>Code</label>
                  <div className="rt-inputiconholder">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="code"
                      {...register('code')}
                    />
                    <span className="text-danger">
                      <ErrorMessage errors={errors} name="code" />
                    </span>
                    {_errors.code && (
                      <span className="text-danger">{_errors.code}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  {showPass.pass ? (
                    <i
                      onClick={() =>
                        setShowPass({ ...showPass, pass: !showPass.pass })
                      }
                      className="icon-eye show_hide_password"
                    ></i>
                  ) : (
                    <i
                      onClick={() =>
                        setShowPass({ ...showPass, pass: !showPass.pass })
                      }
                      className="icon-eye-blocked1 show_hide_password"
                    ></i>
                  )}
                  <div className="rt-inputiconholder">
                    <input
                      type={showPass.pass?'text':'password'}
                      className="form-control"
                      placeholder="*****"
                      {...register('password', {
                        required: "Password is required",
                        min: "Password minimum length must be 8 characters",
                        minLength: 8,
                      })}
                    />
                    <span className="text-danger">
                      <ErrorMessage errors={errors} name="password" />
                    </span>
                    {_errors.password && (
                      <span className="text-danger">{_errors.password}</span>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label>Confirm Password</label>
                  {showPass.confirmPass ? (
                    <i
                      onClick={() =>
                        setShowPass({ ...showPass, confirmPass: !showPass.confirmPass })
                      }
                      className="icon-eye show_hide_password"
                    ></i>
                  ) : (
                    <i
                      onClick={() =>
                        setShowPass({ ...showPass, confirmPass: !showPass.confirmPass })
                      }
                      className="icon-eye-blocked1 show_hide_password"
                    ></i>
                  )}
                  <div className="rt-inputiconholder">
                    <input
                      type={showPass.confirmPass?'text':'password'}
                      className="form-control"
                      placeholder="*****"
                      {...register('confirm')}
                    />
                    <span className="text-danger">
                      <ErrorMessage errors={errors} name="confirm" />
                    </span>
                    {_errors.confirm && (
                      <span className="text-danger">{_errors.confirm}</span>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="rt-btnlogin"
                  disabled={loading}
                >
                  {loading ? (
                    <LoaderComponent />
                  ) : (
                    <i className="icon-right-arrow"></i>
                  )}
                  <span>Reset Password</span>
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
