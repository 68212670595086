/*
 * File: PageHeader.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 7th February 2020 1:46:29 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 10:26:44 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react";
import {
  Container,
  Typography,
  Button,
  Paper,
  Divider,
  Box,
  Breadcrumbs,
  Link,
  colors
} from "@mui/material";
import { useDefaultStyles } from "../Themes/defaultStyles";
import { Add } from "@mui/icons-material";
import { RouteKeys } from "../Routes/RouteKeys";
import { useHistory } from "react-router-dom";
import { Config } from "../Config";

type RouteTypes = keyof typeof RouteKeys;
export interface IMenuItem {
  route: typeof RouteKeys[RouteTypes];
  title: string;
}

export interface IPageHeader {
  heading: string;
  btnTitle?: string;
  onBtnPress?: Function;
  links?: IMenuItem[];
}
export const PageHeader: React.FC<IPageHeader> = props => {
  const styles = useDefaultStyles();

  const history = useHistory();

  let onBtnPress: Function = () => {};

  if (props.onBtnPress) {
    onBtnPress = props.onBtnPress;
  }

  const handleClick = (link: string) => {
    history.push(link);
    console.info("You clicked a breadcrumb.");
  };

  return (
    <>
      <Box className={styles.pageHeader}>
        <Typography variant="h4">{props.heading}</Typography>
        {props.btnTitle && (
          <Button
            onClick={() => onBtnPress()}
            color="primary"
            variant="contained"
          >
            <Add />
            &nbsp;{props.btnTitle}
          </Button>
        )}
      </Box>

      <Breadcrumbs style={{ paddingLeft: 20 }} aria-label="breadcrumb">
        <b>{Config.APP_VERSION}</b>

        {props.links?.map(link => (
          <Link
            key={link.route}
            style={{color: colors.blue[500]}}
            aria-current="page"
            onClick={() => handleClick(link.route)}
          >
            {link.title}
          </Link>
        ))}
      </Breadcrumbs>

      <Divider />
    </>
  );
};

PageHeader.defaultProps = {
  links: [
    // {
    //   title: "Admin Home",
    //   route: RouteKeys.AdminDashboard
    // },
    {
      title: "Jobs",
      route: RouteKeys.JobsList
    },
    {
      title: "New Job",
      route: RouteKeys.AddJob
    },
    {
      title: "Categories",
      route: RouteKeys.CategoryList
    },
    // {
    //   title: "Add Category",
    //   route: RouteKeys.AddJob
    // }
  ]
};
