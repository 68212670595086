/*
 * File: RewardTasksApi.ts
 * Project: Dyme-Web
 * File Created: Tuesday, 3rd August 2021 2:40:30 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Sunday, 27th February 2022 11:35:43 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { log } from "../../Lib/helpers";
import { ApiDelete, ApiGet, ApiPatch, ApiPost, ApiPut } from "../api-client";
import { defaultRequestQuery, IRequestQuery } from "../Interfaces";
import { IEmployeeRefer } from "../Interfaces/EmployeeInterface";

/**
 * Get Refer Settings
 * @param employeeId - EmployeeID
 */
const GetReferSettings = async (jobId: number | string) => {
  const data = await ApiGet(`employees-refer/${jobId}`);

  log("☁️ Get Job Refer Settings RESPONSE: ", data);

  return data;
};

/**
 * Update Refer Settings
 * @param referSettings - IEmployeeRefer
 */
const UpdateReferSettings = async (referSettings: IEmployeeRefer) => {
  const data = await ApiPut(`employees-refer`, referSettings);

  log("☁️ Update Job Refer Settings RESPONSE: ", data);

  return data;
};

interface IRewardsQuery extends IRequestQuery {
  jobId?: number;
}

/**
 * Get Reward Tasks List paginated result
 */
const RewardsList = async (
  query: IRewardsQuery = { ...defaultRequestQuery }
) => {
  query = {
    ...defaultRequestQuery,
    ...query,
  };
  const data = await ApiGet(`employees-rewards`, query);

  log("☁️ GET Rewards List RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Reward Employee
 * @param rewardId - Reward ID
 */
const RewardEmployee = async (rewardId: number) => {
  const data = await ApiPatch(`employees-rewards/${rewardId}`);

  log("☁️ Reward Employee RESPONSE: ", data);

  return data;
};

/**
 * Gift coins to Employee Manually
 * @param employeeIdList - Employee IDs List
 * @param coins - Number of Coins
 * @param message - Message to employee
 */
const GiftMultipleEmployees = async (
  employeeIdList: number[],
  coins: number,
  message: string
) => {
  const data = await ApiPost(`employees-rewards/bulk`, {
    employeeIdList,
    coins,
    message,
  });

  log("☁️ Gift Employees RESPONSE: ", data);

  return data;
};

/**
 * Delete Reward Task
 * @param rewardId - Reward ID
 */
const DeleteTask = async (rewardId: number) => {
  const data = await ApiDelete(`employees-rewards/${rewardId}`);

  log("☁️ Delete Reward Task RESPONSE: ", data);

  return data;
};

/**
 * Get Rewards Given Record
 *
 * @param page Page
 * @param limit Limit
 * @returns
 */
const GetGiftsRecord = async (page = 1, limit = 10, jobId = null) => {
  let query = `payment/transactions/gifts?page=${page}&limit=${limit}`;

  const data = await ApiGet(query);

  log("☁️ Gifts Record RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

export const RewardsApi = {
  RewardsList,

  GetGiftsRecord,

  RewardEmployee,
  GiftMultipleEmployees,

  DeleteTask,
  GetReferSettings,
  UpdateReferSettings,
};
