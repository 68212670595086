/*
 * File: billing.component.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 28th December 2020 8:36:18 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 31st January 2022 9:49:53 pm
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */

// import { useStripe } from "@stripe/react-stripe-js";
import React, { useContext, useEffect, useState } from "react";
// import { images } from "../../../../assets/images/images";
// import Loader from "../../../../Components/Loader";
import {
  // alertObject,
  log,
} from "../../../../Lib/helpers";
import { AuthApi } from "../../../../Services/API/AuthApi";
import { AppContext } from "../../../../Services/AppContext";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import {
  EACL,
  IPaymentTransaction,
  IStripeBank,
  IStripeCard,
} from "../../../../Services/Interfaces/AuthInterface";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { WalletSection } from "./WalletSection";
import PaymentHistory from "./PaymentHistory";
import { PaymentApi } from "../../../../Services/API/PaymentApi";
import { PaginationComponent } from "../../../../Components/common/Pagination/Pagination.component";
import {
  defaultPaginatedData,
  IPaginate,
} from "../../../../Services/Interfaces";
import { checkUserAccess, TLinks } from "../EditProfilePage/EditProfilePage";
import { EditCompanyComponent } from "../user/edit-company.component";
import { ManagersList } from "../user/managers/ManagersList";
import { CustomDyme } from "../user/CustomDyme";

interface Tabprops {
  // children?: React.ReactNode;
  // index: any;
  // value: any;
  selectedTab: TLinks;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabtop: {
    fontFamily: "proxima_novabold",
    fontSize: "18px",
    color: "#2574ff",
  },
}));

export const BillingManagementComponent = (props: Tabprops) => {
  const selectedTab = props.selectedTab;

  const [loadingPayment, setLoadingPayment] = useState(false);
  const [loadingTrans, setLoadingTrans] = useState(true);
  const [transactions, setTrans] =
    useState<IPaginate<IPaymentTransaction>>(defaultPaginatedData);
  const [card, setCard] = useState<IStripeCard | null>(null);
  const [bank, setBank] = useState<IStripeBank | null>(null);

  const { user } = useContext(AppContext);

  // const stripe = useStripe();
  // const elements = useElements();

  useEffect(() => {
    if (selectedTab === "billing" || selectedTab === "payment-history") {
      _getOldTransactions();
      _getConnectedPaymentMethods();
    }
  }, [selectedTab]);

  const _getConnectedPaymentMethods = () => {
    _getUserCard();
    _getUserBank();
  };

  const _getOldTransactions = async (page = 1) => {
    setLoadingTrans(true);
    try {
      const response = await PaymentApi.GetPaymentsRecord(page);
      if (response.hasError) {
        handleApiError(response, "Get OLD Transactions: ", false);
      } else {
        // ? Get Transactions successfully
        log("Get Payment Transactions SUCCESS: ", response);

        setTrans(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoadingTrans(false);
  };

  const _getUserCard = async () => {
    setLoadingPayment(true);
    try {
      const response = await PaymentApi.GetConnectedCards();
      if (response.hasError) {
        handleApiError(response, "Get Stripe Account: ");
      } else {
        // ? Get Customer Payment Info successfully

        let cardInfo = response.data?.length ? response.data[0] : null;

        log("Get Customer Payment Info SUCCESS: ", cardInfo);
        setCard(cardInfo);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoadingPayment(false);
  };

  const _getUserBank = async () => {
    setLoadingPayment(true);
    try {
      const response = await PaymentApi.GetConnectedBanks();
      if (response.hasError) {
        handleApiError(response, "get-banks");
      } else {
        // ? Get Customer Bank Info successfully
        log("Get Customer Bank Info SUCCESS: ", response);

        let bankInfo = response.data?.length ? response.data[0] : null;

        setBank(bankInfo);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoadingPayment(false);
  };

  const checkAccess = (permission: EACL) => {
    const role = user?.user.role;

    if (permission === EACL.ACDL) {
      // todo: check if company has paid for this service.
      // todo: implement this later.
    }

    return checkUserAccess(role, permission);
  };

  const isFirstTab = selectedTab === "company-profile" || selectedTab === "";
  const isAdminManagement = selectedTab === "managers";
  const isWalletTab = selectedTab === "billing";

  return (
    <div className={`rt-billingtab`}>
      {/* <Typography className={classes.Headings}>Billings</Typography> */}
      <div className="rt-profilebox rt-businessplanbilling">
        <div className="rt-profileboxhead">
          {/* <div> */}
          {isWalletTab && (
            <WalletSection
              getPaymentInfo={_getConnectedPaymentMethods}
              card={card}
              bank={bank}
              user={user?.user}
            />
          )}
          {selectedTab === "payment-history" && (
            <>
              <PaymentHistory
                loadingTrans={loadingTrans}
                transactions={transactions.data}
                loadingPayment={loadingPayment}
                refresh={_getOldTransactions}
              />
              <div style={{ margin: 20 }}>
                <PaginationComponent
                  {...transactions}
                  onClick={(v: number) => {
                    _getOldTransactions(v);
                  }}
                />
              </div>
            </>
          )}
          {checkAccess(EACL.ACP) && isFirstTab ? (
            <EditCompanyComponent />
          ) : null}

          {checkAccess(EACL.ACDL) && selectedTab === "custom-dyme" ? (
            <CustomDyme />
          ) : null}

          {isAdminManagement && checkAccess(EACL.ABA) && <ManagersList />}
          {/* </div> */}
        </div>

        <div className="rt-packageplans">
          <ul></ul>
        </div>
      </div>
    </div>
  );
};
