/*
 * File: ApplicantStatsComponent.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 4th December 2020 12:33:16 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:33:18 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect, useState } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { Link } from "react-router-dom";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { JobApi } from "../../../Services/API/JobApi";
import { IApplicationStats, EApplicationStatus } from "../../../Services/Interfaces/ApplicationInterface";
import { TotalJobPostedComponent } from "./TotalJobPosted.component";

interface IApplicantStatsComponent {
  data: IApplicationStats,
  loading: boolean;
}

const ApplicantStatsComponent = ({ data: stats, loading }: IApplicantStatsComponent) => {


  useEffect(() => {
    // const $sub = RxApplicantionsStats.lisener.subscribe((v: IApplicationStats) => setStats(v));
    // fetch();
    // return () => {
    //     $sub.unsubscribe();
    // }
  }, []);



  //EApplicationStatus

  let applied = stats.totalApplications; // status : 1
  let accepted = 0; // status : 3
  let hired = 0; // status: 4
  let rejected = 0; // status: 5
  let viewed = 0; // status : 2

  stats.applicationStats.map(stat => {
    switch (stat.status) {
      // case EApplicationStatus.Applied:
      //   applied = stat.count; break;
      case EApplicationStatus.Hired:
        hired = stat.count; break;
      case EApplicationStatus.Accepted:
        accepted = stat.count; break;
      case EApplicationStatus.Declined:
        rejected = stat.count; break;
      case EApplicationStatus.Viewed:
        viewed = stat.count; break;
    }
  })

  const hiredPercent = applied > 0 ? Math.round((hired / applied) * 100) : 0
  const rejectPercent = applied > 0 ? Math.round((rejected / applied) * 100) : 0

  return (
    <div data-testid="ApplicantStatsComponent">
      <div className="rt-themebox rt-applicantsstates">
        <div className="rt-themeboxhead">
          <h2>Applicant Stats</h2>
        </div>
        <div className="rt-statescontentholder">
          <span className="rt-presentyear">{new Date().getFullYear()}-Present</span>
          <ul className="rt-userstates">
            <li>
              <div className="rt-states rt-appliedstates">
                <div className="rt-progressholder">
                  <CircularProgressbarWithChildren
                    value={100}
                    className="chart-active-job"
                    styles={{
                      path: {
                        stroke: "var(--DodgerBlue)",
                      },
                      background: {
                        fill: "var(--DodgerBlue)",
                      },
                    }}
                  >
                    <div className="inside-circle">100%</div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="rt-statescontent">
                  <h3>{applied}</h3>
                  <span>Applied</span>
                </div>
              </div>
            </li>
            <li>
              <div className="rt-states rt-acceptedstates">
                <div className="rt-progressholder">
                  <CircularProgressbarWithChildren
                    value={hiredPercent}
                    className="chart-active-job"
                    styles={{
                      path: {
                        stroke: "#3dd598",
                      },
                      background: {
                        fill: "#3dd598",
                      },
                    }}
                  >
                    <div className="inside-circle">
                      {hiredPercent}%
                      {/* <i className="icon-jobs c-db"></i> */}
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="rt-statescontent">
                  <h3>{hired}</h3>
                  <span>Hired</span>
                </div>
              </div>
            </li>
            <li>
              <div className="rt-states rt-hiredstates">
                <div className="rt-progressholder">
                  <CircularProgressbarWithChildren
                    value={rejectPercent}
                    className="chart-active-job"
                    styles={{
                      path: {
                        stroke: "red",
                      },
                      background: {
                        fill: "red",
                      },
                    }}
                  >
                    <div className="inside-circle">
                      {rejectPercent}%
                      {/* <i className="icon-jobs c-db"></i> */}
                    </div>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="rt-statescontent">
                  <h3>{rejected}</h3>
                  <span>Declined</span>
                </div>
              </div>
            </li>
          </ul>
          <div className="rt-postajobbutton">
            <Link to={RouteKeys.AddJob} className="rt-btnpostajob">
              <span>Create A Job</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="rt-themebox rt-jobpostedrates">
        <ul className="rt-userstates">
          <TotalJobPostedComponent active={stats.activeJobs} total={stats.totalJobs} />
          <li>
            <div className="rt-states rt-hiredstates">
              <div className="rt-progressholder">
                <CircularProgressbarWithChildren
                  value={hiredPercent}
                  className="chart-active-job"
                  styles={{
                    path: {
                      stroke: "#3dd598",
                    },
                    background: {
                      fill: "#3dd598",
                    },
                  }}
                >
                  <div className="inside-circle">
                    <i
                      className="icon-suitcase"
                      style={{ color: "#3dd598" }}
                    ></i>
                  </div>
                </CircularProgressbarWithChildren>
              </div>
              <div className="rt-statescontent">
                <h3>{hiredPercent}%</h3>
                <span>Hire Rate</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ApplicantStatsComponent;
