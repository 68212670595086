/*
 * File: change-password.component.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 31st December 2020 3:08:54 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 9:07:24 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { LoaderComponent } from "../../../../Components/common/loader.component";
import { IUser } from "../../../../Services/Interfaces/AuthInterface";
import { AuthApi } from "../../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { log } from "../../../../Lib/helpers";
import { AppContext } from "../../../../Services/AppContext";
import { isEmpty } from "lodash";
import { toastify } from "../../../../Services/toast.service";

const schema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string().required(),
  cNewPassword: yup.string().required(),
});

export const ChangePasswordComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const { updateUser, user: userData } = useContext(AppContext);

  // const [user, setUser] = useState<IUser>({});

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      cNewPassword: "",
    },
  });

  const submit = (v: any) => {
    _update(v);
    // })
  };

  const _update = async (newData: any) => {
    const { oldPassword, newPassword: password, cNewPassword } = newData;

    if (password !== cNewPassword) {
      toastify("error", "Confirm Password doesn't match new Password.");
      return;
    }

    setLoading(true);
    try {
      const response = await AuthApi.UpdateProfile({ oldPassword, password });
      if (response.hasError) {
        handleApiError(response, "_update password user profile: ");
      } else {
        log("Change Password SUCCESS: ", response);

        const userRes: IUser = response.data;
        reset({
          oldPassword: "",
          newPassword: "",
          cNewPassword: "",
        });

        if (!_.isEmpty(newData)) {
          toastify("success", "Password updated successfully");
        }

        if (userData) {
          updateUser({
            ...userData,
            user: userRes,
          });
        }
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  return (
    <div className="rt-profilebox">
      <div className="rt-profileboxhead">
        <h3>Change Password</h3>
      </div>
      <div className="rt-formtheme rt-formpassword">
        <fieldset>
          <div className="form-group">
            <label>Old Password</label>
            <i className="icon-eye1"></i>
            <div className="rt-inputiconholder">
              <input
                type="password"
                {...register("oldPassword")}
                className="form-control"
                placeholder="*****"
              />
              <span className="text-danger">
                <ErrorMessage errors={errors} name="oldPassword" />
              </span>
            </div>
          </div>
          <div className="rt-twocols">
            <div className="form-group">
              <label>New Password</label>
              <i className="icon-eye1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="password"
                  {...register("newPassword")}
                  className="form-control"
                  placeholder="*****"
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="newPassword" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <i className="icon-eye1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="password"
                  {...register("cNewPassword")}
                  className="form-control"
                  placeholder="*****"
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="cNewPassword" />
                </span>
              </div>
            </div>
            <div className="rt-updatebtnholder">
              <button
                type="button"
                onClick={handleSubmit(submit)}
                className="rt-btn"
                disabled={loading}
              >
                Update Password
                {loading && <LoaderComponent />}
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};
