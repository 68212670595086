/*
 * File: RouteKeys.ts
 * Project: JobApp-Admin
 * File Created: Monday, 3rd February 2020 10:40:50 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Sun Feb 13 2022
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

export const RouteKeys = {
  Home: "/",

  SignIn: "/",
  Login: "/",
  SignUp: "/",

  SignIn2: "/login2",
  ReferFriend: "/refer-friend",
  ReferBusiness: "/refer-business",
  ReferEmployee: "/refer-employee",

  Forgot: "/reset",
  ValidatePass: "/reset/validate",
  MobileApps: "/apps",

  AdminDashboard: adminRoute(""),
  Dashboard: adminRoute("/dashboard"),
  ChangePass: adminSettingsRoute("/change-password"),
  AdminSettings: adminRoute("/settings"),
  UserProfile: adminSettingsRoute("/profile"),

  AdminCompany: adminRoute("/company"),
  UpdateCompanyProfile: adminCompanyRoute("/company-profile"),
  BillingPage: adminCompanyRoute("/billing"),

  JobsList: adminRoute("/jobs"),
  AddJob: adminRoute("/jobs/create"),
  EditJob: adminRoute("/jobs"),

  CategoryList: adminRoute("/categories"),
  CategoryAdd: adminRoute("/categories/create"),
  CategoryEdit: adminRoute("/categories"),

  ApplicantsList: adminRoute("/applicants"),

  Companies: adminRoute("/companies"),
  CompanyCreate: adminRoute("/companies/create"),

  Branches: adminRoute("/branches"),
  BranchCreate: adminRoute("/branches/create"),

  Employees: adminRoute("/employees"),
  ReferralProgram: adminRoute("/employees-referral"),
  EmployeesPageNew: adminRoute("/employees-page"),

  RewardsGiven : adminRoute("/rewards-given"),
  Plum: adminRoute("/plum"),

  ChatPage: adminRoute("/chat"),

  ReactHooksDemo: adminRoute("/hooks-demo"),
};

function adminRoute(route: string) {
  return `/admin${route}`;
}
function adminSettingsRoute(route: string) {
  return `/admin/settings${route}`;
}
function adminCompanyRoute(route: string) {
  return `/admin/company${route}`;
}
