/*
 * File: edit.profile.component.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 28th December 2020 7:49:16 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 8:40:40 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import Compressor from "compressorjs";

import { LoaderComponent } from "../../../../Components/common/loader.component";
import { IUser } from "../../../../Services/Interfaces/AuthInterface";
import { AuthApi } from "../../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { log } from "../../../../Lib/helpers";
import { AppContext } from "../../../../Services/AppContext";
import { isEmpty } from "lodash";
import { toastify } from "../../../../Services/toast.service";

const schema = yup.object().shape({
  fullName: yup.string().required(),
  email: yup.string().required(),
  phone: yup.string().required(),
});

export const EditProfileComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tempImg, setImage] = useState<any>(null);
  const [profileImage, setProfileImage] = useState<any>(null);

  const { updateUser, user: userData } = useContext(AppContext);

  // const [user, setUser] = useState<IUser>({});
  const user = userData?.user;

  const { register, formState: { errors }, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: user?.fullName,
      email: user?.email,
      phone: user?.phone,
      photo: user?.photo,
    },
  });

  useEffect(() => {
    setImage(null);
    _fetchOrUpdate();
    // getRequest(endpoints.account.me()).then((response: AxiosResponse<any>) => {
    //     setUser(response.data)
    //     Object.keys(response.data).forEach((key:string) => setValue(key, response.data[key]))
    // })
  }, []);

  const selectImage = async (e: any) => {
    const [first] = e.target.files;
    setProfileImage(first);
    setImage(URL.createObjectURL(first));
  };

  const submit = (v: any) => {
    // Object.keys(v).forEach((key: string) => data.append(key, v[key]));

    _fetchOrUpdate(v);
  };

  const _uploadImage = async () => {
    setLoading(true);

    let data = new FormData();

    if (profileImage) {
      // compress image before upload
      const result: any = await new Promise((resolve, reject) => {
        new Compressor(profileImage, {
          quality: 0.6,
          success: resolve,
          error: reject,
        });
      });

      data.append("file", result, result.name);

      try {
        const response = await AuthApi.UploadImage(data);
        if (response.hasError) {
          handleApiError(response, "_upload image user profile: ");
        } else {
          // ? Update Avatar success
          log("Upload User Image SUCCESS: ", response);

          toastify("success", "Profile and Avatar updated successfully");

          if (userData) {
            // update local storage
            updateUser({
              ...userData,
              user: {
                ...userData.user,
                photo: response.data.photo,
              },
            });
          }

          setProfileImage(null);
        }
      } catch (e) {
        handleCatchError(e);
      }
    }
    setLoading(false);
  };

  const _fetchOrUpdate = async (newData = {}) => {
    setLoading(true);
    let uploading = false;
    try {
      const response = await AuthApi.UpdateProfile(newData);
      if (response.hasError) {
        handleApiError(response, "_fetchOrUpdate user profile: ");
      } else {
        // ? Get User success
        log("Get User SUCCESS: ", response);

        const userRes: IUser = response.data;
        const { fullName, email, phone, photo } = userRes;
        reset({
          fullName,
          email,
          phone,
          // photo,
        });

        if (!_.isEmpty(newData)) {
          if (profileImage) {
            uploading = true;
            _uploadImage();
          } else {
            if (userData) {
              // update local storage
              updateUser({ ...userData, user: userRes });
            }
            toastify("success", "Profile updated successfully");
          }
        }
      }
    } catch (e) {
      handleCatchError(e);
    }
    if (!uploading) {
      setLoading(false);
    }
  };

  return (
    <div className="rt-profilebox rt-updateprofile">
      <div className="rt-profileboxhead">
        <h3>Update Your User Profile</h3>
      </div>
      <div className="rt-formtheme rt-formprofile">
        <form>
          <fieldset>
            <div className="form-group">
              <label>Full Name</label>
              <i className="icon-user1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="text"
                  {...register('fullName')}
                  className="form-control"
                  placeholder="Michal Jones"
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="fullName" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>Email</label>
              <i className="icon-email"></i>
              <div className="rt-inputiconholder">
                <input
                  type="email"
                  {...register('email')}
                  className="form-control"
                  placeholder="michal.jones@michaljones.com"
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="email" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <label>Phone Number</label>
              <i className="icon-phone2"></i>
              <div className="rt-inputiconholder">
                <input
                  type="text"
                  {...register('phone')}
                  className="form-control"
                  placeholder="(545) 889-6825"
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="phone" />
                </span>
              </div>
            </div>

            <div className="rt-changeprofileimage">
              <figure className="rt-userprofileimg">
                <img src={tempImg ? tempImg : user?.photo} alt="User Avatar" />
              </figure>
              <span className="rt-changeimage">
                <input
                  type="file"
                  name="profile-pic"
                  id="rt-profileimg"
                  onChange={selectImage}
                  accept="image/x-png,image/gif,image/jpeg"
                />
                <label htmlFor="rt-profileimg">Change Avatar</label>
              </span>
            </div>
            <div className="rt-updatebtnholder">
              <button
                type="button"
                onClick={handleSubmit(submit)}
                className="rt-btn"
                disabled={loading}
              >
                Save
                {loading && <LoaderComponent />}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};
