/*
 * File: NoMatch.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 19th February 2020 7:22:46 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Box, Typography, Container } from "@mui/material";
import { useDefaultStyles } from "../../Themes/defaultStyles";
import { RouteKeys } from "../../Routes/RouteKeys";

export const NoMatch = () => {
  const styles = useDefaultStyles();

  const history = useHistory();

  const _navigate = (route: string, data?: any) => {
    history.push({pathname: route, state: data});
  };

  useEffect(() => {
    _navigate(RouteKeys.SignIn)
  }, [])

  return (
    <>
      <Box display="flex" className={styles.root}>
        <Container className={styles.innerContainer}>
          <Typography variant="h3">Page not found!</Typography>
        </Container>
      </Box>
    </>
  );
};
