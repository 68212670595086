/*
 * File: TotalJobPosted.component.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 4th December 2020 12:33:16 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 29th January 2021 7:07:51 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

interface ITotalJobPostedComponent {
  total: number;
  active: number;
}
export const TotalJobPostedComponent = (props: ITotalJobPostedComponent) => {
  

  // useEffect(() => {
  //   setLoading(true);
  //   fetch();
  // }, []);

  const fetch = (): void => {
    // getRequest(endpoints.job.activeJobs()).then((response: AxiosResponse<{ total: number, active: number }>) => {
    //     setTotal(response.data.total)
    //     setActive(response.data.active)
    //     setLoading(false);
    // })
  };
  

  const {total, active} = props;

  return (
    <li>
      <div className="rt-states rt-jobposted">
        <div className="rt-progressholder">
          <CircularProgressbarWithChildren
            value={(active / total) * 100}
            className="chart-active-job"
            styles={{
              path: {
                stroke: "var(--DodgerBlue)",
              },
              background: {
                fill: "var(--DodgerBlue)",
              },
            }}
          >
            <div className="inside-circle">
              <i className="icon-jobs c-db"></i>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <div className="rt-statescontent">
          <h3>{active} / {total}</h3>
          <span>Active Jobs</span>
        </div>
      </div>
    </li>
  );
};
