/*
 * File: SettingsApi.ts
 * Project: Dyme-Web
 * File Created: Wednesday, 1st December 2021 7:38:54 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 7:44:06 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { log } from "../../Lib/helpers";
import { ApiGet } from "../api-client";

// Get Reward Coins Settings
const GetCoinSettings = async () => {
  const data = await ApiGet(`settings/coins`);

  log(`☁️ GET Coins Settings RESPONSE: `, data);

  return data;
};

export const SettingsApi = {
  GetCoinSettings
}