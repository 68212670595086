/*
 * File: toast.service.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 1st December 2020 12:37:40 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 8:40:40 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import { toast, TypeOptions } from 'react-toastify';
export type TErrorType = TypeOptions; // 'error' | 'info' | 'success' | 'default' | 'dark' | 'warning'

export const toastify = (type: TErrorType, msg: string) => toast(msg, { type })