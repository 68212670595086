/*
 * File: BaseAuth.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 6th February 2020 9:35:45 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react";
import {
  Container,
  Box,
  useTheme,
} from "@mui/material";
import { useDefaultStyles } from "../Themes/defaultStyles";
import { useHistory } from "react-router-dom";
import { SideBar } from "../Routes/SideBar-old";


export const BaseAuth: React.FC = props => {
  const theme = useTheme();

  const styles = useDefaultStyles();

  const history = useHistory();

  const drawerWidth = theme.spacing(12) + 1;

  const _navigate = (route: string, data?: any) => {
    history.push({pathname: route, state: data});
  };

  // const jobIcon = <img alt="job icon" src={images.Bag} style={{width: "30px"}} />;

  return (
    <Box width="auto" className={styles.root}>
      <SideBar />
      <Box width={0.8} style={{ paddingLeft: drawerWidth, paddingRight: 0, height:"100%" }}>
        {props.children}
      </Box>
    </Box>
  );
};
