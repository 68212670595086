/*
 * File: JobInterface.ts
 * Project: JobApp-Admin
 * File Created: Friday, 21st February 2020 10:38:01 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 10:08:17 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { IApplication } from "./ApplicationInterface"
import { IBranch } from "./CompanyInterface"


type TMappedType<T> = {
  [P in keyof T]: T[P];
}

export interface IJob {
  id?: number|string;
  branchId: number|string;
  categoryId: number|string;
  branch?: IBranch;
  category?: ICategory;
  title: string;
  description: string;
  requirements: string;
  benefits: string;
  salary: string | number;
  shift: EJobShift;
  onsite: boolean;
  status: EJobStatus;
  location: string;
  applications: Array<IApplication>;
  createdAt?: string;
  updatedAt?: string;
}

export interface IJobRequest {
  id?: number|string;
  branchId: number|string;
  categoryId: number|string
  title: string;
  description: string;
  requirements: string;
  benefits: string;
  salary: string | number;
  shift: EJobShift;
  onsite: boolean;
  status: EJobStatus;
  location: string
}

// export interface IJob extends IJobBase {
//   location: {
//     type: "Point",
//     coordinates: number[]
//   }
// }




export interface IResumeFile {
  name: string;
  type: string;
  url:  string;
  date: number;
}


export enum EJobShift {
  PartTime= 'partTime',
  FullTime = 'fullTime',
  Any = 'anyTime'
}

export enum EJobStatus {
  saved = 'saved',
  posted = 'posted',
  closed = 'closed'
}

export type TJob = IJob | { id: string | undefined; }

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface ICategory {
  id?: number;
  name: string;
  // jobs?: Array<IJob>;
}


// export interface IApplicationStats {
//   applied: number
//   hired: number
//   rejected: number
//   shortlisted: number
// }








/*
Flow of Status Change:
- applied is done automatically
- clicking applicant changes to viewed
- without Accept, Hire and reject should be disabled.
*/

// jobs list to be populated in dropdown
export interface IJobList {
  id: number;
  title: string;
}

export interface IPastExperience {
  id: number | string;
  role: string;
  companyName: string;
  description: string;
  fromDate: string;
  toDate: string | null; // null if user is currently working here.
}

export interface IResume {
  id:               number;
  userId:           number;
  about:            string;
  haveExperience:   boolean;
  experienceKind:   string;
  education:        string;
  wpm:              number;
  currentlyWorking: boolean;
  pastExperience: IPastExperience[];
  crmUsing:         string;
  crmOld:           string;
  ratingSystem:     string;
  files:          IResumeFile[];
  // user:             IUser;
}
