/*
 * File: Notify.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 18th February 2020 8:02:46 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://whilegeek.com
 */

//! this is not in use because of mui v5 issue
import React from "react";
import { Snackbar } from "@mui/material";
import MuiAlert, { AlertProps } from '@mui/material/Alert';

export function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export enum ENotifyType {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}

export interface INotification {
  title: string;
  type: ENotifyType;
  // onClose?: Function;
}

export interface INotify extends INotification {
  // title: string;
  show: boolean;
  // type: ENotifyType;
  onClose?: Function;
}
export const Notify:React.FC<INotify> = (props) => {
  const _onClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };
  return (
    <Snackbar open={props.show} autoHideDuration={6000} onClose={_onClose}>
      <Alert onClose={_onClose} severity={props.type}>
        {props.title}
      </Alert>
    </Snackbar>
  );
}

Notify.defaultProps = {
  title: '',
  type: ENotifyType.Success
}
