/*
 * File: BranchForm.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 10th July 2020 1:00:00 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import {
  Typography,
  Paper,
  Grid,
  TextField,
  Avatar,
  Input,
  Box,
  Button,
  FormGroup,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AppContext } from "../../../Services/AppContext";
import {
  IBranch,
  TBranch,
  IAddress,
} from "../../../Services/Interfaces/CompanyInterface";
import { Config } from "../../../Config";
import { ENotifyType } from "../../../Components/Notify";
import MapContainer from "../../../Components/MapContainer";
import { log } from "../../../Lib/helpers";
import { BranchApi } from "../../../Services/API/BranchApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { ApiResponse } from "../../../Services/api-client";

const USERS = [
  {
    email: "umar.u@allshorestaffing.com",
    name: "Umar Aamer",
  },
  {
    email: "umardatum@gmail.com",
    name: "Datum",
  },
  {
    email: "umar.u@allshorestaffing.com",
    name: "Allshore",
  },
];

interface IBranchData {
  name: string;
  address: string;
  city: string;
  latitude: string | number;
  longitude: string | number;
  isActive: boolean;
}

const DEFAULT_DATA: IBranchData = {
  name: "",
  address: "",
  city: "",
  latitude: Config.DEFAULT_COORDINATES.lat,
  longitude: Config.DEFAULT_COORDINATES.lng,
  isActive: true,
};
type dataType = keyof typeof DEFAULT_DATA;

interface IBranchForm {
  branch?: TBranch;
  onSubmit?: Function;
  companyId?: number|string
}
export const BranchForm: React.FC<IBranchForm> = (props) => {
  const history = useHistory();
  const { isAdmin } = useContext(AppContext);
  const { branch } = props;

  const { notify, user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ...DEFAULT_DATA,
  });

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  useEffect(() => {
    if (branch && branch.addresses.length) {
      const { addresses, userId, ...others } = branch;

      const addr = addresses[0];

      // only support of 1 address here..
      setData({
        ...data,
        ...others,
        ...addr

        // address: addresses[0].address
      });
    } else {
      setData({
        ...data,
        // name: branch ? branch.name : 'umarss'
      });
    }
  }, [branch]);

  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      name: Yup.string().required("Call Center Name required"),
      address: Yup.string().required("Address required"),
      // logo: Yup.string().required("Logo required")
      // coordinates: Yup. .string().required("Address required"),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      log("branch profile values: ", values);

      alert(JSON.stringify(values));

      // TODO: CALL ADMIN UPDATE API IF LOGGED IN AS SUPER-ADMIN
      // TODO: CALL BUSINESS UPDATE API IF BUSINESS USER

      setLoading(true);

      const { name, address, city, isActive } = values;
      let response: ApiResponse;
      const addressObj = {
        address,
        city,
        latitude: data.latitude,
        longitude: data.longitude,
      };
      const updatedBranch = {
        name,
        addresses: [addressObj],
        isActive,
        companyId: props.companyId || branch?.companyId,
      };
      if (branch && branch.id) {
        //edit
        response = await BranchApi.EditBranch(branch.id, {
          ...updatedBranch,
        });
      } else {
        //create new branch
        response = await BranchApi.CreateBranch({
          // userId: user?.user.id,
          // companyId: props.companyId,
          // name,
          // address: { ...addressObj },
          // isActive,
          ...updatedBranch
        });
      }

      let message: string = "Something went wrong";
      let errorType = ENotifyType.Error;

      try {
        if (response.hasError) {
          message = handleApiError(response, "Branch Form SCREEN: ");
        } else {
          log("Create Branch SUCCESS: ", response);
          message = `Branch ${branch ? "Updated" : "Created"}!`;
          errorType = ENotifyType.Success;

          if (!branch) {
            _navigate(RouteKeys.Companies);
          }

          // response.data;
        }
      } catch (e) {
        handleCatchError(e);
      }

      notify({
        title: message,
        type: errorType,
      });

      setLoading(false);
    },
  });

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = formik;

  const _onSubmit = (e: React.SyntheticEvent) => {
    log("branch form: ", formik.values);
    e.preventDefault();
    formik.handleSubmit();
  };

  const _updateLatLng = (latLng = { lat: 0, lng: 0 }) => {
    log("updating marker: ", latLng);

    setData({
      ...values,
      latitude: latLng.lat,
      longitude: latLng.lng,
    });
  };

  // Default input props for each text input
  const _getInputProps = (key: dataType): any => {
    return {
      value: values[key],
      onChange: handleChange,
      error: touched[key] && !!errors[key],
      onBlur: handleBlur,
      variant: "outlined",
      margin: "normal",
      required: true,
      fullWidth: true,
      name: `${key}`,
      helperText: errors[key],
    };
  };

  return (
    <Paper style={{ padding: 20 }}>
      <form noValidate autoComplete="off" onSubmit={_onSubmit}>
        <Typography variant="h6">Branch Profile</Typography>

        {/* name, address, description, logo, coordinates */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField label="Name" {..._getInputProps("name")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Address" {..._getInputProps("address")} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="City" {..._getInputProps("city")} />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box height={300}>
              <MapContainer
                onMarkerDrag={_updateLatLng}
                coordinates={{latitude: data.latitude, longitude: data.longitude}}
                style={{ height: 300, width: 350 }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ marginTop: 30 }}>
            <FormGroup row>
              <FormControlLabel
                disabled={!isAdmin}
                control={
                  <Switch
                    onChange={() => setFieldValue("isActive", !values.isActive)}
                    checked={!!values.isActive}
                    // inputProps={{ 'aria-label': 'secondary checkbox' }}
                  />
                }
                label={`Branch ${values.isActive ? "Enabled" : "Disabled"}`}
              />
            </FormGroup>

            {isAdmin && (
              <Grid item xs={12} sm={6} style={{ marginTop: 30 }}>
                <Autocomplete
                  options={USERS}
                  // freeSolo
                  getOptionLabel={(option) => option.email}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Business Manager Email"
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} style={{ marginTop: 30 }}>
              <Button
                type="submit"
                disabled={loading}
                fullWidth
                color="primary"
                variant="contained"
              >
                {props.branch ? "Update" : "Create"}
                &nbsp;Branch
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

BranchForm.defaultProps = {
  // canManage: true
};
