/*
 * File: add-new-user.component.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 28th December 2020 7:49:16 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 30th March 2021 11:24:38 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import React, { ChangeEvent, FormEvent, useState } from "react";
import moment from "moment";
import { EACL, IUser } from "../../../../Services/Interfaces/AuthInterface";
import { ValidationErrorComponent } from "../../../../Components/shared/validation-error.component";
import { LoaderComponent } from "../../../../Components/common/loader.component";
import { IValidationError } from "../../../../Services/Interfaces";
interface IProps {
  onClose(): void;
  addUser(user: IUser): void;
}

export const AddNewUserComponent = (props: IProps) => {
  // const [acl, setACL] = useState<EACL[]>([]);
  const [user, setUser] = useState<any>({ isActive: true });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<IValidationError>({});

  // const setPermission = (perm: EACL) => {
  //   let permissions = [...acl];
  //   if (acl.includes(perm)) permissions = acl.filter((_p: EACL) => perm !== _p);
  //   else permissions.push(perm);
  //   setACL([...permissions]);
  // };

  const changeField = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const submit = () => {
    // setSubmitting(true)
    // postRequest(endpoints.company.addUser(), {...user, acl}).then((response: AxiosResponse<IUser>) => {
    //     setSubmitting(false);
    //     toastify('success', 'User added successfully.');
    //     props.onClose();
    //     props.addUser(response.data)
    // }, (error: any) => {
    //     setErrors(error.response.data)
    //     setSubmitting(false);
    // })
  };

  return (
    <div className={`rt-createnewuser`}>
      <div className="rt-profilebox ">
        <div className="rt-profileboxhead">
          <h3>New User</h3>
        </div>
        <div className="rt-createuserhead">
          <div className="rt-useradddate">
            <span>Date:</span>
            <strong>{moment().format("MM/DD/yyyy")}</strong>
          </div>
          <div
            className="rt-onoffswitch"
            onClick={() => setUser({ ...user, isActive: !user.isActive })}
          >
            <input
              type="checkbox"
              name="notification"
              checked={Boolean(user.isActive)}
            />
            <label>
              <span>Active</span>
            </label>
          </div>
        </div>
        <div className="rt-userinfoinput">
          <div className="form-group">
            <label>User Email</label>
            <i className="icon-user1"></i>
            <div className="rt-inputiconholder">
              <input
                type="email"
                name="email"
                onChange={changeField}
                value={user.email}
                className="form-control"
                placeholder="john@doe.com"
              />
            </div>
            <ValidationErrorComponent errors={errors} name="email" />
          </div>
        </div>
        <div className="rt-userinfoinput">
          <div className="form-group">
            <label>Password</label>
            <i className="icon-eye1"></i>
            <div className="rt-inputiconholder">
              <input
                type="text"
                name="password"
                onChange={changeField}
                value={user.password}
                className="form-control"
                placeholder="7896321!!"
              />
            </div>
            <ValidationErrorComponent errors={errors} name="password" />
          </div>
        </div>
      </div>
      <div className="rt-profilebuttons">
        <div className="rt-profilebox rt-profilenotifications">
          <div className="rt-notificationholder">
            <div className="rt-notification rt-generalnotification">
              <div className="rt-profileboxhead">
                <h3>Permissions</h3>
              </div>
              <div className="rt-notihead">
                <h4>Application Access</h4>
              </div>
              {/* <ul>
                {Object.values(EACL).map((p: EACL) => (
                  <li key={p}>
                    <h5>{p}</h5>
                    <div
                      className="rt-onoffswitch"
                      onClick={() => setPermission(p)}
                    >
                      <input
                        type="checkbox"
                        name="acl"
                        checked={acl.includes(p)}
                      />
                      <label></label>
                    </div>
                  </li>
                ))}
              </ul> */}
            </div>
          </div>
        </div>
      </div>
      <div className="rt-updatebtnholder">
        <button type="button" disabled={submitting} onClick={submit}>
          Create User
          {submitting && <LoaderComponent />}
        </button>
        <button type="button" onClick={props.onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};
