/*
 * File: ManagersList.tsx
 * Project: Dyme-Web
 * File Created: Thursday, 23rd September 2021 10:43:18 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 24th September 2021 10:34:07 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import moment from "moment";
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { PaginationComponent } from "../../../../../Components/common/Pagination/Pagination.component";
import SearchingComponent from "../../../../../Components/common/searching.component";
import Loader from "../../../../../Components/Loader";
import { ConfirmModal } from "../../../../../Components/modals/confirm.modal";
import { alertObject, log } from "../../../../../Lib/helpers";
import { EmployeesApi } from "../../../../../Services/API/EmployeesApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../../Services/error-handling";
import {
  defaultPaginatedData,
  IPaginate,
} from "../../../../../Services/Interfaces";
import {
  EEmployeeRole,
  IEmployee,
} from "../../../../../Services/Interfaces/EmployeeInterface";
import { ManagerForm } from "./ManagerForm";

export const ManagersList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState<IEmployee | null>(null);
  const [managers, setManagers] =
    useState<IPaginate<IEmployee>>(defaultPaginatedData);
  const [showConfirmation, setShowConfirmation] = useState<IEmployee | null>(
    null
  );
  const [showForm, setShowForm] = useState(false);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  useEffect(() => {
    _fetchAllEmployees();
  }, []);

  const _fetchAllEmployees = async (page = 1, keyword = "") => {
    setLoading(true);
    try {
      const response = await EmployeesApi.GetEmployees({
        page,
        role: EEmployeeRole.Admin,
        keyword,
      });
      if (response.hasError) {
        handleApiError(response, "Managers List: ");
      } else {
        // ? Get Managers success
        log("Managers List ALL SUCCESS: ", response);

        setManagers(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  const _deleteManager = async (id = 0) => {
    setLoading(true);
    try {
      const response = await EmployeesApi.DeleteEmployee(id);
      if (response.hasError) {
        handleApiError(response, "Manager Delete: ");
      } else {
        // ? Delete Manager success
        log("Manager Delete SUCCESS: ", response);
        setShowConfirmation(null);
        _fetchAllEmployees(managers.page);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  const _onFormClose = () => {
    _fetchAllEmployees();
    setShowForm(false);
    setSelectedEmp(null);
  };

  useEffect(() => {
    // open form popup if employee is selected
    if (selectedEmp) {
      setShowForm(true)
    }
    
  }, [selectedEmp])


  const _onCreateNewPress = () => {
    setSelectedEmp(null)
    setShowForm(true)
  }

  return (
    <>
      <ConfirmModal
        show={!!showConfirmation}
        loading={loading}
        onClose={() => setShowConfirmation(null)}
        onConfirm={() => _deleteManager(showConfirmation?.id)}
        confirmTitle="Delete"
        heading="Deleting Manager!"
        message="Are you sure you want to delete this Admin Manager?"
      />
      <div className={`rt-usermanagementtab`}>
        <div className="rt-profilebox rt-allusers">
          <div className="rt-profileboxhead">
            <h3>Managers ({managers.total})</h3>
            <button
              className="rt-btnpostajob"
              onClick={_onCreateNewPress}
            >
              Create New Admin
            </button>
          </div>
          <div className="rt-profileusers">
            <div className="rt-jobslistsarea">
              <div className="at-jobareahead">
                <SearchingComponent
                  getData={(v: string) => {
                    const keyword = v;
                    const page = 1;
                    _fetchAllEmployees(page, keyword);
                  }}
                  placeholder="Search by Name or email"
                />
              </div>
              <div className="rt-themetableholder">
                <table className="rt-themetable table rt-jobstable">
                  <thead>
                    <tr>
                      <th>
                        <span>User ID</span>
                      </th>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Email</span>
                      </th>
                      <th>
                        <span>Created Date</span>
                      </th>
                      <th>
                        <span>Status</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {managers.data.map((u: IEmployee) => (
                      <tr key={u.id}>
                        <td
                          onClick={() => setSelectedEmp(u)}
                          style={{ cursor: "pointer" }}
                          data-title="User Email"
                        >
                          <span>{u.id}</span>
                        </td>
                        <td
                          onClick={() => setSelectedEmp(u)}
                          style={{ cursor: "pointer" }}
                          data-title="User Name"
                        >
                          <span>{u.fullName}</span>
                        </td>
                        <td data-title="User Email">
                          {/* <span>{(u.acl || []).join(", ")}</span> */}
                          <span>{u.email}</span>
                        </td>
                        <td data-title="Name">
                          <span>{moment().format("MM/DD/yyyy")}</span>
                        </td>
                        <td data-title="Email">
                          <div className="rt-statusbuttonholder">
                            <div
                              className={`rt-selectresult rt-${
                                u.user ? "bgstatusgreen" : "bgstatusred"
                              }`}
                            >
                              <i className="icon-status-active"></i>
                              <em>{u.user ? "Active" : "In-Active"}</em>
                            </div>
                            <Dropdown className="rt-themedropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                <i className="icon-more"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="rt-themedropdownmenu">
                                <Dropdown.Item
                                  onClick={() => setSelectedEmp(u)}
                                >
                                  <i className="icon-eye1"></i>
                                  <span>Edit Manager</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => setShowConfirmation(u)}
                                >
                                  <i className="icon-delete"></i>
                                  <span>Delete Manager</span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <PaginationComponent
                    {...managers}
                    onClick={(v: number) => {
                      _fetchAllEmployees(v);
                    }}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ManagerForm show={showForm} employee={selectedEmp} onClose={_onFormClose} />
      {loading && <Loader />}
    </>
  );
};
