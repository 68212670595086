/*
 * File: ApplicantsListPage.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 18th December 2020 6:41:36 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:34:11 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect, useState } from "react";
import styles from "./ApplicantsListPage.module.scss";
import Dropdown from "react-bootstrap/Dropdown";

import moment from "moment";

import Select from "react-select";
import SearchingComponent from "../../../Components/common/searching.component";
import { ImageComponent } from "../../../Components/common/image.component";
import { PaginationComponent } from "../../../Components/common/Pagination/Pagination.component";
import { ApplicantDetailComponent } from "../../../Components/applications/applicant-detail.component";
import {
  IPaginate,
  ISelect,
  defaultPaginatedData,
} from "../../../Services/Interfaces";
import {
  IJobList,
} from "../../../Services/Interfaces/JobInterface";
import { JobApi } from "../../../Services/API/JobApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { alertObject, log, useQuery, _cloneDeep } from "../../../Lib/helpers";
import { ApplicantsApi } from "../../../Services/API/ApplicantsApi";
import Loader from "../../../Components/Loader";
import { images } from "../../../assets/images/images";
import { useHistory, useParams } from "react-router-dom";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { AppColors } from "../../../Themes/AppColors";
import { statusValueArr, statusTitleArr, IApplication, EApplicationStatus } from "../../../Services/Interfaces/ApplicationInterface";

type TOrderBy = "id" | "status" | "createdAt";
let keyword = "";
let DEFAULT_SELECT = { value: "", label: "All Jobs" };

let DEFAULT_SELECT_STATUS: ISelect[] = [{ value: "", label: "All Statuses" }];

statusValueArr.map((s, i) => {
  return DEFAULT_SELECT_STATUS.push({
    value: s,
    label: statusTitleArr[i],
  });
});

const ApplicantsListPage = () => {
  const [applications, setApplications] =
    useState<IPaginate<IApplication>>(defaultPaginatedData);

  const [jobs, setJobs] = useState<ISelect[]>([]);
  const [selectedJob, setSelectedJob] = useState<ISelect>(DEFAULT_SELECT);

  const [loading, setLoading] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [orderBy, setOrderBy] = useState<TOrderBy>("id");

  const query = useQuery();

  const queryStatuses = query.get("status");

  const [selectedStatus, setSelectedStatus] = useState<ISelect[]>([]);

  const [selectedApplication, setSelectedApplication] =
    useState<IApplication | null>(null);

  useEffect(() => {
    _fetchAllJobs();
  }, []);

  // jobs to show in dropdown
  const _fetchAllJobs = async () => {
    setLoading(true);
    try {
      const response = await JobApi.JobsListAll();
      if (response.hasError) {
        handleApiError(response, "JOB List ALL: ");
      } else {
        // ? Get Jobs success
        log("Jobs List ALL SUCCESS: ", response);

        const jobsList: IJobList[] = response.data.data;

        let jobSelect: ISelect[] = [DEFAULT_SELECT];
        jobsList.map((j: IJobList) => {
          return jobSelect.push({
            label: j.title,
            value: `${j.id}`,
          });
        });

        setJobs(jobSelect);

        _fetchAllApplicants();
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    // if (selectedJob) {
    _fetchAllApplicants();
    // }
  }, [selectedJob, orderBy, selectedStatus]);

  const _fetchAllApplicants = async (page = 1) => {
    setLoading(true);
    try {
      const response = await ApplicantsApi.GetApplicants(selectedJob.value, {
        page,
        orderBy,
        status: selectedStatus
          ? selectedStatus.map((s) => s.value).join(",")
          : "",
      });
      if (response.hasError) {
        handleApiError(response, "Applicants List: ");
      } else {
        // ? Get Applicants success
        log("Applicants List ALL SUCCESS: ", response);

        setApplications(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  /**
   * Change Application Status
   *
   * Flow of Status Change:
   * - applied is done automatically
   * - clicking applicant changes to viewed
   * - without Accept, Hire and reject should be disabled.
   *
   * @param {EApplicationStatus} newStatus - New Status
   * @param {number} appId - Application ID
   * @returns any
   */
  const _changeAppStatus = async (
    newStatus: EApplicationStatus,
    appId: number
  ) => {
    if (!appId) {
      return null;
    }
    setLoading(true);
    try {
      const response = await ApplicantsApi.ChangeStatus(appId, newStatus);
      if (response.hasError && response.status !== 409) {
        // status 409 means payment is not setup.
        handleApiError(response, "Applicants Change Status: ");
        if (response.status === 403) {
          // payment method is not setup.
          // navigate to payment page.

          _navigate(`${RouteKeys.BillingPage}`);
        }
      } else {
        // ? Get Applicants success
        log("Applicants Status change SUCCESS: ", response);

        // UPDATE APPLICATION STATUS IN LIST

        const newApps: IPaginate<IApplication> = _cloneDeep(applications);

        const i = newApps.data.findIndex((app) => app.id === appId);

        if (i > -1) {
          newApps.data[i].status = newStatus;
        }

        if (selectedApplication) {
          // doing this to fix applicant popup data update bug fix.
          let newAppSelected: IApplication = _cloneDeep(selectedApplication);

          newAppSelected.status = newStatus;
          setSelectedApplication(newAppSelected);
        }

        setApplications(newApps);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    // if some application selected change its status to viewed if not already

    if (
      selectedApplication &&
      selectedApplication.status === EApplicationStatus.Applied
    ) {
      _changeAppStatus(EApplicationStatus.Viewed, selectedApplication.id);
    }
  }, [selectedApplication]);

  /* const selectApplication = (app: IApplication) => {
		setShowDetail(true);
		setSelectedApplication(app);
		// setApplicationId(id);
	}; */
  const selectApplication = (app: IApplication) => {
    let show = true;
    if (app.id === selectedApplication?.id) {
      show = false;
    }
    setShowDetail(show);
    setSelectedApplication(show ? app : null);
  };

  const closeDetail = () => {
    setShowDetail(false);
    setSelectedApplication(null);
  };

  const selectJob = (v: any) => {
    // DEFAULT_SELECT = v;
    log("v is: ", v);
    let val = v;
    if (!v) {
      val = DEFAULT_SELECT;
    }
    setSelectedJob(val);
  };

  const selectStatus = (v: any) => {
    // DEFAULT_SELECT = v;
    log("status is: ", v);
    let val = v;
    if (!v || !val.length) {
      // val = [DEFAULT_SELECT_STATUS[0]];
    } else if (val[0].value === "") {
      val.shift();
    } else if (val[val.length - 1].value === "") {
      val = [DEFAULT_SELECT_STATUS[0]];
    }
    log("new selected statuses are: ", selectedStatus);
    setSelectedStatus(val);
  };

  const history = useHistory();
  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  return (
    <div className={styles.ApplicantsListPage} data-testid="ApplicantsListPage">
      <div className="rt-wrapper">
        {/* <div className={`rt-joblistpage rt-applicationspage ${showDetail ? "show-applicantdetail" : "" }`} > */}
        <div
          className={`rt-joblistpage rt-applicationspage ${
            showDetail ? "show-applicantdetail" : ""
          }`}
        >
          <div className="rt-pagetitle">
            <h1>Applications List</h1>
            <div className="rt-headdropdown rt-inputiconholder application_list_page">
              <Select
                value={selectedJob}
                options={jobs}
                classNamePrefix="select"
                onChange={selectJob}
                isClearable={true}
              />
            </div>
            <div className="rt-headdropdown rt-inputiconholder application_list_page">
              <Select
                defaultValue={DEFAULT_SELECT_STATUS[0]}
                value={selectedStatus}
                options={DEFAULT_SELECT_STATUS}
                classNamePrefix="select"
                onChange={selectStatus}
                isMulti
                isClearable
                placeholder="All Statuses"
              />
            </div>
          </div>
          <div className="rt-jobslistholder">
            <div className="rt-jobslistsarea">
              <div className="at-jobareahead">
                <h3>Newest to Oldest ({applications.total} Applications)</h3>
                {/* <SearchingComponent
									getData={(v: string) => {
										keyword = v;
										page = 1;
										getData();
									}}
									/> */}
              </div>
              <div className="rt-themetableholder" id="application_list">
                <table className="rt-themetable table rt-jobstable">
                  <thead>
                    <tr>
                      <th>
                        <span>Applicant</span>
                      </th>
                      <th>
                        <span>Location</span>
                      </th>
                      <th>
                        <span>Job</span>
                      </th>
                      <th
                        onClick={() => setOrderBy("createdAt")}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          style={{
                            color:
                              orderBy === "createdAt"
                                ? AppColors.blueMain
                                : "inherit",
                          }}
                        >
                          Applied Date
                        </span>
                      </th>
                      <th
                        onClick={() => setOrderBy("status")}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          style={{
                            color:
                              orderBy === "status"
                                ? AppColors.blueMain
                                : "inherit",
                          }}
                        >
                          Status
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {applications.data.map((application, ind) => {
                      let statusColor = "green";

                      if (application.status === 5) {
                        statusColor = "red";
                      } else if (application.status < 3) {
                        statusColor = "yellow";
                      }

                      // if (!application.resume) return null;

                      const { user } = application;

                      const homeAddr = user.addresses.find(
                        (addr) => addr.title === "home"
                      );

                      let city,
                        state = "";
                      if (homeAddr) {
                        city = homeAddr.city;
                        state = homeAddr.state;
                      }

                      // contact details only visible if application is accepted or hired.
                      const canView =
                        application.status === EApplicationStatus.Accepted ||
                        EApplicationStatus.Hired === application.status;

                      let visibleName = user.fullName;

                      if (!canView) {
                        const split = visibleName.trim().split(" ");
                        if (split.length > 1) {
                          // means user has entered full name

                          const fName = split[0];
                          const lName = split[split.length - 1];
                          visibleName = fName + " " + lName[0].toUpperCase();
                        }
                      }

                      return (
                        <tr key={application.id}>
                          <td colSpan={6} className="application_item_col">
                            <div
                              className={`application_item_outer_wrapper ${
                                selectedApplication?.id === application.id
                                  ? "opened"
                                  : "closed"
                              }`}
                            >
                              <div className="application_item_wrapper">
                                <table>
                                  <tbody>
                                    <tr
                                      onClick={() =>
                                        selectApplication(application)
                                      }
                                    >
                                      <td data-title="Applicant">
                                        <div className="rt-applicantimgname">
                                          <figure className="rt-applicantimg">
                                            <ImageComponent
                                              src={
                                                user.photo && canView
                                                  ? user.photo
                                                  : images.UserImg
                                              }
                                            />
                                          </figure>

                                          <h4 style={{ cursor: "pointer" }}>
                                            {visibleName}
                                          </h4>
                                        </div>
                                      </td>
                                      <td data-title="City">
                                        <p>
                                          {city}, {state}
                                        </p>
                                      </td>
                                      <td data-title="Job">
                                        <span>{application.job.title}</span>
                                      </td>
                                      <td data-title="Applied Date">
                                        <span>
                                          {moment(application.createdAt).format(
                                            "MM/DD/YYYY"
                                          )}
                                        </span>
                                      </td>
                                      <td data-title="Status">
                                        <div className="rt-statusbuttonholder">
                                          <div
                                            className={`rt-selectresult rt-bgstatus${statusColor}`}
                                          >
                                            <i className="icon-status-active"></i>
                                            <em>
                                              {
                                                EApplicationStatus[
                                                  application.status
                                                ]
                                              }
                                            </em>
                                          </div>
                                          <div className="rt-statusselect">
                                            <span className="rt-active">
                                              <i className="icon-status-active"></i>
                                              <em>Active</em>
                                            </span>
                                            <span className="rt-deactivated">
                                              <i className="icon-status-deactivated"></i>
                                              <em>Deactivated</em>
                                            </span>
                                            <span className="rt-saved">
                                              <i className="icon-status-save"></i>
                                              <em>Saved</em>
                                            </span>
                                            <span className="rt-deleted">
                                              <i className="icon-status-deactivated"></i>
                                              <em>Deleted</em>
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                
                              </div>
                              {selectedApplication?.id === application.id &&
                                application.resumeId && (
                                  <div className="application_detail">
                                    <ApplicantDetailComponent
                                      application={selectedApplication}
                                      onStatusChange={_changeAppStatus}
                                      close={closeDetail}
                                    />
                                  </div>
                                )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                  <PaginationComponent
                    {...applications}
                    onClick={(v: number) => {
                      _fetchAllApplicants(v);
                    }}
                  />
                </table>
              </div>
            </div>
            {loading && <Loader />}
            {/* {showDetail && selectedApplication && (
							<ApplicantDetailComponent
								application={selectedApplication}
								onStatusChange={_changeAppStatus}
								close={closeDetail}
							/>
						)}
						 */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantsListPage;
