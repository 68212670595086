/*
 * File: BranchesListPage.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 13th July 2020 7:25:08 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */


import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { BaseAuth } from "../../../Components/BaseAuth";
import { PageHeader } from "../../../Components/PageHeader";
import MaterialTable from "material-table";
import { IBranch } from "../../../Services/Interfaces/CompanyInterface";
import { ENotifyType } from "../../../Components/Notify";
import { handleApiError, handleCatchError } from "../../../Services/error-handling";
import { log } from "../../../Lib/helpers";
import { BranchApi } from "../../../Services/API/BranchApi";
import { AppContext } from "../../../Services/AppContext";
import { companyLinks } from "../Companies/CompaniesListPage";
import { BranchTable } from "./BranchTable";


const defaultInfo = {
  address: {
    address: '',
    city: '',
    latitude: '',
    longitude: ''
  },
  isActive: true,
  description: '',
  logo: ''
}

export const BranchesListPage: React.FC = () => {
  const history = useHistory();

  const {notify} = useContext(AppContext)

  const [branches, setBranches] = useState<IBranch[]>([
    // {
    //   name: "DatumSquare IT Services",
    //   ...defaultInfo
    // },

    // {
    //   name: "Allshore Staffing",
    //   ...defaultInfo
    // },
  ]);
  const [loading, setLoading] = useState(false);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  useEffect(() => {
    __fetchBranches()
  }, [])

  const __fetchBranches = async () => {
    setLoading(true);

    
    let message: string = "Something went wrong";
    let errorType = ENotifyType.Error;
    
    try {
      const response = await BranchApi.GetBranches();
      if (response.hasError) {
        message = handleApiError(response, "Branches List: ");
      } else {
        log("Branch List: ", response);
        
        errorType = ENotifyType.Success;
        message = "Branches Fetched"

        setBranches(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    if (errorType === ENotifyType.Error) {
      notify({
        title: message,
        type: errorType
      });
    }

    setLoading(false);

  }


  return (
    <BaseAuth>
      <PageHeader
        heading="Branches"
        // btnTitle="Add Branch"
        // onBtnPress={() => _navigate(RouteKeys.BranchCreate)}
        links={companyLinks}
      />

      <BranchTable branches={branches} onDelete={__fetchBranches} />
    </BaseAuth>
  );
};


