/*
 * File: NewJobPage.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 11th December 2020 10:21:37 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 8:59:52 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect, useState } from "react";
import styles from "./NewJobPage.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  convertFromHTML,
  convertFromRaw,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import {
  EJobShift,
  EJobStatus,
  ICategory,
  IJob,
  IJobRequest,
} from "../../../../Services/Interfaces/JobInterface";
import { LoaderComponent } from "../../../../Components/common/loader.component";
import { toastify } from "../../../../Services/toast.service";
import { IDynamic, IValidationError } from "../../../../Services/Interfaces";
import { ImageComponent } from "../../../../Components/common/image.component";
import { images } from "../../../../assets/images/images";
import { ValidationErrorComponent } from "../../../../Components/shared/validation-error.component";
import { Config } from "../../../../Config";
import { CategoryApi } from "../../../../Services/API/CategoryApi";
import { CompanyApi } from "../../../../Services/API/CompanyApi";
import {
  IBranch,
  ICompany,
} from "../../../../Services/Interfaces/CompanyInterface";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { alertObject, latLngToString, log } from "../../../../Lib/helpers";
import { IApiResponse } from "../../../../Services/api-client";
import { JobApi } from "../../../../Services/API/JobApi";
import { RouteKeys } from "../../../../Routes/RouteKeys";
import { AuthApi } from "../../../../Services/API/AuthApi";
import { Avatar } from "@mui/material";
import { ChangeEventHandler } from "react";

// const jobsOptions = [
//   { value: "Developer", label: "Developer" },
//   { value: "Software Engineer", label: "Software Engineer" },
//   { value: "Mobile Developer", label: "Mobile Developer" },
//   { value: "Marketing Manaer", label: "Marketing Manaer" },
// ];
const jobSchema = yup.object().shape({
  title: yup.string().required(),
  // description: yup.string().required(),
  // requirements: yup.string().required(),
  salary: yup.string(),
  shift: yup.string().required(),
  location: yup.string(),
  address: yup.string(),
});

interface ISelect {
  value: any;
  label: string;
}

interface LocationState {
  job?: IJob;
}

const NewJobPage: React.FC = (props: any) => {
  const locationState = useLocation<LocationState>();
  const paramJob = locationState.state?.job;

  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [location, setLocation] = useState<any>(
    paramJob?.location || Config.DEFAULT_COORDS_STR
  );
  const [categories, setCategories] = useState<ISelect[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [category, setCategory] = useState<any>({
    label: paramJob?.category?.name || "Select Category",
    value: paramJob?.category?.id || 0,
  });
  const [branchId, setBranchId] = useState<any>(paramJob?.branchId || 0);
  const [branchName, setBranchName] = useState("");
  const [branch, setBranch] = useState(paramJob?.branch);
  const [myLatLng, setMyLatLng] = useState<string>("");
  const [status, setStatus] = useState(paramJob?.status || EJobStatus.saved);

  const [description, setDescription] = useState<EditorState>();
  const [requirements, setRequirements] = useState<EditorState>();
  const [benefits, setBenefits] = useState<EditorState>();

  const history = useHistory();
  const params = useParams<IDynamic>();
  const [s_errors, setErrors] = useState<IValidationError>({});

  const editMode = params.id ? true : false;

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(jobSchema),
    defaultValues: {
      title: "",
      description: "",
      requirements: "",
      benefits: "",
      salary: "",
      shift: EJobShift.FullTime,
      status: EJobStatus.saved,
      address: "",
      location: Config.DEFAULT_COORDS_STR,
      ...paramJob,
      onsite: paramJob ? (paramJob.onsite ? 1 : 0) : 1,
      // categoryId: 0
    },
  });

  //? first mount effect
  useEffect(() => {
    // if (params.id) getJob();

    // fetch categories and branches to populate form.
    log("job param: ", locationState);
    _updateEditors(paramJob);
    _fetchData();
  }, []);

  const _updateEditors = (jobObj: IJob | undefined) => {
    if (!jobObj) {
      setDescription(EditorState.createEmpty());
      setRequirements(EditorState.createEmpty());
      setBenefits(EditorState.createEmpty());
      return;
    }

    // ? description
    let blocksFromHTML = convertFromHTML(jobObj.description);
    let state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const desc = EditorState.createWithContent(state);
    setDescription(desc);

    // ? requirements
    blocksFromHTML = convertFromHTML(jobObj.requirements);
    state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const reqs = EditorState.createWithContent(state);
    setRequirements(reqs);

    // ? benefits
    blocksFromHTML = convertFromHTML(jobObj.benefits);
    state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const bfts = EditorState.createWithContent(state);
    setBenefits(bfts);

    // const benefs = EditorState.createWithContent(state)
    // setBenefits(benefs);
  };

  const _populateFormData = (jobData: IJob | undefined = paramJob) => {
    if (jobData) {
      const {
        title,
        // description,
        // requirements,
        salary,
        onsite,
        branchId,
        shift,
        status,
        location,
        category,
      } = jobData;

      reset({
        title,
        // description,
        // requirements,
        salary,
        onsite: onsite ? 1 : 0,
        location,
        status,
        shift,
      });

      if (category) {
        setCategory({ label: category?.name, value: category?.id });
      }

      setBranchId(branchId);
      setStatus(status);

      // description.

      // EditorState.set(description, {

      // })
      // description.
      // setDescription(EditorState.set())
      //@ts-ignore

      // setDescription.;
    }
  };

  const _fetchData = async () => {
    setLoading(true);

    try {
      const [categoriesResponse, companyResponse, jobResponse] =
        await Promise.all([
          CategoryApi.GetCategories(),
          AuthApi.GetCompanyProfile(),
          JobApi.GetJob(params.id),
        ]);

      log(
        "Got Cat, Com and Job res: ",
        categoriesResponse,
        companyResponse,
        jobResponse
      );

      // fill job form with data if edit state
      if (!categoriesResponse.hasError) {
        const categoriesData: ICategory[] = categoriesResponse.data;

        const cats = categoriesData.map((cat) => {
          return {
            value: cat.id,
            label: cat.name,
          };
        });

        log("Cats data: ", cats);

        setCategories(cats);
        // setCompanies(companyResponse.data);

        if (companyResponse.data && !companyResponse.hasError) {
          // const comps: ICompany[] = companyResponse.data;
          const companyBranch: IBranch | null = companyResponse.data;

          let errorMsg = "";
          if (!companyBranch) {
            errorMsg = "Please create company profile first";
          } else {
            setBranchId(companyBranch.id);
            // set branch name
            setBranchName(companyBranch.name);
            setBranch(companyBranch);

            if (!companyBranch?.addresses.length) {
              _navigate(RouteKeys.AdminSettings);
              toastify("info", "Please update company address first.");
              return;
            }

            if (editMode) {
              if (!jobResponse.hasError) {
                const jobData: IJob = jobResponse.data;
                _populateFormData(jobData);
                setStatus(jobData.status);

                _updateEditors(jobData);

                log("now updating map for job location: ", jobData.location);
                // setLocation(jobData.location);
              } else {
                handleApiError(jobResponse);
                _navigate(RouteKeys.JobsList);
              }
            }
          }

          if (errorMsg !== "") toastify("warning", errorMsg);
        }
      } else {
        const errObj = categoriesResponse.hasError
          ? categoriesResponse
          : companyResponse;
        handleApiError(errObj);
      }

      // navigate to settings if company profile doesn't exist or some error.
      if (companyResponse.hasError) {
        _navigate(RouteKeys.AdminSettings);
        toastify("info", "Please create your company profile first.");
        return;
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  
  const isFormInvalid = (v: any) => {
    let errs: any = {};
    let hr = parseInt(v.salary) || 0;
    // let mins = parseInt(v.minSalary) || 0;
    // let maxs = parseInt(v.maxSalary) || 0;
    if (hr < 1) errs.salary = ["Hourly Rate is required."];

    const catId = category?.value;
    if (!catId) {
      errs.categoryId = "Please select category";
    }

    console.log(errs, v);

    setErrors(errs);
    return Object.keys(errs).length > 0;
  };

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const submit = async (status: EJobStatus, v: any) => {
    if (isFormInvalid(v) || !branch) return;

    // if (description) {
    // 	alert(JSON.stringify(stateToHTML(description?.getCurrentContent()))); return;
    // }

    // status === EJobStatus.posted ? setLoading(true) : setSaving(true);
    let request: any;

    // const [latitude, longitude] = location.split(",");

    // TODO: SELECTED LOCATION FROM DROPDOWN
    // const addr = branch.addresses[0];
    // const branchLocation = `${addr.latitude},${addr.longitude}`;
    let newJob: IJobRequest = {
      ...v,
      status,
      branchId,
      categoryId: category.value,
      // location: branchLocation,
      onsite: v.onsite === "1" ? true : false,
      description: description
        ? stateToHTML(description.getCurrentContent())
        : "",
      requirements: requirements
        ? stateToHTML(requirements.getCurrentContent())
        : "",
      benefits: benefits ? stateToHTML(benefits.getCurrentContent()) : "",
    };

    if (editMode) {
      newJob = {
        ...newJob,
        id: params.id,
      };
    }

    newJob.salary = parseFloat(`${newJob.salary}`);

    alertObject(newJob);

    setLoading(true);

    let response: IApiResponse;

    if (editMode) {
      response = await JobApi.EditJob(newJob);
    } else {
      response = await JobApi.CreateJob({
        ...newJob,
      });
    }

    let success: any = null;

    try {
      if (response.hasError) {
        handleApiError(response, "JOB FORM: ");
      } else {
        // ? Job success
        log("JOB FORM: ", response);

        success = response.data;
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);

    if (success) {
      toastify(
        "success",
        `Job ${editMode ? "Updated" : "Created"} Successfully!`
      );

      if (!editMode) {
        _navigate(RouteKeys.EditJob + "/" + success.id);
      }
    }
  };

  const toolbarConfig = {
    // list: { inDropdown: true },
    // textAlign: { inDropdown: true },
    // link: { inDropdown: true },
    // history: true,
    // options: ["inline", "blockType", "list", "textAlign", "link", "emoji"],
    // inline: {
    //   inDropdown: true,
    //   className: undefined,
    //   component: undefined,
    //   dropdownClassName: undefined,
    //   options: ["bold", "italic", "underline", "strikethrough"],
    // },
  };

  const editorProps = {
    // toolbarOnFocus: true,
    wrapperClassName: "wrapper-class",
    editorClassName: "editor-class",
    toolbarClassName: "toolbar-class",
    toolbar: { toolbarConfig },
  };

  return (
    <div className={styles.NewJobPage} data-testid="NewJobPage">
      <div className="rt-wrapper">
        <div className="rt-createnewjobpage">
          <div className="rt-pagetitle">
            <h1>
              {editMode ? `Update (${EJobStatus[status]})` : "Create New"} Job
            </h1>

            <div
              style={{ flexDirection: "row", cursor: "pointer" }}
              className="rt-companynamelogo"
              onClick={() => history.push(RouteKeys.AdminSettings)}
            >
              <span>{branch?.name}</span>

              <img
                style={{ borderRadius: 20 }}
                src={branch?.logo || images.LogoHeader}
                alt="Company Logo"
              />
            </div>
          </div>
          <div className="rt-createjob">
            <form className="rt-formtheme rt-formcreatenewjob">
              <fieldset>
                <div className="form-group">
                  <em className="rt-mandatorydot"></em>
                  <label>Job Title</label>
                  <i className="icon-type"></i>
                  <div className="rt-inputiconholder">
                    <input
                      type="text"
                      className="form-control"
                      // placeholder="Add Job Title"
                      {...register("title")}
                    />
                    <span className="text-danger">
                      <ErrorMessage errors={errors} name="title" />
                    </span>
                    <ValidationErrorComponent errors={s_errors} name="title" />
                  </div>
                </div>
                {/* <div className="form-group" onClick={() => _navigate(RouteKeys.AdminSettings)} style={{cursor: 'pointer'}}> */}
                <div className="form-group">
                  <label>Job Location</label>

                  <div className="rt-inputiconholder">
                    <span className="rt-select">
                      <select {...register("location")} value={location}>
                        {branch?.addresses.map((addr) => {
                          const locationStr = latLngToString(
                            addr.latitude,
                            addr.longitude
                          );
                          return (
                            <option
                              value={locationStr}
                              // selected={locationStr === location}
                              key={locationStr}
                            >
                              {addr.address}
                            </option>
                          );
                        })}
                      </select>
                    </span>
                  </div>
                </div>
                <div className="rt-twocol">
                  <div className="form-group">
                    <em className="rt-mandatorydot"></em>
                    <label>Job Description</label>
                    <div className="rt-inputiconholder">
                      {/* <textarea
												// placeholder="Add Lines here"
												name="description"
												ref={register}
											></textarea> */}
                      <Editor
                        editorState={description}
                        onEditorStateChange={(es) => setDescription(es)}
                        {...editorProps}
                      />
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="description" />
                      </span>
                      <ValidationErrorComponent
                        errors={s_errors}
                        name="description"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <em className="rt-mandatorydot"></em>
                    <label>Job Requirements</label>
                    <div className="rt-inputiconholder">
                      {/* <textarea
												// placeholder="Add Lines here"
												name="requirements"
												ref={register}
											></textarea> */}
                      <Editor
                        editorState={requirements}
                        onEditorStateChange={(es) => setRequirements(es)}
                        {...editorProps}
                      />
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="requirements" />
                      </span>
                      <ValidationErrorComponent
                        errors={s_errors}
                        name="requirements"
                      />
                    </div>
                  </div>
                </div>
                <div className="rt-twocol">
                  {/* <div className={`rt-salarytypeholder rt-ishourlyrate`}> */}
                  <div className="form-group">
                    <em className="rt-mandatorydot"></em>
                    <label>Job Benefits</label>
                    <div className="rt-inputiconholder">
                      <Editor
                        editorState={benefits}
                        onEditorStateChange={(es) => setBenefits(es)}
                        {...editorProps}
                      />
                      <span className="text-danger">
                        <ErrorMessage errors={errors} name="benefits" />
                      </span>
                      <ValidationErrorComponent
                        errors={s_errors}
                        name="benefits"
                      />
                    </div>
                  </div>
                  {/* </div> */}

                  <div className="form-group mix_fields_wrapper">
                    <div className="rt-twocol">
                      <div className={`form-group`}>
                        <em className="rt-mandatorydot"></em>
                        <label>Hourly Rate</label>
                        <i className="icon-dollar"></i>
                        <div className="rt-inputiconholder">
                          <input
                            type="number"
                            className="form-control"
                            // placeholder="$20"
                            {...register("salary")}
                          />
                          <span className="text-danger">
                            <ErrorMessage errors={errors} name="salary" />
                          </span>
                          <ValidationErrorComponent
                            errors={s_errors}
                            name="salary"
                          />
                        </div>
                      </div>
                      <div className={`form-group`}>
                        <label>Onsite</label>
                        <div className="rt-inputiconholder">
                          <span className="rt-select">
                            <select {...register("onsite")}>
                              <option value={1}>Yes</option>
                              <option value={0}>No</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="rt-twocol">
                      <div className="">
                        <div className="form-group">
                          <label>Job Category</label>
                          <div className="rt-inputiconholder">
                            {/* <span className="rt-select"> */}
                            {/* <select name="category" ref={register} multiple>
															<option value="Sales">Sales</option>
															<option value="Accounts">Accounts</option>
														</select> */}
                            <Select
                              //   isMulti
                              // isClearable={this.state.value.some(v => !v.isFixed)}
                              value={category}
                              // className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(v: any) => setCategory(v)}
                              options={categories}
                              isClearable={false}
                              // ref={register}
                            />
                            <span className="text-danger">
                              {s_errors.categoryId && (
                                <p>Please Select Category</p>
                              )}
                            </span>

                            {/* </span> */}
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Job Type</label>
                          <div className="rt-inputiconholder">
                            <span className="rt-select">
                              <select {...register("shift")}>
                                <option value={EJobShift.FullTime}>
                                  Full Time
                                </option>
                                <option value={EJobShift.PartTime}>
                                  Part Time
                                </option>
                                <option value={EJobShift.Any}>Both Time</option>
                              </select>
                              <span className="text-danger">
                                <ErrorMessage errors={errors} name="shift" />
                              </span>
                            </span>
                          </div>
                        </div>
                        {/* <div className="form-group">
													<label>Job Status</label>
													<div className="rt-inputiconholder">
														<span className="rt-select">
														<select name="status" ref={register}>
															<option value={EJobStatus.saved}>Save</option>
															<option value={EJobStatus.posted}>Publish</option>
															<option value={EJobStatus.closed}>
															Deactivate
															</option>
														</select>
														<span className="text-danger">
															<ErrorMessage errors={errors} name="status" />
														</span>
														</span>
													</div>
													</div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rt-formbtns">
                  <Button
                    type="button"
                    className="rt-btnlogin"
                    disabled={loading || saving}
                    onClick={handleSubmit((v: any) =>
                      submit(EJobStatus.saved, v)
                    )}
                  >
                    <span>{editMode ? "Update" : "Create"} and Save</span>
                    {saving ? <LoaderComponent /> : ""}
                  </Button>
                  <Button
                    type="button"
                    className="rt-btnlogin"
                    disabled={loading || saving}
                    onClick={handleSubmit((v: any) =>
                      submit(EJobStatus.posted, v)
                    )}
                  >
                    <span>{editMode ? "Update" : "Create"} and Publish</span>
                    {loading ? <LoaderComponent /> : ""}
                  </Button>
                  <Button
                    type="button"
                    className="rt-btnlogin rt-btndelete"
                    onClick={history.goBack}
                  >
                    <span>Cancel</span>
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewJobPage;
