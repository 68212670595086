/*
 * File: GoogleMap.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 31st March 2021 7:21:14 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 1:39:29 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Config } from "../Config";
import { log } from "../Lib/helpers";
import { IAddress } from "../Services/Interfaces/CompanyInterface";

interface IGoogleMap {
  coords?: string;
  addr: string;
  useUserLocation?: boolean;
  onNewAddr: (newAddr: IAddress, index:number) => void;
  id: number;
}

export const GoogleMap = (props: IGoogleMap) => {
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
  } = useForm({
    defaultValues: {
      address: "",
      city: "",
      state: "",
      country: "",
      latitude: "",
      longitude: "",
    },
  });

  const inputId = "pac-input"+props.id
  const locationPickerId = "locationPickerMap"+props.id

  const [location, setLocation] = useState(props.coords);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const _splitAddress = (place: any): string => {
    // log("place in split: ", place);
    if (place.address_components.length) {
      let locality,
        city,
        state,
        country = "";

      place.address_components.map((ac: any) => {
        if (ac.types[0] === "locality") {
          // some area
          locality = ac.long_name;
        } else if (ac.types[0] === "administrative_area_level_2") {
          // city
          city = ac.long_name;
        } else if (ac.types[0] === "administrative_area_level_1") {
          // state
          state = ac.short_name;
        } else if (ac.types[0] === "country") {
          // country
          country = ac.long_name;
        }
      });

      let address = place.formatted_address;

      // if (!address) {
      //   if (place.name) {
      //     address = place.name;
      //   }
      //   address = [address, locality || city, state, country].join(", ");
      // }

      let inputField = (document.getElementById(inputId) as HTMLInputElement)

      if (inputField) {
        address = inputField.value
      }


      
      let latitude,
        longitude = "";
      if (place.geometry) {
        const { location } = place.geometry;
        latitude = location.lat();
        longitude = location.lng();
      }

      city = city || locality || "";

      const addrObj: IAddress = {
        address,
        city,
        state,
        country,
        latitude,
        longitude,
      };

      // log("got addr: ", JSON.stringify(addrObj), addrObj);

      props.onNewAddr(addrObj, props.id);

      // setValue("address", address);

      return address;

      // let a1 =
      //   (address_components[0] && address_components[0].short_name) || "";

      // let a2 =
      //   (address_components[1] && address_components[1].short_name) || "";

      // let a3 =
      //   (address_components[2] && address_components[2].short_name) || "";

      // log("breakdown address: ", a1, a2, a3);

      // return [a1, a2, a3].join(" ");
    }
    return "";
  };

  const showMap = useCallback((coordStr: string | undefined = props.coords) => {
    // log("got in showMap: ", coordStr);
    if (!coordStr) return;
    const [lat, lng]: any = coordStr.split(",");
    let latLng = { lat: Number(lat), lng: Number(lng) };

    // const map = new google.maps.Map(
    //   document.getElementById(locationPickerId),
    //   {
    //     zoom: 13,
    //     center: latLng,
    //     mapTypeId: "terrain",
    //   }
    // );
    const input = document.getElementById(inputId);

    const autocomplete = new google.maps.places.Autocomplete(input);

    // autocomplete.bindTo("bounds", map);

    autocomplete.setFields(["address_components", "geometry", "icon", "name"]);

    let infoWindow = new google.maps.InfoWindow({
      content: "Current Location",
      position: latLng,
    });

    // const marker = new google.maps.Marker({
    //   map,
    //   anchorPoint: new google.maps.Point(0, -29),
    // });

    // marker.setPosition(infoWindow.position);

    // marker.setVisible(true);

    autocomplete.addListener("place_changed", () => {
      infoWindow.close();
      // marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      // if (place.geometry.viewport) {
      //   map.fitBounds(place.geometry.viewport);
      // } else {
      //   map.setCenter(place.geometry.location);
      //   map.setZoom(17); // Why 17? Because it looks good.
      // }

      // marker.setPosition(place.geometry.location);
      // marker.setVisible(true);
      let _address = "";

      // if (place.address_components) {
      //   // let a1, a2, a3 = '';

      //   let a1 =
      //     (place.address_components[0] &&
      //       place.address_components[0].short_name) ||
      //     "";

      //   let a2 =
      //     (place.address_components[1] &&
      //       place.address_components[1].short_name) ||
      //     "";

      //   let a3 =
      //     (place.address_components[2] &&
      //       place.address_components[2].short_name) ||
      //     "";

      //     log('breakdown address: ', a1, a2, a3)

      //   _address = [a1,a2,a3].join(" ");
      // }

      log("place on change: ", place);
      _address = _splitAddress(place);
      // setAddress(_address);
      // _updateFormVal("address", _address);
      let _location = place.geometry.location;
      setLocation(`${_location.lat()},${_location.lng()}`);
    });

    // map.addListener("click", (mapsMouseEvent: any) => {
    //   infoWindow = new google.maps.InfoWindow({
    //     position: mapsMouseEvent.latLng,
    //   });

    //   marker.setVisible(true);

    //   marker.setPosition(infoWindow.position);
    //   marker.setVisible(true);

    //   log("clicked map...", mapsMouseEvent);

    //   getLocationAddress(mapsMouseEvent.latLng.toJSON());
    // });
  }, []);
  // }, [props.coords, props.useUserLocation]);

  const getLocationAddress = (coords: any): void => {
    var geocoder = new google.maps.Geocoder();
    var myLatlng = new google.maps.LatLng(coords.lat, coords.lng);
    setLocation(`${coords.lat},${coords.lng}`);
    geocoder.geocode(
      {
        latLng: myLatlng,
      },
      (results: any, status: any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            // log("address got is: ", results);
            // let address = results[0].formatted_address;
            // props.onNewAddr(address);

            _splitAddress(results[0]);

            // setValue("address", address);
            // setAddress(address);
            // _updateFormVal("address", address);
          }
        }
      }
    );
  };

  useEffect(() => {
    let latLng = props.coords;
    let [lat, lng]: any = props?.coords?.split(",");
    if (props.useUserLocation) {
      log("getting user location...");
      // get user's current location
      window.navigator.geolocation.getCurrentPosition(
        ({ coords }: any) => {
          log("coordinates got: ", coords);
          console.log(coords);
          latLng = `${coords.latitude},${coords.longitude}`;
          // if (!editMode && latLng) {
          showMap(latLng);
          // }
          // setMyLatLng(latLng);
          // getLocationAddress({ lat: coords.latitude, lng: coords.longitude });
          lat = coords.latitude;
          lng = coords.longitude;
        },
        (e) => {
          log('error on geolocation', e.message)
          showMap(Config.DEFAULT_COORDS_STR);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    } else {
      showMap(latLng);
    }

    getLocationAddress({ lat, lng });
    if (props.addr) {
      setValue("address", props.addr);
    }
  }, [props.coords, props.useUserLocation, showMap]);

  if (!google) {
    return null;
  }

  return (
    <div className="form-group">
      {/* <label>Location</label> */}
      <div className="rt-jobmaparea">
        <figure className="rt-mapplaceholder">
          <div id="pac-card">
            <input
              id={inputId}
              className="form-control"
              type="text"
              defaultValue={props.addr}
              // ref={register}
              {...register('address')}
            />
          </div>
          {/* <div
            id={locationPickerId}
            style={{ width: "100%", height: "300px", marginTop: "20px" }}
          ></div> */}
        </figure>
      </div>
    </div>
  );
};

GoogleMap.defaultProps = {
  coords: "", //Config.DEFAULT_COORDS_STR,
  useUserLocation: false,
};
