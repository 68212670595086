/*
 * File: NewHiresComponent.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 4th December 2020 12:33:16 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:36:56 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import React, { useState } from "react";
import { images } from "../../../assets/images/images";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { NewHiredStat } from "../../../Services/Interfaces/ApplicationInterface";
import styles from "./NewHiresComponent.module.scss";

interface INewHiresComponent {
  data: NewHiredStat[];
  loading: boolean;
}
const NewHiresComponent = (props: INewHiresComponent) => {
  return (
    <div className={styles.NewHiresComponent} data-testid="NewHiresComponent">
      <div className="rt-themebox rt-newhirings">
        <div className="rt-themeboxhead">
          <h2>New Hires</h2>
          <button className="rt-btndots">
            <i className="icon-more"></i>
          </button>
        </div>
        <ul className="rt-newhiredlists">
          {props.data.map((user) => (
            <li key={user.id}>
              <div className="rt-newhirelist">
                <figure className="rt-newhiredimg">
                  <img
                    src={user.photo !== "" ? user.photo : images.HireImg1}
                    alt="Hired People"
                  />
                </figure>
                <div className="rt-hirednamedesig">
                  <h3>{user.fullName}</h3>
                  <span>{user.job.title}</span>
                </div>
                <div className="rt-btnactions">
                  <button className="rt-btnaction" type="button">
                    <i className="icon-email"></i>
                  </button>
                  <button className="rt-btnaction" type="button">
                    <i className="icon-eye1"></i>
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        <a className="rt-btnviewmore" href={RouteKeys.ApplicantsList}>
          View More
        </a>
      </div>
    </div>
  );
};

export default NewHiresComponent;
