/*
 * File: SuccessPopup.tsx
 * Project: Dyme-Web
 * File Created: Tuesday, 30th November 2021 1:05:31 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:48:12 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React,{ useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props:any) {

  const { success,setSuccess, msg } = props

  useEffect(() => {
        setTimeout(() => {
          setSuccess(false);
        }, 1000);
      }, [])
  return (
    <div>
      <Dialog
        open={success}
        onClose={setSuccess}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="mx-2"
      >
        <DialogTitle id="alert-dialog-title">{"Success!"} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className="pb-2">
          {msg}
          </DialogContentText>
        </DialogContent>
       
      </Dialog>
    </div>
  );
}
