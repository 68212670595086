/*
 * File: user-management.component.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 28th December 2020 7:49:16 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Saturday, 25th September 2021 1:24:36 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { ChangeEvent, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { AddNewUserComponent } from "./add-new-user.component";
import moment from "moment";
import { debounce } from "lodash";
import { ViewUserComponent } from "./view-user.component";
import { IPaginate, defaultPaginatedData } from "../../../../Services/Interfaces";
import {
  EUserType,
  IUser,
} from "../../../../Services/Interfaces/AuthInterface";
import { ConfirmModal } from "../../../../Components/modals/confirm.modal";
import SearchingComponent from "../../../../Components/common/searching.component";
import { PaginationComponent } from "../../../../Components/common/Pagination/Pagination.component";
import { UserApi } from "../../../../Services/API/UsersApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { log } from "../../../../Lib/helpers";
import { toastify } from "../../../../Services/toast.service";
import Loader from "../../../../Components/Loader";

let keyword: string = "";

export const UserManagementTabComponent = () => {
  const [newUser, setNewUserTab] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [deleteID, setDeleteID] = useState<any>(null);
  const [users, setUsers] = useState<IPaginate<IUser>>(defaultPaginatedData);
  const [user, setUser] = useState<any>();
  const [showProfile, setShowProfile] = useState<boolean>(false);

  let page: number = 1;

  useEffect(() => {
    keyword = "";
    getData();
  }, []);

  const getData = async (page = 1, keyword = '') => {
    setLoading(true);

    // add search in query params

    let searchQuery: any;
    if (keyword !== '') {
      searchQuery = {
        "$or": [
          {
            fullName: {
              "$cont": keyword
            },
          },
          {
            email: {
              "$cont": keyword
            }
          },
          {
            phone: {
              "$cont": keyword
            }
          }
        ]
      }
    }

    searchQuery = JSON.stringify(searchQuery)

    const response = await UserApi.UsersList(page, searchQuery);
    try {
      if (response.hasError) {
        handleApiError(response, "Users List: ");
      } else {
        // ? Get Users success
        log("Users List SUCCESS: ", response);
        setUsers(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  const addUser = (user: IUser) =>
    setUsers({ ...users, total: users.total + 1, data: [user, ...users.data] });

  const selectUser = (_u: IUser) => {
    if (showProfile && _u.id === user.id) {
      setShowProfile(false)
    } else {

      setShowProfile(true);
      setUser(_u);
    }
  };

  const updateUser = (_user: IUser) => {
    const _users = users.data.map((u: IUser) => {
      if (_user.id !== u.id) return u;
      else return { ...u, ..._user };
    });
    setUsers({ ...users, data: _users });
  };

  const showCofirmation = (id: string | number) => {
    setDeleteID(id);
    setShowConfirm(true);
  };

  const hideConfirm = (): void => {
    setShowConfirm(false);
    setDeleteID(null);
    setDeleting(false);
  };

  const onDelete = async () => {
    setDeleting(true);

    const response = await UserApi.UserDelete(deleteID);
    try {
      if (response.hasError) {
        handleApiError(response, "User Delete: ");
      } else {
        // ? Delete User success
        log("Users Delete SUCCESS: ", response);

        toastify("success", "User deleted");
        const data = users.data.filter((u: IUser) => u.id !== deleteID);
        setUsers({ ...users, data });
        setShowProfile(false);
        hideConfirm();
      }
    } catch (e) {
      handleCatchError(e);
    }
    setDeleting(false);

    // deleteRequest(endpoints.company.deleteUser(deleteID)).then((response: AxiosResponse<{ message: string }>) => {
    //     toastify('success', response.data.message)
    //     const data = users.data.filter((u: IUser) => u.id !== deleteID);
    //     setUsers({ ...users, data });
    //     setShowProfile(false);
    //     hideConfirm();
    // }, ({ response }: any) => {
    //     toastify('error', response.data.message)
    // })
  };

  return (
    <>
      <ConfirmModal
        show={showConfirm}
        loading={deleting}
        onClose={hideConfirm}
        onConfirm={onDelete}
        confirmTitle="Delete"
        heading="Deleting User!"
        message="Are you sure you want to delete this user?"
      />
      <div
        className={`rt-usermanagementtab ${newUser ? "d-none" : ""} ${
          showProfile ? "rt-showuserprofile" : ""
        }`}
      >
        <div className="rt-profilebox rt-allusers">
          <div className="rt-profileboxhead">
            <h3>Users ({users.total})</h3>
            {/* <button
              className="rt-btnpostajob"
              onClick={() => setNewUserTab(true)}
            >
              Create New User
            </button> */}
          </div>
          <div className="rt-profileusers">
            <div className="rt-jobslistsarea">
              <div className="at-jobareahead">
                <SearchingComponent
                  getData={(v: string) => {
                    keyword = v;
                    page = 1;
                    getData(page, keyword);
                  }}
                  placeholder="Search by Name, email or phone"
                />
              </div>
              <div className="rt-themetableholder">
                <table className="rt-themetable table rt-jobstable">
                  <thead>
                    <tr>
                      <th>
                        <span>User ID</span>
                      </th>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>ACL</span>
                      </th>
                      <th>
                        <span>Created Date</span>
                      </th>
                      <th>
                        <span>Status</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.data.map((u: IUser) => (
                      <tr key={u.id}>
                        <td
                          onClick={() => selectUser(u)}
                          style={{ cursor: "pointer" }}
                          data-title="User Email"
                        >
                          <span>{u.email}</span>
                        </td>
                        <td
                          onClick={() => selectUser(u)}
                          style={{ cursor: "pointer" }}
                          data-title="User Email"
                        >
                          <span>{u.fullName}</span>
                        </td>
                        <td data-title="Data Registered">
                          {/* <span>{(u.acl || []).join(", ")}</span> */}
                          <span>
                            {u.role === EUserType.Noob ? "Candidate" : u.role}
                          </span>
                        </td>
                        <td data-title="Name">
                          <span>{moment().format("MM/DD/yyyy")}</span>
                        </td>
                        <td data-title="Email">
                          <div className="rt-statusbuttonholder">
                            <div
                              className={`rt-selectresult rt-${
                                u.isActive ? "bgstatusgreen" : "bgstatusred"
                              }`}
                            >
                              <i className="icon-status-active"></i>
                              <em>{u.isActive ? "Active" : "In-Active"}</em>
                            </div>
                            <Dropdown className="rt-themedropdown">
                              <Dropdown.Toggle id="dropdown-basic">
                                <i className="icon-more"></i>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className="rt-themedropdownmenu">
                                <Dropdown.Item onClick={() => selectUser(u)}>
                                  <i className="icon-eye1"></i>
                                  <span>View User</span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => showCofirmation(u.id)}
                                >
                                  <i className="icon-delete"></i>
                                  <span>Delete User</span>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <PaginationComponent
                    {...users}
                    onClick={(v: number) => {
                      page = v;
                      getData(v);
                    }}
                  />
                </table>
              </div>
            </div>
          </div>
        </div>
        {showProfile && user ? (
          <ViewUserComponent
            user={user}
            onUpdate={updateUser}
            onDelete={showCofirmation}
            onClose={() => setShowProfile(false)}
          />
        ) : (
          ""
        )}
      </div>
      {newUser && (
        <AddNewUserComponent
          onClose={() => setNewUserTab(false)}
          addUser={addUser}
        />
      )}
      {loading && <Loader />}
    </>
  );
};
