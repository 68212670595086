/*
 * File: Message.tsx
 * Project: Dyme-Web
 * File Created: Monday, 14th June 2021 5:16:48 pm
 * Author: Rahul Khera (umaraamer@gmail.com)
 * -----
 * Last Modified: Thu Mar 03 2022
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import MuiDialog from "../../../Components/modals/modalLayout";
import {
  TextField,
  Typography,
  Grid,
  FormControl,
  Select,
  InputLabel,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { inputLabelClasses } from "@mui/material/InputLabel";

import RichTextEditor from "react-rte";
import MenuItem from "@mui/material/MenuItem";
import makeStyles from "@mui/styles/makeStyles";
import InfoIcon from "@mui/icons-material/Info";
import Button from "../../../Components/common/ButtonComponent/ButtonComponent";
import { TemplateApi } from "../../../Services/API/TemplateApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log, _cloneDeep, compareArrays } from "../../../Lib/helpers";
import {
  ITemplateRequest,
  ITemplate,
  ISmsEmps,
  IEmailEmps,
} from "../../../Services/Interfaces/TemplateInterface";
import { AppContext } from "../../../Services/AppContext";
import { toastify } from "../../../Services/toast.service";
import { IPaginate, ISelect } from "../../../Services/Interfaces";
import Autocomplete from "@mui/material/Autocomplete";
import { ConfirmModal } from "../../../Components/modals/confirm.modal";
import { IApiResponse } from "../../../Services/api-client";

import {
  IEmployee,
  IEmployeeBase,
} from "../../../Services/Interfaces/EmployeeInterface";
import Chip from "@mui/material/Chip";
import { IBranch } from "../../../Services/Interfaces/CompanyInterface";
import { Editor } from "react-draft-wysiwyg";
import {
  convertFromHTML,
  convertFromRaw,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import Confirmation from "./Confirmation";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AlertMessage from "./AlertMessage";
import SuccessPopup from "./SuccessPopup";
import { values } from "lodash";
import ClickAwayListener from "@mui/material/ClickAwayListener";
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';

interface MessageProps {
  openMessage: boolean;
  setOpenMessage: (val: boolean) => void;
  inviteStatus: boolean;
  selectedEmployees: Array<number>;
  employees: IPaginate<IEmployee>;
  referType: string;
  _updateReferralSettings: () => Promise<boolean>;
  companyInfo: IBranch | undefined;
  setSelectedEmployees: (data: Array<number>) => void;
  handleEditBox: (val: boolean) => void;
  setUpdateTemplate: (val: any) => void;
  create: boolean;
  setCreate: (val: boolean) => void;
}

export const defaultBranchInfo = {
  addresses: [],
  logo: "",
  name: "",
};

const MessageBox = (props: MessageProps) => {
  const {
    openMessage,
    setOpenMessage,
    employees,
    selectedEmployees,
    referType,
    companyInfo,
    _updateReferralSettings,
    setSelectedEmployees,
    handleEditBox,
    setUpdateTemplate,
    create,
    setCreate,
  } = props;
  const classes = useStyles();

  const DEFAULT_TEMPLATE = {
    id: "",
    title: "",
    name: "",
    text: "",
    branch: defaultBranchInfo,
    medium: "",
    subject: "",
    label: "",
    value: "",
  };

  const [state, setState] = useState({
    title: { label: "Invite", value: "invite" },
    name: DEFAULT_TEMPLATE,
    message: "",
    checked: "sms",
    createName: "",
    createTitle: { label: "", value: "" },
    subject: "",
    //email: RichTextEditor.createEmptyValue(),
    textEditorMessage: RichTextEditor.createEmptyValue(),
    customCategory: "",
  });

  const [email, setEmail] = useState<EditorState>();

  interface TemplateWithLabel extends ITemplateRequest {
    label: string;
    value: string | number;
  }

  const [nameList, setNameList] = useState<Array<TemplateWithLabel>>([]);
  const [openCreateTemplate, setOpenCreateTemplate] = useState(false);
  const [typeList, setTypeList] = useState<Array<ISelect>>([]);
  const [templates, setAllTemplates] = useState<any>({});
  const [selectedMessageEmployees, setSelectedMessageEmployees] = useState<
    Array<IEmployee>
  >([]);
  const [global, setGlobal] = useState(false);
  const { user } = useContext(AppContext);
  const [toggle, setToggle] = useState(false);
  const [show, setShowConfirm] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<any>({
    name: false,
    medium: false,
    title: false,
    subject: false,
    message: false,
  });

  const [subjectShrink, setSubjectShrink] = useState(false);
  const [messageShrink, setMessageShrink] = useState(false);

  useEffect(() => {
    if (companyInfo) {
      _fetchAllTemplates();
    }
  }, [companyInfo]);

  const validate = (type: string) => {
    console.log(nameList, "nameList");
    const nameTemp = nameList.filter((t, i) => {
      return t.id == state.name.value;
    });
    switch (type) {
      case "editDelete":
        if (
          user?.user.role === "admin" &&
          nameTemp[0]?.branch?.id == companyInfo?.id
        ) {
          return true;
        } else if (user?.user.role === "superAdmin") {
          return true;
        } else {
          return false;
        }
      case "create":
        if (user?.user.role === "admin" && companyInfo?.id) {
          return true;
        } else if (user?.user.role === "superAdmin") {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };
  const filterNameList = () => {
    const names: Array<any> = [];
    if (state?.title?.value) {
      templates[state?.title?.value]?.forEach(
        (val: ITemplateRequest, i: number) => {
          if (val.medium === state.checked) {
            names.push({ ...val, label: val.name, value: val.id });
          }
        }
      );
      setNameList(names);
      // if (names.length) {
      //   setState({
      //     ...state,
      //     name: names[0],
      //   });
      // }
    } else {
      setState({
        ...state,
        name: DEFAULT_TEMPLATE,
      });
      resetNameList(templates);
    }
  };

  useEffect(() => {
    filterNameList();
  }, [state.title.value]);

  useEffect(() => {
    const selectedVal = nameList.filter(
      (temp: TemplateWithLabel, i: number) => {
        return temp.value === state.name.value;
      }
    );
    //console.log("selectedVal :", selectedVal);
    if (selectedVal.length) {
      setState({
        ...state,
        message: selectedVal[0].text,
        subject: selectedVal[0].subject,
        createName: selectedVal[0].name,
        customCategory: selectedVal[0].title,
      });
    } else {
      setState({
        ...state,
        message: "",
        subject: "",
      });
    }
  }, [state.name.value]);

  useEffect(() => {
    if (selectedEmployees.length && employees?.data?.length) {
      const filteredData = employees.data.filter(
        (val: IEmployee, i: number) => {
          if (selectedEmployees.includes(val.id)) {
            return val;
          }
        }
      );
      setSelectedMessageEmployees(filteredData);
    } else {
      setSelectedMessageEmployees([]);
    }
  }, [selectedEmployees]);

  const filterTypeList = (list: Array<ISelect>) => {
    let types: any = {};
    Object.values(list).forEach((item) => {
      Object.values(item).forEach((val) => {
        if (val.medium === state.checked) {
          types[val.title] = {
            value: val.title,
            label: val.title.concat("_" + val.medium),
            medium: val.medium,
          };
        }
      });
    });
    const categoryList: Array<ISelect> = [];
    for (const property in types) {
      categoryList.push({ value: property, label: property });
    }
    //console.log("categoryList", categoryList);
    setTypeList(categoryList);
  };

  const _fetchAllTemplates = async () => {
    try {
      let response: IApiResponse;
      if (companyInfo?.id && user?.user.role === "admin") {
        response = await TemplateApi.GetTemplate(companyInfo.id);
      } else {
        response = await TemplateApi.GetTemplate();
      }
      if (response.hasError) {
        handleApiError(response, "TEMPLATE List ALL: ");
      } else {
        log("Templates List ALL SUCCESS: ", response);
        //console.log("Response.data fetchAllTemplates", response.data);
        setAllTemplates(response.data);
        filterTypeList(response.data);
        resetNameList(response.data);
        setUpdateTemplate((prev: any) => !prev);
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  const resetNameList = (data: any) => {
    let newNameList: Array<TemplateWithLabel> = [];
    if (Object.values(data).length) {
      Object.values(data).forEach((item: any, i: number) => {
        item.forEach((val: any, i: number) => {
          if (val.medium === state.checked) {
            newNameList.push({ ...val, label: val.name, value: val.id });
          }
        });
      });
      //console.log("NewNameList : ", newNameList);
      setNameList(newNameList);
    } else {
      setNameList([]);
    }
  };

  const _sendSms = async () => {
    if (selectedMessageEmployees.length) {
      // const phoneList: Array<string> = [];
      const empIds: Array<number> = [];
      selectedMessageEmployees.forEach((emp, i) => {
        // const code = emp.phone.split(emp.phone.slice(-10))[0];
        // const phoneVal = code ? emp.phone : `+1${emp.phone}`;
        empIds.push(emp.id);
      });
      const payload: ISmsEmps = {
        // phone: phoneList,
        empIds,
        text: `${state.subject} ${state.message}`,
      };
      try {
        const response = await TemplateApi.SendSmsToEmp(payload);
        if (response.hasError) {
          handleApiError(response, "SEND SMS : ");
        } else {
          log("SEND SMS SUCCESS: ", response);
          setOpenMessage(false);
          setSelectedMessageEmployees([]);
          setSelectedEmployees([]);
          toastify("success", response.data.message);
        }
      } catch (e) {
        handleCatchError(e);
      }
    }
  };
  const _sendEmail = async () => {
    if (selectedMessageEmployees.length) {
      // const phoneList: Array<string> = [];
      const empIds: Array<number> = [];
      selectedMessageEmployees.forEach((emp, i) => {
        // const code = emp.phone.split(emp.phone.slice(-10))[0];
        // const phoneVal = code ? emp.phone : `+1${emp.phone}`;
        empIds.push(emp.id);
      });
      const payload: IEmailEmps = {
        // phone: phoneList,
        empIds,
        text: (email ? stateToHTML(email?.getCurrentContent()) : ""),
        subject: `${state.subject}`,
      };
      try {
        const response = await TemplateApi.SendEmailToEmp(payload);
        if (response.hasError) {
          handleApiError(response, "SEND EMAIL : ");
        } else {
          log("SEND EMAIL SUCCESS: ", response);
          setOpenMessage(false);
          setSelectedMessageEmployees([]);
          setSelectedEmployees([]);
          toastify("success", response.data.message);
        }
      } catch (e) {
        handleCatchError(e);
      }
    }
  };
  const _sendSmsAndInvite = async () => {
    // if (referType === "invite") {
    //   const val = await _updateReferralSettings();
    //   val && _sendSms();
    // } else {
    _sendSms();
    // }
  };
  const _sendEmailAndInvite = async () => {
    // if (referType === "invite") {
    //   const val = await _updateReferralSettings();
    //   val && _sendEmail();
    // } else {
    _sendEmail();
    // }
  };

  const _createTemplate = async () => {
    const payload: ITemplate = {
      name: state.createName,
      // title: state.customCategory?state.customCategory : state?.title?.value,
      title: state.title.value,
      text:
        state.checked === "email"
          ? (email ? stateToHTML(email?.getCurrentContent()) : "")
          : state.message,
      medium: state.checked,
      subject: state.subject,
    };
    if (companyInfo?.id && user?.user.role === "admin") {
      payload.branchId = companyInfo.id;
    }
    try {
      const response = await TemplateApi.CreateTemplate(payload);
      if (response.hasError) {
        handleApiError(response, "TEMPLATE CREATE: ");
      } else {
        // log("Templates CREATE SUCCESS: ", response);
        // toastify("success", "SMS Template created successfully");
        setSuccess(true);
        setToggle(true);
        onRequestCloseTemplate();
        _fetchAllTemplates();
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  const handleCreteOpenTemplate = () => {
    const val = validate("create");
    if (val) {
      // onRequestCloseMessage();
      setOpenCreateTemplate(true);
    } else {
      toastify("info", "You do not have Branch");
    }
  };
  const _editTemplate = async () => {
    const val = validate("editDelete");
    if (val) {
      const selectedOption = nameList.filter((temp, i) => {
        return temp.value === state.name.value;
      });
      const payload: any = {
        id: selectedOption[0].value,
        title: state.customCategory,
        text:
          state.checked === "email"
            ? (email ? stateToHTML(email?.getCurrentContent()) : "")
            : state.message,
        // text: state.textEditorMessage? state.textEditorMessage :state.message,
        // name: selectedOption[0].label,
        name: state.createName,
        subject: state.subject,
        medium: state.checked,
      };
      if (state?.name?.branch) {
        payload.branchId = state.name.branch;
      }
      try {
        const response = await TemplateApi.EditTemplate(payload);
        if (response.hasError) {
          handleApiError(response, "TEMPLATE EDIT: ");
        } else {
          log("TEMPLATE EDIT SUCCESS: ", response);
          _fetchAllTemplates();
          // onRequestOpenMessage();
          setOpenCreateTemplate(false);
          toastify("success", "Template edit successfully");
        }
      } catch (e) {
        handleCatchError(e);
      }
    } else {
      toastify("info", "You do not have permission to edit Template");
    }
  };
  const _deleteTemplate = async () => {
    const val = validate("editDelete");
    if (val) {
      try {
        let response: IApiResponse;
        if (user?.user?.role === "admin") {
          response = await TemplateApi.TemplateDelete(
            state.name.value,
            companyInfo?.id
          );
        } else {
          response = await TemplateApi.TemplateDelete(state.name.value);
        }
        if (response.hasError) {
          handleApiError(response, "TEMPLATE DELETE: ");
        } else {
          log("TEMPLATE DELETE SUCCESS: ", response);
          _fetchAllTemplates();
          resetState();
          toastify("success", "Template deleted successfully");
        }
      } catch (e) {
        handleCatchError(e);
      }
    } else {
      toastify("info", "You do not have permission to delete Template");
    }
  };
  const renderSendText = () => {
    // let frontText = referType === "invite" ? "Invite and Send" : "Send";
    let frontText = "Send";
    switch (state.checked) {
      case "sms":
        return `${frontText} SMS`;
      case "email":
        return `${frontText} Email`;
      default:
        return "Send";
    }
  };
  const handleOpenConfirm = () => {
    const val = validate("editDelete");
    if (val) {
      // onRequestCloseMessage();
      setShowConfirm(true);
      setOpenCreateTemplate(false);
      setCreate(false);
    } else {
      toastify("info", "You do not have permission to delete Template");
    }
  };
  const handleConfirm = () => {
    _deleteTemplate();
    setShowConfirm(false);
  };

  const dialogActions = (
    <Grid
      container
      justifyContent="flex-end"
      className="my-3 d-flex justify-content-between"
    >
      <Grid item>
        <div
          //className = "text-white p-2 rounded text-xs cursor-pointer font-semibold ml-3"
          style={{
            padding: 10,
            
            borderRadius: 4,
            width : 128,
            textAlign : "center",
            color: "#fff",
            backgroundColor: "#005EFF",
            fontSize: 14,
            cursor: "pointer",
            fontWeight: 600,
            marginRight: 10,
          }}
          onClick={
            state.checked === "sms" ? _sendSmsAndInvite : _sendEmailAndInvite
          }
        >
          {renderSendText()}
        </div>
      </Grid>

      <Grid item>
        <div
          //className = "text-white p-2 rounded text-xs cursor-pointer font-semibold ml-3"
          style={{
            padding: 10,
            borderStyle : "solid",
            borderRadius: 4,
            borderWidth : 1,
            width : 184,
            textAlign : "center", 
            borderColor: "#ddd",
            color: "#4B6B73",
            backgroundColor: "#F1F5F6",
            fontSize: 14,
            cursor: "pointer",
            fontWeight: 600,
            marginRight: 10,
          }}
          onClick={handleCreteOpenTemplate}
        >
          Create | Edit Templates
        </div>
      </Grid>

    </Grid>
  );

  const resetState = () => {
    setState({
      ...state,
      title: { label: "Invite", value: "invite" },
      name: DEFAULT_TEMPLATE,
      message: "",
      createName: "",
      createTitle: { label: "", value: "" },
      subject: "",
    });
  };

  const onRequestCloseMessage = () => {
    // setOpenMessage(false);
  };
  const onRequestOpenMessage = () => {
    setOpenMessage(true);
  };

  const onRequestCloseTemplate = () => {
    setOpenCreateTemplate(false);
    setCreate(false);
    // resetState();
  };

  const handleChange = (e: any, key: string, val?: any) => {
    console.log("key", key, e.target.value);
    if (
      key === "createTitle" ||
      key === "title" ||
      key === "name" ||
      key === "createName"
    ) {
      if (val) {
        if (key === "name") {
          setState({
            ...state,
            [key]: val,
            createName: val.label,
          });
          let blocksFromHTML = convertFromHTML(val.text);
          let emailContent = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap
          );
          const emailContentWithState = EditorState.createWithContent(emailContent);
          setEmail(emailContentWithState);
          //setEmail(emailContent);
        } else {
          //console.log("Here");
          setState({
            ...state,
            [key]: val,
            name : DEFAULT_TEMPLATE,
            subject : "",
          });
          setEmail(undefined);
        }
      } else {
        if (key === "createName") {
          setState({
            ...state,
            [key]: e.target.value,
          });
        } else {
          //console.log("here"); // Medium Change state
          setState({
            ...state,
            [key]: { label: e.target.value, value: e.target.value },
            subject : "",
            name : DEFAULT_TEMPLATE
          });
          setEmail(undefined)
        }
      }
    } else {
      setState({
        ...state,
        [key]: e.target.value,
        name : DEFAULT_TEMPLATE,
        subject : "",
      });
      setEmail(undefined);
    }
  };
  const handleDelete = (data: IEmployee) => {
    setSelectedMessageEmployees(
      selectedMessageEmployees.filter((val: IEmployee, i: number) => {
        return val.id !== data.id;
      })
    );
  };

  const handleToggle = (e: any, key: string) => {
    setState({
      ...state,
      [key]: e.target.name,
    });
  };
  useEffect(() => {
    if (Object.keys(templates).length) {
      filterTypeList(templates);
      filterNameList();
    }
  }, [state.checked]);

  // const handleChangeEmail = (text: any) => {
  //   setState({
  //     ...state,
  //     email: text,
  //   });
  // };

  // let name = JSON.parse(localStorage.getItem("user") || "");
  // if (name) {
  //   console.log(name.user.fullName, "fullName");
  // }
  // let userObj = name;
  // userObj && userObj.user && console.log(userObj?.user?.fullName, "kkkkkkkk");

  const handleValidate = () => {
    const errors: any = {};
    let validated = true;
    if (!state.createName) {
      errors["name"] = true;
      validated = false;
    }
    if (!state.subject || state.subject === "Add New Medium") {
      errors["subject"] = true;
      validated = false;
    }
    if (!state.title) {
      errors["title"] = true;
      validated = false;
    }
    // if (!subject) {
    //   errors["subject"] = true;
    //   validated = false;
    // }
    if (!state.message) {
      errors["message"] = true;
      validated = false;
    }

    if (validated === true) {
      handleSubmit();
      setError({
        name: false,
        // medium: false,
        // title: false,
        subject: false,
        message: false,
      });
    }
    setError({ ...errors });
  };
  const handleSubmit = () => {
    _createTemplate();
  };

  // const handleCheckUser = () => {
  //   if (user?.user?.role === "superAdmin" || defaultBranchInfo !== null) {
  //     _editTemplate();
  //   } else {
  //     setGlobal(true);
  //   }
  // };

  useEffect(() => {
    log("create in Message box...");
    if (create) {
      setOpenCreateTemplate(true);
    }
  }, [create]);

  const handleClickAway = () => {
    setOpenMessage(false);
    resetState();
  };

  //console.log("State : ", state);

  return (
    <>
      {toggle === true ? <Confirmation toggle={toggle} /> : null}
      {global === true ? (
        <AlertMessage globle={global} setGloble={setGlobal} />
      ) : null}
      {success === true ? (
        <SuccessPopup
          success={success}
          setSuccess={setSuccess}
          msg={`${state?.createName} template successfully saved`}
        />
      ) : null}
      <ClickAwayListener onClickAway={handleClickAway}>
        <MuiDialog
          open={openMessage}
          title={""}
          dialogClasses={{
            paper: classes.dialogPaper,
          }}
          dialogActions={dialogActions}
          onRequestClose={handleClickAway}
          dialogActionsClassName={classes.templateDialogAction}
        >
          <div>
            <h5 style = {{fontFamily :"'Monsterrat', sans-serif", color : "#4B6B73", fontWeight : 700}}>Message</h5>
          </div>
          <Grid className="mb-3" alignItems="center">
            <div className="d-flex align-items-center">
              <Typography className={`${classes.heading} mr-5`}>To:</Typography>
              <Typography>
                <div className={classes.chipWrapper}>
                  {selectedMessageEmployees.length
                    ? selectedMessageEmployees.map((data) => {
                        let icon;
                        return (
                          <li key={data.id}>
                            <Chip
                              icon={icon}
                              label={data?.fullName}
                              onDelete={() => handleDelete(data)}
                              className={classes.chip}
                            />
                          </li>
                        );
                      })
                    : "No Employees Selected"}
                </div>
              </Typography>
            </div>
          </Grid>
            <div style = {{
              display : "flex", 
              flexDirection : "row", 
              justifyContent : "space-between", 
              marginBottom : 16, 
              fontFamily : "'Monsterrat', sans-serif"
              }}>
            <FormControl variant="outlined" className={classes.formControl}>
              <div className={classes.dropDown} style = {{width : 277}}>
                <InputLabel id="medium-select-label" 
                  style = {{
                    color : "#005EFF", 
                    fontSize : 15, 
                    fontWeight : 600,
                    fontFamily : "'Monsterrat', sans-serif"
                  }}>
                    Medium
                </InputLabel>
                <Select
                  style={{ 
                    width: "100%", 
                    fontFamily : "'Monsterrat', sans-serif", 
                    color : "#4B6B73", 
                    fontWeight : 700, 
                    fontSize : 14 
                  }}
                  variant="outlined"
                  value={state.checked}
                  onChange={(e) => handleChange(e, "checked")}
                  labelId="medium-select-label"
                  label={"Medium"}
                >
                  <MenuItem value={"sms"}>SMS</MenuItem>
                  <MenuItem value={"email"}>Email</MenuItem>
                </Select>
              </div>
            </FormControl>
            <div>

            </div>
            <FormControl
              variant="outlined"
              className={`${classes.formControl} `}
              style = {{width : 272}}
            >
              <div className={classes.dropDown}>
                <InputLabel id="category-select-label" style = {{
                    color : "#005EFF", 
                    fontSize : 15, 
                    fontWeight : 600,
                    fontFamily : "'Monsterrat', sans-serif"
                  }}>Category</InputLabel>
                <Select
                  style={{ 
                    width: "100%", 
                    fontFamily : "'Monsterrat', sans-serif", 
                    color : "#4B6B73", 
                    fontWeight : 700, 
                    fontSize : 14 
                  }}
                  variant="outlined"
                  value={state.title.value}
                  onChange={(e: any) => handleChange(e, "title")}
                  labelId="category-select-label"
                  label={"Category"}
                >
                  <MenuItem value={"message"}>Message</MenuItem>
                  <MenuItem value={"invite"}>Invite</MenuItem>
                </Select>

                {/* <Autocomplete
                // options={typeList.filter((val) =>
                //   val.label.includes(state.checked)
                // )}
                options={typeList}
                disableClearable={true}
                value={state.title}
                onChange={(e: any, val: any) => handleChange(e, "title", val)}
                getOptionLabel={(option: any) => option.value}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder="Name"
                    variant="outlined"
                    fullWidth
                  />
                )}
              /> */}
              </div>
            </FormControl>
            </div>

            <div className="d-flex align-items-center mb-3">
              <TextField
                label="Subject" 
                variant="outlined"
                defaultValue="Subject..."
                fullWidth
                className=""
                value={state.subject || ""}
                id="filled-secondary"
                //onSelect={() => setSubjectShrink(true)}
                
                InputLabelProps={{
                  //shrink: subjectShrink, // can be controlled via hook but not using
                  shrink: true,
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                  sx: {
                    fontWeight : 600,
                    [`&.${inputLabelClasses.shrink}`]: {
                      color: "#005EFF",
                      fontFamily : "'Monsterrat', sans-serif"
                    }, 
                    fontFamily : "'Monsterrat', sans-serif"
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  },
                  sx: {
                    fontSize : 15, 
                    color : "#4B6B73",
                    fontWeight : 600,
                  }
                }}
                placeholder="Enter Subject"
                onChange={(e) => handleChange(e, "subject")}
              />
            </div>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex align-items-center" style = {{width : 277}}>

                <FormControl
                  className={`${classes.formControl}`}
                  fullWidth
                  variant="outlined"
                >
                  <Autocomplete
                    value={state.name}
                    disablePortal
                    id="templates-options-list"
                    options={nameList}
                    sx={{ width: 300 }}
                    onChange={(e: any, val: any) => handleChange(e, "name", val)}
                    getOptionLabel={(option: any) => option.label}
                    disableClearable={true}
                    renderInput={(params) => 
                      <TextField 
                        placeholder="Name"
                        {...params} 
                        label="Templates" 
                        InputLabelProps={{ 
                          shrink: true,
                          sx: {
                            fontWeight : 600,
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "#005EFF",
                              fontFamily : "'Monsterrat', sans-serif"
                            }, 
                            fontFamily : "'Monsterrat', sans-serif"
                          } 
                        }}
                      />
                    }
                  />

                  {/* <Autocomplete
                    disablePortal
                    id="templates-options-list"
                    sx={{ width: 300 }}
                    options={nameList}
                    disableClearable={true}
                    value={state.name}
                    //  defaultValue={nameList?.filter(val=>val.name==state.createName)[0]||{ id: "",
                    //  title: "",
                    //  name: "",
                    //  text: "",
                    //  branch: defaultBranchInfo,
                    //  medium: "",
                    //  subject: "",
                    //  label: "",
                    //  value: ""}}
                    // defaultValue={state.createName}
                    onChange={(e: any, val: any) => handleChange(e, "name", val)}
                    getOptionLabel={(option: any) => option.label}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder="Name"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  /> */}
                </FormControl>
              </div>
            </div>
          {state.checked !== "email" ? (
            <TextField
              fullWidth
              value={state.message || ""}
              id="filled-secondary"
              variant="outlined"
              placeholder="Write SMS here – Max 140 characters per SMS."
              multiline
              rows={8}
              onChange={(e) => handleChange(e, "message")}
              label="Message" 
              
              onSelect={() => setMessageShrink(true)}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
                sx: {
                  fontWeight : 600,
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#005EFF",
                    fontFamily : "'Monsterrat', sans-serif"
                  }, 
                  fontFamily : "'Monsterrat', sans-serif"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                sx: {
                  fontSize : 15, 
                  color : "#4B6B73",
                  fontWeight : 600,
                }
              }}
            />
          ) : (
            <Editor
              editorState={email}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              //onChange={handleChangeEmail}
              onEditorStateChange={(es) => {setEmail(es)}}
            />
          )}
          <Typography style = {{
            marginRight : 1, 
            fontFamily : "'Monsterrat', sans-serif", 
            color : "#959595",

          }}>
            *Invitation Link will be included automatically if user has not
            created account.
          </Typography>
        </MuiDialog>
      </ClickAwayListener>
      <form onSubmit={handleValidate}>
        <MuiDialog
          open={openCreateTemplate}
          dialogClasses={{
            paper: classes.dialogPaper,
          }}
          title={"Create Template"}
          dialogActions={
            <div className="d-flex justify-content-around">
              <Grid container className="my-3 d-flex">
                {/* <div>
                  <Grid item>
                    {" "}
                    <Button
                      className={`${classes.bottomBtn} text-light`}
                      btncolor="bgBlue"
                      disabled={!state.name.value}
                      // onClick={() => _editTemplate()}
                      onClick={handleCheckUser}
                    >
                      Update existing template
                    </Button>
                  </Grid>
                </div> */}
                <div>
                  <Grid item>
                    {" "}
                    <Button
                      //className={`${classes.bottomBtn} text-light`}
                      // disabled={
                      //   !state.createTitle.value ||
                      //   !state.createName ||
                      //   state.checked === "email"
                      // }
                      // onClick={_createTemplate}
                      onClick={() => handleValidate()}
                      btncolor="bgTorqtoise"
                      type="submit"
                      style = {{
                        marginLeft : 10,
                        backgroundColor : "#005EFF", 
                        fontFamily : "'Monsterrat', sans-serif"
                      }}
                    >
                      Save as new template
                    </Button>
                  </Grid>
                </div>
                {/* <Grid item>
                  {" "}
                  <Button
                    className={`${classes.bottomBtn} text-light`}
                    disabled={!state.name.value}
                    onClick={() => handleOpenConfirm()}
                    btncolor="bgDanger"
                  >
                    Delete
                  </Button>
                </Grid> */}
              </Grid>
            </div>
          }
          onRequestClose={onRequestCloseTemplate}
          dialogActionsClassName={classes.templateDialogAction}
        >
          <div className="d-flex">
            <Grid
              container
              alignItems="center"
              className=" py-2 justify-content-between"
            >
              <div className={`${classes.nameTextField} d-flex`}>
                {/* <Typography className={`${classes.heading} mr-2 pt-1`}>
                  Name:
                </Typography> */}

                <TextField
                  fullWidth
                  required
                  value={state.createName}
                  id="filled-secondary"
                  variant="outlined"
                  // InputLabelProps={{ shrink: false }}
                  placeholder="Name"
                  error={error.name}
                  helperText={error.name && "required"}
                  onChange={(e) => handleChange(e, "createName")}
                  // onChange={(e) => handleEditName(e, "editname")}
                  className={`${classes.underlineRemove} ${classes.underlineName}`}
                  label="Name" 
                  // variant="outlined"
                  defaultValue="Name..."
                  // fullWidth
                  // className=""
                  // value={state.subject || ""}
                  // id="filled-secondary"
              //onSelect={() => setSubjectShrink(true)}
              
                  InputLabelProps={{
                    //shrink: subjectShrink, // can be controlled via hook but not using
                    shrink: true,
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                    sx: {
                      fontWeight : 600,
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#005EFF",
                        fontFamily : "'Monsterrat', sans-serif"
                      }, 
                      fontFamily : "'Monsterrat', sans-serif"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    },
                    sx: {
                      fontSize : 15, 
                      color : "#4B6B73",
                      fontWeight : 600,
                    }
                  }}
                  // onChange={(e) => handleChange(e, "subject")}
                />
              </div>
              <div className="d-flex">
                <Typography className={`${classes.owner} ml-4 mr-2`}>
                  Owner:
                </Typography>
                {user?.user?.fullName}
              </div>
            </Grid>
          </div>
          <Grid container alignItems="center" className="py-2">
            <Grid item container sm={3} alignItems="center">
              <Grid item sm={4}>
                <Typography className={`mr-2`}>Type:</Typography>
              </Grid>
              <Grid item sm={8} className="pr-2">
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} w-100`}
                >
                  {state?.name?.branch ? "Custom" : "Global"}
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container sm={4} alignItems="center">
              {/* <Grid item sm={4}>
                <Typography className={`mr-2`}>Medium:</Typography>
              </Grid> */}
              <Grid item sm={8}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} w-100`}
                  
                >
                  {/* <InputLabel id = "demo-simple-select-outlined-label">Medium</InputLabel> */}
                  {/* <Select
                    required
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state.checked}
                    //input={<OutlinedInput label="Medium" />}
                    onChange={(e) => handleChange(e, "checked")}
                  >
                    <MenuItem value={"sms"}>SMS</MenuItem>
                    <MenuItem value={"email"}>Email</MenuItem>
                  </Select> */}
                  <InputLabel id="template-medium-select-label" 
                    style = {{
                      color : "#005EFF", 
                      fontSize : 15, 
                      fontWeight : 600,
                      fontFamily : "'Monsterrat', sans-serif"
                    }}>
                      Medium
                  </InputLabel>
                  <Select
                    style={{ 
                      width: "100%", 
                      fontFamily : "'Monsterrat', sans-serif", 
                      color : "#4B6B73", 
                      fontWeight : 700, 
                      fontSize : 14 
                    }}
                    variant="outlined"
                    value={state.checked}
                    onChange={(e) => handleChange(e, "checked")}
                    labelId="template-medium-select-label"
                    label={"Medium"}
                  >
                    <MenuItem value={"email"}>Email</MenuItem>
                    <MenuItem value={"sms"}>SMS</MenuItem>
                    
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container sm={5} alignItems="center">
              <Grid item sm={4}>
                <Typography className="pl-2">Category:</Typography>
              </Grid>
              <Grid item sm={8}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl}
                d-flex flex-row align-items-center`}
                >
                  {/* <Autocomplete
                    value={state.title}
                    id="free-solo-demo"
                    freeSolo
                    getOptionLabel={(option: ISelect) => option.value}
                    options={typeList}
                    onChange={(e: any, val: any) =>
                      handleChange(e, "title", val)
                    }
                    renderInput={(params: any) => (
                      <TextField required
                        {...params}
                        margin="normal"
                        variant="outlined"
                        placeholder="Category"
                        value={state.title}
                        onChange={(e: any, val: any) =>
                          handleChange(e, "title", val)
                        }

                      />
                    )}
                  /> */}
                  {/* <Select
                    required
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={state?.title?.value}
                    name="title"
                    onChange={(e: any) => handleChange(e, "title")}
                  >
                    <MenuItem value={"message"}>Message</MenuItem>
                    <MenuItem value={"invite"}>invite</MenuItem>
                  </Select> */}
                  <InputLabel id="template-category-select-label" style = {{
                      color : "#005EFF", 
                      fontSize : 15, 
                      fontWeight : 600,
                      fontFamily : "'Monsterrat', sans-serif"
                    }}>Category</InputLabel>
                  <Select
                    style={{ 
                      width: "100%", 
                      fontFamily : "'Monsterrat', sans-serif", 
                      color : "#4B6B73", 
                      fontWeight : 700, 
                      fontSize : 14 
                    }}
                    variant="outlined"
                    value={state?.title?.value}
                    onChange={(e: any) => handleChange(e, "title")}
                    labelId="template-category-select-label"
                    label={"Category"}
                  >
                    <MenuItem value={"message"}>Message</MenuItem>
                    <MenuItem value={"invite"}>Invite</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <div className="d-flex align-items-center py-2">
            

            <TextField
              fullWidth
              value={state.subject}
              id="filled-secondary"
              variant="outlined"
              placeholder="Subject"
              label="Subject"
              className={classes.underlineRemove}
              error={error.subject}
              helperText={error.subject && "required"}
              onChange={(e) => handleChange(e, "subject")}
              InputLabelProps={{
                //shrink: subjectShrink, // can be controlled via hook but not using
                shrink: true,
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
                sx: {
                  fontWeight : 600,
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#005EFF",
                    fontFamily : "'Monsterrat', sans-serif"
                  }, 
                  fontFamily : "'Monsterrat', sans-serif"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                sx: {
                  fontSize : 15, 
                  color : "#4B6B73",
                  fontWeight : 600,
                }
              }}
            />
          </div>
          {state.checked !== "email" ? (
            <TextField
              sx={{ marginTop : 2}}
              fullWidth
              value={state.message}
              id="filled-secondary"
              variant="outlined"
              label="Message"
              InputLabelProps={{
                //shrink: subjectShrink, // can be controlled via hook but not using
                shrink: true,
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
                sx: {
                  fontWeight : 600,
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#005EFF",
                    fontFamily : "'Monsterrat', sans-serif"
                  }, 
                  fontFamily : "'Monsterrat', sans-serif", 
                }
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                sx: {
                  fontSize : 15, 
                  color : "#4B6B73",
                  fontWeight : 600,
                  marginRight : 10
                }
              }}
              placeholder="Write SMS here – Max 140 characters per SMS"
              multiline
              rows={8}
              //className={classes.inputBase}
              onChange={(e) => handleChange(e, "message")}
              error={error.subject}
              helperText={error.subject && "required"}
            />
          ) : (
            
            <Editor
              editorState={email}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              //onChange={handleChangeEmail}
              onEditorStateChange={(es) => {setEmail(es)}}
            />
          )}
        </MuiDialog>
      </form>
      <ConfirmModal
        message={"Are you sure you want to delete Template?"}
        onConfirm={handleConfirm}
        show={show}
        onClose={() => setShowConfirm(false)}
      />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "0.8rem 2.5rem",
    borderRadius: "10px",
    marginRight: "10px",
  },

  dialogPaper: {
    width: "100%",
    maxWidth: "700px",
  },
  select: {
    border: "1px solid #f2f5f9 ",
    padding: "15px 20px",
    borderRadius: "10px",
    width: "100%",
  },
  formControl: {
    "&>div": {
      width: "100%",
      "&:before": {
        content: "none",
      },
    },
  },
  heading: {
    color: "#0d66fc",
    fontWeight: 500,
  },
  subjectHeading: {
    color: "#0d66fc",
    fontWeight: 500,
  },
  saveTemplatePopUp: {
    width: "100%",
    maxWidth: "450px",
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  chipWrapper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  checkbox: {
    color: "#93939e !important",
  },
  checked: {
    color: "#63d699 !important",
  },
  templateDialogAction: {
    padding: "0 24px",
  },
  dropDown: {
    minWidth: "150px",
  },
  underlineRemove: {
    "&>div": {
      "&:before": {
        border: "none",
      },
    },
  },
  underlineName: {
    width: "100%",
  },
  inputBase: {
    "&>div": {
      borderRadius: "0",
    },
  },
  nameTextField: {
    width: "58%",
  },
  owner: {
    fontWeight: 500,
    // marginLeft:"50%",
  },

  cssLabel: {
    color : '#4B6B73', 
    fontSize : 15,
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#005EFF !important`,
      borderWidth : 1, 
    },
    color : "#4B6B73",
    fontFamily : "'Monsterrat', sans-serif"
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#ddd !important',
    fontFamily : "'Monsterrat', sans-serif"
  },
}));

export const messageStyles = {
  useStyles,
};

export default MessageBox;
