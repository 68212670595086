/*
 * File: ButtonComponent.tsx
 * Project: Dyme-Web
 * File Created: Monday, 14th June 2021 5:16:48 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 5th January 2022 7:49:59 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { ComponentProps } from "react";
import styles from "./ButtonComponent.module.scss";
import { Button } from "@mui/material";
import Loader from "../../Loader";
import makeStyles from '@mui/styles/makeStyles';
interface IButton {
  btncolor?: "bgWarning" | "bgBlue" | "bgDanger" | "bgTorqtoise";
  className?: string;
  loading?: boolean;
  text?: string;
  disabled?: boolean;
  onClick?: any;
  children?: any;
  style?: React.CSSProperties;
  type?: "button" | "submit";
}

const useStyles = makeStyles((theme) => ({
  bgWarning: {
    backgroundColor: "#fbc542",
    "&:hover": {
      backgroundColor: "#fbc542",
    },
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  bgTorqtoise: {
    backgroundColor: "#63d699",
    "&:hover": {
      backgroundColor: "#63d699",
    },
  },
  bgDanger: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
}));
const ButtonComponent: React.FC<IButton> = (props: IButton) => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      className={`${props.className ? props.className : "btn btn-primary"} ${
        props.btncolor && classes[props.btncolor]
      } `}
    >
      {props.loading ? (
        <Loader
          hidemodal
          size={20}
          style={{
            margin: 10,
            marginBottom: 0,
            opacity: props.loading ? 1 : 0,
          }}
        />
      ) : (
        <>
          {props.children}
          {props.text}
        </>
      )}
    </Button>
  );
};
export default ButtonComponent;
