/*
 * File: CompanyApi.ts
 * Project: JobApp-Admin
 * File Created: Wednesday, 8th July 2020 12:02:13 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 14th July 2020 9:36:34 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import { IAddress, ICompany } from "../Interfaces/CompanyInterface";
import { ApiPost, ApiGet, ApiPut, ApiDelete } from "../api-client";
import { log } from "../../Lib/helpers";

/**
 * Create a New Company
 * 
 * @param userId User ID
 * @param name Name
 * @param description Description
 * @param address Address Object
 * @param isActive Active status
 */
const CreateCompany = async (
  userId: number|string|undefined,
  name: string,
  description: string,
  address: IAddress,
  isActive: boolean
) => {
  const data = await ApiPost(`company`, {
    userId,
    name,
    description,
    address,
    isActive,
  });

  log("☁️ Create Company RESPONSE: ", data);

  return data;
};

/**
 * Edit Company
 * @param companyId - CompanyID
 * @param company - Company Data Object
 */
const EditCompany = async (companyId:number|string, company:ICompany) => {
  const data = await ApiPut(`company/${companyId}`, {...company})

  log("☁️ Update Company RESPONSE: ", data);

  return data;
}

const GetCompanies = async () => {
  const data = await ApiGet(`company`)

  log("☁️ Get Companies RESPONSE: ", data);

  return data;
}

/**
 * Get Company
 * @param companyId - Company ID
 */
const GetCompany = async (companyId:number|string) => {
  const data = await ApiGet(`company/${companyId}`)

  log("☁️ Get Company RESPONSE: ", data);

  return data;
}

/**
 * Delete Company
 * @param companyId - Company ID
 */
const DeleteCompany = async (companyId:number|string) => {
  const data = await ApiDelete(`company/${companyId}`)

  log("☁️ Delete Company RESPONSE: ", data);

  return data;
}

export const CompanyApi = {
  CreateCompany,
  GetCompanies,
  GetCompany,
  EditCompany,
  DeleteCompany
}