/*
 * File: CustomDyme.tsx
 * Project: Dyme-Web
 * File Created: Monday, 31st January 2022 8:47:46 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 2nd February 2022 1:22:11 pm
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Compressor from "compressorjs";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../../../Components/common/ButtonComponent/ButtonComponent";
import { LoaderComponent } from "../../../../Components/common/loader.component";
import { log } from "../../../../Lib/helpers";
import { ApiResponse } from "../../../../Services/api-client";
import { AuthApi } from "../../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { IBranch } from "../../../../Services/Interfaces/CompanyInterface";
import { toastify } from "../../../../Services/toast.service";

export const CustomDyme = () => {
  const history = useHistory();

  const classes = useStyles();

  const [tempImg, setImage] = useState<any>(null);

  const [company, setCompany] = useState<IBranch | null>(null);

  const [companyImage, setCompanyImage] = useState<any>(null);

  const [loading, setLoading] = useState<boolean>(true);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const selectImage = (e: any) => {
    const [first] = e.target.files;
    setCompanyImage(first);
    setImage(URL.createObjectURL(first));
  };

  useEffect(() => {
    _fetchCompany();
  }, []);

  const _fetchCompany = async () => {
    setLoading(true);

    try {
      let response: ApiResponse;

      response = await AuthApi.GetCompanyProfile();

      if (response.hasError) {
        handleApiError(response, "_fetch company profile: ");
      } else {
        // ? Get / Update Company success
        log("Get Company Branch SUCCESS: ", response);

        const res: IBranch = response.data;

        setCompanyImage(null);
        setCompany(res);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  const _uploadImage = async () => {
    log("trying to upload now..");
    setLoading(true);
    try {
      let data = new FormData();

      if (companyImage) {
        // compress image before upload
        const result: any = await new Promise((resolve, reject) => {
          new Compressor(companyImage, {
            quality: 0.6,
            success: resolve,
            error: reject,
          });
        });

        data.append("dyme", result, result.name);

        try {
          const response = await AuthApi.UploadImage(data);
          if (response.hasError) {
            handleApiError(response, "upload_custom_dyme");
          } else {
            // ? upload dyme sucess
            log("Upload Custom Dyme Logo SUCCESS: ", response);

            toastify("success", "Custom Dyme Logo updated successfully");

            setCompanyImage(null);

            setCompany(response.data);
          }
        } catch (e) {
          handleCatchError(e);
        }
      }
    } catch (error) {
      log("error on upload: ", error);
      handleCatchError(error);
    }
    setLoading(false);
  };

  return (
    <div className="rt-profilebox rt-updateprofile">
      <div className="rt-profileboxhead">
        <h3>Update Custom Dyme Logo for your Employees in mobile app</h3>
      </div>
      <div className="rt-formtheme rt-formprofile">
        <div className="rt-changeprofileimage">
          <figure className="rt-userprofileimg">
            <img
              src={tempImg ? tempImg : company?.dymeLogo}
              alt="Custom Dyme"
            />
          </figure>
          <span className="rt-changeimage">
            <input
              type="file"
              name="company-pic"
              id="rt-companyimg"
              onChange={selectImage}
              accept="image/x-png,image/gif,image/jpeg"
            />
            <label htmlFor="rt-companyimg">Change Dyme</label>
          </span>
        </div>

        <div className="d-flex justify-content-between">
          <ButtonComponent
            className={`${classes.bottomBtn} ${classes.bgBlue}`}
            onClick={_uploadImage}
            disabled={loading}
            type="button"
          >
            Save
            {loading && <LoaderComponent />}
          </ButtonComponent>
        </div>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "0.8rem 2.5rem",
    borderRadius: "7px",
    // marginRight: "10px",
    float: "right",
    marginBottom: "40px",
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
}));
