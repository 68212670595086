/*
 * File: RewardsGiven.tsx
 * Project: Dyme-Web
 * File Created: Sunday, 27th February 2022 10:52:48 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Sunday, 24th April 2022 1:00:25 am
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {
  TableCell,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Select from "react-select";

import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { AppColors } from "../../../Themes/AppColors";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faComment,
  faThumbsUp,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { ImageComponent } from "../../../Components/common/image.component";
import context from "react-bootstrap/esm/AccordionContext";
import { log } from "../../../Lib/helpers";
import { PaymentApi } from "../../../Services/API/PaymentApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import moment from "moment";
import { IPaymentTransaction } from "../../../Services/Interfaces/AuthInterface";
import { defaultPaginatedData, IPaginate } from "../../../Services/Interfaces";
import Loader from "../../../Components/Loader";
import { RewardsApi } from "../../../Services/API/RewardApi";
import { IGiftTransaction } from "../../../Services/Interfaces/SocialInterface";

export default function RewardsGiven() {
  const [transactionsData, setTransactionsData] =
    useState<IPaginate<IGiftTransaction>>(defaultPaginatedData);
  const [transactionsDataLoading, setTransactionsDataLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [loadingBalance, setLoadingBalance] = useState(false);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    _getGiftsRecord();
    _getBalance();
  }, []);

  const _getBalance = async () => {
    setLoadingBalance(true);
    try {
      const response = await PaymentApi.GetRemainingBalance();
      if (response.hasError) {
        handleApiError(response, "Get balance: ");
      } else {
        log("Get Balance SUCCESS: ", response.data);

        setBalance(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoadingBalance(false);
  };

  const _getGiftsRecord = async (page = 1) => {
    try {
      setTransactionsDataLoading(true);
      const response = await RewardsApi.GetGiftsRecord(page);
      if (response.hasError) {
        handleApiError(response, "Get Gifts Record: ", false);
      } else {
        // ? Get Transactions successfully
        log("Get Gift Transactions SUCCESS: ", response);
        setTransactionsData(response.data);
        setTransactionsDataLoading(false);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setTransactionsDataLoading(false);
  };

  const classes = useStyles();
  const selectOptions = [
    { id: 1, label: "All", value: "All" },
    { id: 2, label: "Some", value: "Some" },
    { id: 3, label: "Other", value: "Other" },
  ];

  return (
    <div
      className={classes.root}
      style={{ marginTop: 100, marginLeft: 100, minHeight: 650 }}
    >
      <div>
        <h4
          //className = " font-bold text-gray-600"
          style={{
            color: "#6b6b6b",
            fontWeight: 600,
            fontFamily: "'Montserrat', sans-serif",
            marginBottom: 10,
          }}
        >
          Rewards History
        </h4>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginRight: 48,
          marginBottom: 15,
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: 16,
            color: "#4B6B73",
            fontFamily: "'Monsterrat', sans-serif",
            marginRight: 20,
            marginTop: 4,
          }}
        >
          Total Balance :{" "}
          <b style={{ color: "#005EFF", fontSize: 20 }}>${balance}</b> |
        </div>

        <div
          style={{
            fontWeight: 600,
            fontSize: 16,
            color: "#4B6B73",
            fontFamily: "'Monsterrat', sans-serif",
            marginRight: 20,
            marginTop: 4,
          }}
        >
          Total Given : <b style={{ color: "#005EFF", fontSize: 20 }}>-</b>
        </div>

        {/**DropDown Selecter */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginRight: 16,
            justifyContent: "space-evenly",
          }}
        >
          <div
            style={{
              marginRight: 5,
              marginTop: 8,
              fontWeight: 600,
              fontSize: 16,
              fontFamily: "'Monsterrat', sans-serif",
              color: "#4B6B73",
            }}
          >
            Show :
          </div>
          <Select
            onChange={(item) => {
              //console.log("Chosen item : ", item);
              //setSelectedCity(city)
            }}
            isDisabled={false}
            isLoading={false}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            name="cities"
            options={selectOptions}
            placeholder={"Select.."}
            components={{
              IndicatorSeparator: () => null,
            }}
            // styles={customStyles}
          />
        </div> */}

        {/**Send Message button */}
        {/* <div>
          <div
            style={{
              padding: 8,
              paddingRight: 20,
              paddingLeft: 20,
              borderRadius: 4,
              color: "#fff",
              backgroundColor: "#005EFF",
              fontSize: 12,
              cursor: "pointer",
              fontWeight: 600,
              fontFamily: "'Monsterrat', sans-serif",
            }}
          >
            Send Message
          </div>
        </div> */}
      </div>

      <div style={{ borderRadius: 4, marginTop: 30, marginLeft: 15 }}>
        <TableContainer
          style={{
            borderWidth: 1,
            borderColor: "#E4E4E4",
            borderRadius: 4,
            borderStyle: "solid",
            //maxWidth: 1280,
            width: "90vw",
            // margin : "auto"
          }}
        >
          <Table sx={{ width: "90vw" }} aria-label="simple table">
            <TableHead
              style={{
                backgroundColor: "#F1F5F6",
                fontSize: 12,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#E4E4E4",
              }}
            >
              <TableRow className={classes.tableRow}>
                <TableCell align="center" padding={"checkbox"}>
                  <div
                    className="form-check"
                    style={{ marginLeft: 14, marginBottom: 24 }}
                  >
                    <input
                      type="checkbox"
                      //onChange={handleSelectAllClick}
                      className="form-check-input"
                      checked={false}
                    />
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    DATE
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 24,
                    }}
                  >
                    GIVEN BY
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    EMPLOYEE
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    AWARD GIVEN
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    LIKES
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    COMMENTS
                  </div>
                </TableCell>

                <TableCell
                  align="left"
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {transactionsData.count
                ? transactionsData.data.map((transaction, index: any) => {
                    const transactionDate = moment(transaction.createdAt);
                    const avatarLink = transaction.receiver
                      ? transaction.receiver.photo
                      : transaction.admin.photo;
                    return (
                      <TableRow>
                        <TableCell align="left" padding="checkbox">
                          <div
                            className="form-check"
                            style={{ marginLeft: 14, marginBottom: 24 }}
                          >
                            <input
                              type="checkbox"
                              // onChange={(event: any) =>
                              //   handleSelectEmployee(event, employee.id)
                              // }
                              className="form-check-input"
                              // checked={isItemSelected}
                            />
                          </div>
                        </TableCell>
                        <TableCell
                          className={classes.tableCellSecondary}
                          align="left"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: 24,
                            }}
                          >
                            <div style={{ marginTop: 4, marginLeft: 2 }}>
                              <FontAwesomeIcon
                                icon={faCalendarAlt}
                                color={"#005EFF"}
                              />
                              <span style={{ marginLeft: 6, marginTop: 2 }}>
                                {transactionDate.format("L")}
                              </span>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.tableCellSecondary}
                          align="left"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: 32,
                            }}
                          >
                            <ImageComponent
                              src={avatarLink}
                              //className=" h-6 w-6 rounded-xl mr-2"
                              style={{
                                height: 24,
                                width: 24,
                                borderRadius: "50%",
                                marginRight: 4,
                              }}
                            />
                            <span style={{ marginLeft: 6, marginTop: 2 }}>
                              {transaction?.admin?.fullName}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.tableCellSecondary}
                          align="left"
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginLeft: 32,
                            }}
                          >
                            <ImageComponent
                              src={transaction.receiver?.photo}
                              style={{
                                height: 24,
                                width: 24,
                                borderRadius: "50%",
                                marginRight: 4,
                              }}
                            />
                            <span style={{ marginLeft: 6, marginTop: 2 }}>
                              {transaction.receiver?.fullName}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.tableCellSecondary}
                          align="left"
                        >
                          <div style={{ marginLeft: 35 }}>
                            <b>$ {transaction.amount}</b>
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.tableCellSecondary}
                          align="left"
                        >
                          <div style={{ marginTop: 4, marginLeft: 2 }}>
                            <FontAwesomeIcon
                              icon={faThumbsUp}
                              color={"#005EFF"}
                            />
                            <span style={{ marginLeft: 6, marginTop: 2 }}>
                              {transaction.feed.likesCount}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.tableCellSecondary}
                          align="left"
                        >
                          <div style={{ marginTop: 4, marginLeft: 2 }}>
                            <FontAwesomeIcon
                              icon={faComment}
                              color={"#35DA81"}
                            />
                            <span style={{ marginLeft: 6, marginTop: 2 }}>
                              {transaction.feed.commentsCount}
                            </span>
                          </div>
                        </TableCell>

                        <TableCell
                          className={classes.tableCellSecondary}
                          align="left"
                        >
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            //onClick={(e) => handleToggle(e, employee.id)}
                            size="small"
                            //className= "mr-10"
                            style={{ marginRight: 32 }}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
            {transactionsDataLoading && <Loader />}
          </Table>
        </TableContainer>

        <div
          style={{
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
            marginTop: 48,
            marginBottom: 16,
          }}
        >
          <Pagination
            count={Math.floor(transactionsData.pageCount)}
            page={page}
            className={classes.root}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                onClick={() => {
                  setPage(item.page);
                  _getGiftsRecord(item.page);
                }}
                classes={{ selected: classes.selected }}
              />
            )}
            variant="outlined"
          />
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   width: "100%",
  // },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  mainTable: {
    overflowY: "auto",
    // maxHeight: '600px',
  },
  topbtn: {
    marginLeft: "20%",
  },
  table: {
    minWidth: 750,
    // maxHeight: "300px",
    // position: "sticky",
    borderWidth: "1px",
    borderColor: "rgba(224, 224, 224, 1)",
    borderStyle: "solid",
    borderRadius: "4px",
  },
  inviteBtn: {
    backgroundColor: "#63d699",
    textTransform: "capitalize",
    marginRight: "5px",
    borderRadius: "2px",
  },
  smsBtn: {
    backgroundColor: AppColors.darkBlue,
    textTransform: "capitalize",
    borderRadius: "2px",
  },
  tableHead: {
    backgroundColor: "#fafafb",

    "&:first-child": {
      borderRadius: "12px 0 0 12px",
    },
    "&:last-child": {
      borderRadius: "0 12px 12px 0",
    },
  },
  checkbox: {
    color: "#93939e !important",
    borderWidth: "0.5px",
  },
  checked: {
    color: "#63d699 !important",
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "10px 0px",
    borderRadius: "10px",
    fontSize: "12px",
    minWidth: "120px",
  },
  bgWarning: {
    backgroundColor: AppColors.yellow,
    "&:hover": {
      backgroundColor: AppColors.yellow,
    },
  },
  bgDanger: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  bgBlue: {
    backgroundColor: AppColors.blueMain,
    "&:hover": {
      backgroundColor: AppColors.blueMain,
    },
  },
  bgGift: {
    backgroundColor: AppColors.darkBlue,
    "&:hover": {
      backgroundColor: AppColors.darkBlue,
    },
  },
  bgTorqtoise: {
    backgroundColor: "#63d699",
    "&:hover": {
      backgroundColor: "#63d699",
    },
  },

  tableRow: {
    height: 30,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  tableCell: {
    padding: "15px 16px",
    fontSize: 12,
    fontWeight: "600",
    color: "#4B6B73",
    lineHeight: "15px",
    backgroundColor: "#F1F5F6",
    fontFamily: "'Montserrat',sans-serif",
    // borderRightStyle : "solid",

    // borderRightWidth : "1px",
  },

  tableCellSecondary: {
    padding: 4,
    fontSize: 12,
    color: "#4B6B73",
    lineHeight: "15px",
    backgroundColor: "#fff",
    fontFamily: "'Montserrat',sans-serif",
  },

  menuStyle: {
    //backgroundColor : "green",
    //fontSize : 10,
    //fontWeight : '500',
    color: "#4B6B73 !important",
    padding: 0,
    lineHeight: "15px",
    //fontFamily : "'Montserrat',sans-serif",
  },

  iconStyle: {
    color: "#4B6B73 !important",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#000",
    },
  },
  selected: {
    backgroundColor: "#047CFF",
    color: "#ddd",
  },
  root: {
    "& .Mui-selected": {
      backgroundColor: "#047CFF",
      color: "#fff",
    },
  },
}));
