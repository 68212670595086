/*
 * File: AuthPage.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 30th November 2020 10:58:31 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 13th January 2022 1:57:15 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useState } from "react";
import { images } from "../../../assets/images/images";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import { Link, useHistory, useParams } from "react-router-dom";
import "react-phone-number-input/style.css";
// import GitInfo from "react-git-info/macro";
import { toastify } from "../../../Services/toast.service";
import { LoaderComponent } from "../../../Components/common/loader.component";
import { alertObject, log } from "../../../Lib/helpers";
import { RegisterForm } from "./RegisterForm";
import { AuthApi } from "../../../Services/API/AuthApi";
import { ENotifyType } from "../../../Components/Notify";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import {
  EUserType,
  ILoginResponse,
  TLoginType,
} from "../../../Services/Interfaces/AuthInterface";
import { Config } from "../../../Config";
import { AppContext } from "../../../Services/AppContext";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { EEmployeeRole } from "../../../Services/Interfaces/EmployeeInterface";
import FacebookLogin from "./FacebookLogin";
import { IApiResponse } from "../../../Services/api-client";
import { GoogleLogin } from "./GoogleLogin";

const loginSchema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

// interface IAuthPage {
//   refer?: "business" | "friend" | "";
// }
const AuthPage = () => {
  // const [isLoginPage, setLoginPage] = useState(props.refer ? false : true); // get from url

  // const referType = props.refer;
  // const { id } = useParams();

  // const gitInfo = GitInfo();
  // console.log(gitInfo.branch);
  // console.log(gitInfo.tags);
  // console.log(gitInfo.commit.date);
  // console.log(gitInfo.commit.hash);
  // console.log(gitInfo.commit.message);
  // console.log(gitInfo.commit.shortHash);

  const history = useHistory();

  const [_errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);

  const { updateUser } = useContext(AppContext);

  const {
    register: login,
    formState: { errors },
    handleSubmit: loginSubmit,
  } = useForm({
    resolver: yupResolver(loginSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const _tryLogin = async (
    v: any,
    socialLoginResponse: IApiResponse | null = null
    // loginType: TLoginType = null
  ) => {
    setLoading(true);

    let response: IApiResponse;

    try {
      if (socialLoginResponse) {
        response = socialLoginResponse;
      } else {
        const { username, password } = v;
        response = await AuthApi.Login(username, password);
      }
      if (response.hasError) {
        handleApiError(response, "login");

        // message = response.data.message;
      } else {
        // ? USER Login success
        // ! use a common function to login user
        log("LOGIN SUCCESS: ", response);

        let newUser: ILoginResponse = response.data;

        // dont allow login if this is normal employee
        // allow login if user role is not noob
        // else allow login if employee role is admin

        let allowLogin = true;

        if (newUser.user.role === EUserType.Noob) {
          allowLogin = false;

          const { employee } = newUser;

          if (employee && employee.role !== EEmployeeRole.Noob) {
            allowLogin = true;
          }
        }

        if (allowLogin) {
          newUser.ttl =
            new Date().getTime() + Config.LOGOUT_AFTER_MINUTES * 60 * 1000; // newUser.ttl

          updateUser(newUser);
        } else {
          // don't allow login and redirect to apps page.

          _navigate(RouteKeys.MobileApps);
        }
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  return (
    <div
      className="Auth_Page auth_page_wrapper referal_page"
      data-testid="AuthPage"
    >
      <header className="auth_header">
        <div className="fluid-container login_header">
          <div className="row">
            <div className="col-md-12">
              <img src={images.DymeBlueLogo} alt="Dyme Jobs Logo" />
            </div>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-md-5 login_form_section">
            <div className="login_form_wrapper">
              <h3 className="black_title text-center">Log In to Dyme</h3>
              {/* <p>{gitInfo.commit.message}</p> */}
              <div className="container">
                <div className="row">
                  <FacebookLogin
                    afterLogin={_tryLogin}
                    title="Continue with Facebook"
                  />
                </div>
                <br />
                <div className="row">
                  <GoogleLogin
                    afterLogin={_tryLogin}
                    title="Continue with Google"
                  />
                </div>
              </div>

              <div id="form_wrapper">
                <form
                  className="rt-formtheme rt-formsignin"
                  onSubmit={loginSubmit((data) => _tryLogin(data, null))}
                >
                  <fieldset>
                    <div className="form-group">
                      <div className="rt-inputiconholder">
                        <input
                          type="text"
                          className="form-control username_field"
                          placeholder="john@doe.com"
                          {...login("username")}
                        />
                        <span className="text-danger">
                          <ErrorMessage errors={errors} name="username" />
                        </span>
                        {_errors.username && (
                          <span className="text-danger">
                            {_errors.username}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="rt-inputiconholder password_wrapper">
                        <input
                          type={showPass ? "text" : "password"}
                          className="form-control password_field"
                          placeholder="Password"
                          {...login("password")}
                        />
                        {showPass ? (
                          <i
                            onClick={() => setShowPass(!showPass)}
                            className="icon-eye show_hide_password"
                          ></i>
                        ) : (
                          <i
                            onClick={() => setShowPass(!showPass)}
                            className="icon-eye-blocked1 show_hide_password"
                          ></i>
                        )}
                        <span className="text-danger">
                          <ErrorMessage errors={errors} name="password" />
                        </span>
                        {_errors.password && (
                          <span className="text-danger">
                            {_errors.password}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="blue_button"
                        disabled={loading}
                      >
                        {loading ? <LoaderComponent /> : ""}
                        <span>Sign In</span>
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
              <div className="rt-btnforgotpass">
                <Link to={RouteKeys.Forgot}>Don’t remember your password?</Link>
              </div>
            </div>
          </div>
          <div className="col-md-7">
            <div className="register_form_wrapper">
              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
      <footer className="auth_footer">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <p>
                Having trouble? Call 1 8444-992-DATA (3282) or{" "}
                <a href="">Contact Support</a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>
                &copy; {new Date().getFullYear()} Dyme |{" "}
                <a href={Config.TERMS_LINK}>Terms & Conditions</a> |{" "}
                <a href={Config.PRIVACY_LINK}>Privacy</a>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

AuthPage.defaultProps = {
  refer: "",
};

export default AuthPage;
