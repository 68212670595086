/*
 * File: UsersApi.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 12th January 2021 12:50:39 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 16th September 2021 8:51:27 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { log } from "../../Lib/helpers";
import { ApiPost, ApiGet, ApiPatch, ApiDelete } from "../api-client";
import { IUser, IUserRequest } from "../Interfaces/AuthInterface";

/**
 * Get User's Public profile without Auth
 */
const GetUserNoAuth = async (userId:number) => {
  const data = await ApiGet(`auth/public-user/${userId}`);

  log("☁️ GET User Public Profile RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Create a User
 */
 const CreateUser = async (user: IUserRequest) => {
  const data = await ApiPost(`user`, user);

  log("☁️ User CREATE RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Edit User
 */
const EditUser = async (user: IUserRequest|Partial<IUser>) => {
  const data = await ApiPatch(`user/${user.id}`, {
    ...user,
  });

  log("☁️ USER EDIT RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Users List paginated result
 */
const UsersList = async (page = 1, searchQuery = '', limit = 10) => {

  let q = `user?page=${page}&limit=${limit}&sort=id,DESC`

  if (searchQuery !== '') {
    q = `${q}&s=${searchQuery}`
  }

  const data = await ApiGet(q);

  log("☁️ USERS LIST RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Delete User
 */
const UserDelete = async (id: number | string) => {
  const data = await ApiDelete(`user/${id}`);

  log("☁️ USER DELETE RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

export const UserApi = {
  GetUserNoAuth,
  UsersList,
  EditUser,
  CreateUser,
  UserDelete,
};
