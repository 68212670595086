/*
 * File: images.ts
 * Project: JobApp-Admin
 * File Created: Monday, 30th November 2020 11:00:14 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 22nd November 2021 7:23:10 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

export const images = {
    loginBG: require('./login-bg.png').default,
    Logo: require('./dyme-blue-logo.png').default,
    DymeLogo: require('./dyme-logo.png').default,
    DymeBlueLogo: require('./dyme-blue-logo.png').default,
    LogoHeader: require('./dymed-blue.png').default,
    UserImg: require('./user-placeholder.png').default,
    HireImg1: require('./hire-image/img-01.png').default,
    HireImg2: require('./hire-image/img-02.png').default,
    HireImg3: require('./hire-image/img-03.png').default,
    HireImg4: require('./hire-image/img-04.png').default,
    HireImg5: require('./hire-image/img-05.png').default,
    CompanyLogo: require('./google-logo.png').default,
    MapPlaceholder: require('./mp-placeholder.jpg').default,
    MapPlaceholder2: require('./map-placeholder2.jpg').default,
    FilterImage: require('./filter-icon.png').default,
    UserImg1: require('./chat-user/img-01.jpg').default,
    PlaceHolder: require('./place-holder.jpg').default,
    CardPlaceholder: require('./card-placeholder.jpg').default,
    
    
    Apple: require('./apple.png').default,
    Google: require('./google-logo.png').default,
    Facebook: require('./facebook.png').default,

    // Logo: require("../assets/images/logo.svg"),
  Bag: require("./breifcase.png"),
  // CardPlaceholder: require("../assets/images/card-placeholder.jpg"),
}

// export const loadImage = (string: string) => `${env.backend}/uploads/${string}`