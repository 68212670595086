/*
 * File: resumeOptions.ts
 * Project: JobApp-Admin
 * File Created: Thursday, 24th December 2020 1:27:50 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 24th December 2020 1:47:38 am
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */


const expKinds = [
  {
    title: "Inbound Sales",
    value: 1
  },
  {
    title: "Inbound Customer Service",
    value: 2
  },
  {
    title: "Outbound Sales",
    value: 3
  },
  {
    title: "Inbound Tech Support",
    value: 4
  },
]


const classesObj = [
  {
    title: "Some High School",
    value: 1,
  },
  {
    title: "High School Diploma",
    value: 2,
  },
  {
    title: "Some College",
    value: 3,
  },
  {
    title: "Bachelor's Degree",
    value: 4,
  },
];
const classes = classesObj.map(c => c.title)


const speedsObj = [
  {
    title: "Slowpoke",
    speed: "10-30 WPM",
    value: 1,
    pic: null
  },
  {
    title: "Everyman",
    speed: "31-50 WPM",
    value: 2,
    pic: null
  },
  {
    title: "Blazin'nFast",
    speed: "51-70 WPM",
    value: 3,
    pic: null
  },
  {
    title: "Hacker",
    speed: "71 or more WPM",
    value: 4,
    pic: null
  },
];
const speeds = speedsObj.map(s => `${s.title} ${s.speed}`)

export const ResumeData = {
  expKinds,
  classes,
  classesObj,
  speeds,
  speedsObj
}