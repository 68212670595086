/*
* File: authStyles.ts
* Project: JobApp-Admin
* File Created: Tuesday, 4th February 2020 9:33:57 pm
* Author: Umar Aamer (umar.u@allshorestaffing.com)
* -----
* Last Modified: Tuesday, 4th February 2020 9:33:58 pm
* -----
* Copyright 2020 - 2021 WhileGeek, https://umar.tech
*/
import { Theme, colors } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useDefaultStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      minHeight: '100vh',
      backgroundColor: theme.palette.background.default // colors.grey[300],
      // backgroundColor: colors.grey[300],
    },
    innerContainer: {
      margin: theme.spacing(8, 0, 8),
      // marginBottom: theme.spacing(8),
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    pageHeader: {
      display: 'flex',
      flex: 1, 
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(2),
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      backgroundColor: theme.palette.secondary.main
    },
  })
);