/*
 * File: helpers.ts
 * Project: jobapp-admin
 * File Created: Friday, 31st January 2020 1:02:37 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 10:29:59 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import cloneDeep from 'lodash/cloneDeep'
import Reactotron from 'reactotron-react-js'
import { TStorageKeys } from '../Services/Interfaces/AuthInterface';
import { Config } from '../Config';
import { useLocation } from 'react-router';

/**
 * GU-Lib - Compare 2 Arrays
 * @param {Array} array1 - First Array
 * @param {Array} array2 - Second Array
 */
export function compareArrays(array1 = [], array2 = []) {
  return isEqual(sortBy(array1), sortBy(array2));
}

/**
 * GU Lib - Deeply clone object or any data
 * @param data - Anything
 */
export function _cloneDeep<T>(data: T): T {
  return cloneDeep(data);
}



/**
 * GU-Lib - Check if is string
 *
 * @param {*} data
 */
export function isString(data: any) {
  return typeof data === "string";
}

/**
 * GU-Lib - Check if valid string and not empty
 * 
 * @param str - string
 */
export function isEmpty(str: any) {
  return (!str || 0 === str.length);
}

export function inDevMode() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return true
  }
  return false;
}

/**
 * GU-Lib - Console Log
 *
 * @param  {...any} shitPile
 *
 */
export const log = (...shitPile: any) => {
  if (inDevMode()) {
    //@ts-ignore
    Reactotron.log(...shitPile);
    console.log(...shitPile);
  }
};

export const warn = (...shitPile: any) => {
  if (inDevMode()) {
    //@ts-ignore
    Reactotron.warn(...shitPile);
    console.warn(...shitPile);
  }
};
export const error = (...shitPile: any) => {
  if (inDevMode()) {
    //@ts-ignore
    Reactotron.error(...shitPile);
    console.debug(...shitPile);
  }
};




/**
 * GU Lib - Validate Email
 * @param email Email
 */
export function isValidEmail(email: string) {
  return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
}




export const getTimestamp = () => Date.now().toString();

/**
 * Returns concatenated (YEAR MONTH DATE HOURS MINUTES)
 */
export const getMergedDateTime = (date: string | null = null, formatted = false) => {
  const d = !date ? new Date() : new Date(date);

  if (formatted) {
    return d.toLocaleString()
  }

  return d.getFullYear() + (formatted ? "/" : "") + ("0" + (d.getMonth() + 1)).slice(-2) + (formatted ? "/" : "") + ("0" + d.getDate()).slice(-2) + (formatted ? " " : "") + ("0" + d.getHours()).slice(-2) + (formatted ? ":" : "") + ("0" + d.getMinutes()).slice(-2) + (formatted ? " " + d.getTime() : "")
}

export const isValidTimestamp = (timestamp: string | number) => (new Date(timestamp)).getTime() > 0


/**
 * GU Lib - Parse total amount to standard 3 decimal places
 * 
 * @param amount - Amount to Parse
 * @param afterDecimalPlaced - Number of digits after decimal
 */
export const parseAmount = (amount: string | number, afterDecimalPlaced = 2) => {

  // log('======> GOT TO PARSE: ', amount)

  try {
    let newAmount: number | string = Number(amount).toFixed(afterDecimalPlaced);

    return Number(newAmount);

  } catch (e) {
    return amount;
  }
}


/**
 * Format phone number like (123) 456-7890
 * @param phoneNumberString - Phone Number
 */
export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    var intlCode = ''; //(match[1] ? '+1 ' : '')
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }
  return null
}

/**
 * GU Lib - Get Initials of full name
 * @param name Name
 */
export const getInitialsOfName = (name: string): string => {

  try {
    let initials: RegExpMatchArray | string = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || '') + (initials.pop() || '')
    ).toUpperCase();
    return initials.toString();
  } catch (e) {
    return '';
  }

}

/**
 * GU - Update App AsyncStorage
 * @param TStorageKeys - Data Key
 * @param data - Data Value
 */
export function storageUpdate(key: TStorageKeys, data: any) {
  log(`UPDATING STORAGE with key ${key}: `, data)
  return window.localStorage.setItem(key, JSON.stringify(data));
}


/**
 * GU - Get AsyncStorage Item
 * @param TStorageKeys - Data Key
 */
export function storageGet(key: TStorageKeys) {
  const data = window.localStorage.getItem(key);

  return data ? JSON.parse(data) : data;
}

// Regular expression for validating phone number
export const PHONE_REG_EXP = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


// Show object in alert box
export const alertObject = (obj: any = { test: "hola" }) => {
  if (!Config.IS_PRODUCTION)
    alert(JSON.stringify(obj, null, 2));
}


// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// convert latitude and longitude to a string.
export const latLngToString = (
  latitude: number | string,
  longitude: number | string
) => {

  latitude = Number(latitude).toFixed(7)
  latitude = Number(latitude).toFixed(7)

  return `${latitude},${longitude}`;
};
