/*
 * File: index.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 1st December 2020 2:10:51 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 3rd August 2021 10:10:00 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
export interface IDynamic {
  [key: string]: any
}
export interface IQuery extends IDynamic {
  page?: number
  limit?: number
  keyword?: string
  skip?: number
}

export interface IValidationError {
  [key: string]: string[]
}

export interface IPaginate<T> extends IQuery {
  data: Array<T>
  count: number
  total: number
  page: number
  pageCount: number
}
export const defaultPaginatedData: IPaginate<any> = { data: [], count: 0, total: 0, page: 1, pageCount: 1 } 

export interface ISelect {
  value: any
  label: string
}

export interface beforeHire{
    label: string,
    reward: number,
    id: string,
    status: number,
    active:boolean
  
}
export interface postHire{
  daysAfterHire: number,
  reward: number,
  active: boolean,
  id: string,
}


export interface IRequestQuery {
  [key: string]: any;
  page?: number;
  orderBy?: string;
  status?: string;
  limit?: number;
  keyword?: string;
}

export const defaultRequestQuery = {
  page: 1,
  orderBy: "id",
  status: undefined,
  limit: 10,
  keyword: '',
}