/* eslint-disable react/style-prop-object */
/*
 * File: MobileApps.ts
 * Project: JobApp-Admin
 * File Created: Friday, 11th June 2021 5:36:50 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 16th November 2021 2:23:55 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react";
import dymedBlue from "../../assets/images/dymeWord.png";
import dymedBlueLogo from "../../assets/images/dyme-purple-logo.png";
import "./MobileApps.css";
import { RouteKeys } from "../../Routes/RouteKeys";
import { useHistory } from "react-router";

const MobileApps = () => {

  const history = useHistory()

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const _navigateToHome = () => {
    _navigate(RouteKeys.Home)
  }

  return (
    <div className="mobileAppWrapper">
      <a href={"/"} onClick={_navigateToHome}>
        <img src={dymedBlueLogo} alt="logo" />
      </a>

      <h3 className="dymeAppHeading">Continue on Dyme Jobs Mobile App</h3>
      <div>
        <a href="https://play.google.com/store/apps/details?id=com.buddyrise&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            alt="Get it on Google Play"
            className="appsLogo"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </div>

      <div>
        <a
          href="https://apps.apple.com/us/app/dyme-jobs-and-rewards/id1526557944?itsct=apps_box_badge&amp;itscg=30200"
          className="dymeIOSLink"
        >
          <img
            src="https://apple-resources.s3.amazonaws.com/media-badges/download-on-the-app-store/black/en-us.svg"
            alt="Download on the App Store"
            className="dymeIOSImg"
          />
        </a>

      </div>
      <div className="dymeWord">
        <a href={"/"} onClick={_navigateToHome}>
          <img src={dymedBlue} alt={"logo"} />
        </a>
      </div>
    </div>
  );
};

export default MobileApps;
