/*
 * File: image.component.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 1st December 2020 12:42:02 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 20th May 2021 8:34:58 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import React from "react";
import { ImageProps } from "react-bootstrap";
import { images } from "../../assets/images/images";

interface IProps extends ImageProps {
  src?: string;
}
export const ImageComponent = (props: IProps) => (
  <img
    {...props}
    src={props.src ? props.src : images.UserImg}
    alt="Applicant"
  />
);
