/*
 * File: ChangePassPage.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 26th February 2020 12:19:13 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { PageHeader } from "../../../Components/PageHeader";
import { BaseAuth } from "../../../Components/BaseAuth";
import { SettingsContainer } from "./SettingsContainer";
import {
  Box,
  Typography,
  Container,
  Paper,
  TextField,
  Button
} from "@mui/material";
import { AppContext } from "../../../Services/AppContext";
import { useFormik } from "formik";
import { log } from "../../../Lib/helpers";
import { AuthApi } from "../../../Services/API/AuthApi";
import { ENotifyType } from "../../../Components/Notify";
import {
  handleApiError,
  handleCatchError
} from "../../../Services/error-handling";
import { RouteKeys } from "../../../Routes/RouteKeys";

const DEFAULT_DATA = {
  oldPassword: "",
  newPassword: "",
  cNewPassword: ""
};
// ! NOT IN USE ANYMORE BECAUSE OF NEW UI
export const ChangePassPage: React.FC = () => {
  const history = useHistory();
  const { user, notify } = useContext(AppContext);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ...DEFAULT_DATA
  });

  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Old Password required"),
      newPassword: Yup.string().required("New Password required"),
      cNewPassword: Yup.string().oneOf(
        [Yup.ref("newPassword")],
        "Passwords must match"
      )
    }),
    enableReinitialize: true,

    onSubmit: async values => {
      log("change pass values: ", values);

      setLoading(true);

      const { newPassword, oldPassword } = values;
      const response = await AuthApi.ChangePassword(oldPassword, newPassword);

      let message: string = "Something went wrong";
      let errorType = ENotifyType.Error;

      try {
        if (response.hasError) {
          handleApiError(response, "CHANGE PASS SCREEN: ");

          if (response.status === 400) {
            message = "Invalid current password.";
          }
        } else {
          // ? Change pass success
          log("Change Pass SUCCESS: ", response);
          message = "Password Changed!";
          errorType = ENotifyType.Success;

          // ? password changed
          formik.resetForm();
          // response.data;
        }
      } catch (e) {
        handleCatchError(e);
      }

      notify({
        title: message,
        type: errorType
      });

      setLoading(false);
    }
  });

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const _onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  const { handleChange, handleBlur, values, touched, errors } = formik;

  return (
    <SettingsContainer>
      <Paper style={{ padding: 20, maxWidth: 500, marginTop: 10 }}>
        <form noValidate autoComplete="off" onSubmit={_onSubmit}>
          <Typography variant="h6">Change Password</Typography>
          <TextField
            variant="outlined"
            margin="normal"
            required
            // fullWidth
            name="oldPassword"
            label="Old Password"
            type="password"
            autoComplete="current-password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.oldPassword}
            error={touched.oldPassword && !!errors.oldPassword}
            helperText={errors.oldPassword}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.newPassword}
            error={touched.newPassword && !!errors.newPassword}
            helperText={errors.newPassword}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="cNewPassword"
            label="Re-type new Password"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.cNewPassword}
            error={touched.cNewPassword && !!errors.cNewPassword}
            helperText={errors.cNewPassword}
          />

          <Button
            type="submit"
            disabled={loading}
            fullWidth
            color="primary"
            variant="contained"
            style={{ marginTop: 30 }}
            // className={styles.submit}
          >
            Change password
          </Button>
        </form>
      </Paper>
    </SettingsContainer>
  );
};
