/*
 * File: EmployeesTableNew.tsx
 * Project: Dyme-Web
 * File Created: Monday, 10th January 2022 7:42:59 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Sunday, 24th April 2022 12:54:12 am
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {
  TableCell,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import EditIcon from "@mui/icons-material/Edit";
import GiftIcon from "@mui/icons-material/CardGiftcard";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

import EmailIcon from "@mui/icons-material/Email";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import {
  EEmployeeRole,
  IEmployee,
  IEmployeeBase,
} from "../../../Services/Interfaces/EmployeeInterface";
import { IPaginate, ISelect } from "../../../Services/Interfaces";
import { ImageComponent } from "../../../Components/common/image.component";
import NumberFormat from "react-number-format";
import { ConfirmModal } from "../../../Components/modals/confirm.modal";
import { AppColors } from "../../../Themes/AppColors";
import { TReferSaveType } from "./ReferralProgram";
import { EmployeesApi } from "../../../Services/API/EmployeesApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log } from "../../../Lib/helpers";
import { useDollarValue } from "../../../Services/useDollarValue";
import Loader from "../../../Components/Loader";
import { GiftCoinModal } from "./GiftCoinModal";

interface HeadCell {
  id: string;
  label: string;
}

interface EnhancedTableProps {
  headCells: Array<HeadCell>;
  rowCount: number;
  numSelected: number;
  onSelectAllClick: (event: any) => void;
  classes: any;
  renderFormRow: () => any;
  showNewRow: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    headCells,
    renderFormRow,
    showNewRow,
  } = props;

  return (
    <TableHead
    //className="border-1"
    >
      <TableRow>
        <TableCell padding="checkbox" className={classes.tableHead}>
          <Checkbox
            classes={{
              root: classes.checkbox,
              checked: classes.checked,
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells?.map((headCell: HeadCell, i: number) => (
          <TableCell
            className={classes.tableHead}
            // align={"center"}
            padding="normal"
            key={headCell.label}
            id={headCell.label}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
      {showNewRow && renderFormRow()}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const DEFAULT_DATA: IEmployeeBase = {
  fullName: "",
  title: "",
  email: "",
  phone: "",
  role: EEmployeeRole.Noob,
};
type dataType = keyof typeof DEFAULT_DATA;
interface IEnhancedTable {
  // rows: Array<Row>;
  headCells: Array<HeadCell>;
  _fetchAllEmployees: (v?: number, Keyword?: string) => void;
  orderBy: string;
  showNewRow: boolean;
  _hideFormRow: () => void;
  handleSubmit: () => void;
  employees: IPaginate<IEmployee>;
  editableEmpId: number;
  _deleteEmployee: (id: number) => void;
  _editEmployee: (id: number) => void;
  defaultInputProps: (key: dataType) => any;
  fetchEmployeeloading: boolean;
  inviteLoading: boolean;
  setSelectedEmployees: (data: Array<number> | []) => void;
  selectedEmployees: Array<number>;
  selectedJob: ISelect;
  selectedJobEmployees: Array<number>;
  _updateReferralSettings: (type?: TReferSaveType, empId?: number) => void;
  setOpenMessage: (val: boolean, id: number) => void;
  _showNewRow: () => void;
  saveLoading: boolean;
}

interface IMenuBtn {
  id: number | null;
  anchorEl: null;
}

export function EmployeesTableNew(props: IEnhancedTable) {
  const {
    headCells,
    _fetchAllEmployees,
    orderBy,
    showNewRow,
    _hideFormRow,
    handleSubmit,
    employees,
    editableEmpId,
    _editEmployee,
    _deleteEmployee,
    defaultInputProps,
    fetchEmployeeloading,
    inviteLoading,
    setSelectedEmployees,
    selectedEmployees,
    selectedJob,
    selectedJobEmployees,
    _updateReferralSettings,
    setOpenMessage,
    _showNewRow,
    saveLoading,
  } = props;

  // 3 dot menu button
  const [menuBtn, setMenuBtn] = useState<IMenuBtn>({
    id: null,
    anchorEl: null,
  });

  const [show, setShowConfirm] = useState(false);
  const [deleteEmpId, setDeleteEmpId] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showCoinBox, setShowCoinBox] = useState(false);

  const { dollarLoading, dollarValue } = useDollarValue();

  const [selectedEmpForGift, setSelectedEmpForGift] =
    useState<IEmployee | null>(null);

  const classes = useStyles();

  const handleToggle = (e: any, id: number) => {
    setMenuBtn({
      anchorEl: e.currentTarget,
      id: id,
    });
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = employees?.data?.map((n, i) => n.id);
      setSelectedEmployees(newSelecteds);
      return;
    }
    setSelectedEmployees([]);
  };

  const handleSelectEmployee = (event: any, name: number) => {
    const selectedIndex = selectedEmployees.indexOf(name);
    let newSelected: Array<number> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEmployees, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedEmployees.slice(1));
    } else if (selectedIndex === selectedEmployees.length - 1) {
      newSelected = newSelected.concat(selectedEmployees.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedEmployees.slice(0, selectedIndex),
        selectedEmployees.slice(selectedIndex + 1)
      );
    }

    setSelectedEmployees(newSelected);
  };

  // new employee row | edit employee row
  const renderFormRow = () => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell />
        <TableCell
          align="left"
          className={classes.tableCellSecondary}
          data-title="Name"
        >
          <div className="rt-applicantimgname">
            <TextField
              inputProps={{
                min: 0,
                style: {
                  textAlign: "center",
                  fontFamily: "'Montserrat', sans-serif",
                  backgroundColor: "#fff",
                  fontSize: 12,
                },
              }}
              variant="outlined"
              placeholder="Full Name"
              autoComplete="off"
              {...defaultInputProps("fullName")}
              size="small"
              //InputProps={{ disableUnderline: true }}
            />
          </div>
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCellSecondary}
          data-title="Title"
        >
          <TextField
            inputProps={{
              min: 0,
              style: {
                textAlign: "center",
                fontFamily: "'Montserrat', sans-serif",
                backgroundColor: "#fff",
                fontSize: 12,
              },
            }}
            variant="outlined"
            placeholder="Job Title"
            {...defaultInputProps("title")}
            required={false}
            size="small"
            //InputProps={{ disableUnderline: true }}
          />
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCellSecondary}
          data-title="Phone"
        >
          {/* <input {...defaultInputProps("phone")} /> */}

          <NumberFormat
            variant="outlined"
            placeholder="Mobile Number"
            customInput={TextField}
            format="###-###-####"
            // format="+## (###) ###-####"
            allowEmptyFormatting
            mask="_"
            {...defaultInputProps("phone")}
            required={false}
            style={{
              backgroundColor: "#fff",
              textAlign: "center",
              color: "#4B6B73",
            }}
            //className="font-sans text-center px-10 bg-white"
          />
        </TableCell>
        <TableCell
          align="left"
          className={classes.tableCellSecondary}
          data-title="Email"
        >
          <TextField
            inputProps={{
              min: 0,
              style: {
                textAlign: "center",
                fontFamily: "'Montserrat', sans-serif",
                backgroundColor: "#fff",
                fontSize: 12,
              },
            }}
            variant="outlined"
            placeholder="Email"
            {...defaultInputProps("email")}
            required={false}
            size="small"
            //InputProps={{ disableUnderline: true }}
          />
        </TableCell>
        <TableCell align="left" className={classes.tableCellSecondary} />{" "}
        {/* status is not required in form */}
        <TableCell align="left" className={classes.tableCellSecondary}>
          <span
            style={{ cursor: "pointer", marginRight: 20 }}
            onClick={_hideFormRow}
          >
            <i className="icon-cross" style={{ fontSize: 12 }}></i>
          </span>
          <span style={{ cursor: "pointer" }} onClick={() => handleSubmit()}>
            <i className="icon-checkmark" style={{ fontSize: 12 }}></i>
          </span>
        </TableCell>
        <TableCell align="left" className={classes.tableCellSecondary} />
      </TableRow>
    );
  };

  const closeRowMenu = () => {
    setMenuBtn({ id: null, anchorEl: null });
  };
  const isSelected = (name: number) => selectedEmployees.indexOf(name) !== -1;
  const _handleEditDelete = (id: number, type: string) => {
    if (type === "edit") {
      _editEmployee(id);
    } else {
      setShowConfirm(true);
      setDeleteEmpId(id);
    }
    closeRowMenu();
  };

  const handleConfirm = () => {
    _deleteEmployee(deleteEmpId);
    setShowConfirm(false);
  };

  useEffect(() => {
    if (selectedEmpForGift) {
      setShowCoinBox(true);
    } else {
      setShowCoinBox(false);
    }
  }, [selectedEmpForGift]);

  const _giftSingleEmp = (emp: IEmployee) => {
    log("showing gift box");
    closeRowMenu();

    setSelectedEmpForGift(emp);
  };

  const _closeGiftBox = () => {
    log("closing gift box");

    setSelectedEmpForGift(null);
    setShowCoinBox(false);
  };

  return (
    <div className={classes.root}>
      <div
        //className = "rounded"
        style={{ borderRadius: 4 }}
      >
        <TableContainer
          //className = "border border-gray-300 rounded"
          style={{
            borderWidth: 1,
            borderColor: "#E4E4E4",
            borderRadius: 4,
            borderStyle: "solid",
            //maxWidth: 1366,
            width: "90vw",
          }}
        >
          <Table sx={{ width: "90vw" }} aria-label="simple table">
            <TableHead
              //className = "bg-gray-100 text-sm"
              style={{
                backgroundColor: "#F1F5F6",
                fontSize: 12,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "#E4E4E4",
              }}
            >
              <TableRow className={classes.tableRow}>
                <TableCell align="left" padding={"checkbox"}>
                  <div
                    className="form-check"
                    style={{ marginLeft: 14, marginBottom: 24 }}
                  >
                    <input
                      type="checkbox"
                      onChange={handleSelectAllClick}
                      className="form-check-input"
                      checked={
                        employees.count > 0 &&
                        selectedEmployees.length === employees.count
                      }
                    />
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    //className = "border-l py-0.5 pl-4"
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    Name
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    //className = "border-l py-0.5"
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    Title
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    //className = "border-l py-0.5"
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    Mobile
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    //className = "border-l py-0.5"
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    Email Address
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    //className = "border-l py-0.5"
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    Account Status
                  </div>
                </TableCell>

                <TableCell align="left" className={classes.tableCell}>
                  <div
                    //className = "border-l py-0.5"
                    style={{
                      borderLeftWidth: 1,
                      borderRightWidth: 0,
                      borderTopWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftColor: "#D7E0E2",
                      borderStyle: "solid",
                      paddingTop: 2,
                      paddingBottom: 2,
                      paddingLeft: 8,
                    }}
                  >
                    State
                  </div>
                </TableCell>

                <TableCell
                  align="left"
                  className={classes.tableCell}
                ></TableCell>
              </TableRow>
              {showNewRow && renderFormRow()}
            </TableHead>

            <TableBody>
              {employees?.data?.map((employee, index) => {
                const haveAccount = !!employee.userId;
                const isItemSelected = isSelected(employee.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                if (editableEmpId === employee.id) {
                  return renderFormRow();
                }

                return (
                  <TableRow>
                    <TableCell align="left" padding="checkbox">
                      <div
                        className="form-check"
                        style={{ marginLeft: 14, marginBottom: 24 }}
                      >
                        <input
                          type="checkbox"
                          onChange={(event: any) =>
                            handleSelectEmployee(event, employee.id)
                          }
                          className="form-check-input"
                          checked={isItemSelected}
                        />
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.tableCellSecondary}
                      align="left"
                    >
                      <div
                        //className = "flex flex-row ml-3 pl-3"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginLeft: 24,
                        }}
                      >
                        <ImageComponent
                          src={employee.user?.photo}
                          //className=" h-6 w-6 rounded-xl mr-2"
                          style={{
                            height: 24,
                            width: 24,
                            borderRadius: "50%",
                            marginRight: 4,
                          }}
                        />
                        <div
                          //className = "mt-1"
                          style={{ marginTop: 4, marginLeft: 2 }}
                        >
                          {employee.fullName}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.tableCellSecondary}
                      align="left"
                    >
                      <div style={{ marginLeft: 20 }}>{employee.title}</div>
                    </TableCell>
                    <TableCell
                      className={classes.tableCellSecondary}
                      align="left"
                    >
                      <NumberFormat
                        // disabled
                        // format="###-###-####"
                        // format="+1 (###) ###-####"
                        format="###-###-####"
                        value={employee.phone}
                        style={{
                          backgroundColor: "#fff",
                          textAlign: "center",
                          color: "#4B6B73",
                        }}
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCellSecondary}
                      align="left"
                    >
                      <div style={{ marginLeft: 20 }}>{employee.email}</div>
                    </TableCell>

                    <TableCell
                      className={classes.tableCellSecondary}
                      align="left"
                    >
                      {haveAccount ? (
                        <div
                          //className = " text-white rounded text-xs font-semibold ml-4"
                          style={{
                            color: "#fff",
                            borderRadius: 5,
                            fontSize: 12,
                            fontWeight: 600,
                            marginLeft: 24,
                          }}
                        >
                          <p
                            // className = "bg-green-400 inline px-1 rounded"
                            style={{
                              backgroundColor: "#35DA81",
                              display: "inline",
                              paddingLeft: 4,
                              paddingRight: 4,
                              borderRadius: 4,
                            }}
                          >
                            Account Created
                          </p>
                        </div>
                      ) : (
                        <div
                          // className = "text-white rounded text-xs font-semibold ml-4"
                          style={{
                            color: "#fff",
                            borderRadius: 5,
                            fontSize: 12,
                            fontWeight: 600,
                            marginLeft: 24,
                          }}
                        >
                          <p
                            // className = "bg-red-400 inline px-1 rounded"
                            style={{
                              backgroundColor: "#F18686",
                              display: "inline",
                              paddingLeft: 4,
                              paddingRight: 4,
                              borderRadius: 4,
                            }}
                          >
                            Not invited
                          </p>
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.tableCellSecondary}
                      align="left"
                    >
                      {haveAccount ? (
                        <p
                          //className = "inline ml-4"
                          style={{ display: "inline", marginLeft: 16 }}
                        >
                          <span>
                            <CheckCircleOutlineOutlinedIcon
                              style={{
                                color: "#35DA81",
                                fontSize: 16,
                                marginBottom: 3,
                                marginRight: 2,
                              }}
                            />
                          </span>
                          Active
                        </p>
                      ) : (
                        <p
                          //className = "inline ml-4"
                          style={{ display: "inline", marginLeft: 16 }}
                        >
                          <span>
                            <RemoveCircleOutlineOutlinedIcon
                              style={{
                                color: "#F06060",
                                fontSize: 16,
                                marginBottom: 3,
                                marginRight: 2,
                              }}
                            />
                          </span>
                          Inactive
                        </p>
                      )}
                    </TableCell>

                    <TableCell
                      className={classes.tableCellSecondary}
                      align="right"
                    >
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleToggle(e, employee.id)}
                        size="small"
                        //className= "mr-10"
                        style={{ marginRight: 32 }}
                      >
                        <MoreVertIcon />
                      </IconButton>

                      <Menu
                        id="long-menu"
                        anchorEl={menuBtn.anchorEl}
                        keepMounted
                        open={menuBtn.id === employee.id}
                        onClose={closeRowMenu}
                        classes={{ paper: classes.menuStyle }}
                      >
                        <MenuItem
                          onClick={() => setOpenMessage(true, employee.id)}
                          style={{
                            fontSize: 14,
                            fontFamily: "'Monteserrat', sans-serif",
                            fontWeight: "normal",
                          }}
                          //className={{fontFamily : "'Montserrat', sans-serif", fontSize : 12}}
                        >
                          <EmailIcon
                            //className="mr-1 h-4 w-4"
                            style={{ marginRight: 4, height: 16, width: 16 }}
                          />{" "}
                          {!haveAccount ? "Invite to Dyme" : "Message"}
                        </MenuItem>

                        <MenuItem
                          onClick={() => _handleEditDelete(employee.id, "edit")}
                          style={{ fontSize: 14, fontWeight: "normal" }}
                          //className={{fontFamily : "'Montserrat', sans-serif", fontSize : 12}}
                        >
                          <EditIcon
                            //className="mr-1 h-4 w-4"
                            style={{
                              marginRight: 4,
                              height: 16,
                              width: 16,
                              fontFamily: "'Monteserrat', sans-serif",
                            }}
                          />{" "}
                          Edit
                        </MenuItem>

                        <MenuItem
                          onClick={() => _giftSingleEmp(employee)}
                          style={{
                            fontSize: 14,
                            fontFamily: "'Monteserrat', sans-serif",
                            fontWeight: "normal",
                          }}
                          //className={{fontFamily : "'Montserrat', sans-serif", fontSize : 12}}
                        >
                          <GiftIcon
                            //className="mr-1 h-4 w-4"
                            style={{
                              marginRight: 4,
                              height: 16,
                              width: 16,
                              fontFamily: "'Monteserrat', sans-serif",
                            }}
                          />{" "}
                          Gift Dymes
                        </MenuItem>

                        <MenuItem
                          onClick={() =>
                            _handleEditDelete(employee.id, "delete")
                          }
                          style={{
                            fontSize: 14,
                            fontFamily: "'Monteserrat', sans-serif",
                            fontWeight: "normal",
                          }}
                        >
                          <DeleteIcon
                            //className="mr-1 h-4 w-4"
                            style={{
                              marginRight: 4,
                              height: 16,
                              width: 16,
                              fontFamily: "'Monteserrat', sans-serif",
                            }}
                          />{" "}
                          Delete
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <ConfirmModal
              message={"Are you sure you want to delete Employee?"}
              onConfirm={handleConfirm}
              show={show}
              onClose={() => setShowConfirm(false)}
            />
          </Table>
        </TableContainer>
      </div>

      {showCoinBox && !dollarLoading && (
        <GiftCoinModal
          dollarValue={dollarValue}
          employee={selectedEmpForGift}
          employees={employees}
          selectedEmpIds={selectedEmployees}
          closeGiftBox={_closeGiftBox}
        />
      )}

      {(loading || dollarLoading) && <Loader />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  // root: {
  //   width: "100%",
  // },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  mainTable: {
    overflowY: "auto",
    // maxHeight: '600px',
  },
  topbtn: {
    marginLeft: "20%",
  },
  table: {
    minWidth: 750,
    // maxHeight: "300px",
    // position: "sticky",
    borderWidth: "1px",
    borderColor: "rgba(224, 224, 224, 1)",
    borderStyle: "solid",
    borderRadius: "4px",
  },
  inviteBtn: {
    backgroundColor: "#63d699",
    textTransform: "capitalize",
    marginRight: "5px",
    borderRadius: "2px",
  },
  smsBtn: {
    backgroundColor: AppColors.darkBlue,
    textTransform: "capitalize",
    borderRadius: "2px",
  },
  tableHead: {
    backgroundColor: "#fafafb",

    "&:first-child": {
      borderRadius: "12px 0 0 12px",
    },
    "&:last-child": {
      borderRadius: "0 12px 12px 0",
    },
  },
  checkbox: {
    color: "#93939e !important",
    borderWidth: "0.5px",
  },
  checked: {
    color: "#63d699 !important",
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "10px 0px",
    borderRadius: "10px",
    fontSize: "12px",
    minWidth: "120px",
  },
  bgWarning: {
    backgroundColor: AppColors.yellow,
    "&:hover": {
      backgroundColor: AppColors.yellow,
    },
  },
  bgDanger: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  bgBlue: {
    backgroundColor: AppColors.blueMain,
    "&:hover": {
      backgroundColor: AppColors.blueMain,
    },
  },
  bgGift: {
    backgroundColor: AppColors.darkBlue,
    "&:hover": {
      backgroundColor: AppColors.darkBlue,
    },
  },
  bgTorqtoise: {
    backgroundColor: "#63d699",
    "&:hover": {
      backgroundColor: "#63d699",
    },
  },

  tableRow: {
    height: 30,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  tableCell: {
    padding: "15px 16px",
    fontSize: 12,
    fontWeight: "600",
    color: "#4B6B73",
    lineHeight: "15px",
    backgroundColor: "#F1F5F6",
    fontFamily: "'Montserrat',sans-serif",
    // borderRightStyle : "solid",

    // borderRightWidth : "1px",
  },

  tableCellSecondary: {
    padding: 4,
    fontSize: 12,
    color: "#4B6B73",
    lineHeight: "15px",
    backgroundColor: "#fff",
    fontFamily: "'Montserrat',sans-serif",
  },

  menuStyle: {
    //backgroundColor : "green",
    //fontSize : 10,
    //fontWeight : '500',
    color: "#4B6B73 !important",
    padding: 0,
    lineHeight: "15px",
    //fontFamily : "'Montserrat',sans-serif",
  },

  iconStyle: {
    color: "#4B6B73 !important",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#000",
    },
  },
  selected: {
    backgroundColor: "#047CFF",
    color: "#ddd",
  },
  root: {
    "& .Mui-selected": {
      backgroundColor: "#047CFF",
      color: "#fff",
    },
  },
}));
