/*
 * File: ForgotPasswordPage.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 3rd December 2020 8:01:26 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 8:40:40 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState } from "react";
import { images } from "../../../assets/images/images";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { LoaderComponent } from "../../../Components/common/loader.component";
import ResetPasswordPage from "../ResetPasswordPage/ResetPasswordPage";
import { AuthApi } from "../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log } from "../../../Lib/helpers";
import { toastify } from "../../../Services/toast.service";

const forgotSchema = yup.object().shape({
  email: yup.string().required(),
});

const ForgotPasswordPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [email, setEmail] = useState("");

  const [sent, setSent] = useState(false);

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(forgotSchema),
    defaultValues: {
      email: "",
    },
  });
  const submit = async (v: any) => {
    setLoading(true);

    const { email } = v;
    setEmail(email);
    const response = await AuthApi.ForgotPassword(v);

    try {
      if (response.hasError) {
        handleApiError(response);
      } else {
        // ? USER Login success
        log("FORGOT PASSWORD: ", response);
        toastify('success',response.data.message)
        setSent(true);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  if (sent) return <ResetPasswordPage email={email} />;

  return (
    <div className="ForgotPasswordPage" data-testid="ForgotPasswordPage">
      <figure className="rt-loginimg">
        <img src={images.loginBG} alt="Login Image" />
      </figure>
      <div className="rt-loginarea">
        <div className="rt-logincontent">
          <div className="rt-logoarea">
            <strong className="rt-logo">
              <img src={images.Logo} alt="Logo Image" />
            </strong>
          </div>
          <div className="rt-loginsignupholder">
            <div className="rt-formtextarea">
              <div className="rt-forheading">
                <h3>Forgot Password</h3>
              </div>
              <div className="rt-description">
                <p>Enter your email below,</p>
                <p>we will send you the reset password link.</p>
              </div>
            </div>
            <form
              className="rt-formtheme rt-formsignin"
              onSubmit={handleSubmit(submit)}
            >
              <fieldset>
                <div className="form-group">
                  <label>Email</label>
                  <i className="icon-email"></i>
                  <div className="rt-inputiconholder">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="john@doe.com"
                      {...register('email')}
                    />
                    {errors.email && (
                      <span className="text-danger">Email is required.</span>
                    )}
                  </div>
                </div>
                <button
                  type="submit"
                  className="rt-btnlogin"
                  disabled={loading}
                >
                  {loading ? (
                    <LoaderComponent />
                  ) : (
                    <i className="icon-right-arrow"></i>
                  )}

                  <span>Submit</span>
                </button>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
