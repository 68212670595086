/*
 * File: modalLayout.tsx
 * Project: Dyme-Web
 * File Created: Tuesday, 30th November 2021 1:05:04 am
 * Author: Rahul Khera (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:53:35 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import makeStyles from '@mui/styles/makeStyles';

interface DialogPropsType {
  title?: string;
  open: boolean;
  onRequestClose?: () => void;
  children: any;
  dialogActions?: any;
  dialogClasses?: any;
  dialogActionsClassName?: string;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function MuiDialog(props: DialogPropsType) {
  const {
    title,
    onRequestClose,
    open,
    dialogActions,
    dialogClasses,
    dialogActionsClassName,
  } = props;
  const [toggle, setToggle] = React.useState(false);
  const classes = useStyles();
  const handleClose = () => {
    setToggle(false);
    onRequestClose && onRequestClose();
  };
  useEffect(() => {
    setToggle(open);
  }, [open]);

  return (
    <div>
      <Dialog
        open={toggle}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={dialogClasses}
      >
        <DialogTitle id="alert-dialog-title">
          {title}{" "}
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{props.children}</DialogContent>
        <DialogActions
          className={dialogActionsClassName && dialogActionsClassName}
        >
          {dialogActions}
        </DialogActions>
      </Dialog>
    </div>
  );
}
