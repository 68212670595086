/*
 * File: confirm.modal.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 8th December 2020 7:39:24 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 8th December 2020 8:21:29 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { LoaderComponent } from "../common/loader.component";

interface IProps {
  show?: boolean;
  loading?: boolean;
  onClose?: any;
  onConfirm?: any;
  confirmTitle?: string;
  cancelTitle?: string;
  heading?: string;
  message?: string;
}

export const ConfirmModal = ({
  show,
  loading,
  heading,
  message,
  cancelTitle,
  confirmTitle,
  onClose,
  onConfirm,
  ...rest
}: IProps) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      className="rt-thememodal rt-thememodaldelete"
      centered
    >
      <Modal.Header closeButton className="rt-modalheader">
        <Modal.Title>{heading || "Confirmation!"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {message || "Are you sure you want to perform this action?"}
      </Modal.Body>
      <Button
        disabled={loading}
        variant="primary"
        onClick={() => onConfirm(rest)}
        className="rt-btndelete"
      >
        {confirmTitle || "Confirm"}
        {loading && <LoaderComponent />}
      </Button>
    </Modal>
  );
};
