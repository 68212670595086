/*
 * File: applicant-detail.component.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 18th December 2020 6:58:47 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:32:40 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment";
import { images } from "../../assets/images/images";
import { ResumeData } from "../../Services/resumeOptions";
import { toastify } from "../../Services/toast.service";
import { IApplication, EApplicationStatus } from "../../Services/Interfaces/ApplicationInterface";

interface IProps {
  close(): void;
  application: IApplication;
  onStatusChange: (newStatus: EApplicationStatus, appId: number) => void;
}
export const ApplicantDetailComponent = (props: IProps) => {
  const { application } = props;
  const { resume, user } = application;
  const { pastExperience } = resume;
  const { addresses } = user;

  const [status, setStatus] = useState(application.status);

  // const [application, setApplication] = useState<IApplication>({} as any);

  useEffect(() => {
    setStatus(props.application.status);
  }, [props.application.status]);

  const show = () => {
    // getRequest(endpoints.company.application(id)).then((response: AxiosResponse<IApplication>) => {
    //     setApplication(response.data);
    // })
  };

  const _changeAppStatus = (newStatus: EApplicationStatus) => {
    if (newStatus === status) {
      alert("Applicant is already " + EApplicationStatus[status]);
      return;
    }
    props.onStatusChange(newStatus, application.id);
    // setStatus(newStatus);
  };

  const homeAddr = addresses.find((addr) => addr.title === "home");

  let city,
    state = "";
  if (homeAddr) {
    city = homeAddr.city;
    state = homeAddr.state;
  }

  const userClass = ResumeData.classesObj.find(
    (cl) => cl.value === +resume.education
  );

  const userExpValList = resume.experienceKind.split(",");

  const userExpList = ResumeData.expKinds.filter((exp) =>
    userExpValList.includes(`${exp.value}`)
  );

  const userSpeed = ResumeData.speedsObj.find((s) => s.value === resume.wpm);

  // contact details only visible if job is accepted.
  const canView =
    status === EApplicationStatus.Accepted ||
    EApplicationStatus.Hired === status;

  let userPhone = user.phone && canView ? user.phone : "**-***-******";

  return (
    <div className="rt-jobpreviewarea">
      <div className="rt-applicantpreviewarea">
        <div className="rt-applicantinfoarea">
          <div className="fluid-container">
            <div className="row">
              <div className="col-md-8">
                <div
                  className="rt-applicantdetail"
                  style={{ marginBottom: "-30px" }}
                >
                  <div className="rt-applicantitle" title="abc">
                    <i className="icon-address-book"></i>
                    <h4 style={{ marginRight: 10 }}>Contact Details</h4>
                    <i className="icon-phone"></i>
                    {userPhone}
                    <i className="icon-email"></i>
                    {canView ? user.email : "****@******"}
                    <i className="icon-linkedin"></i>
                    {canView ? "/in/umaraamer" : "/in/********"}
                  </div>
                  {/* <div className="rt-applicantcontent">
										<div className="strong"><i className="icon-phone"></i> {user.phone}</div>
										<div className="strong"><i className="icon-email"></i> {user.email}</div>
									</div> */}
                </div>
                <div className="rt-applicantdetail">
                  <div className="rt-applicantitle">
                    <i className="icon-user2"></i>
                    <h4>About</h4>
                  </div>
                  <div className="rt-applicantcontent">
                    <h5>{resume.about}</h5>
                  </div>

                  {resume.files.map((file) => {
                    if (!canView) {
                      return null;
                    }
                    return (
                      <div key={file.url} className="rt-applicantcontent">
                        <hr />
                        <a href={file.url}>
                          <h5 style={{ color: "blue" }}>
                            Download {file.name} ({moment(file.date).format("L")})
                          </h5>
                        </a>
                      </div>
                    );
                  })}
                </div>
                <div className="rt-applicantdetail">
                  <div className="rt-applicantitle">
                    <i className="icon-suitcase"></i>
                    <h4>Experience</h4>
                  </div>
                  <div className="rt-applicantcontent">
                    {pastExperience.map((exp, ind) => (
                      <div key={exp.id} className="applicant_exp_item">
                        <div className="exp_num">{ind + 1}</div>
                        <h5>{exp.role}</h5>
                        <p className="company_name">{exp.companyName}</p>
                        <p className="exp_date">
                          {moment(exp.fromDate).format("MMM, YYYY")} -{" "}
                          {exp.toDate
                            ? moment(exp.fromDate).format("MMM, YYYY")
                            : "Present"}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="rt-applicantdetail">
                  <div className="rt-applicantitle">
                    <i className="icon-book2"></i>
                    <h4>Education</h4>
                  </div>
                  <div className="rt-applicantcontent">
                    <h5>{userClass ? userClass.title : "N/A"}</h5>
                  </div>
                </div>
                <div className="rt-applicantdetail">
                  <div className="rt-applicantitle">
                    <i className="icon-pen-nidal"></i>
                    <h4>Skills</h4>
                  </div>
                  <div className="rt-applicantcontent">
                    <div className="rt-skillholder">
                      <div className="skill_item">
                        {" "}
                        {/* loop through this wrapper */}
                        <p>Words Per Min</p>
                        <h5>
                          {userSpeed?.title} {userSpeed?.speed}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="rt-applicantdetail">
            <div className="rt-applicantitle">
              <i className="icon-building"></i>
              <h4>Past Employment</h4>
            </div>
            <div className="rt-pastworkinfo">
              <span className="rt-workcount">1</span>
              <div className="rt-workhead">
                <h5>
                  Salesforce,<span>Project Manager</span>
                </h5>
                <em>03/23/2017 - Present</em>
              </div>
              <div className="rt-description">
                <p>
                  Synth polaroid bitters chillwave pickled. Vegan disrupt
                  tousled, Portland keffiyeh aesthetic food truck sriracha
                  cornhole single-origin coffee church-key roof party. Leggings
                  ethical McSweeney's, normcore you probably haven't heard of
                  them Marfa organic squid. Slow-carb 90's ennui Godard pug
                  asymmetrical, narwhal VHS Tonx High Life. Retro dreamcatcher
                  synth Godard pickled Etsy jean shorts beard, pour-over fanny
                  pack mumblecore. Quinoa retro aesthetic polaroid, Williamsburg
                  American Apparel plaid small batch. Blue Bottle Vice fanny
                  pack, Williamsburg roof party Wes Anderson mlkshk seitan
                  brunch before they sold out lo-fi XOXO tofu scenester small
                  batch.
                </p>
              </div>
            </div>
            <div className="rt-pastworkinfo">
              <span className="rt-workcount">2</span>
              <div className="rt-workhead">
                <h5>
                  Salesforce,<span>Project Manager</span>
                </h5>
                <em>03/23/2017 - Present</em>
              </div>
              <div className="rt-description">
                <p>
                  Synth polaroid bitters chillwave pickled. Vegan disrupt
                  tousled, Portland keffiyeh aesthetic food truck sriracha
                  cornhole single-origin coffee church-key roof party. Leggings
                  ethical McSweeney's, normcore you probably haven't heard of
                  them Marfa organic squid. Slow-carb 90's ennui Godard pug
                  asymmetrical, narwhal VHS Tonx High Life. Retro dreamcatcher
                  synth Godard pickled Etsy jean shorts beard, pour-over fanny
                  pack mumblecore. Quinoa retro aesthetic polaroid, Williamsburg
                  American Apparel plaid small batch. Blue Bottle Vice fanny
                  pack, Williamsburg roof party Wes Anderson mlkshk seitan
                  brunch before they sold out lo-fi XOXO tofu scenester small
                  batch.
                </p>
              </div>
            </div>
          </div> */}
        </div>
        <div className="rt-btnsholder">
          {EApplicationStatus.Accepted === status ? null : (
            <Button
              onClick={() => _changeAppStatus(EApplicationStatus.Accepted)}
              // disabled={EApplicationStatus.Accepted === status}
              type="button"
              className="rt-btnlogin rt-bgaccept"
            >
              <span>Accept</span>
            </Button>
          )}
          {EApplicationStatus.Declined === status ? null : (
            <Button
              onClick={() => _changeAppStatus(EApplicationStatus.Declined)}
              type="button"
              className="rt-btnlogin rt-bgreject"
            >
              <span>Decline</span>
            </Button>
          )}

          {EApplicationStatus.Hired === status ||
          EApplicationStatus.Viewed === status ||
          EApplicationStatus.Declined === status ? null : (
            <Button
              onClick={() => _changeAppStatus(EApplicationStatus.Hired)}
              type="button"
              className="rt-btnlogin rt-bghired"
            >
              <span>Hire</span>
            </Button>
          )}

          <Button
            onClick={() =>
              toastify("info", "Saving applicant support will be added later.")
            }
            //disabled={EApplicationStatus.Saved === status}
            type="button"
            className="rt-btnlogin rt-bgsave"
          >
            <span>Save</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
