/*
 * File: CategoryListPage.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 21st July 2020 12:00:11 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:53:35 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import * as React from "react";
import { useHistory } from "react-router-dom";
import { BaseAuth } from "../../../Components/BaseAuth";
import MaterialTable from "material-table";
import { PageHeader } from "../../../Components/PageHeader";
import { AppContext } from "../../../Services/AppContext";
import { ENotifyType } from "../../../Components/Notify";
import { CategoryApi } from "../../../Services/API/CategoryApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { useEffect } from "react";
import { ICategory } from "../../../Services/Interfaces/JobInterface";
import { ApiResponse, IApiResponse } from "../../../Services/api-client";
import { log } from "../../../Lib/helpers";

export const CategoryListPage = () => {
  const history = useHistory();
  const { notify } = React.useContext(AppContext);
  const [categories, setCategories] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    _fetchCategories();
  }, []);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const _notify = (
    errorType = ENotifyType.Error,
    message = "Something went wrong"
  ) => {
    if (errorType === ENotifyType.Error) {
      notify({
        title: message,
        type: errorType,
      });
    }
  };

  const _fetchCategories = async () => {
    setLoading(true);

    let message = "";
    let errorType = ENotifyType.Error;

    try {
      const response = await CategoryApi.GetCategories();
      if (response.hasError) {
        message = handleApiError(response, "Categories Get Error: ");
      } else {
        setCategories(response.data);
        errorType = ENotifyType.Success;
      }
    } catch (e) {
      handleCatchError(e);
    }

    _notify(errorType, message);

    setLoading(false);
  };

  const _addOrUpdateCategory = async (
    name: string,
    id: number | null = null
  ) => {
    setLoading(true);

    let response: IApiResponse;

    if (id) {
      // edit category
      response = await CategoryApi.EditCategory(id, {
        name,
      });
    } else {
      response = await CategoryApi.CreateCategory({
        name,
      });
    }

    let message = "";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        message = handleApiError(response, "Categories Create/Edit Error: ");
      } else {
        _fetchCategories();
        errorType = ENotifyType.Success;
      }
    } catch (e) {
      handleCatchError(e);
    }

    _notify(errorType, message);

    setLoading(false);
  };

  const _deleteCategory = async (id: number) => {
    let category:ICategory;
    const found = categories.find((cat:ICategory) => cat.id === id)

    if (found) {
      category = found;
      // if(category.jobs?.length) {
      //   alert(`Cannot delete this category because there are ${category.jobs?.length} jobs associated with it.`);
      //   log(category.jobs)
      //   return false;
      // }
    }

    setLoading(true);

    const response = await CategoryApi.DeleteCategory(id);

    let message = "";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        message = handleApiError(response, "Categories Delete Error: ");
      } else {
        _fetchCategories();
        errorType = ENotifyType.Success;
      }
    } catch (e) {
      handleCatchError(e);
    }

    _notify(errorType, message);

    setLoading(false);
  };

  return (
    <BaseAuth>
      <PageHeader
        heading="Categories"
        btnTitle="Add Category"
        // onBtnPress={() => _navigate(RouteKeys.CompanyCreate)}
        // links={joblink}
      />

      <MaterialTable
        isLoading={loading}
        editable={{
          onRowAdd: async (rowData: ICategory) => {
            _addOrUpdateCategory(rowData.name);
          },
          onRowUpdate: async (rowData: ICategory) => {
            _addOrUpdateCategory(rowData.name, rowData.id);
          },
          onRowDelete: async (rowData: ICategory) => {
            if (rowData.id) {
              _deleteCategory(rowData.id);
            }
          },
        }}
        columns={[
          { title: "ID", field: "id" },
          { title: "Name", field: "name" },
        ]}
        data={categories}
        title="Categories List"
        actions={
          [
            // {
            //   icon: "edit",
            //   tooltip: "Edit Company",
            //   onClick: (event, rowData: any) => alert("edit"),
            // },
            // {
            //   icon: "delete",
            //   tooltip: "Delete Company",
            //   onClick: (event, rowData: any) => {
            //     _onDelete(rowData.id);
            //   },
            // },
          ]
        }
        options={{
          actionsColumnIndex: -1,
        }}
      />
    </BaseAuth>
  );
};
