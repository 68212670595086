/*
 * File: errorHandling.ts
 * Project: jobapp-admin
 * File Created: Friday, 31st January 2020 1:21:25 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 13th January 2022 1:57:15 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { Config } from "../Config/index";
import { log, isString } from "../Lib/helpers";
import { IApiResponse } from "./api-client";
import { TErrorType, toastify } from "./toast.service";

/**
 * Handle Error in Catch
 * @param e - Error Object
 */
export const handleCatchError = (e: any, from: string | null = null) => {
  // const errorDrop = DropDownAlertHolder.getDropDown();
  log(`ERROR: ${from || " CATCH ERROR: "}: `, e.message, e);

  let errorTitle = "Something went wrong";
  let errorDesc = Config.IS_PRODUCTION ? "" : e.message;
  if (e.message === "440") {
    errorTitle = "Please Login again";
    errorDesc = "Session timeout";
  }

  toastify("error", errorTitle);

  // errorDrop.alertWithType(
  //   'error',
  //   errorTitle,
  //   errorDesc,
  // );
};

/**
 * Handle API response error
 * @param response - API Response
 * @param from - From screen
 */
export const handleApiError = (
  response: IApiResponse,
  from: string | null = null,
  alert = true
) => {
  // const errorDrop = DropDownAlertHolder.getDropDown();
  const { data, status } = response;

  let errorType: TErrorType = "info";

  let err = "Something went wrong";
  let err2 = err;
  try {
    if (status === 409) {
      // 409 is conflict error, either username, phone or email.
      // err = "Account with same email already exists.";
      err = data.message.split("(").pop().split(")").join("");
    } else if (data) {
      err = data.message;
    }

    switch (status) {
      case 404:
        errorType = "warning";
        break;
      default:
        errorType = "error";
        break;
    }
  } catch (e: any) {
    err2 = e.message;
  }

  log(`ERROR: ${from || "API RESPONSE ERROR: "}`, err2, err);

  if (alert) {
    log("error handle: ", err, err2);

    if (Array.isArray(err)) {
      // if this is array, show first error
      err = err[0];
    }

    if (from === "login" && status === 400) {
      err = "Invalid username or password.";
    }

    toastify(errorType, err || err2);
  }

  return err;

  // errorDrop.alertWithType('error', 'Error', err.trim());
};
