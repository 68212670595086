/*
 * File: RegisterForm.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 2nd December 2020 9:01:14 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 10:29:59 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useContext, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";
import PinInput from "react-pin-input";
import { LoaderComponent } from "../../../Components/common/loader.component";
import { alertObject, log } from "../../../Lib/helpers";
import { AuthApi } from "../../../Services/API/AuthApi";
import { ENotifyType } from "../../../Components/Notify";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { toastify } from "../../../Services/toast.service";
import { useHistory } from "react-router-dom";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { ILoginResponse } from "../../../Services/Interfaces/AuthInterface";
import { Config } from "../../../Config";
import { AppContext } from "../../../Services/AppContext";

const schema = yup.object().shape({
  // fullName: yup.string().required(),
  // email: yup.string().required(),
  // password: yup.string().required(),
  // confirmPassword: yup.string().required(),

  fullName: yup.string().required("Full Name required"),
  email: yup.string().email("Invalid email address").required("Email Required"),
  password: yup.string().required("Password required"),
});

interface IRegisterForm {
  referBy?: number;
  referType?: "business" | "friend" | "";
}
export const RegisterForm = ({ referBy, referType }: IRegisterForm) => {
  const history = useHistory();

  const { updateUser } = useContext(AppContext);

  const [_errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const [phone, setPhone] = useState("");

  const [phoneCode, setPhoneCode] = useState("");
  const [phoneVerified, SetPhoneVerified] = useState<boolean>(false);
  const [verifyingPhone, setPhoneVerifying] = useState<boolean>(false);

  const [success, setSuccess] = useState(false); // account created successfully.

  useEffect(() => {
    // toastify("success", "hola");
  }, []);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const { register, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      phone: "",
      fullName: "",
      password: "",
      // confirmPassword: "",
    },
  });

 
  //IReferUserDto

  const letsSignup = async (v: any) => {
    v.phone = phone;
    // if (!phoneVerified)
    //   return toastify("error", "Phone number is not verified.");


    setLoading(true);

    const { fullName, email, password } = v;

    const response = await AuthApi.Register(
      email,
      phone,
      email,
      fullName,
      password,
      true, //this is a business that is creating account, so their role will be admin.
    );

    let message: string = "";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        handleApiError(response, "SIGN UP SCREEN: ");

        message = response.data;

        if (response.data) {
          message = response.data.message;
          setErrors(response.data);
        }
      } else {
        // ? Account creation success
        log("REGISTER SUCCESS: ", response);
        message = "Account created!";
        errorType = ENotifyType.Success;

        // IF REFER BY BUSINESS, LOGIN AND NAVIGATE TO SETTINGS PAGE.

        let newUser: ILoginResponse = response.data;

        newUser.ttl =
          new Date().getTime() + Config.LOGOUT_AFTER_MINUTES * 60 * 1000; // newUser.ttl

        updateUser(newUser);

        setSuccess(true);

        toastify(errorType, message);
      }
    } catch (e) {
      handleCatchError(e);
    }

    // toastify(errorType, "stuff");
    // alert(message)
    setLoading(false);
  };

  const checkVerification = (v: any) => {
    if (v.length === 6) {
      if (String(phoneCode) === String(v)) SetPhoneVerified(true);
    }
  };

  const verifyPhone = () => {
    setPhoneVerifying(true);
    setPhoneCode("");
    // postRequest(endpoints.auth.verifyPhone(), { phone }).then(
    //   (response: AxiosResponse<any>) => {
    //     setPhoneVerifying(false);
    //     setPhoneCode(response.data.pin);
    //     toastify("success", response.data.message);
    //   },
    //   (error: AxiosError) => {
    //     toastify("error", error.response?.data.message);
    //     setPhoneVerifying(false);
    //     setPhoneCode("");
    //   }
    // );
  };

  // if (success && referType === "friend") {
  //   return (
  //     <h1>
  //       Thank you for registration. Please download our app to apply for jobs.
  //     </h1>
  //   );
  // }
  return (
    <div id="form_wrapper" className="register_form">
      <form
        className="rt-formtheme rt-forsignup"
        onSubmit={handleSubmit(letsSignup)}
      >
        <fieldset>
          <div className="blue_bg">
            <h3 className="signup_title">Create a Dyme Account</h3>
            <div className="form-group">
              <div className="rt-inputiconholder">
                <input
                  type="text"
                  className="form-control username_field"
                  placeholder="John Doe"
                  {...register('fullName', {required: "Full name is require"})}
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="fullName" />
                </span>
              </div>
            </div>
            <div className="form-group">
              <div className="rt-inputiconholder">
                <input
                  type="email"
                  className="form-control email_field"
                  placeholder="john@doe.com"
                  {...register('email')}
                />
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="email" />
                </span>
                {_errors.email && (
                  <span className="text-danger">{_errors.email}</span>
                )}
              </div>
            </div>
            <div className="form-group">
              <div className="rt-inputiconholder">
                <PhoneInput
                  placeholder="Enter phone number"
                  value={phone}
                  className="phone_field"
                  onChange={(v: any) => (
                    setPhone(v), setPhoneCode(""), SetPhoneVerified(false)
                  )}
                />
                {/* <button
                  type="button"
                  className="ender_code_button"
                  onClick={verifyPhone}
                  disabled={verifyingPhone}
                >
                  {verifyingPhone ? <LoaderComponent /> : "Send Code"}
                </button> */}
                <span className="text-danger">
                  <ErrorMessage errors={errors} name="phone" />
                </span>
                {_errors.phone && (
                  <span className="text-danger">{_errors.phone}</span>
                )}
              </div>
            </div>
            {phoneCode && (
              <div className="form-group">
                <label>Pin Code</label>
                <i
                  className={phoneVerified ? "icon-checkmark" : "icon-cross"}
                ></i>
                <div className="rt-inputiconholder">
                  <PinInput
                    length={6}
                    initialValue=""
                    secret
                    type="numeric"
                    inputMode="number"
                    style={{ padding: "10px" }}
                    inputStyle={{
                      borderColor: phoneVerified ? "green" : "red",
                    }}
                    inputFocusStyle={{ borderColor: "blue" }}
                    onComplete={checkVerification}
                    autoSelect={true}
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  />
                </div>
              </div>
            )}
            <div className="form-group">
              <div className="rt-inputiconholder password_wrapper">
                <input
                  type={showPass ? "text" : "password"}
                  className="form-control password_field"
                  placeholder="Password"
                  {...register('password', {
                    required: "Password is required",
                    min: "Password minimum length must be 8 characters",
                    minLength: 8,
                  })}
                />

                {showPass ? (
                  <i
                    onClick={() => setShowPass(!showPass)}
                    className="icon-eye show_hide_password"
                  ></i>
                ) : (
                  <i
                    onClick={() => setShowPass(!showPass)}
                    className="icon-eye-blocked1 show_hide_password"
                  ></i>
                )}

                <span className="text-danger">
                  <ErrorMessage errors={errors} name="password" />
                </span>
                {_errors.password && (
                  <span className="text-danger">{_errors.password}</span>
                )}
              </div>
            </div>
          </div>
          {/* <div className="form-group">
							<label>Confirm Password</label>
							<i className="icon-eye1"></i>
							<div className="rt-inputiconholder">
								<input
								type="password"
								className="form-control"
								placeholder="*****"
								{...register('confirmPassword')}
								/>
								<span className="text-danger">
								<ErrorMessage errors={errors} name="confirmPassword" />
								</span>
								{_errors.confirmPassword && (
								<span className="text-danger">{_errors.confirmPassword}</span>
								)}
							</div>
							</div> */}

          <button
            type="submit"
            className="blue_button"
            // disabled={registering || !phoneVerified}
          >
            {loading ? <LoaderComponent /> : ""}
            <span>Sign Up</span>
          </button>
          <div className="rt-btnalreadyhave">
            <p>
              By submitting this form, you are agreeing to Dyme's{" "}
              <a href={Config.PRIVACY_LINK}>Privacy Policy</a> and{" "}
              <a href="">Terms of Servce</a>.
            </p>
            <p>
              You are also agreeing to receive information and offers relevant
              to <a href={Config.TERMS_LINK}>Dyme's</a> service, and can opt-out
              at any time.
            </p>
          </div>
          {/* <div className="rt-btnalreadyhave">
							<a href="#" onClick={() => _navigate(RouteKeys.SignIn)}>
								Already have an account?
							</a>
							<span> Let’s Sign In</span>
						</div> */}
        </fieldset>
      </form>
    </div>
  );
};

RegisterForm.defaultProps = {};
