/*
 * File: CategoryApi.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 21st July 2020 1:20:04 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 10th September 2020 11:50:28 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import { ICategory } from "../Interfaces/JobInterface";
import { ApiPost, ApiPut, ApiGet, ApiDelete, ApiPatch } from "../api-client";
import { log } from "../../Lib/helpers";

/**
 * Create a New Category
 * 
 * @param userId User ID
 * @param name Name
 * @param description Description
 * @param address Address Object
 * @param isActive Active status
 */
const CreateCategory = async (
  category:ICategory
) => {
  const data = await ApiPost(`categories`,category);

  log("☁️ Create Category RESPONSE: ", data);

  return data;
};

/**
 * Edit Category
 * @param categoryId - CategoryID
 * @param category - Category Data Object
 */
const EditCategory = async (categoryId:number|string, category:ICategory) => {
  const data = await ApiPatch(`categories/${categoryId}`, {...category})

  log("☁️ Update Category RESPONSE: ", data);

  return data;
}

const GetCategories = async () => {
  const data = await ApiGet(`categories`)

  log("☁️ Get Categories RESPONSE: ", data);

  return data;
}

/**
 * Delete Category
 * @param categoryId - Category ID
 */
const DeleteCategory = async (categoryId:number|string) => {
  const data = await ApiDelete(`categories/${categoryId}`)

  log("☁️ Delete Category RESPONSE: ", data);

  return data;
}

export const CategoryApi = {
  CreateCategory,
  GetCategories,
  EditCategory,
  DeleteCategory,
}