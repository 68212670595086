/*
 * File: EditProfilePage.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 25th December 2020 2:20:40 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Saturday, 5th March 2022 12:04:44 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext, useState, useEffect } from "react";
import styles from "./EditProfilePage.module.scss";
import { EditProfileComponent } from "../user/edit.profile.component";
import { UserManagementTabComponent } from "../user/user-management.component";
import { EACL, EUserType } from "../../../../Services/Interfaces/AuthInterface";
import { ChangePasswordComponent } from "../user/change-password.component";
import { Config } from "../../../../Config";
import { AppContext } from "../../../../Services/AppContext";
import { useHistory, useParams } from "react-router";
import { RouteKeys } from "../../../../Routes/RouteKeys";
import { log } from "../../../../Lib/helpers";
import ButtonComponent from "../../../../Components/common/ButtonComponent/ButtonComponent";
import { PlumApi } from "../../../../Services/API/PlumApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { ENotifyType } from "../../../../Components/Notify";
import RefreshVouchersWidget from "../../../../Components/RefreshVouchersWidget";

//? this is actually the main settings page ..

export type TLinks =
  | "company-profile"
  | "custom-dyme"
  | "billing"
  | ""
  | "profile"
  | "manage-users"
  | "managers"
  | "payment-history"
  | "change-password";

export const checkUserAccess = (
  role: EUserType | undefined,
  permission: EACL
) => {
  if (!role) return false;

  if (role === EUserType.SuperAdmin) {
    return true;
  } else if (role === EUserType.Noob) {
    return false;
  }

  switch (permission) {
    case EACL.AUM:
      break;
    case EACL.ABA:
      return role === EUserType.Admin;
    case EACL.ACP:
      return role === EUserType.Admin;
    case EACL.ACDL:
      return role === EUserType.Admin;
    default:
      return false;
  }
};

const EditProfilePage = () => {
  const { user, notify } = useContext(AppContext);

  const history = useHistory();

  const { firstPage }: { firstPage: TLinks | "" } = useParams();

  const [selectedTab, setSelectedTab] = useState<TLinks>("");

  useEffect(() => {
    log("page is: ", firstPage);
    // resetAll();
    if (firstPage) {
      setSelectedTab(firstPage);
    }
  }, [firstPage]);

  const _navigate = (route: TLinks) => {
    setSelectedTab(route);
    history.push(`${RouteKeys.AdminSettings}/${route}`);
  };

  const showUserProfile = () => {
    _navigate("profile");
  };

  const showChangePass = () => {
    _navigate("change-password");
  };

  const showUserManagement = () => {
    _navigate("manage-users");
  };

  const showCreateNewUser = () => {
    _navigate("");
  };

  const checkAccess = (permission: EACL) => {
    const role = user?.user.role;

    return checkUserAccess(role, permission);
  };

  const isUserManagement = selectedTab === "manage-users";

  const isChangePass = selectedTab === "change-password";
  const isFirstTab = selectedTab === "profile" || selectedTab === "";

  return (
    <div className={styles.EditProfilePage} data-testid="EditProfilePage">
      <div className="rt-wrapper">
        <div className="rt-profilepage">
          <div className="rt-themetabs">
            <h3 style={{ fontFamily: "proxima_novabold" }}>Settings</h3>

            <ul
              className="rt-themenavtabs"
              style={{
                fontSize: "20px",
                paddingTop: "6px",
                fontFamily: "proxima_novaregular",
              }}
            >
              <li
                className={isFirstTab ? "rt-active" : ""}
                onClick={showUserProfile}
              >
                <button type="button">User Profile</button>
              </li>
              <li
                className={selectedTab === "change-password" ? "rt-active" : ""}
                onClick={showChangePass}
              >
                <button type="button">Change Password</button>
              </li>

              {checkAccess(EACL.AUM) && (
                <li
                  className={selectedTab === "manage-users" ? "rt-active" : ""}
                  onClick={showUserManagement}
                >
                  <button type="button">All Users</button>
                </li>
              )}
            </ul>
            <hr style={{ marginTop: "45px" }} />

            <div className="rt-tabscontent">
              {/*Profile Setting Tab Start*/}

              <div>
                {isUserManagement && checkAccess(EACL.AUM) && (
                  <UserManagementTabComponent />
                )}
                {isFirstTab && (
                  <div className={`rt-profiletab`}>
                    <EditProfileComponent />
                    <RefreshVouchersWidget />
                  </div>
                )}
                {isChangePass && <ChangePasswordComponent />}
              </div>
            </div>

            {/* <div className={`rt-profiletab`}>
              
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfilePage;
