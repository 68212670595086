/*
 * File: PlumApi.ts
 * Project: JobApp-Admin
 * File Created: Saturday, 3rd July 2021 1:50:18 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 17th September 2021 12:29:55 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { ApiDelete, ApiGet, ApiPost, ApiPut} from "../api-client";
import { log } from "../../Lib/helpers";
import {Config} from '../../Config'

// ! not in use anymore due to plum api update
const sendCodeRequest = async (code: string) => {
  const redirect_uri = `${window.location.origin}/admin/plum`;
  const payload = {
    
    redirect_uri,
    code,
  };
  const data = await ApiPost('/gifts/plum/create', payload);

  log("☁️ GET TOKEN REQUEST: ", data);

  return data;
};

const refreshVoucherRequest= async () => {
  
  const data = await ApiPost('/gifts/cache-refresh');

  log("☁️ REFRESH VOUCHER REQUEST: ", data);

  return data;
};

// Refresh Plum Token if it expires
const refreshPlumToken = async () => {
  
  const data = await ApiPut('/gifts/plum/refresh-token');

  log("☁️ REFRESH Plum Token REQUEST: ", data);

  return data;
};

export const PlumApi = {
  sendCodeRequest,
  refreshVoucherRequest,
  refreshPlumToken
};
