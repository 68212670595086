/*
 * File: GiftCoinModal.tsx
 * Project: Dyme-Web
 * File Created: Tuesday, 30th November 2021 12:43:25 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 17th December 2021 8:55:36 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Modal,
  Box,
  Chip,
  Typography,
  TextField,
  Grid,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useHistory } from "react-router-dom";
import { log } from "../../../Lib/helpers";
import { RewardsApi } from "../../../Services/API/RewardApi";
import { handleCatchError } from "../../../Services/error-handling";
import { IEmployee } from "../../../Services/Interfaces/EmployeeInterface";
import { kFormatter } from "../../../Lib";
import { AppContext } from "../../../Services/AppContext";
import { ENotifyType } from "../../../Components/Notify";
import { IPaginate } from "../../../Services/Interfaces";
import { messageStyles } from "./Message";

interface IGiftCoinModal {
  closeGiftBox: () => void;
  dollarValue: number;

  employee: IEmployee | null; // if 1 employee is selected.

  employees: IPaginate<IEmployee>; // all available employees.
  selectedEmpIds: number[]; // selected employee IDs
}

export const GiftCoinModal = (props: IGiftCoinModal) => {
  const history = useHistory();

  const { notify } = useContext(AppContext);

  log("starting gift coin modal");

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const [coinsCount, setCoinsCount] = useState(5000);

  const [msg, setMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState<IEmployee[]>([]);

  const { employee, dollarValue, employees, selectedEmpIds } = props;

  const classes = messageStyles.useStyles();

  useEffect(() => {
    if (selectedEmpIds.length && employees?.data?.length) {
      const filteredData = employees.data.filter(
        (val: IEmployee, i: number) => {
          if (selectedEmpIds.includes(val.id) && val.userId) {
            return val;
          }
          return false;
        }
      );
      setSelectedEmployees(filteredData);
    } else {
      setSelectedEmployees([]);
    }
  }, [selectedEmpIds, employees]);

  // gift coins to employee api
  const _giftCoins = async () => {
    // if employee is not selected from menu, return

    if (!employee && !selectedEmployees.length) {
      notify({
        title: `Please select employees first.`,
        type: ENotifyType.Error,
      });

      return;
    }

    if (employee && !employee?.user) {
      notify({
        title: `${employee.fullName} can only be rewarded after they create account.`,
        type: ENotifyType.Error,
      });
      return;
    }

    if (coinsCount < 5000) {
      notify({
        title: `Minimum amount to reward Dymes is 5000`,
        type: ENotifyType.Error,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await RewardsApi.GiftMultipleEmployees(
        employee ? [employee.id] : selectedEmployees.map((se) => se.id),
        coinsCount,
        msg
      );
      if (response.hasError) {
        notify({ title: response.data.message, type: ENotifyType.Error });
      } else {
        // ? success
        log("Gift Coins success: ", response);
        notify({
          title: employee
            ? `Dymes rewarded to ${employee.fullName} successfully`
            : `Dymes rewarded to ${response.data.length} selected Employees.`,
          type: ENotifyType.Success,
        });
        _closeGiftBox();
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  const _closeGiftBox = () => {
    props.closeGiftBox();
  };

  const _updateCount = (count: number) => {
    setCoinsCount(count);
  };

  const _handleEmpRemove = (data: IEmployee) => {
    // setSelectedMessageEmployees(
    //   selectedMessageEmployees.filter((val: IEmployee, i: number) => {
    //     return val.id !== data.id;
    //   })
    // );

    setSelectedEmployees((selectedEmp) => {
      return selectedEmp.filter((val: IEmployee, i: number) => {
        return val.id !== data.id;
      });
    });
  };

  return (
    <Modal
      // open={!!employee || employees.length}
      open={true}
      onClose={_closeGiftBox}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "0.2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        {!employee ? (
          <Grid className="mb-3" alignItems="center">
            <div className="d-flex align-items-center">
              <Typography className={`${classes.heading} mr-5`}>To:</Typography>
              <Typography>
                <div className={classes.chipWrapper}>
                  {selectedEmployees.length
                    ? selectedEmployees.map((data) => {
                        let icon;
                        return (
                          <li key={data.id}>
                            <Chip
                              icon={icon}
                              label={data?.fullName}
                              onDelete={() => _handleEmpRemove(data)}
                              className={classes.chip}
                            />
                          </li>
                        );
                      })
                    : "No Employees Selected"}
                </div>
              </Typography>
            </div>
          </Grid>
        ) : null}
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Select amount of Dymes to reward{" "}
          {employee ? `${employee.fullName} (${employee?.title})` : ""}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {kFormatter(dollarValue)} Dyme coins equal $1. It will be deducted
          from your business account. Employees can buy gift cards from Dyme
          Mobile app.
        </Typography>
        <br />
        <Box>
          {[5000, 10000, 15000, 20000, 25000, 50000].map((num) => (
            <CoinBtn
              coinCount={num}
              onSelect={() => _updateCount(num)}
              selectedCount={coinsCount}
              key={num}
            />
          ))}
        </Box>
        {/* custom amount */}
        <Box>
          <TextField
            variant="filled"
            type="number"
            label="Dymes Amount"
            value={coinsCount}
            onChange={(v) => _updateCount(+v.target.value)}
            fullWidth
            placeholder=""
            required
            inputProps={{ min: 5000, step: 1000 }}
          />
        </Box>
        <br />
        <Box>
          <TextField
            variant="filled"
            label="Message"
            fullWidth
            multiline
            rows={3}
            placeholder="Message to Employee"
            value={msg}
            onChange={(v) => setMsg(v.target.value)}
          />
        </Box>
        <br /> <br />
        <LoadingButton
          loading={loading}
          onClick={_giftCoins}
          variant="contained"
          color="success"
        >
          Gift Dymes
        </LoadingButton>
        <Button
          onClick={_closeGiftBox}
          style={{ marginLeft: 20 }}
          variant="outlined"
          color="secondary"
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

interface ICoinBtn {
  coinCount: number;
  onSelect: (coinCount: number) => void;
  selectedCount: number;
  // dollarValue: number;
}
export const CoinBtn = (props: ICoinBtn) => {
  const { coinCount, selectedCount } = props;

  const _selectCount = (count: number) => {
    props.onSelect(count);
  };

  const _isSelected = selectedCount === coinCount;

  return (
    <Chip
      onClick={() => _selectCount(coinCount)}
      label={kFormatter(coinCount) + " Dymes"}
      color={_isSelected ? "success" : "primary"}
      variant={_isSelected ? "filled" : "outlined"}
      sx={{ marginRight: 2, marginBottom: 2 }}
    />
  );
};
