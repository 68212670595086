/*
 * File: ApplicationInterface.ts
 * Project: Dyme-Web
 * File Created: Monday, 10th January 2022 9:25:15 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:51:58 pm
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */

import { IUser } from "./AuthInterface";
import { IResume } from "./JobInterface";

export interface StatusLog {
  date:   number;
  status: number;
  reward: number;
}

interface IJob {
  id?: number|string;
  branchId: number|string;
  categoryId: number|string;
  // branch?: IBranch;
  // category?: ICategory;
  title: string;
  description: string;
  requirements: string;
  benefits: string;
  salary: string | number;
  // shift: EJobShift;
  onsite: boolean;
  // status: EJobStatus;
  location: string;
  // applications: TMappedType<IApplication>[];
  createdAt?: string;
  updatedAt?: string;
}

export interface IApplication {
  id:        number;
  updatedAt: string;
  createdAt: string;
  userId:    number;
  jobId:     number;
  resumeId:  number;
  status:    EApplicationStatus;
  statusLog: StatusLog[];
  transactionId: number;
  user:       IUser;
  job:       IJob;
  resume:    IResume;
}


export interface IApplicationStats {
  totalJobs:         number;
  activeJobs:         number;
  totalApplications: number;
  applicationStats:  ApplicationStat[];
  newHiredStats:     NewHiredStat[];
}

export interface ApplicationStat {
  status: number;
  count:  number;
}

export interface NewHiredStat {
  id:       number;
  fullName: string;
  photo:    string;
  job:      IJob;
}


export const applicationStatsInitial: IApplicationStats = {
  totalJobs: 1,
  activeJobs: 1,
  totalApplications: 1,
  applicationStats: [],
  newHiredStats: []
}

// earlier: rejected | accepted | saved
export enum EApplicationStatus {
  "Job Viewed" = 0, // user created account
  Applied = 1,
  Viewed = 2,
  Accepted = 3,
  Hired = 4, PostHire = 44,
  Declined = 5,
}

export const statusTitleArr = Object.keys(EApplicationStatus).filter((key:any) => !isNaN(Number(EApplicationStatus[key])));
export const statusValueArr = Object.keys(EApplicationStatus).filter((key:any) => isNaN(Number(EApplicationStatus[key])));