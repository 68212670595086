/*
 * File: index.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 8th December 2020 7:46:04 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 10:31:21 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
export * from './format.helper'

export const toTitleCase = (str = '') => {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

/**
 * K Formatter (Kay - Format) | 3600 => 3.6k
 * @param num - number
 * @returns num 
 */
 export function kFormatter(num?:string | number) {
    num = num ? +num : 0;

    const fixedNumber: number = +(Math.abs(num)/1000).toFixed(1)

    return Math.abs(num) > 999 ? Math.sign(num)*fixedNumber + 'K' : Math.sign(num)*Math.abs(num)
  }