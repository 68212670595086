/*
 * File: PaymentHistory.tsx
 * Project: Dyme-Web
 * File Created: Monday, 16th August 2021 7:40:46 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:34:38 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react";
import Table from "@mui/material/Table";
import moment from "moment";
import { Typography } from "@mui/material";
import LoadingButton from '@mui/lab/LoadingButton';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import makeStyles from '@mui/styles/makeStyles';
import NumberFormat from "react-number-format";
import { IPaymentTransaction } from "../../../../Services/Interfaces/AuthInterface";
import { RefreshOutlined } from "@mui/icons-material";
import { EApplicationStatus } from "../../../../Services/Interfaces/ApplicationInterface";
const useStyles = makeStyles({
  firstDateClass: {
    fontFamily: "proxima_novaregular",
    fontSize: "20px",
  },
  feesClass: {
    fontFamily: "proxima_novaregular",
    fontSize: "15px",
    marginTop: "7px",
    marginRight: "25px",
  },
  completedTextClass: {
    marginTop: "25px",
    marginBottom: "8px",
    fontSize: "18px",
    fonFamily: "proxima_novaregular",
  },
  imageClass: {
    width: "40px",
    height: "40px",
    borderRadius: "20px",
  },
  nameFontStyle: {
    fontFamily: "proxima_novabold",
    fontSize: "15px",
  },
  paidTextClass: {
    fontFamily: "proxima_novaregular",
  },
  imageTableCell: {
    paddingRight: "0px",
  },
  dateFontClass: {
    fontFamily: "proxima_novaregular",
    color: "#696974",
    paddingLeft: "0px",
    paddingRight: "2px",
  },
  amountClass: {
    color: "#EF626E",
    paddingRight: "0px",
  },
  amountClassGreen: {
    color: "#63D699",
    paddingRight: "0px",
  },
  commentTextClass: {
    fontFamily: "proxima_novaregular",
  },
  borderTopClass: {
    width: "100%",
  },
});

interface IPaymentHistory {
  loadingTrans: boolean;
  loadingPayment: boolean;
  transactions: IPaymentTransaction[];
  refresh: () => void;
}

const PaymentHistory = (props: IPaymentHistory) => {
  const { loadingTrans, transactions, loadingPayment } = props;
  console.log(transactions, loadingTrans, loadingPayment);
  const classes = useStyles();

  return (
    <>
      <div>
        {/* <Typography className={classes.firstDateClass}>
          July 01, 2021 - July 31, 2021
        </Typography>
        <Typography className={classes.feesClass} display="inline">
          {"Venom fees in this period: $0.00"}
        </Typography>
        <Typography className={classes.feesClass} display="inline">
          {"Year-to-Date 2021 Venom fees: $0.00"}
        </Typography> */}
        <LoadingButton
        loadingPosition="start"
        loading={props.loadingTrans}
        startIcon={<RefreshOutlined />}
        variant="outlined"
        onClick={props.refresh}>
          

        {/* <Typography className={classes.completedTextClass}> */}
          Refresh Transactions
        {/* </Typography> */}
          </LoadingButton>

        <TableContainer>
          <Table>
            <TableBody>
              {/* <Divider className={classes.borderTopClass}/> */}
              <TableRow>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
              </TableRow>
              {transactions.map((payment) => {
                const paymentDate = moment(payment.createdAt);

                let newAmount = payment.amount;
                if (!payment.isAdded) {
                  newAmount = newAmount * -1;
                }

                const avatarLink = payment.receiver
                  ? payment.receiver.photo
                  : payment.admin.photo;

                return (
                  <TableRow key={payment.id}>
                    <TableCell align="left" className={classes.dateFontClass}>
                      {paymentDate.format("L")}
                    </TableCell>
                    <TableCell className={classes.imageTableCell}>
                      <img src={avatarLink} className={classes.imageClass} />
                    </TableCell>
                    <TableCell className={classes.nameFontStyle}>
                      <span className={classes.paidTextClass}>Admin</span>{" "}
                      {payment.admin.fullName}
                      <span className={classes.paidTextClass}>
                        {" "}
                        {payment.receiver ? "rewarded" : "charged"}{" "}
                      </span>{" "}
                      {payment.receiver ? (
                        <>
                          <span className={classes.paidTextClass}>
                            Employee{" "}
                          </span>
                          {payment.receiver.fullName}
                        </>
                      ) : (
                        " Business account"
                      )}
                      {payment?.application?.job ? (
                        <Typography>
                          {payment.application.job.title} for Applicant{" "}
                          {payment.application.user.fullName} [{
                          payment.applicationStatus ? 
                          EApplicationStatus[payment.applicationStatus] : "Dyme Fee"}]
                        </Typography>
                      ) : null}
                    </TableCell>
                    <TableCell
                      className={
                        newAmount < 0
                          ? classes.amountClass
                          : classes.amountClassGreen
                      }
                    >
                      <NumberFormat
                        decimalScale={2}
                        allowNegative={true}
                        decimalSeparator="."
                        displayType={"text"}
                        prefix={"$"}
                        fixedDecimalScale={true}
                        value={newAmount}
                      />
                    </TableCell>
                    <TableCell
                      className={
                        newAmount < 0
                          ? classes.amountClass
                          : classes.amountClassGreen
                      }
                    >
                      <NumberFormat
                        decimalScale={2}
                        allowNegative={true}
                        decimalSeparator="."
                        displayType={"text"}
                        prefix={"$"}
                        fixedDecimalScale={true}
                        value={payment.balance}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
export default PaymentHistory;
