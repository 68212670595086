/*
 * File: EmployeesApi.ts
 * Project: JobApp-Admin
 * File Created: Thursday, 6th May 2021 10:40:16 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Thursday, 23rd September 2021 11:43:21 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { log } from "../../Lib/helpers";
import { ApiDelete, ApiGet, ApiPatch, ApiPost, ApiPut } from "../api-client";
import { defaultRequestQuery, IRequestQuery } from "../Interfaces";
import { EEmployeeRole, IEmployeeBase } from "../Interfaces/EmployeeInterface";

/**
 * Get Employees
 * role? : noob | admin | superAdmin
 *
 * @param query{IRequestQuery} - Query
 */
const GetEmployees = async (
  query: IRequestQuery = {...defaultRequestQuery}
) => {
  // role? : noob | admin | superAdmin
  query = {
    ...defaultRequestQuery,
    ...query
  }
  const data = await ApiGet(
    `employees`, query
  );

  log("☁️ GET EMPLOYEES RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Employee with their Company Profile without Auth.
 */
const GetEmployeeNoAuth = async (
  employeeId:number
) => {
  const data = await ApiGet(`employees-public/${employeeId}`);

  log("☁️ Get Employee No Auth RESPONSE: ", data);

  return data;
};



/**
 * Create Employee
 * @param employee - Employee Object
 * @returns 
 */
const CreateEmployee = async (
  employee:IEmployeeBase
) => {
  const data = await ApiPost(`employees`,employee);

  log("☁️ Create Employee RESPONSE: ", data);

  return data;
};

/**
 * Create Admin Employee
 * @param employee - Employee Object
 * @returns 
 */
const CreateAdminEmployee = async (
  employee:IEmployeeBase
) => {
  const data = await ApiPost(`employees/admin`,employee);

  log("☁️ Create Employee RESPONSE: ", data);

  return data;
};

/**
 * Edit Admin Employee
 * @param employeeId - EmployeeID
 * @param employee - Employee Data Object
 */
 const EditAdminEmployee = async (employeeId:number|string, employee:IEmployeeBase) => {
  const data = await ApiPut(`employees/admin/${employeeId}`, {...employee})

  log("☁️ Update Admin Employee RESPONSE: ", data);

  return data;
}


/**
 * Create Employee
 * @param employee - Employee Object
 * @returns 
 */
const BulkAddEmployees = async (
  employees:IEmployeeBase[]
) => {
  const data = await ApiPost(`employees`,employees);

  log("☁️ Bulk Employees Add RESPONSE: ", data);

  return data;
};

/**
 * Edit Employee
 * @param employeeId - EmployeeID
 * @param employee - Employee Data Object
 */
const EditEmployee = async (employeeId:number|string, employee:IEmployeeBase) => {
  const data = await ApiPut(`employees/${employeeId}`, {...employee})

  log("☁️ Update Employee RESPONSE: ", data);

  return data;
}

/**
 * Change Role of Employee to noob or admin
 * This is only accessible by Branch Owner
 * 
 * @param employeeId Employee ID
 * @param newRole New Role (noob | admin | superAdmin)
 * @returns 
 */
const ChangeEmployeeRole = async (employeeId:number|string, newRole:EEmployeeRole) => {
  const data = await ApiPatch(`employees/change-role/${employeeId}/${newRole}`)

  log("☁️ Change Employee Role RESPONSE: ", data);

  return data;
}

/**
 * Delete Employee
 * @param employeeId - EmployeeID
 */
const DeleteEmployee = async (employeeId:number|string) => {
  const data = await ApiDelete(`employees/${employeeId}`)

  log("☁️ Delete Employee RESPONSE: ", data);

  return data;
}



export const EmployeesApi = {
  GetEmployees,
  GetEmployeeNoAuth,
  
  CreateEmployee,
  BulkAddEmployees,
  EditEmployee,
  
  ChangeEmployeeRole,
  
  CreateAdminEmployee,
  EditAdminEmployee,
  
  DeleteEmployee
};