/*
 * File: AddBalance.tsx
 * Project: Dyme-Web
 * File Created: Friday, 20th August 2021 8:40:32 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Fri Feb 11 2022
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useEffect, useState } from "react";
import { FormControl, Select } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "../../../../Components/common/ButtonComponent/ButtonComponent";
import { Modal } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import ThankyouBox from "./Thankyou";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { EChargeVia, PaymentApi } from "../../../../Services/API/PaymentApi";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import addBalanceIcon from "../../../../assets/images/addmoneyIcon.png";
import { toastify } from "../../../../Services/toast.service";
import { log } from "../../../../Lib/helpers";
import {InputLabel} from "@mui/material";

const AddBalance = (props: any) => {
  const { open, setOpen } = props;
  const classes = useStyles();
  const [amount, setAmount] = useState(0);

  const [newTotal, setNewTotal] = useState(0);

  const [source, setSource] = useState(EChargeVia.Card);

  const [loading, setLoading] = useState(false);

  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    setNewTotal(amount + (amount * 0.03) + 0.31);
  }, [amount]);

  const handleHide = () => {
    setOpen(false);
  };

  // const handleSubmit = () => {
  //   _chargeAccount();
  // };

  const _chargeAccount = async () => {

    setLoading(true);

    try {
      const response = await PaymentApi.ChargeAccount(+amount, source);
      if (response.hasError) {
        log('response api has error....')
        handleApiError(response, "charge-balance");
      } else {
        setToggle(true);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  const _closeThankYou = () => {
    setToggle(false);
    handleHide();
  };

  return (
    <>
      {toggle === true ? (
        <ThankyouBox
          toggle={toggle}
          setToggle={_closeThankYou}
          amount={amount}
          source={source}
        />
      ) : null}
      <Modal
        size="lg"
        show={open}
        onHide={handleHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={classes.modalBox}
      >
        <div className={classes.checkCircle}>
          <img src={addBalanceIcon} />
        </div>

        <Modal.Header closeButton className="mx-3" style={{ border: "none" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className={classes.addBalance}
          >
            Add to Balance
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <form onSubmit={handleSubmit}> */}
          <input type="hidden" name="token" />

          <div className="form-row  d-flex bd-highlight  mx-3 mb-3">
            {/* <label className={`${classes.Payfrom} h5 p-2 flex-grow-1 w-50`}>
              Amount
            </label> */}
            <TextField
              fullWidth
              placeholder="$"
              variant="outlined"
              onChange={(e) => setAmount(+e.target.value)}
              label="Amount" 
              InputLabelProps={{
                //shrink: subjectShrink, // can be controlled via hook but not using
                shrink: true,
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
                sx: {
                  fontWeight : 600,
                  [`&.${inputLabelClasses.shrink}`]: {
                    color: "#005EFF",
                    fontFamily : "'Monsterrat', sans-serif"
                  }, 
                  fontFamily : "'Monsterrat', sans-serif"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                },
                sx: {
                  fontSize : 15, 
                  color : "#4B6B73",
                  fontWeight : 600,
                }
              }}
            />
          </div>
          <div className="form-row d-flex bd-highlight mx-3 pb-5 mt-4">
            {/* <label className={`${classes.Payfrom} h5 p-2 flex-grow-1 w-50`}>
              Pay From
            </label> */}
            <FormControl
              variant="outlined"
              className={`${classes.formControl} w-100`}
            >
              <InputLabel id="pay-from-label" style = {{
                color : "#005EFF", 
                fontSize : 15, 
                fontWeight : 600,
                fontFamily : "'Monsterrat', sans-serif"
              }}>Pay From</InputLabel>

              <Select
                style={{ 
                  width: "100%", 
                  fontFamily : "'Monsterrat', sans-serif", 
                  color : "#4B6B73", 
                  fontWeight : 700, 
                  fontSize : 14 
                }}
                variant="outlined"
                value={source}
                onChange={(e: any) => setSource(e.target.value)}

                labelId="pay-from-label"
                label={"Category"}
              >
                {/* <MenuItem value=" " primaryText={'Select division'} /> */}
                <MenuItem value={EChargeVia.Card} style = {{fontFamily : "'Monsterrat', sans-serif"}}>Credit / Debit Card</MenuItem>
                <MenuItem value={EChargeVia.Bank} style = {{fontFamily : "'Monsterrat', sans-serif"}}>Bank Transfer</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="form-row  d-flex bd-highlight  mx-3 mb-3" style = {{fontFamily : "'Monsterrat', sans-serif"}}>
            <label className={`${classes.Payfrom} h6 p-2 flex-grow-1 w-50`} style = {{ color : "#4B6B73", fontSize : 20, fontWeight : 600}}>
              Total including payment processing. <b style = {{ color : "#005EFF"}}>$ {newTotal}</b>
            </label>
          </div>

          <div className="mb-2 mx-2">
            <Button
              // className={`${classes.bottomBtn} ${classes.bgTorqtoise}`}
              // type="submit"
              onClick={_chargeAccount}
              disabled={!amount || loading}
              loading={loading}
              style = {{
                marginLeft : 10,
                backgroundColor : "#005EFF", 
                fontFamily : "'Monsterrat', sans-serif"
              }}
            >
              Add Balance
            </Button>
          </div>
          {/* </form> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    padding: "36px",
  },
  paper: {
    marginRight: theme.spacing(1),
    cursor: "pointer",
  },
  bgTorqtoise: {
    backgroundColor: "#009900",
    "&:hover": {
      backgroundColor: "#009100",
    },
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "1rem ",
    borderRadius: "35px",
    marginRight: "10px",
    width: "100%",
    marginBottom: "20px",
    fontSize: "16px",
    fontFamily: "proxima_novaregular",
  },
  borderRight: {
    borderRight: "1px solid #e8e8e8",
    marginLeft: theme.spacing(-3),
  },
  content: {
    padding: "40px",
  },
  Text: {
    paddingTop: "15px",
    fontFamily: "proxima_novaregular",
    fontSize: "18px",
  },
  Method: {},
  Icon: {
    fill: "#2574ff",
    fontSize: "50",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalBox: {
    "&>div": {
      "&>div": {
        borderRadius: "15px",
        width: "90%",
      },
    },
  },
  formControl: {
    fontFamily: "proxima_nova",
    "&>div": {
      width: "100%",
      "&:before": {
        content: "none",
      },
    },
  },
  addBalance: {
    fontFamily: "proxima_novabold",
  },
  Payfrom: {
    fontFamily: "proxima_novaregular",
  },
  checkCircle: {
    position: "absolute",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  checkIcon: {
    color: "green",
    fontSize: "70px",
  },

  cssLabel: {
    color : '#4B6B73', 
    fontSize : 15,
  },

  cssOutlinedInput: {
    '&$cssFocused $notchedOutline': {
      borderColor: `#005EFF !important`,
      borderWidth : 1, 
    },
    color : "#4B6B73",
    fontFamily : "'Monsterrat', sans-serif"
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#ddd !important',
    fontFamily : "'Monsterrat', sans-serif"
  },
}));

export default AddBalance;
