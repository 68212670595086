/*
 * File: SettingInterface.ts
 * Project: Dyme-Web
 * File Created: Wednesday, 1st December 2021 7:40:35 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 8:47:57 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

export enum ESettingType {
  REFERRAL = 'REFERRAL', // when someone create account on referral
  APPLIED = 'APPLIED', // when someone who was referred applies to a job.
  VIEWED = 'VIEWED', // when someone who was referred is accepted for job interview.
  ACCEPTED = 'ACCEPTED', // when someone who was referred is accepted for job interview.
  HIRED = 'HIRED', // when someone who was referred is hired for a job.
  REDEEM = 'REDEEM', // when someone redeems coins to buy gift cards.
  DOLLAR = '1_DOLLAR', // no use for feed, just useful for coin settings
  GIFT = 'GIFT', // no use for feed, just useful for coin settings
}

export interface ISettings {
  id:       number;
  key:      ESettingType;
  values:   number[];
  isActive: boolean;
}