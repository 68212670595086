/*
 * File: BranchAddPage.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 13th July 2020 7:25:08 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 1:28:43 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { BaseAuth } from "../../../Components/BaseAuth";
import { PageHeader } from "../../../Components/PageHeader";
import { BranchForm } from "./BranchForm";
import { companyLinks } from "../Companies/CompaniesListPage";

export const BranchAddPage: React.FC = () => {
  const history = useHistory();

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  //@ts-ignore
  const { companyId } = useParams();

  return (
    <BaseAuth>
      <PageHeader heading="Add Branch" links={companyLinks} />
      <BranchForm companyId={companyId} />
    </BaseAuth>
  );
};
