/*
 * File: SettingsContainer.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 9th March 2020 8:48:32 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { PageHeader } from "../../../Components/PageHeader";
import { BaseAuth } from "../../../Components/BaseAuth";
import {
  Typography,
  Container,
  Paper,
} from "@mui/material";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { AppContext } from "../../../Services/AppContext";

// ! NOT IN USE ANYMORE BECAUSE OF NEW UI
export const SettingsContainer: React.FC = props => {
  const history = useHistory();
  const { user } = useContext(AppContext);

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  const fullName = `${user?.user.fullName}`;

  return (
    <BaseAuth>
      <PageHeader
        heading="Account Settings"
        links={[
          {
            route: RouteKeys.UpdateCompanyProfile,
            title: "Company / Call Center Profile"
          },

          {
            route: RouteKeys.ChangePass,
            title: "Change Password"
          }
        ]}
      />

      <br />
      <Container style={{marginLeft: 'initial'}}>
        <Paper style={{ padding: 20, marginBottom: 30 }}>
          <Typography variant="subtitle1">{fullName}</Typography>
          <Typography variant="subtitle2">{user?.user.email}</Typography>
        </Paper>
        {props.children}
      </Container>
    </BaseAuth>
  );
};
