/*
 * File: AuthInterface.ts
 * Project: JobApp-Admin
 * File Created: Monday, 17th February 2020 5:46:09 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 31st January 2022 8:55:59 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { IApplication } from "./ApplicationInterface";
import { IEmployee } from "./EmployeeInterface";

export interface ILoginResponse {
  ttl: number;
  user: IUser;
  accessToken: string;
  employee: IEmployee | null;
}

// user object in login and register response
export interface IUser {
  id: number;
  updatedAt: string;
  createdAt: string;
  username: string;
  email: string;
  phone: string;
  fullName: string;
  role: EUserType;
  photo: string;
  isActive: boolean;
  verifiedEmail: boolean;
  verifiedPhone: boolean;
  addresses: IUserAddress[];
}
export interface IUserRequest {
  id?: number;
  username: string;
  email: string;
  phone?: string;
  fullName: string;
  role: EUserType;
  password?: string;
  isActive: boolean;
}

export interface IUserAddress {
  id: number;
  userId: number;
  title: string;
  address: string;
  zipCode: string;
  state: string;
  city: string;
  country: string;
  location: string;
}

export enum EUserType {
  SuperAdmin = "superAdmin",
  Admin = "admin",
  Noob = "noob",
}

export type TStorageKeys = "user";

export enum EACL {
  ACP = "Access to Company Profile",
  ACDL = "Access to Company Custom Dyme Logo",
  AUM = "Access to User Management",
  ABA = "Access to Business Admins",
  ABM = "Access to Billing Management",
  AJS = "Access to Jobs Section",
  AAS = "Access to Applicants Section",
  ACS = "Access to Candidates Section",
  ACMS = "Access to Chat/Messages Section",
  CP = "Change Password",
}
export interface IStripeTransaction {
  id: string;
  created_at: string;
  invoice_amount: string;
  payment_method: string;
  transaction_id: string;
}

export interface IStripeBank {
  id: string;
  object: string;
  account_holder_name: null;
  account_holder_type: null;
  account_type: null;
  bank_name: string;
  country: string;
  currency: string;
  customer: string;
  fingerprint: string;
  last4: string;
  metadata: BankMetadata;
  routing_number: string;
  status: string;
}

interface BankMetadata {}

export interface IStripeCard {
  card: Card;
}

export interface Card {
  brand: string;
  checks: Checks;
  country: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  generated_from: null;
  last4: string;
  networks: Networks;
  three_d_secure_usage: ThreeDSecureUsage;
  wallet: null;
}

export interface Checks {
  address_line1_check: null;
  address_postal_code_check: string;
  cvc_check: string;
}

export interface Networks {
  available: string[];
  preferred: null;
}

export interface ThreeDSecureUsage {
  supported: boolean;
}

export interface IPaymentTransaction {
  id: number;
  branchId: number;
  adminId: number;
  receiverId: number;
  applicationId: number;
  applicationStatus: number;
  accountId: number;
  amount: number;
  isAdded: boolean;
  balance: number;
  createdAt: string;
  transactionInfo: any;
  admin: IUser;
  receiver: IUser | null;
  application: IApplication | null;
}

export interface IReferUserDto {
  referredBy?: number | string;
  isBusiness?: boolean;
  jobId?: number; // used to find employee ID if referred by employee or navigate user to job. This is used along with referredBy.
  employeeId?: number; // used to find company / branch which referred their employee to create account. This links employee with their employer.
}

export interface IFacebookLoginDto extends IReferUserDto {
  email?: string;
  name?: string;
  photoURL?: string;
  accessToken?: string;
}

export interface IGoogleLoginDto extends IReferUserDto {
  googleId?: string;
  idToken: string;
}

export type TLoginType = "facebook" | "google" | "apple" | null;
