/*
 * File: CompanyProfilePage.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 9th March 2020 8:40:41 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Thursday, 31st December 2020 2:24:43 am
 * -----
 * Copyright 2020 - 2020 Allshore, https://allshorestaffing.com
 */

import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { SettingsContainer } from "./SettingsContainer";
import { CompanyForm } from "../Companies/CompanyForm";

// ! NOT IN USE ANYMORE BECAUSE OF NEW UI
export const CompanyProfilePage: React.FC = () => {

  return (
    <SettingsContainer>
      {/* <CompanyForm company={{id: "1"}} canManage={false} /> */}
    </SettingsContainer>
  );
};
