/*
 * File: EmployeeInterface.ts
 * Project: JobApp-Admin
 * File Created: Thursday, 6th May 2021 11:22:54 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 10:03:39 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { IApplication } from "./ApplicationInterface";
import { IUser } from "./AuthInterface";
import { IBranch } from "./CompanyInterface";

export enum EEmployeeRole {
  SuperAdmin = 'superAdmin', // super admin of this company
  Admin = 'admin', // employee manager of this company
  Noob = 'noob' // simple employee of this company with no access to business admin.
}
export interface IEmployeeBase {
  fullName: string;
  title?: string;
  email: string;
  phone?: string;
  role?: EEmployeeRole;
}

export interface IEmployee extends IEmployeeBase {
  id: number;
  userId?: number;
  branchId: number;
  __branch__: IBranch;
  invited?: boolean;
  user?: IUser;
}

export interface IEmployeeRefer {
  jobId: number;
  selectedEmps: number[];
  deactivateEmps: boolean; // true if you want to delete employees, false otherwise.
  settings: IReferSettings;
}

export interface IReferSettings {
  statusReward: IStatusReward[];
  postHireRewards: IPostHireReward[];
}

export interface IStatusReward {
  status: number; // 1 (applied) | 3 (accepted) | 4 (hired)
  reward: number; // amount in dollars
  active: boolean;
}

export interface IPostHireReward {
  daysAfterHire: number;
  reward: number,
  active: boolean;
}

export enum ERewardStatus {
  InProgress = 'in-progress', // days are not passed yet to be rewarded.
  Pending = 'pending', // ready to be rewarded.
  Rewarded = 'rewarded',
  Declined = 'declined', // admin cancelled the reward.
}

export enum ERewardType {
  BeforeHire = 'before-hire',
  PostHire = 'post-hire',
}

export interface IRewardTask {
  id:            number;
  applicationId: number;
  employeeId:    number;
  coinId:        number;
  dateHired:     string;
  rewardType:    ERewardType;
  incentiveDays: number;
  rewardAmount:  number;
  updatedAt:     string;
  createdAt:     string;
  application:   IApplication;
  coin:          ICoin;
  employee:      IEmployee;
}



export interface ICoin {
  id:          number;
  uId:         string;
  userId:      number;
  fromId:      number;
  amount:      number;
  balance:     number;
  description: null;
  isAdded:     boolean;
  action:      string;
  createdAt:   string;
}


