/*
 * File: BillingPage.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 25th December 2020 2:20:40 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 31st January 2022 9:45:32 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import styles from "./EditProfilePage.module.scss";
import { BillingManagementComponent } from "../billing/billing.component";
import { useHistory, useParams } from "react-router";
import { RouteKeys } from "../../../../Routes/RouteKeys";
import { checkUserAccess, TLinks } from "./EditProfilePage";
import { log } from "../../../../Lib/helpers";
import { EACL } from "../../../../Services/Interfaces/AuthInterface";
import { AppContext } from "../../../../Services/AppContext";

const BillingPage = () => {
  const { firstPage }: { firstPage: TLinks } = useParams();

  const { user, notify } = useContext(AppContext);

  const [selectedTab, setSelectedTab] = useState<TLinks>("");

  const isFirstTab = selectedTab === "company-profile" || selectedTab === "";

  const history = useHistory();

  const _navigate = (route: TLinks) => {
    setSelectedTab(route);
    history.push(`${RouteKeys.AdminCompany}/${route}`);
  };

  useEffect(() => {
    log("page is: ", firstPage);
    // resetAll();
    if (firstPage) {
      setSelectedTab(firstPage);
    }
  }, [firstPage]);

  const showCompanyProfile = () => {
    _navigate("company-profile");
  };
  const showCompanyCustomDyme = () => {
    _navigate("custom-dyme");
  };
  const showPaymentHistory = () => {
    _navigate("payment-history");
  };
  const showBilling = () => {
    _navigate("billing");
  };

  const showAdminsManagement = () => {
    _navigate("managers");
  };

  const checkAccess = (permission: EACL) => {
    const role = user?.user.role;

    return checkUserAccess(role, permission);
  };

  return (
    <div className={styles.EditProfilePage} data-testid="EditProfilePage">
      <div className="rt-wrapper">
        <div className="rt-profilepage">
          <div className="rt-themetabs">
            <h3 style={{ fontFamily: "proxima_novabold" }}>Company Account</h3>

            <ul
              className="rt-themenavtabs"
              style={{
                fontSize: "20px",
                paddingTop: "6px",
                fontFamily: "proxima_novaregular",
              }}
            >
              <li
                className={isFirstTab ? "rt-active" : ""}
                onClick={showCompanyProfile}
              >
                <button type="button">Company Profile</button>
              </li>
              <li
                className={selectedTab === "custom-dyme" ? "rt-active" : ""}
                onClick={showCompanyCustomDyme}
              >
                <button type="button">Custom Dyme</button>
              </li>
              <li
                className={selectedTab === "billing" ? "rt-active" : ""}
                onClick={showBilling}
              >
                <button type="button">Wallet</button>
              </li>
              <li
                className={selectedTab === "payment-history" ? "rt-active" : ""}
                onClick={showPaymentHistory}
              >
                <button type="button">Payment History</button>
              </li>
              {checkAccess(EACL.ABA) && (
                <li
                  className={selectedTab === "managers" ? "rt-active" : ""}
                  onClick={showAdminsManagement}
                >
                  <button type="button">Admin Users</button>
                </li>
              )}
            </ul>
            <hr style={{ marginTop: "45px" }} />
            <div className="rt-tabscontent">
              <div
                className={`rt-profiletab ${!isFirstTab ? "d-none" : ""}`}
              ></div>

              <BillingManagementComponent selectedTab={selectedTab} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BillingPage;
