/*
 * File: CompanyEditPage.tsx
 * Project: JobApp-Admin
 * File Created: Wednesday, 11th March 2020 1:45:18 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 29th September 2021 1:28:43 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { BaseAuth } from "../../../Components/BaseAuth";
import { PageHeader } from "../../../Components/PageHeader";
import Loader from "../../../Components/Loader";
import { companyLinks } from "./CompaniesListPage";
import { CompanyForm } from "./CompanyForm";
import { log } from "../../../Lib/helpers";
import { ICompany } from "../../../Services/Interfaces/CompanyInterface";
import { CompanyApi } from "../../../Services/API/CompanyApi";
import { ENotifyType } from "../../../Components/Notify";
import { handleApiError, handleCatchError } from "../../../Services/error-handling";
import { AppContext } from "../../../Services/AppContext";

interface LocationState {
  company?: ICompany
}

export const CompanyEditPage = () => {
  const history = useHistory()
  const {notify} = useContext(AppContext)

  //@ts-ignore
  const { id } = useParams();
  
  // const [company, setCompany] = useState({id})

  const location = useLocation<LocationState>()
  const [loading, setLoading] = useState(false)
  const [company, setCompany] = useState(location.state?.company)

  const _navigate = (route: string, data?: any) => {
    history.push({pathname: route, state: data});
  };

  useEffect(() => {
    log('got company: ', company)
    _getCompany()
  }, [])

  const _getCompany = async () => {

    if (!id) {
      return false;
    }
    setLoading(true);

    const response = await CompanyApi.GetCompany(id);

    let message: string = "Something went wrong";
    let errorType = ENotifyType.Error;

    try {
      if (response.hasError) {
        message = handleApiError(response, "Company Get: ");
      } else {
        log("Company Data: ", response);
        
        errorType = ENotifyType.Success;
        message = "Company Fetched"

        setCompany(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    notify({
      title: message,
      type: errorType
    });

    setLoading(false);
  }

  const _editCompany = () => {

  }

  return (
    <BaseAuth>
      <PageHeader heading="Edit Company" links={companyLinks} />
      {loading && company ? <Loader /> : <CompanyForm company={company} onSubmit={_editCompany} />}
    </BaseAuth>
  )
}