/*
 * File: ReactotronConfig.ts
 * Project: jobapp-admin
 * File Created: Monday, 3rd February 2020 7:36:19 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Monday, 16th March 2020 7:39:32 pm
 * -----
 * Copyright 2020 - 2020 Allshore, https://allshorestaffing.com
 */

import Reactotron from 'reactotron-react-js'
//@ts-ignore
import apisaucePlugin from 'reactotron-apisauce'

Reactotron
  .configure() // we can use plugins here -- more on this later
  .use(apisaucePlugin({
    // ignoreContentTypes: /^(image)\/.*$/i   // <--- a way to skip printing the body of some requests (default is any image)
  }))
  .connect() // let's connect!