/*
 * File: validation-error.component.tsx
 * Project: JobApp-Admin
 * File Created: Saturday, 12th December 2020 12:15:07 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Saturday, 12th December 2020 1:09:19 am
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */
import React from "react";
interface IProps {
  errors: any;
  name: any;
}
export const ValidationErrorComponent = (props: IProps) => {
  // console.log(props.errors[props.name]);

  return (
    <ul className={`${!props.errors[props.name] ? "d-none" : ""}`}>
      {(props.errors[props.name] || []).map((error: string, index: number) => (
        <li key={`user-validation-909-${index}`} className="text-danger">
          {error}
        </li>
      ))}
    </ul>
  );
};
