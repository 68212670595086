/*
 * File: BranchApi.ts
 * Project: JobApp-Admin
 * File Created: Monday, 13th July 2020 8:45:05 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Saturday, 10th July 2021 12:42:11 am
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import { IAddress, IBranch } from "../Interfaces/CompanyInterface";
import { ApiPost, ApiGet, ApiPut, ApiDelete } from "../api-client";
import { log } from "../../Lib/helpers";
import { BranchEditPage } from "../../Pages/Admin/Branches/BranchEditPage";

/**
 * Create a New Branch
 * 
 * @param userId User ID
 * @param name Name
 * @param description Description
 * @param address Address Object
 * @param isActive Active status
 */
const CreateBranch = async (
  branch:IBranch
) => {
  const data = await ApiPost(`branch`,branch);

  log("☁️ Create Branch RESPONSE: ", data);

  return data;
};

/**
 * Edit Branch
 * @param branchId - BranchID
 * @param branch - Branch Data Object
 */
const EditBranch = async (branchId:number|string, branch:IBranch) => {
  const data = await ApiPut(`branch/${branchId}`, {...branch})

  log("☁️ Update Branch RESPONSE: ", data);

  return data;
}

const GetBranches = async () => {
  const data = await ApiGet(`branch`)

  log("☁️ Get Branches RESPONSE: ", data);

  return data;
}

/**
 * Get Branch
 * @param branchId - Branch ID
 */
const GetBranch = async (branchId:number|string) => {
  const data = await ApiGet(`branch/${branchId}`)

  log("☁️ Get Branch RESPONSE: ", data);

  return data;
}


/**
 * Get Branch Public Profile
 * @param branchId - Branch ID
 */
 const GetBranchNoAuth = async (branchId:number|string) => {
  const data = await ApiGet(`branch-public/${branchId}`)

  log("☁️ Get Branch Public RESPONSE: ", data);

  return data;
}


/**
 * Delete/Remove Branch
 * @param branchId - Branch ID
 */
const DeleteBranch = async (branchId:number|string) => {
  const data = await ApiDelete(`branch/${branchId}`)

  log("☁️ Delete Branch RESPONSE: ", data);

  return data;
}

export const BranchApi = {
  CreateBranch,
  GetBranches,
  GetBranch,
  GetBranchNoAuth,
  EditBranch,
  DeleteBranch
}