/*
 * File: theme.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 4th February 2020 1:04:01 am
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:06 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import { createTheme as createMuiTheme, adaptV4Theme } from '@mui/material/styles';
import { AppColors } from './AppColors';
import { colors } from '@mui/material';


export const createTheme = (darkMode: boolean) => createMuiTheme(adaptV4Theme({
  palette: {
    mode: darkMode ? "dark" : "light",
    background: {
      default: darkMode ? AppColors.grey : AppColors.white,
    },
    primary: {
      main: darkMode ? AppColors.greyLight : AppColors.grey
    },
    secondary: {
      main: darkMode ? colors.grey[600] : AppColors.grey
    },
    
    text: {
      primary: darkMode ? AppColors.greyLight : AppColors.grey,
      secondary: darkMode ? AppColors.greyLight : AppColors.grey
    }
  },

  overrides: {
    MuiContainer: {
      root: {
        // backgroundColor: 'blue'
      },
    },
    MuiPaper: {
      // root: {
      //   backgroundColor: 'none'
      // }
    },
    MuiTypography: {
      root: {
        color: darkMode ? AppColors.greyLight : AppColors.grey,
      },
      h3: {
        textTransform: 'uppercase',
        letterSpacing: 2
      },
      h4: {
        textTransform: 'uppercase',
        letterSpacing: 2,
        fontWeight: 500
      }
    },
    MuiLink: {
      underlineHover: {
        textDecoration: 'none',
        textDecorationLine: 'none',
        cursor: 'pointer',
        // color: darkMode ? "#ffffff" : '#000000'
      },
      underlineAlways: {
        textDecorationLine: 'none'
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: AppColors.grey,
        color: 'white',
      },

      colorSecondary: {
        backgroundColor: 'blue'
      }
    },
    MuiButton: {
      containedPrimary: {
        // color: AppColors.grey,
        backgroundColor: darkMode ? colors.grey[600] : AppColors.grey,
        color: darkMode ? AppColors.white : AppColors.white
      },
      containedSecondary: {
        // color: AppColors.grey,
        backgroundColor: colors.grey[500],
        color: darkMode ? AppColors.white : AppColors.white
      },
      

    },
    MuiOutlinedInput: {
      colorSecondary: {
        color: darkMode ? AppColors.white : AppColors.grey
      }
    },
    MuiTableCell: {
      head: {
        textTransform: 'uppercase',
        fontWeight: 800
      }
    }
  }
}))