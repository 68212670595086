/*
 * File: FacebookLogin.tsx
 * Project: Dyme-Web
 * File Created: Monday, 15th November 2021 9:23:20 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 11th January 2022 2:34:36 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState } from "react";
import ReactFacebookLogin from "react-facebook-login";
import { images } from "../../../assets/images/images";
import { LoaderComponent } from "../../../Components/common/loader.component";
import { log } from "../../../Lib/helpers";
import { IApiResponse } from "../../../Services/api-client";
import { AuthApi } from "../../../Services/API/AuthApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import {
  IReferUserDto,
  TLoginType,
} from "../../../Services/Interfaces/AuthInterface";

interface IFacebookLogin {
  referData?: IReferUserDto;
  afterLogin: (data: any, fbLoginResponse: IApiResponse) => void;
  title?: string;
}
export default function FacebookLogin({
  afterLogin,
  referData,
  title
}: IFacebookLogin) {
  const [loading, setLoading] = useState(false);
  const _onClick = () => {
    setLoading(true);
    log("fb click");
  };

  const _onFbResponse = async (res: any) => {
    log("FB Response: ", res);

    if (res.userID) {
      try {
        const response = await AuthApi.FacebookLogin({
          email: res.email,
          name: res.name,
          photoURL: res.picture?.data?.url,
          accessToken: res.accessToken,
          ...referData,
        });
        if (response.hasError) {
          handleApiError(response, "fb-login");

          // message = response.data.message;
        } else {
          // ? Facebook Login success
          log("FB LOGIN SUCCESS: ", response);

          afterLogin(null, response);
        }
      } catch (e) {
        handleCatchError(e);
      }
    }

    setLoading(false);
  };

  return (
    <>
      <ReactFacebookLogin
        appId="1277304422694190"
        autoLoad={false}
        fields="name,email,picture"
        onClick={_onClick}
        callback={_onFbResponse}
        textButton={title}
        containerStyle={{
          flex: 1,
        }}
        buttonStyle={{
          borderRadius: 40,
          width: "99%",
          backgroundColor: "white",
          color: "#000",
          border: "1px solid white",
        }}
        size="medium"
        // cssClass="login_button facebook"
        // icon="fa-facebook-square"
        icon={
          <img
            src={images.Facebook}
            style={{ marginRight: 10, width: 30 }}
            alt="login with facebook"
          />
        }
      />
      {loading ? <LoaderComponent /> : null}
    </>
  );
}


FacebookLogin.defaultProps = {
  title: "Sign up with Facebook"
}