/*
 * File: ReactHooksDemo.tsx
 * Project: Dyme-Web
 * File Created: Friday, 26th November 2021 5:27:50 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 29th November 2021 10:23:03 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React, { useState, useEffect, useCallback } from "react";
import { log } from "../../../Lib/helpers";
import { ApiGet } from "../../../Services/api-client";
import styles from "./react-hooks.module.scss";

interface IAddr {
  id: number;
  city: string;
  full_address: string;
}

// useCallback usage to prevent extra re-rendering
// appending on array with proper update state without using deepClone

export const ReactHooksDemo = () => {
  
  const [addresses, setAddresses] = useState<IAddr[]>([]);

  const _getData = useCallback(async () => {
    const response = await ApiGet("https://random-data-api.com/api/address/random_address")

    log("addr data: ", response);

    const newAddr = response.data;
    if (newAddr) {

      setAddresses(oldAddr => {
        return [...oldAddr, newAddr]
      });
    }
  }, []);

  useEffect(() => {
    _getData();
    const interval = setInterval(() => {
      _getData();
    }, 5000);

    return () => clearInterval(interval);
  }, [_getData]);

  return (
    <div className={styles.container}>
      <h1>React Hooks Demo</h1>
      <p>This will print new address every 5 seconds...</p>
      {addresses.map((addr) => {
        return <pre key={addr.id}>{addr.full_address}</pre>;
      })}
    </div>
  );
};
