/*
 * File: ChatPage.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 21st May 2021 5:30:09 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 21st May 2021 5:45:32 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState } from 'react';
import { images } from '../../../assets/images/images';
import styles from './ChatPage.module.scss';
import Modal from 'react-bootstrap/Modal'
import ButtonComponent from '../../../Components/common/ButtonComponent/ButtonComponent';

const ChatPage: React.FC = () => {
	const [loading, setLoading] = useState(false);
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	return <div className={styles.ChatPage} data-testid="ChatPage">
		<div className="rt-wrapper">
			<div className="rt-chat">
				<div className="rt-pagetitle"><h1>Chat / Messages</h1></div>
				<div className="rt-chatbox">
					<aside className="rt-chatsidebar">
						<div className="rt-sidebarhead">
							<h3>Messages</h3>
							<button className="rt-btnfilter" type="button"><img src={images.FilterImage} alt="Filter Icon" /></button>
							<form className="rt-formtheme rt-formsearch">
								<fieldset>
									<div className="form-group">
										<i className="icon-search1"></i>
										<input type="search" name="search" className="form-control" placeholder="Search for messages…" />
									</div>
								</fieldset>
							</form>
						</div>
						<ul className="rt-chatusers">
							<li>
								<div className="rt-user rt-unreadmessage">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
							<li>
								<div className="rt-user">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
							<li>
								<div className="rt-user">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
							<li>
								<div className="rt-user">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
							<li>
								<div className="rt-user">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
							<li>
								<div className="rt-user">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
							<li>
								<div className="rt-user">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
							<li>
								<div className="rt-user">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<figure className="rt-userimg">
										<img src={images.UserImg1} alt="User Image" />
									</figure>
									<div className="rt-usernamemessage">
										<h3>Susan May</h3>
										<p>Inner and outer peace</p>
									</div>
								</div>
							</li>
						</ul>
					</aside>
					<div className="rt-chatpanel">
						<div className="rt-chathead">
							<div className="rt-userjobinfo">
								<figure className="rt-jobuserimg">
									<img src={images.UserImg1} alt="User Image" />
								</figure>
								<div className="rt-jobuserinfo">
									<h4>Dalton Payne</h4>
									<span>Foreign Exchange Trader</span>
									<ul>
										<li>
											<span>North American Technology</span>
										</li>
										<li>
											<span>421 North Vermont Drive</span>
										</li>
									</ul>
								</div>
							</div>
							<ButtonComponent onClick={handleShow} type="button" text="" className="rt-btnjobcard">Job Card</ButtonComponent>
						</div>
						<div className="rt-chatholder">
							<div className="rt-chatmessages">
								<div className="rt-message rt-sendermessage">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<p>Inner and outer peace</p>
								</div>
								<div className="rt-message">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<p>Inner and outer peace</p>
								</div>
								<div className="rt-message rt-sendermessage">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<p>I am attaching a new file for you!</p>
								</div>
								<div className="rt-chatfile">
									<button type="button" className="rt-btndownloadfile"><i className="icon-down-arrow-2"></i></button>
									<div className="rt-filenamesize">
										<h3>File Attachment in process.pdf</h3>
										<span>PDF - 225KB</span>
										<div className="rt-fileprogressbar">

										</div>
									</div>
								</div>
								<div className="rt-message">
									<time className="rt-messagetime" dateTime="2020-12-12">1:05 pm</time>
									<p>I tend to be the peacemaker between friends</p>
								</div>
							</div>
							<form className="rt-formtheme rt-formsendchat">
								<fieldset>
									<div className="form-group">
										<i className="icon-type"></i>
										<textarea placeholder="Type your message here"></textarea>
										<div className="rt-attachfiles">
											<input type="file" name="file" id="rt-selectfiles" />
											<label htmlFor="rt-selectfiles"><i className="icon-attachment1"></i></label>
										</div>
									</div>
								</fieldset>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Modal className="rt-thememodal rt-modaljob" show={show} onHide={handleClose}>
			{/* <Modal.Header closeButton className="rt-modalheader">
          <Modal.Title>Delete Candidate</Modal.Title>
        </Modal.Header> */}
			<Modal.Body>
				<div className="rt-jobpreviewarea">
					<ul className="rt-btnactions">
						<li>
							<button type="button"><i className="icon-delete"></i></button>
						</li>
						<li>
							<button type="button"><i className="icon-edit"></i></button>
						</li>
						<li>
							<button type="button"><i className="icon-cancel"></i></button>
						</li>
					</ul>
					<div className="rt-jobpreview">
						<div className="rt-johead">
							<div className="rt-applicationscount"><em>1,083</em><span>Applicants</span></div>
							<figure className="rt-conpmanylogo"><img src={images.CompanyLogo} alt="Company Logo" /></figure>
							<h3 className="rt-jobname">Princess Hotels Sales <span>Representative</span></h3>
							<ul className="rt-jobinfotags">
								<li>
									<span className="rt-bghourly"><i className="icon-hourly-wage"></i><em>Hourly Wage</em></span>
								</li>
								<li>
									<span className="rt-bgonsite"><i className="icon-onsite"></i><em>Onsite</em></span>
								</li>
								<li>
									<span className="rt-bgfulltime"><i className="icon-full-time"></i><em>Full Time</em></span>
								</li>
								<li>
									<span className="rt-bgsales"><i className="icon-sales"></i><em>Sales</em></span>
								</li>
								<li>
									<span className="rt-bgprice"><i className="icon-dollar"></i><em>$12,000 - $14,000</em></span>
								</li>
							</ul>
							<div className="rt-joblocation">
								<i className="icon-jobs"></i>
								<div className="rt-jobaddress">
									<h4>Rochester, NY</h4>
									<p>Corporate Headquarters: 343 Business Place, Suite 314</p>
									<span>866-996-3837</span>
								</div>
							</div>
							<div className="rt-jobmaparea">
								<figure className="rt-mapplaceholder">
									<img src={images.MapPlaceholder} alt="Map Placeholder"></img>
								</figure>
							</div>
							<div className="rt-jobinoarea">
								<div className="rt-jobinbox">
									<h4>Job Description</h4>
									<div className="rt-description">
										<p>I’m looking for someone to watch our baby boy 3 times this week. Must have experience working with babies.</p>
									</div>
								</div>
								<div className="rt-jobinbox">
									<h4>Job Requirements</h4>
									<ul>
										<li>
											<span>6 Months work experience</span>
										</li>
										<li>
											<span>Know the principles of animation and you can create high fidelity prototypes.</span>
										</li>
										<li>
											<span>Direct working experience using Adobe After
												Effects, Adobe Premiere and Adobe Photoshop.</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	</div>
};

export default ChatPage;