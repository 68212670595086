/*
 * File: CompanyInterface.ts
 * Project: JobApp-Admin
 * File Created: Tuesday, 10th March 2020 12:21:21 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 2nd February 2022 12:50:21 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import { Config } from "../../Config";
import { ICoordinates } from "./JobInterface";

export interface ICompany {
  id?: number | string;
  userId?: number | string;
  name: string;
  description: string;
  address: IAddress;
  logo?: string;
  img?: string;
  isActive: boolean;
  branches?: IBranch[];
}

export interface IBranch {
  id?: number | string;
  companyId?: number | string;
  userId?: number | string;
  name: string;
  // address: IAddress;
  addresses: IAddress[];
  logo?: string;
  dymeLogo?: string | null;
  isActive?: boolean;
}

export interface IAddress {
  title?: string;
  address: string;
  city: string;
  state?: string;
  zipCode?: string;
  country?: string;
  latitude: number | string;
  longitude: number | string;
}

export type TCompany = ICompany | undefined;
export type TBranch = IBranch | undefined;

export const defaultAddress = {
  address: "",
  city: "",
  latitude: Config.DEFAULT_COORDINATES.lat,
  longitude: Config.DEFAULT_COORDINATES.lng,
  country: "",
  state: "",
  title: "",
  zipCode: "",
};
