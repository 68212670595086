/*
 * File: Pagination.component.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 1st December 2020 12:42:02 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 8th January 2021 11:57:23 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import React from 'react'
import { Pagination } from 'react-bootstrap'
import { IPaginate } from '../../../Services/Interfaces';

interface IProps extends IPaginate<any> {
    onClick(page: number): void
}

export const PaginationComponent = (data: IProps) => {
    
    const items = () => {
        let _items = [];
        for (let index = 1; index <= data.pageCount; index++) {
            _items.push(  <Pagination.Item key={index} onClick={() => data.onClick(index)} active={ index=== data.page }>{index}</Pagination.Item>)
        }
        return _items;
    }

    const next = () => data.onClick(data.page+1)

    const prev = () => data.onClick(data.page-1)

    const first = () => data.onClick(1)
    const last = () => data.onClick(data.pageCount)

    return (
        data.pageCount > 1 ? <Pagination>
            {
                data.page > 1 && (
                    <>
                        <Pagination.First onClick={first}/>
                        <Pagination.Prev onClick={prev}/>
                    </>
                )
            }
            {
                items()
            }
            {
                data.page < data.pageCount && (
                    <>
                        <Pagination.Next onClick={next}/>
                        <Pagination.Last onClick={last}/>
                    </>
                )
            }
        </Pagination> : <></>
    )
}
