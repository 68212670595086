/*
 * File: ReferralProgram.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 20th May 2021 6:31:32 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Sunday, 24th April 2022 12:57:11 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  TextField,
  TextFieldProps,
  TableCell,
  TableRow,
  PaginationItem,
} from "@mui/material";

import Loader from "../../../Components/Loader";

import makeStyles from "@mui/styles/makeStyles";
import { EmployeesTableNew } from "./EmployeesTableNew";
import {
  IPaginate,
  ISelect,
  defaultPaginatedData,
  beforeHire,
  postHire,
} from "../../../Services/Interfaces";

import { useFormik } from "formik";
import { AppContext } from "../../../Services/AppContext";
import { ApiResponse } from "../../../Services/api-client";
import { ENotifyType } from "../../../Components/Notify";
import MessageBox from "./Message";
import {
  EEmployeeRole,
  IEmployee,
  IEmployeeBase,
  IEmployeeRefer,
  IPostHireReward,
  IStatusReward,
} from "../../../Services/Interfaces/EmployeeInterface";
import { EmployeesApi } from "../../../Services/API/EmployeesApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import * as Yup from "yup";

import { IBranch } from "../../../Services/Interfaces/CompanyInterface";

import { log } from "../../../Lib/helpers";
import { RewardsApi } from "../../../Services/API/RewardApi";
import Button from "@mui/material/Button";
import { ImportEmployees } from "./ImportEmployees";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faPlus, faInbox } from "@fortawesome/free-solid-svg-icons";

import NumberFormat from "react-number-format";
import Pagination from "@mui/material/Pagination";
import { useDollarValue } from "../../../Services/useDollarValue";
import { GiftCoinModal } from "./GiftCoinModal";
import { AuthApi } from "../../../Services/API/AuthApi";

export type TReferSaveType = "save" | "invite" | "deactivate";

// const useStyles = makeStyles({
//   tableRow: {
//     height: 30
//   },
//   tableCell: {
//     padding: "0px 16px"
//   }
// });
interface IMenuBtn {
  id: number | null;
  anchorEl: null;
}

const titleList = [
  { label: "All", id: "title" + 1, checked: false, type: "title" },
  { label: "Call Center Rep", id: "title" + 2, checked: false, type: "title" },
];
const statusList = [
  { label: "Invited", id: "status" + 1, checked: false, type: "status" },
  { label: "Active", id: "status" + 2, checked: false, type: "status" },
  { label: "Not Active", id: "status" + 1, checked: false, type: "status" },
  { label: "Not Invited", id: "status" + 2, checked: false, type: "status" },
];

const headerCellsData = [
  {
    id: "Name",
    label: "Name",
  },
  { id: "Title", label: "Title" },
  { id: "Mobile", label: "Mobile" },
  {
    id: "EmailAddress",
    label: "Email Address",
  },

  { id: "status", label: "Account Status" },
  { id: "Rewards", label: "Rewards" },
  {
    id: "invite",
    label: "",
  },
  {
    id: "extraData",
    label: "",
  },
];
const beforeHireDefaultData = [
  {
    label: "Employee Friend Viewed Job",
    reward: 0,
    id: "before1",
    status: 0,
    active: false,
  },
  {
    label: "Employee Friend Applied",
    reward: 0,
    id: "before2",
    status: 1,
    active: false,
  },
  {
    label: "Employee Friend App Accepted",
    reward: 0,
    id: "before3",
    status: 3,
    active: false,
  },
  {
    label: "Employee Friend Hired",
    reward: 0,
    id: "before4",
    status: 4,
    active: false,
  },
];
const POST_HIRE_DATA: postHire[] = [
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post1",
  },
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post2",
  },
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post3",
  },
  {
    daysAfterHire: 1,
    reward: 0,
    active: false,
    id: "post4",
  },
];

type dataType = keyof typeof DEFAULT_DATA;

const DEFAULT_DATA: IEmployeeBase = {
  fullName: "",
  title: "",
  email: "",
  phone: "",
  role: EEmployeeRole.Noob,
};
let DEFAULT_SELECT = { value: "", label: "" };

type TOrderBy = "id" | "status" | "createdAt";

const EmployeesPage = () => {
  const [loading, setLoading] = useState({
    addEmployee: false, // table hook
    saveData: false, // table hook
    inviteAll: false,
    invite: false, // table hook but skip ----
    fetchEmployee: false, // table loading hook ----
    fetchJobs: false,
  });

  const [toggle, setToggle] = React.useState(false);

  const [selectedEmpForGift, setSelectedEmpForGift] =
    useState<IEmployee | null>(null);
  const [referType, setReferType] = useState("");
  const [create, setCreate] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);

  // table hook ----
  const [orderBy, setOrderBy] = useState<TOrderBy>("id");

  // table hook ----
  const [employees, setEmployees] =
    useState<IPaginate<IEmployee>>(defaultPaginatedData);

  // table check box hook -------
  const [checked, setChecked] = useState({
    title: titleList,
    status: statusList,
  }) as any;

  const classes = useStyles();

  // table hook ----
  const [editableEmpId, setEditableEmpId] = useState(0);
  const { dollarLoading, dollarValue, getCoinSettings } = useDollarValue();
  const [inviteStatus, setInviteStatus] = useState(false);
  const [beforeHireData, setBeforeHireData] = useState(beforeHireDefaultData);
  const [postHireData, setPostHireData] = useState(POST_HIRE_DATA);
  const [selectedJob, setSelectedJob] = useState<ISelect>(DEFAULT_SELECT);
  const [selectedJobEmployees, setSelectedJobEmployees] = useState([]);
  const [statusReward, setStatusReward] = useState([]);
  const [companyInfo, setCompanyInfo] = useState<IBranch | undefined>();

  // table hooks ------
  const [selectedEmployees, setSelectedEmployees] = React.useState([]) as any;

  // const [message, setMessage] = useState("");

  const { user } = useContext(AppContext);
  // table hook ---------
  const [showNewRow, setShowNewRow] = useState(false);
  const [data, setData] = useState({
    ...DEFAULT_DATA,
  });

  // table import employee----
  const [showEmpImport, setShowEmpImport] = useState(false);

  // table method -------
  const _showNewRow = () => {
    _resetForm();
    setEditableEmpId(0);
    setShowNewRow(true);
  };

  // table method -------
  const _hideFormRow = () => {
    _resetForm();
    setEditableEmpId(0);
    setShowNewRow(false);
  };
  const { notify } = useContext(AppContext);

  useEffect(() => {
    if (editableEmpId) {
      // find the employee by selected id
      // update data form
      try {
        const index = employees.data.findIndex((e) => e.id === editableEmpId);
        const { email, fullName, title, phone, role } = employees.data[index];
        setData({
          ...data,
          email,
          fullName,
          title,
          phone,
          role,
        });
      } catch (e) {}
    } else {
      _resetForm();
    }
  }, [editableEmpId]);

  useEffect(() => {
    _fetchAllEmployees();
    _fetchCompanyProfile();
  }, []);

  useEffect(() => {
    if (selectedEmpForGift) {
      setShowCoinBox(true);
    } else {
      setShowCoinBox(false);
    }
  }, [selectedEmpForGift]);

  const handleEditBox = (val: boolean) => {
    setToggle(val);
  };

  const [updateTemplate, setUpdateTemplate] = useState(false);

  const _resetForm = () => {
    setData({ ...DEFAULT_DATA });
  };

  const handleMessageClick = (type: any) => {
    setReferType("");
    if (type === "create") {
      log("opening create template box..");
      setCreate(true);
    } else {
      if (selectedEmployees.length) {
        setOpenMessage(true);
      } else {
        alert("Please select Employees first.");
      }
    }
  };

  // table method ------
  const _editEmployee = (id = 0) => {
    setEditableEmpId(id);
  };

  // table method ------
  const _deleteEmployee = async (id = 0) => {
    // // setLoading(true);
    try {
      const response = await EmployeesApi.DeleteEmployee(id);
      if (response.hasError) {
        handleApiError(response, "Employee Delete: ");
      } else {
        // ? Delete Employee success
        log("Employee Delete SUCCESS: ", response);
        _fetchAllEmployees(employees.page);
      }
    } catch (e) {
      handleCatchError(e);
    }
    // // setLoading(false);
  };

  const _fetchAllEmployees = async (page = 1, keyword = "") => {
    setLoading({ ...loading, fetchEmployee: true });
    try {
      const response = await EmployeesApi.GetEmployees({
        page,
        orderBy,
        keyword,
      });
      if (response.hasError) {
        handleApiError(response, "Employees List: ");
      } else {
        // ? Get Employees success
        log("Employees List ALL SUCCESS: ", response);

        setEmployees(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading({ ...loading, fetchEmployee: false });
  };

  const _fetchCompanyProfile = async () => {
    try {
      const response = await AuthApi.GetCompanyProfile();
      if (response.hasError) {
        handleApiError(response, "COMPANY PROFILE: ");
      } else {
        log("COMPANY PROFILE SUCCESS: ", response);
        setCompanyInfo(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }
  };

  const isValidPhoneNumber = (phone: string) => {
    let phoneRegex =
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;
    if (phone.match(phoneRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const formik = useFormik({
    initialValues: data,
    validationSchema: Yup.object({
      fullName: Yup.string().required("Employee Name required"),
      // title: Yup.string().required("Job Title required"),
      email: Yup.string().email().required("Email required"),
      // phone: Yup.string().required("Phone number required"),
    }),
    enableReinitialize: true,

    onSubmit: async (values) => {
      log("emp values: ", values);
      //let phoneVal = values.phone?.replace(/\s/g, "");
      let phoneVal = values.phone?.split("-").join("");
      let payload = values;
      //console.log("Payload", payload);
      if (phoneVal && isValidPhoneNumber(phoneVal)) {
        payload = { ...values, phone: phoneVal };
      } else {
        payload = { ...values, phone: "" };
      }
      /**
       * After employee Created, remove that row, pull all employees again
       */

      setLoading({ ...loading, fetchEmployee: true });

      // const { name, address, city, isActive } = values;
      let response: ApiResponse;

      if (editableEmpId) {
        //edit employee
        response = await EmployeesApi.EditEmployee(editableEmpId, {
          ...payload,
        });
      } else {
        //create new employee
        response = await EmployeesApi.CreateEmployee({
          ...payload,
        });
      }

      let message: string = "Something went wrong";
      let errorType = ENotifyType.Error;

      try {
        if (response.hasError) {
          message = handleApiError(response, "Employee Form SCREEN: ");
        } else {
          log("Create Employee SUCCESS: ", response);
          message = `Employee ${editableEmpId ? "Updated" : "Created"}!`;
          errorType = ENotifyType.Success;

          _fetchAllEmployees(); // fetch employees

          _hideFormRow();
          // clear form or hide

          // response.data;
        }
      } catch (e) {
        handleCatchError(e);
      }

      notify({
        title: message,
        type: errorType,
      });

      setLoading({ ...loading, fetchEmployee: false });
    },
  });

  const {
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    handleSubmit, // table submit method ----
  } = formik;

  // table method --------
  const defaultInputProps = (key: dataType): TextFieldProps => {
    return {
      value: values[key],
      onChange: handleChange,
      error: touched[key] && !!errors[key],
      onBlur: handleBlur,
      variant: "standard",
      margin: "dense",
      required: true,
      fullWidth: true,
      name: `${key}`,
      helperText: errors[key],
      onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
          handleSubmit();
        }
      },
    };
  };

  const [menuBtn, setMenuBtn] = useState<IMenuBtn>({
    id: null,
    anchorEl: null,
  });

  const closeRowMenu = () => {
    setMenuBtn({ id: null, anchorEl: null });
  };
  const [show, setShowConfirm] = useState(false);
  const [deleteEmpId, setDeleteEmpId] = useState(0);

  const _handleEditDelete = (id: number, type: string) => {
    if (type === "edit") {
      _editEmployee(id);
    } else {
      setShowConfirm(true);
      setDeleteEmpId(id);
    }
    closeRowMenu();
  };

  const isSelected = (name: number) => selectedEmployees.indexOf(name) !== -1;

  const handleToggle = (e: any, id: number) => {
    setMenuBtn({
      anchorEl: e.currentTarget,
      id: id,
    });
  };

  const handleConfirm = () => {
    _deleteEmployee(deleteEmpId);
    setShowConfirm(false);
  };

  const renderFormRow = () => {
    return (
      <TableRow className={classes.tableRow}>
        <TableCell />
        <TableCell
          align="center"
          className={classes.tableCellSecondary}
          data-title="Name"
        >
          <div className="rt-applicantimgname">
            <TextField
              inputProps={{
                min: 0,
                style: {
                  textAlign: "center",
                  fontFamily: "'Montserrat', sans-serif",
                  backgroundColor: "#fff",
                  fontSize: 12,
                },
              }}
              variant="outlined"
              placeholder="Full Name"
              autoComplete="off"
              {...defaultInputProps("fullName")}
              size="small"
              //InputProps={{ disableUnderline: true }}
            />
          </div>
        </TableCell>
        <TableCell
          align="center"
          className={classes.tableCellSecondary}
          data-title="Title"
        >
          <TextField
            inputProps={{
              min: 0,
              style: {
                textAlign: "center",
                fontFamily: "'Montserrat', sans-serif",
                backgroundColor: "#fff",
                fontSize: 12,
              },
            }}
            variant="outlined"
            placeholder="Job Title"
            {...defaultInputProps("title")}
            required={false}
            size="small"
            //InputProps={{ disableUnderline: true }}
          />
        </TableCell>
        <TableCell
          align="center"
          className={classes.tableCellSecondary}
          data-title="Phone"
        >
          {/* <input {...defaultInputProps("phone")} /> */}

          <NumberFormat
            //variant="outlined"
            placeholder="Mobile Number"
            customInput={TextField}
            format="###-###-####"
            // format="+## (###) ###-####"
            allowEmptyFormatting
            inputProps={{
              min: 0,
              style: {
                textAlign: "center",
                fontFamily: "'Montserrat', sans-serif",
                backgroundColor: "#fff",
                fontSize: 12,
              },
            }}
            mask="_"
            onChange={handleChange}
            //{...defaultInputProps("phone")}
          />
        </TableCell>
        <TableCell
          align="center"
          className={classes.tableCellSecondary}
          data-title="Email"
        >
          <TextField
            inputProps={{
              min: 0,
              style: {
                textAlign: "center",
                fontFamily: "'Montserrat', sans-serif",
                backgroundColor: "#fff",
                fontSize: 12,
              },
            }}
            variant="outlined"
            placeholder="Email"
            {...defaultInputProps("email")}
            required={false}
            size="small"
            //InputProps={{ disableUnderline: true }}
          />
        </TableCell>
        <TableCell align="center" className={classes.tableCellSecondary} />{" "}
        {/* status is not required in form */}
        <TableCell align="center" className={classes.tableCellSecondary}>
          <span
            style={{ cursor: "pointer", marginRight: 20 }}
            onClick={_hideFormRow}
          >
            <i className="icon-cross" style={{ fontSize: 12 }}></i>
          </span>
          <span style={{ cursor: "pointer" }} onClick={() => handleSubmit()}>
            <i className="icon-checkmark" style={{ fontSize: 12 }}></i>
          </span>
        </TableCell>
        <TableCell align="center" className={classes.tableCellSecondary} />
      </TableRow>
    );
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = employees?.data?.map((n, i) => n.id);
      setSelectedEmployees(newSelecteds);
      return;
    }
    setSelectedEmployees([]);
  };

  const handleSelectEmployee = (event: any, name: number) => {
    const selectedIndex = selectedEmployees.indexOf(name);
    let newSelected: Array<number> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEmployees, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedEmployees.slice(1));
    } else if (selectedIndex === selectedEmployees.length - 1) {
      newSelected = newSelected.concat(selectedEmployees.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedEmployees.slice(0, selectedIndex),
        selectedEmployees.slice(selectedIndex + 1)
      );
    }

    setSelectedEmployees(newSelected);
  };

  const _toggleEmpImportPopup = () => {
    setShowEmpImport(!showEmpImport);
  };

  const _onSearch = (keyword: string) => {
    _fetchAllEmployees(1, keyword);
  };

  const [showCoinBox, setShowCoinBox] = useState(false);

  const _giftMultipleEmployees = () => {
    if (!selectedEmployees.length) {
      alert("Please select employees first to gift dymes.");
      return;
    }

    setShowCoinBox(true);
  };

  const _closeGiftBox = () => {
    log("closing gift box");
    // setShowCoinBox(false);

    setSelectedEmpForGift(null);
    setShowCoinBox(false);
  };

  const _fetchReferralSettings = async (id: number) => {
    setLoading({ ...loading, fetchJobs: true });

    try {
      const response = await RewardsApi.GetReferSettings(id);
      if (response.hasError) {
        handleApiError(response, "GET REFERRAL SETTINGS: ");
        setSelectedJobEmployees([]);
        setStatusReward([]);
        setBeforeHireData(beforeHireDefaultData);
      } else {
        // ? Get Data success
        log("GET REFERRAL SETTINGS SUCCESS: ", response);
        const settings = response.data.settings;
        const { statusReward, postHireRewards } = settings;
        let newBeforeHireData: Array<beforeHire> = [];
        beforeHireData.forEach((val, i) => {
          for (let i = 0; i < statusReward.length; i++) {
            if (val.status === statusReward[i].status) {
              newBeforeHireData.push({
                ...val,
                active: statusReward[i].active,
                status: statusReward[i].status,
                reward: statusReward[i].reward,
              });
              break;
            }
          }
        });

        let newPostHireData: IPostHireReward[] = [];
        postHireData.forEach((val, i) => {
          for (let i = 0; i < postHireRewards.length; i++) {
            if (val.daysAfterHire === postHireRewards[i].daysAfterHire) {
              newPostHireData.push({
                ...val,
                daysAfterHire: postHireRewards[i].daysAfterHire,
                reward: postHireRewards[i].reward,
                active: postHireRewards[i].active,
              });
              break;
            }
          }
        });

        setStatusReward(statusReward);
        setBeforeHireData(newBeforeHireData);
        setPostHireData(postHireRewards);
        setSelectedJobEmployees(response.data.selectedEmps);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading({ ...loading, fetchJobs: false });
  };

  const _updateReferralSettings = async (
    type?: TReferSaveType,
    employeeId?: number
  ) => {
    const statusReward: Array<IStatusReward> = [];
    beforeHireData.forEach((data, i) => {
      statusReward.push({
        status: data.status,
        reward: data.reward,
        active: data.active,
      });
    });

    const postHireRewards: Array<IPostHireReward> = [];

    postHireData.forEach((data, i) => {
      postHireRewards.push({
        daysAfterHire: data.daysAfterHire,
        reward: data.reward,
        active: data.active,
      });
    });

    let status = false;
    const selectedEmps = employeeId ? [employeeId] : selectedEmployees;
    if (employeeId) {
      setLoading({ ...loading, invite: true });
    } else {
      if (type === "save") {
        setLoading({ ...loading, saveData: true });
      } else if (type === "invite") {
        setLoading({ ...loading, inviteAll: true });
      }
    }
    const referSettings: IEmployeeRefer = {
      jobId: selectedJob.value,
      selectedEmps: selectedEmps,
      deactivateEmps: type === "deactivate" && selectedJob.value ? true : false,
      settings: {
        statusReward,
        postHireRewards,
      },
    };
    try {
      const response = await RewardsApi.UpdateReferSettings(referSettings);
      if (response.hasError) {
        handleApiError(response, "UPDATE REFERRAL SETTINGS: ");
        status = false;
      } else {
        // ? Get Jobs success
        log("UPDATE REFERRAL SETTINGS SUCCESS: ", response);
        _fetchReferralSettings(selectedJob.value);
        setSelectedEmployees([]);
        status = true;
      }
    } catch (e) {
      handleCatchError(e);
      status = false;
    }
    setLoading({
      ...loading,
      saveData: false,
      inviteAll: false,
      invite: false,
    });
    return status;
  };

  const handleInvite = (val: boolean, id?: number) => {
    setOpenMessage(val);
    setReferType("invite");
    if (id) {
      setSelectedEmployees([id]);
    }
  };

  const _giftSingleEmp = (emp: IEmployee) => {
    log("showing gift box");
    closeRowMenu();

    setSelectedEmpForGift(emp);
  };

  return (
    <>
      <div
        //className = "flex flex-row mt-24 ml-24 px-4"
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 126,
          marginLeft: 120,
          paddingRight: 10,
        }}
      >
        <div>
          <h4
            //className = " font-bold text-gray-600"
            style={{
              color: "#6b6b6b",
              fontWeight: 600,
              fontFamily: "'Montserrat', sans-serif",
            }}
          >
            Employees
          </h4>
        </div>
        {loading.fetchEmployee && (
          <Loader
            hidemodal
            size={18}
            style={{
              marginTop: 4,
              marginLeft: 8,
              marginBottom: 0,
              opacity: loading.fetchEmployee ? 1 : 0,
            }}
          />
        )}
      </div>

      <div
        //className = " mt-8 ml-24 px-4 mr-24 flex flex-row justify-between"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 15,
          marginLeft: 112,
          marginRight: 112,
          fontFamily: "'Montserrat', sans-serif",
        }}
      >
        <div
          //className = "flex flex-row"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div
            //className = "flex flex-row border border-gray-400 px-2 py-1 rounded-sm "
            style={{
              display: "flex",
              flexDirection: "row",
              borderStyle: "solid",
              borderWidth: 1,
              borderColor: "#CED9E0",
              paddingLeft: 8,
              paddingRight: 8,
              borderRadius: 4,
              marginLeft: 8,
            }}
          >
            <div style={{ marginTop: 4 }}>
              <FontAwesomeIcon icon={faSearch} color={"#4B6B73"} />
            </div>
            <input
              className="employee-search"
              id="employee-search"
              style={{
                width: 264,
                outline: "none",
                fontFamily: "'Montserrat', sans-serif",
                backgroundColor: "#fff",
                marginLeft: 6,
                color: "#4B6B73",
                fontWeight: 600,
                border: "none",
              }}
              placeholder="Search Employees"
              onChange={(e) => {
                _onSearch(e.target.value);
              }}
            />
          </div>

          <div
            //className = "flex flex-row ml-7 mt-1 cursor-pointer"

            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: 24,
              marginTop: 8,
              cursor: "pointer",
            }}
            onClick={_showNewRow}
          >
            <FontAwesomeIcon
              //className = " mr-2"
              style={{ marginRight: 8 }}
              icon={faPlus}
              color="#4B6B73"
            />
            <div
              //className = "font-semibold text-xs"
              style={{ fontWeight: 600, fontSize: 12, color: "#4B6B73" }}
            >
              Add Employees
            </div>
          </div>

          <div
            // className = "flex flex-row ml-7 mt-1 cursor-pointer"
            style={{
              display: "flex",
              flexDirection: "row",
              marginLeft: 24,
              marginTop: 8,
              cursor: "pointer",
            }}
            onClick={_toggleEmpImportPopup}
          >
            <FontAwesomeIcon
              //className = " mr-2"
              style={{ marginRight: 8 }}
              icon={faInbox}
              color="#4B6B73"
            />
            <div
              //className = "font-semibold text-xs"
              style={{ fontWeight: 600, fontSize: 12, color: "#4B6B73" }}
            >
              Import Employees
            </div>
          </div>
        </div>

        {/**Send Message and Gift Buttons */}
        <div
          //className = "flex flex-row"
          style={{ display: "flex", flexDirection: "row" }}
        >
          <div
            //className = "font-semibold mr-2"
            style={{
              padding: 8,
              borderRadius: 4,
              color: "#fff",
              backgroundColor: "#005EFF",
              fontSize: 12,
              cursor: "pointer",
              fontWeight: 600,
              marginRight: 10,
            }}
            onClick={_giftMultipleEmployees}
          >
            Gift Employees
          </div>

          <div
            //className = "text-white p-2 rounded text-xs cursor-pointer font-semibold ml-3"
            style={{
              padding: 8,
              borderRadius: 4,
              color: "#fff",
              backgroundColor: "#005EFF",
              fontSize: 12,
              cursor: "pointer",
              fontWeight: 600,
              marginRight: 10,
            }}
            onClick={() => handleMessageClick("message")}
          >
            Send Message
          </div>
        </div>
      </div>

      <div
        //className = "mx-28 mt-10 rounded"
        style={{
          marginRight: 124,
          marginLeft: 124,
          marginTop: 30,
          borderRadius: 4,
        }}
      >
        <EmployeesTableNew
          headCells={headerCellsData}
          _hideFormRow={_hideFormRow}
          _fetchAllEmployees={_fetchAllEmployees}
          orderBy={orderBy}
          showNewRow={showNewRow}
          handleSubmit={handleSubmit}
          employees={employees}
          editableEmpId={editableEmpId}
          _deleteEmployee={_deleteEmployee}
          _editEmployee={_editEmployee}
          defaultInputProps={defaultInputProps}
          fetchEmployeeloading={loading.fetchEmployee}
          inviteLoading={loading.invite}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          selectedJob={selectedJob}
          selectedJobEmployees={selectedJobEmployees}
          _updateReferralSettings={_updateReferralSettings}
          setOpenMessage={(val: boolean, id?: number) => handleInvite(val, id)}
          _showNewRow={_showNewRow}
          saveLoading={loading.saveData}
        />
        {/* {loading.fetchEmployee && <Loader
                //hideModal

              />} */}

        <div
          //className = "grid justify-items-center items-center mt-10 mb-8"
          style={{
            display: "grid",
            justifyItems: "center",
            alignItems: "center",
            marginTop: 48,
          }}
        >
          <Pagination
            count={employees.pageCount}
            page={employees.page}
            className={classes.root}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                onClick={() => {
                  _fetchAllEmployees(item.page);
                }}
                classes={{ selected: classes.selected }}
              />
            )}
            variant="outlined"
          />
        </div>

        {/* Modals and Popups */}
        {showCoinBox && !dollarLoading && (
          <GiftCoinModal
            dollarValue={dollarValue}
            employee={selectedEmpForGift}
            employees={employees}
            selectedEmpIds={selectedEmployees}
            closeGiftBox={_closeGiftBox}
          />
        )}

        {showEmpImport ? (
          <Modal
            show={showEmpImport}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="mx-2">
              <Typography
                style={{ fontFamily: "proxima_novabold", fontSize: "25px" }}
                className="mb-5"
              >
                Import Employees using CSV
              </Typography>
              <ImportEmployees onFinish={_fetchAllEmployees} />
            </Modal.Body>
            <Modal.Footer className="mt-3">
              <Button
                onClick={_toggleEmpImportPopup}
                className={`${classes.bottomBtn} ${classes.bgBlue}`}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        ) : null}

        {openMessage || create ? (
          <MessageBox
            openMessage={openMessage}
            setOpenMessage={(val: boolean) => setOpenMessage(val)}
            inviteStatus={inviteStatus}
            employees={employees}
            selectedEmployees={selectedEmployees}
            referType={referType}
            _updateReferralSettings={_updateReferralSettings}
            companyInfo={companyInfo}
            setSelectedEmployees={setSelectedEmployees}
            handleEditBox={handleEditBox}
            setUpdateTemplate={setUpdateTemplate}
            create={create}
            setCreate={setCreate}
          />
        ) : null}
      </div>
    </>
  );
};

export default EmployeesPage;

const useStyles = makeStyles((theme) => ({
  search: {
    position: "relative",
    borderRadius: "2px",
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    position: "absolute",
    top: "49%",
    transform: "translate(0, -50%)",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fill: "#666",
    },
  },
  inputRoot: {
    width: "100%",
  },
  inputInput: {
    padding: "20px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: `1.5px solid #f2f5f9 !important`,
    borderRadius: "10px !important",
  },
  select: {
    border: "1px solid #f2f5f9 ",
    padding: "10px",
    borderRadius: "10px",
  },
  formControl: {
    "&>div": {
      "&:before": {
        content: "none",
      },
    },
  },
  tab: {
    color: "black",
    cursor: "pointer",
  },
  tabActive: {
    borderBottom: `3px solid #2574ff`,
  },
  tabInactive: {
    color: "grey",
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  tag: {
    backgroundColor: "#fafafb",
    borderRadius: "5px",
    paddingLeft: "12px",
  },
  checkbox: {
    color: "#93939e !important",
    borderWidth: "1px",
  },
  checked: {
    color: "#63d699 !important",
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    // padding: "0.8rem 2.5rem",
    borderRadius: "7px",
    // marginRight: "10px",
    fontSize: "12px",
    padding: "10px 20px",
  },
  bgWarning: {
    backgroundColor: "#fbc542",
    "&:hover": {
      backgroundColor: "#fbc542",
    },
  },
  bgDanger: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  bgBlue: {
    backgroundColor: "#0d66fc",
    "&:hover": {
      backgroundColor: "#0d66fc",
    },
  },
  bgTorqtoise: {
    backgroundColor: "#4169E1",
    "&:hover": {
      backgroundColor: "#0000FF",
    },
  },
  autocompleteInput: {
    paddingLeft: "40px !important",
  },
  searchfield: {
    padding: "3px !important",
  },

  statustablehead: {
    textAlign: "center",
  },

  cssLabel: {
    color: "red",
  },

  cssOutlinedInput: {
    "&$cssFocused $notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },
  },

  cssFocused: {},

  notchedOutline: {
    borderWidth: "1px",
    borderColor: "red !important",
  },
  tableRow: {
    height: 30,
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
  tableCell: {
    padding: "15px 16px",
    fontSize: 12,
    fontWeight: "600",
    color: "#4B6B73",
    lineHeight: "15px",
    backgroundColor: "#F1F5F6",
    fontFamily: "'Montserrat',sans-serif",
    // borderRightStyle : "solid",

    // borderRightWidth : "1px",
  },

  tableCellSecondary: {
    padding: 4,
    fontSize: 12,
    color: "#4B6B73",
    lineHeight: "15px",
    backgroundColor: "#fff",
    fontFamily: "'Montserrat',sans-serif",
  },

  menuStyle: {
    //backgroundColor : "green",
    //fontSize : 10,
    //fontWeight : '500',
    color: "#4B6B73 !important",
    padding: 0,
    lineHeight: "15px",
    //fontFamily : "'Montserrat',sans-serif",
  },

  iconStyle: {
    color: "#4B6B73 !important",
  },
  ul: {
    "& .MuiPaginationItem-root": {
      color: "#000",
    },
  },
  selected: {
    backgroundColor: "#047CFF",
    color: "#ddd",
    fontFamily: "'Monsterrat', sans-serif",
  },
  root: {
    "& .Mui-selected": {
      backgroundColor: "#047CFF",
      color: "#fff",
      fontFamily: "'Monsterrat', sans-serif",
    },
  },
}));
