/*
 * File: ApplicantsApi.ts
 * Project: JobApp-Admin
 * File Created: Wednesday, 23rd December 2020 1:42:07 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 10:02:15 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */

import stringify from "qs-stringify";
import { log } from "../../Lib/helpers";
import { ApiGet, ApiPatch, ApiPost } from "../api-client";
import { defaultRequestQuery, IRequestQuery } from "../Interfaces";
import { EApplicationStatus } from "../Interfaces/ApplicationInterface";

/**
 * Get Applicants of all jobs with their resumes
 *
 * @param jobIds - Job IDs
 * @param query{IRequestQuery} - Query
 */
const GetApplicants = async (
  jobIds: string,
  query: IRequestQuery = {...defaultRequestQuery}
) => {
  query = {
    ...defaultRequestQuery,
    ...query
  }
  const data = await ApiGet(
    `application/manage?jobIds=${jobIds}&${stringify(query)}`
  );

  log("☁️ GET APPLICANT RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Change Application Status of Applicant
 *
 * @param applicationId - Application ID
 * @param newStatus - New Application Status
 */
const ChangeStatus = async (
  applicationId: number,
  newStatus: EApplicationStatus
) => {
  const data = await ApiPatch(
    `application/${applicationId}/status/${newStatus}`
  );

  log("☁️ Update application status RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

export const ApplicantsApi = {
  GetApplicants,
  ChangeStatus,
};
