/*
 * File: job-detail.component.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 8th December 2020 7:45:10 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 17th September 2021 1:49:09 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { EJobStatus, IJob } from "../../../Services/Interfaces/JobInterface";
import { ConfirmModal } from "../../../Components/modals/confirm.modal";
import { images } from "../../../assets/images/images";
import { toastify } from "../../../Services/toast.service";
import { currencyFormat } from "../../../Lib";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { latLngToString } from "../../../Lib/helpers";

const JobDetailComponent = ({
  selectedJob,
  close,
  onDelete,
}: {
  selectedJob: IJob;
  close: any;
  onDelete: (job: IJob) => void;
}) => {
  // const company = useSelector((state: IReducer) => state.auth.user.company)
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [jobId, setJobId] = useState<any>(null);

  const deleteJob = () => {
    onDelete(selectedJob);
    return;
    setJobId(selectedJob?.id);
    setShowConfirm(true);
  };

  const confirmDelete = () => {
    // setDeleting(true);
    // deleteRequest(endpoints.job.delete(jobId)).then((response: AxiosResponse<IJob>) => {
    //     hideDeletingModal(true)
    // })
  };

  const hideDeletingModal = (status = false) => {
    setDeleting(false);
    setShowConfirm(false);
    setJobId(null);
    if (status) toastify("success", "Job deleted successfully");
  };

  const {
    applications,
    title,
    onsite,
    branch,
    category,
    salary,
    id,
    location,
  } = selectedJob;

  let selectedAddress = "";

  branch?.addresses.find((ba) => {
    const locationStr = latLngToString(ba.latitude, ba.longitude);
    if (locationStr === location) {
      selectedAddress = ba.address;
    }
  });

  return (
    <div className="rt-jobpreviewarea">
      <ul className="rt-btnactions">
        {selectedJob?.status !== EJobStatus.closed && (
          <li>
            <button style = {{border:"none", backgroundColor: "#f4f5f9"}} type="button" onClick={deleteJob}>
              <i className="icon-delete"></i>
            </button>
          </li>
        )}
        <li>
          <Link to={`${RouteKeys.EditJob}/${id}`}>
            <i className="icon-edit"></i>
          </Link>
        </li>
        <li>
          <button style = {{border:"none", backgroundColor: "#f4f5f9"}} type="button" onClick={close}>
            <i className="icon-cancel"></i>
          </button>
        </li>
      </ul>
      <div className="rt-jobpreview">
        <div className="rt-johead">
          <div className="rt-applicationscount">
            <em>{applications?.length}</em>
            <span>Applicants</span>
          </div>
          <figure className="rt-conpmanylogo">
            <img src={images.CompanyLogo} alt="Company Logo" />
          </figure>
          <h3 className="rt-jobname">{title}</h3>
          <ul className="rt-jobinfotags">
            <li>
              <span className="rt-bgonsite capitalize">
                <i className="icon-full-time"></i>
                <em>{onsite ? "Onsite" : "Remote"}</em>
              </span>
            </li>
            {/* {(selectedJob.categories || []).map((v: any) => ( */}
            <li>
              <span className="rt-bgfulltime">
                <i className="icon-price-tag"></i>
                <em>{category?.name}</em>
              </span>
            </li>
            {/* ))} */}
            <li>
              <span className="rt-bgprice">
                <i className="icon-dollar"></i>

                <em>{currencyFormat("USD", salary as number)}/Hr</em>
              </span>
            </li>
          </ul>
          <div className="rt-joblocation">
            <i className="icon-jobs"></i>
            <div className="rt-jobaddress">
              {/* <h4>{(address || "N/A").split(",")[0]}</h4> */}
              {/* <h4>{selectedJob. branch?.addresses[0].address}</h4>
              <p>{branch?.addresses[0].city}</p> */}
              <span>{selectedAddress}</span>
            </div>
          </div>

          <div className="rt-jobinoarea">
            <div className="rt-jobinbox">
              <h6>Job Description</h6>
              <div dangerouslySetInnerHTML={{__html: selectedJob.description}}></div>
              {/* <span>{selectedJob.description}</span> */}
            </div>
            <br />
            <div className="rt-jobinbox">
              <h6>Job Requirements</h6>
              {/* <span>{selectedJob.requirements}</span> */}
              <div dangerouslySetInnerHTML={{__html: selectedJob.requirements}}></div>
            </div>
            <br />

            <div className="rt-jobinbox">
              <h6>Job Benefits</h6>
              {/* <span>{selectedJob.benefits}</span> */}
              <div dangerouslySetInnerHTML={{__html: selectedJob.benefits}}></div>
            </div>
          </div>

          <hr />

          <div className="rt-jobmaparea">
            <figure className="rt-mapplaceholder">
              <div className="mapouter">
                <div className="gmap_canvas">
                  <div
                    id="showLocationOnMap"
                    style={{ height: "300px", width: "100%" }}
                  ></div>
                </div>
              </div>
            </figure>
          </div>
        </div>
      </div>
      <ConfirmModal
        loading={deleting}
        onClose={hideDeletingModal}
        onConfirm={confirmDelete}
        show={showConfirm}
        message="Are you sure you want to delete this job?"
        confirmTitle="Delete Job"
      />
    </div>
  );
};

JobDetailComponent.defaultProps = {
  selectedJob: {
    applications: [],
  },
};

export { JobDetailComponent };
