/*
 * File: JobApi.ts
 * Project: JobApp-Admin
 * File Created: Friday, 21st February 2020 9:45:42 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Tuesday, 3rd August 2021 2:51:14 am
 * -----
 * Copyright 2020 - 2020 Allshore, https://allshorestaffing.com
 */

import { log } from "../../Lib/helpers";
import { ApiPost, ApiGet, ApiPatch, ApiPut, ApiDelete } from "../api-client";
import { IEmployeeRefer } from "../Interfaces/EmployeeInterface";

import { IJob, IJobRequest } from "../Interfaces/JobInterface";

/**
 * Create a Job
 */
const CreateJob = async (job: IJobRequest) => {
  const data = await ApiPost(`jobs-manage`, job);

  log("☁️ JOB CREATE RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Job
 */
const GetJob = async (id: string | number) => {
  const data = await ApiGet(`jobs-manage/${id}`);

  log("☁️ JOB GET RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Edit Job
 */
const EditJob = async (job: IJobRequest) => {
  // const {
  //   id,
  //   branchId,
  //   categoryId,
  //   title,
  //   description,
  //   requirements,
  //   benefits,
  //   salary,
  //   shift,
  //   onsite,
  //   status,
  //   location,
  // } = job;
  
  const data = await ApiPatch(`jobs-manage/${job.id}`, job);

  log("☁️ JOB EDIT RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Jobs List paginated result
 */
const JobsList = async (page = 1, limit = 10) => {
  const data = await ApiGet(`jobs-manage?page=${page}&limit=${limit}`);

  log("☁️ JOB LIST RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get All jobs list with id and title to be populated in dropdown
 */
const JobsListAll = async () => {
  const data = await ApiGet(`jobs-manage/list-all`);

  log("☁️ JOB LIST ALL RESPONSE: ", data);

  return data;
};

/**
 * Delete Job
 */
const JobDelete = async (id: number | string) => {
  const data = await ApiDelete(`jobs-manage/${id}`);

  log("☁️ JOB DELETE RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Dashboard Stats
 */
const Stats = async (isSuperAdmin = false) => {
  const data = await ApiGet(`jobs-manage/${isSuperAdmin ? 'stats' : 'company-stats'}`);

  log("☁️ JOB STATS RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Get Job No Auth
 * Get Job info 
 */
 const GetJobNoAuth = async (id: string | number) => {
  const data = await ApiGet(`jobs/${id}`);

  log("☁️ JOB GET NO AUTH RESPONSE: ", data);

  if (data.ok) {
  }

  return data;
};

export const JobApi = {
  CreateJob,
  GetJob,
  EditJob,
  JobsList,
  JobsListAll,
  JobDelete,
  Stats,
  GetJobNoAuth
};
