/*
 * File: StripeInput.tsx
 * Project: Dyme-Web
 * File Created: Tuesday, 28th September 2021 12:15:30 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 28th September 2021 12:37:30 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */


import React, { useRef, useImperativeHandle } from 'react'


interface IStripeInput {
     Component: React.ElementType
     inputRef: React.Ref<any>
}
const StripeInput = (
     { Component, inputRef, ...props }: IStripeInput
) => {
     const elementRef = useRef<HTMLInputElement>();
     useImperativeHandle(inputRef, () => ({
          focus: () => elementRef?.current?.focus
     }));
     return (
          <Component
               onReady={(element: any) => (elementRef.current = element)}     
               {...props}
          />
     )
}
export default StripeInput