/*
 * File: DashboardPage.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 4th December 2020 12:32:03 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:36:17 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */
import React, { useEffect, useState,useContext } from "react";
import { useHistory } from "react-router-dom";
import ApplicantStatsComponent from "../../Components/widgets/ApplicantStatsComponent/ApplicantStatsComponent";
import NewHiresComponent from "../../Components/widgets/NewHiresComponent/NewHiresComponent";
import RecentJobsPostedComponent from "../../Components/widgets/RecentJobsPostedComponent/RecentJobsPostedComponent";
import { log } from "../../Lib/helpers";
import { RouteKeys } from "../../Routes/RouteKeys";
import { JobApi } from "../../Services/API/JobApi";
import { PlumApi } from "../../Services/API/PlumApi";
import { AppContext } from "../../Services/AppContext";

import {
  handleApiError,
  handleCatchError,
} from "../../Services/error-handling";
import { IPaginate, defaultPaginatedData } from "../../Services/Interfaces";
import {
  IJob,
} from "../../Services/Interfaces/JobInterface";
import styles from "./DashboardPage.module.scss";
import Button from '../../Components/common/ButtonComponent/ButtonComponent'
import { EUserType } from "../../Services/Interfaces/AuthInterface";
import { applicationStatsInitial } from "../../Services/Interfaces/ApplicationInterface";
const DashboardPage: React.FC = () => {
  const history = useHistory();
  // const auth = useSelector((state: IReducer) => state.auth)

  // useEffect(() => {
  //     if (!auth.access_token) history.push('/auth');
  // }, [ auth ])

  const [loading, setLoading] = React.useState(false);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  
  const [jobs, setJobs] = useState<IPaginate<IJob>>(defaultPaginatedData);
  const [stats, setStats] = useState(applicationStatsInitial);
  const { user } = useContext(AppContext);

  useEffect(() => {
    _fetchData();
  }, []);

  const _fetchData = async () => {
    setLoading(true);
    const data = await Promise.all([_fetchJobs(), _fetchStats()]);

    log("Promise Stats Response: ", data);
    setLoading(false);
  };

  const _fetchJobs = async () => {
    setLoadingJobs(true);

    const response = await JobApi.JobsList(1, 10);
    try {
      if (response.hasError) {
        handleApiError(response, "JOB List in stats: ");
      } else {
        // ? Get Jobs success
        log("Jobs List SUCCESS: ", response);
        setJobs(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoadingJobs(false);
  };

  const _fetchStats = async () => {
    setLoadingStats(true);

    const response = await JobApi.Stats();
    try {
      if (response.hasError) {
        handleApiError(response, "JOB stats: ", false);
        // redirect to settings if forbidden
        if (response.status === 403) {
          history.push(RouteKeys.AdminSettings)
        }
      } else {
        // ? Get Jobs success
        log("Jobs Stats SUCCESS: ", response);
        setStats(response.data);
      }
    } catch (e) {
      handleCatchError(e);

    }

    setLoadingStats(false);
  };
  

  return (
    <div className={styles.DashboardPage} data-testid="DashboardPage">
      <div className="rt-wrapper">
        <div className="rt-dashboard">
          <div className="rt-pagetitle d-flex justify-content-between">
            <h1>Dashboard</h1>
            
          </div>
          <div className="rt-applicationstates">
            <ApplicantStatsComponent
              data={stats}
              loading={loadingStats}
            />
          </div>
          <div className="rt-newhiresrecentjobs">
            <NewHiresComponent
              data={stats.newHiredStats}
              loading={loadingStats}
            />
            <RecentJobsPostedComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
