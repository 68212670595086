/*
 * File: RefreshVouchersWidget.tsx
 * Project: Dyme-Web
 * File Created: Friday, 4th March 2022 11:58:04 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Saturday, 5th March 2022 12:02:00 am
 * -----
 * Copyright 2020 - 2022 WhileGeek, https://umar.tech
 */

import React, { useContext, useState } from "react";
import { Config } from "../Config";
import { log } from "../Lib/helpers";
import { PlumApi } from "../Services/API/PlumApi";
import { AppContext } from "../Services/AppContext";
import { handleApiError, handleCatchError } from "../Services/error-handling";
import { EUserType } from "../Services/Interfaces/AuthInterface";
import ButtonComponent from "./common/ButtonComponent/ButtonComponent";
import { ENotifyType } from "./Notify";

export default function RefreshVouchersWidget() {
  const { user, notify } = useContext(AppContext);

  const [loadingRefreshVoucher, setLoadingRefreshVoucher] = useState(false);

  const handleRefreshVoucher = async () => {
    setLoadingRefreshVoucher(true);

    const response = await PlumApi.refreshVoucherRequest();
    try {
      if (response.hasError) {
        handleApiError(response, "REFRESH VOUCHER: ");
      } else {
        // ? success
        log("Refresh voucher request: ", response);
        notify({
          title: "Plum Gift cards cache refreshed successfully",
          type: ENotifyType.Success,
        });
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoadingRefreshVoucher(false);
  };

  const handleRefreshPlumToken = async () => {
    setLoadingRefreshVoucher(true);

    const response = await PlumApi.refreshPlumToken();
    try {
      if (response.hasError) {
        handleApiError(response, "REFRESH Plum TOKEN: ");
      } else {
        log("Refresh plum request: ", response);
        notify({
          title: "Plum API Token refreshed successfully",
          type: ENotifyType.Success,
        });
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoadingRefreshVoucher(false);
  };

  return (
    <div className="rt-profilebuttons">
      <div className="rt-profilebox rt-profilenotifications">
        <div className="rt-profileboxhead">
          <h3>Dyme Jobs Admin</h3>
        </div>
        <h5>Dyme Jobs Mobile Apps</h5>
        <a
          href={Config.APP_LINK_ANDROID}
          target="_blank"
          rel="noopener noreferrer"
        >
          Android
        </a>{" "}
        |{" "}
        <a href={Config.APP_LINK_IOS} target="_blank" rel="noopener noreferrer">
          iOS
        </a>
        <hr />
        <h6>{Config.APP_VERSION}</h6>
        {user?.user?.role === EUserType.SuperAdmin && (
          // {user?.user?.email === "umardatum@gmail.com" && (
          <>
            <hr />
            <h5>Plum Gift Cards API Fix:</h5>
            <ButtonComponent
              className="text-capitalize text-white py-2 px-4"
              btncolor="bgDanger"
              onClick={handleRefreshVoucher}
              loading={loadingRefreshVoucher}
              style={{ marginTop: 10 }}
            >
              Refresh Gift Vouchers
            </ButtonComponent>
            <br />
            <ButtonComponent
              className="text-capitalize text-white py-2 px-3"
              btncolor="bgDanger"
              onClick={handleRefreshPlumToken}
              loading={loadingRefreshVoucher}
              style={{ marginTop: 30 }}
            >
              Refresh Plum API Token
            </ButtonComponent>
          </>
        )}
      </div>
    </div>
  );
}
