/*
 * File: AlertMessage.tsx
 * Project: Dyme-Web
 * File Created: Friday, 17th September 2021 1:58:40 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 17th September 2021 1:59:38 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Button from "../../../Components/common/ButtonComponent/ButtonComponent";

const useStyles = makeStyles((theme: Theme) => ({
    PopupText: {
      fontFamily: "proxima_nova",
      fontSize:"18px",
    },
    bottombtn:{
        fontFamily: "proxima_nova",  
    }
  }));

const AlertMessage = (props : any) => {
    const classes = useStyles();
    const{ globle ,setGloble} = props;
    
    const handleGloble=(e:any)=>{
        setGloble(false)
    }
    return (
            <Dialog
                open={globle}
                keepMounted
                onClose={handleGloble}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{"Oops..."}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" className={classes.PopupText}>
                        It is a global Template.You can not edit a global Template.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.PopupText}>
                    <Button onClick={handleGloble} btncolor="bgBlue" className={`${classes.bottombtn} text-light mx-2 my-2`}>
                        okay.Got it
                    </Button>

                </DialogActions>
            </Dialog>
    )
}
export default AlertMessage;