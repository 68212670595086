/*
 * File: RecentJobsPostedComponent.tsx
 * Project: JobApp-Admin
 * File Created: Friday, 4th December 2020 12:33:16 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Monday, 10th January 2022 9:35:56 pm
 * -----
 * Copyright 2020 - 2020 WhileGeek, https://umar.tech
 */
import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import ButtonComponent from "../../common/ButtonComponent/ButtonComponent";
import {
  IJob,
} from "../../../Services/Interfaces/JobInterface";
import { IPaginate, defaultPaginatedData } from "../../../Services/Interfaces";
import { JobApi } from "../../../Services/API/JobApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log } from "../../../Lib/helpers";
import { RouteKeys } from "../../../Routes/RouteKeys";
import Loader from "../../Loader";
import { EApplicationStatus } from "../../../Services/Interfaces/ApplicationInterface";

const RecentJobsPostedComponent: React.FC = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState<IPaginate<IJob>>(defaultPaginatedData);
  const history = useHistory()

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    setLoading(true);

    const response = await JobApi.JobsList(1, 10);
    try {
      if (response.hasError) {
        handleApiError(response, "JOB List in stats: ");
      } else {
        // ? Get Jobs success
        log("Jobs List SUCCESS: ", response);
        setJobs(response.data);
      }
    } catch (e) {
      handleCatchError(e);
    }

    setLoading(false);
  };

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  return (
    <div data-testid="RecentJobsPostedComponent">
      <div className="rt-themebox rt-resentjobsbox">
        <div className="rt-themeboxhead">
          <h2>Recent Jobs Posted</h2>
          <button className="rt-btndots">
            <i className="icon-more"></i>
          </button>
          {loading && <Loader style={{marginLeft: 50}} hidemodal={true} />}
        </div>
        <div className="rt-recentjobsposts">
          <div className="rt-themetableholder">
            <table className="rt-themetable table">
              <thead>
                <tr>
                  <th>
                    <span>Job Title</span>
                  </th>
                  {/* <th>
                    <span>Address</span>
                  </th> */}
                  <th>
                    <ul>
                      <li>
                        <span>Applied</span>
                      </li>
                      <li>
                        <span>Hired</span>
                      </li>
                      {/* <li>
                        <span>Actions</span>
                      </li> */}
                    </ul>
                  </th>
                </tr>
              </thead>
              <tbody>
                {jobs.data.map((job: IJob) => (
                  <tr key={job.id}>
                    <td data-title="Job Title/Date">
                      <h3 onClick={() => _navigate(`${RouteKeys.EditJob}/${job?.id}`,{ job })}>
                        {job.title} <i>(${job.salary})</i>
                      </h3>
                      <time>
                        {moment(job.createdAt).fromNow()}
                      </time>
                    </td>
                    {/* <td data-title="Address">
                      <p>
                        <ShowMoreText
                          lines={1}
                          more=""
                          less=""
                          className="content-css"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                          width={250}
                        >
                          {job.title || "N/A"}
                        </ShowMoreText>
                      </p>
                    </td> */}
                    <td data-title="Applied/Hired">
                      <div className="rt-countdropdown">
                        <ul>
                          <li>
                            <i className="icon-suitcase"></i>
                            <span>{job?.applications?.length}</span>
                          </li>
                          <li>
                            <i className="icon-suitcase"></i>
                            <span>
                              {
                                job?.applications?.filter(
                                  (j) => j.status === EApplicationStatus.Hired
                                ).length
                              }
                            </span>
                          </li>
                        </ul>
                        {/* <Dropdown className="rt-themedropdown">
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <i className="icon-more"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="rt-themedropdownmenu">
                            <Dropdown.Item href="#">
                              <i className="icon-edit"></i>
                              <span>Edit Job</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#">
                              <i className="icon-eye1"></i>
                              <span>View Job</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={handleShow}>
                              <i className="icon-delete"></i>
                              <span>Delete Job</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Link className="rt-btnviewmore" to={RouteKeys.JobsList}>
          View More
        </Link>
      </div>
      <Modal
        className="rt-thememodal rt-thememodaldelete"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="rt-modalheader">
          <Modal.Title>Delete Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this Job?</p>
        </Modal.Body>
        <ButtonComponent
          type="button"
          text=""
          className="rt-btndelete"
          onClick={handleClose}
        >
          <span>Yes, Delete Job</span>
        </ButtonComponent>
      </Modal>
    </div>
  );
};

export default RecentJobsPostedComponent;
