/*
 * File: view-user.component.tsx
 * Project: JobApp-Admin
 * File Created: Monday, 28th December 2020 7:49:16 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 17th September 2021 12:14:07 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { ChangeEvent, useEffect, useState } from "react";
import { LoaderComponent } from "../../../../Components/common/loader.component";
import { ValidationErrorComponent } from "../../../../Components/shared/validation-error.component";
import { log, _cloneDeep } from "../../../../Lib/helpers";
import { UserApi } from "../../../../Services/API/UsersApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../../Services/error-handling";
import { IValidationError } from "../../../../Services/Interfaces";
import { EACL, IUser } from "../../../../Services/Interfaces/AuthInterface";
import { toastify } from "../../../../Services/toast.service";

interface IProps {
  user: any;
  onClose(): void;
  onUpdate(user: IUser): void;
  onDelete(id: string): void;
}
export const ViewUserComponent = (props: IProps) => {
  const [acl, setACL] = useState<EACL[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [user, setUser] = useState<Partial<IUser>>({ isActive: true });
  const [errors, setErrors] = useState<IValidationError>({});

  useEffect(() => {
    setUser(props.user);
    // setACL(props.user.acl);
  }, [props.user]);

  // const setPermission = (perm: EACL) => {
  //   let permissions = [...acl];
  //   if (acl.includes(perm)) permissions = acl.filter((_p: EACL) => perm !== _p);
  //   else permissions.push(perm);
  //   setACL([...permissions]);
  // };

  const changeField = (e: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const update = async () => {
    setSubmitting(true);
    try {
      const response = await UserApi.EditUser(user);
      if (response.hasError) {
        handleApiError(response, "User Update: ");
      } else {
        log("User Update SUCCESS: ", response);

        setUser(response.data);
        props.onUpdate(response.data);
        toastify('success', 'Profile updated successfully.');
      }
    } catch (e) {
      handleCatchError(e);
    }
    setSubmitting(false);

    // setSubmitting(true)
    // putRequest(endpoints.company.updateUser(props.user.id), { ...user, acl }).then((response: AxiosResponse<any>) => {
    //     setSubmitting(false)
    //     toastify('success', 'Profile udpated successfully.');
    //     props.onUpdate({...user, acl });
    // }, (error: any) => {
    //     setSubmitting(false)
    //     setErrors(error.response.data)
    // })
  };

  return (
    <div className="rt-profilebuttons">
      <div className="rt-profilebox rt-profilenotifications">
        <div className="rt-profileboxhead">
          <h3>User Information [id: {user.id}]</h3>
        </div>
        <div className="rt-notificationholder">
          <div className="rt-userinfoinput">
            <div className="form-group">
              <label>User Email</label>
              <i className="icon-user1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="text"
                  name="email"
                  value={user.email}
                  onChange={changeField}
                  className="form-control"
                  placeholder="john@doe.com"
                />
              </div>
              <ValidationErrorComponent errors={errors} name="email" />
            </div>
          </div>
          <div className="rt-userinfoinput">
            <div className="form-group">
              <label>User Phone</label>
              <i className="icon-user1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="text"
                  name="phone"
                  value={user.phone}
                  onChange={changeField}
                  className="form-control"
                  placeholder="+92 333 5251661"
                />
              </div>
              <ValidationErrorComponent errors={errors} name="phone" />
            </div>
          </div>
          <div className="rt-userinfoinput">
            <div className="form-group">
              <label>Full Name</label>
              <i className="icon-user1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="text"
                  name="fullName"
                  value={user.fullName}
                  onChange={changeField}
                  className="form-control"
                  placeholder="John Doe"
                />
              </div>
              <ValidationErrorComponent errors={errors} name="fullName" />
            </div>
          </div>
          {/* <div className="rt-userinfoinput">
            <div className="form-group">
              <label>Password</label>
              <i className="icon-eye1"></i>
              <div className="rt-inputiconholder">
                <input
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={changeField}
                  className="form-control"
                  placeholder="Abcd@123"
                />
              </div>
              <ValidationErrorComponent errors={errors} name="password" />
            </div>
          </div> */}
          <div className="rt-notification rt-generalnotification">
            <div className="rt-profileboxhead">
              <h3>Permissions</h3>
            </div>
            <div className="rt-notihead">
              {/* <h4>Application Access</h4> */}
            <div
              className="rt-onoffswitch"
              onClick={() => setUser({ ...user, isActive: !user.isActive })}
            >
              <input
                type="checkbox"
                name="notification"
                checked={Boolean(user.isActive)}
              />
              <label>
              <span>Active</span>
            </label>
            </div>
            </div>
            {/* <ul>
              {Object.values(EACL).map((p: EACL) => (
                <li key={p}>
                  <h5>{p}</h5>
                  <div
                    className="rt-onoffswitch"
                    onClick={() => setPermission(p)}
                  >
                    <input
                      type="checkbox"
                      name="acl"
                      checked={acl.includes(p)}
                    />
                    <label></label>
                  </div>
                </li>
              ))}
            </ul> */}
            <div className="rt-btnholder">
              <button
                className="rt-btndeleteuser"
                style={{ background: "var(--DodgerBlue) " }}
                onClick={update}
                disabled={submitting}
              >
                Update User
                {submitting && <LoaderComponent />}
              </button>
              <button
                onClick={() => props.onDelete(props.user.id)}
                className="rt-btndeleteuser"
              >
                Delete User
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
