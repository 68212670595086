/*
 * File: AuthApi.ts
 * Project: JobApp-Admin
 * File Created: Saturday, 15th February 2020 12:27:43 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Friday, 21st January 2022 1:09:29 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://whilegeek.com
 */

import { ApiPost, ApiGet, ApiPatch, ApiUploadPost } from "../api-client";
import { log } from "../../Lib/helpers";
import {
  IFacebookLoginDto,
  IGoogleLoginDto,
  IUser,
} from "../Interfaces/AuthInterface";

/**
 * Register user / Create user account
 *
 * @param email - Email
 * @param phone - Mobile Number
 * @param username - Username
 * @param fullName - Full Name
 * @param password - Password
 */
const Register = async (
  email: string,
  phone: string | null,
  username: string,
  fullName: string,
  password: string,
  isBusiness: boolean,
  photo: string = "",
  referredBy: number | string = 0,
  jobId: number = 0,
  employeeId: number = 0
) => {
  const data = await ApiPost(`auth/signup`, {
    email,
    phone: phone === "" ? null : phone,
    username,
    fullName,
    password,
    referredBy,
    jobId,
    employeeId,
    isBusiness,
  });

  log("☁️ Register RESPONSE: ", data);

  if (data.ok) {
    // storageUpdate("user", data.data)
  }

  return data;
};

/**
 * Login user with email and password
 * @param username - Email / Username / Phone
 * @param password - Password
 */
const Login = async (username: string, password: string) => {
  const data = await ApiPost(`auth/signin`, {
    username,
    password,
  });

  log("☁️ LOGIN RESPONSE: ", data);

  if (data.ok) {
    // storageUpdate("user", data.data)
  }

  return data;
};

/**
 * Login user with Facebook
 * @param fbLoginDTO - facebook login Data
 */
const FacebookLogin = async (fbLoginDTO: IFacebookLoginDto) => {
  const data = await ApiPost(`auth/login/facebook`, fbLoginDTO);

  log("☁️ Facebook LOGIN RESPONSE: ", data);

  return data;
};

/**
 * Login user with Google
 * @param googleLoginDTO - google login Data
 */
const GoogleLogin = async (googleLoginDTO: IGoogleLoginDto) => {
  const data = await ApiPost(`auth/login/google`, googleLoginDTO);

  log("☁️ Google LOGIN RESPONSE: ", data);

  return data;
};

interface IForgotPasswordPayload {
  email?: string;
  phone?: string;
}
const ForgotPassword = async (payload: IForgotPasswordPayload) => {
  const data = await ApiPost(`auth/forgot-password`, payload);

  log("☁️ FORGOT PASSWORD: ", data);

  if (data.ok) {
  }

  return data;
};

interface IResetWEbPayload {
  code: string;
  password: string;
  confirm: string;
  email: string;
}

const ResetWebPassword = async (payload: IResetWEbPayload) => {
  const { code, password, confirm, email } = payload;
  const data = await ApiPatch(`auth/reset-password/${code}`, {
    email,
    password,
    confirm,
  });

  log("☁️ RESET PASSWORD: ", data);

  if (data.ok) {
  }

  return data;
};

/**
 * Forget Password / Reset Password Request
 * ! not implemented yet
 * @param email Email
 */
const ResetRequest = async (email: string) => {
  const data = await ApiGet(`auth/test`, { email });

  log("☁️ RESET PASSWORD REQUEST RESPONSE: ", data);

  // if (data.ok) {
  // }

  return data;
};

/**
 * Reset Password
 * ! not implemented yet
 * @param newPassword New Password
 * @param code Code
 * @param email Email
 */
const ResetPassword = async (
  newPassword: string,
  code: string,
  email: string
) => {
  const data = await ApiGet(`auth/test`, { newPassword, code, email });

  log("☁️ RESET PASSWORD RESPONSE: ", data);

  return data;
};

/**
 * Logout user
 * ! not implemented yet
 */
const Logout = async () => {
  const data = await ApiPost(`auth/test`);

  log("☁️ LOGOUT RESPONSE (not implemented yet): ", data);

  if (data.ok) {
    // storageUpdate("user", data.data)
  }

  return data;
};

/**
 * Update Profile | Get Profile
 * This can only update email, phone, fullName, photo
 * It returns user profile if object is empty otherwise updates it
 *
 * @param user IUser
 */
const UpdateProfile = async (user: Partial<any> = {}) => {
  const data = await ApiPatch(`auth/profile`, { ...user });

  log("☁️ UPDATE Profile / GET Profile RESPONSE: ", data);

  // if (data.ok) {
  // }

  return data;
};

/**
 * Get Company Profile
 */
const GetCompanyProfile = async () => {
  const data = await ApiGet(`auth/company-profile`);

  log("☁️ GET Company Profile RESPONSE: ", data);

  return data;
};

/**
 * Update Company Profile | Get Company Profile
 *
 * @param branch IBranch
 */
const UpdateCompanyProfile = async (branch: Partial<any> = {}) => {
  const data = await ApiPatch(`auth/company-profile`, { ...branch });

  log("☁️ UPDATE Company Profile RESPONSE: ", data);

  // if (data.ok) {
  // }

  return data;
};

/**
 *
 * @param props - Company or User Image "file" | "logo" | "dyme"
 */
const UploadImage = async (props: {}) => {
  const data = await ApiUploadPost(`auth/upload`, props);

  log("☁️ UPDATE Image RESPONSE: ", data);

  // if (data.ok) {
  // }

  return data;
};

/**
 * Change Password
 *
 * @param oldPassword Old Password
 * @param newPassword New Password
 */
const ChangePassword = async (oldPassword: string, newPassword: string) => {
  const data = await ApiPatch(`auth/profile`, {
    oldPassword,
    password: newPassword,
  });

  log("☁️ CHANGE PASSWORD RESPONSE: ", data);

  // if (data.ok) {
  // }

  return data;
};

// ? PAYMENT SYSTEM

/**
 * Create Stripe Account
 *
 * @param payment_method Stripe Payment Method ID
 */
const CreateStripeAccount = async (payment_method: string) => {
  const data = await ApiPost(`auth/create-stripe-account`, {
    payment_method,
  });
  log("☁️ CREATE STRIPE ACCOUNT RESPONSE: ", data);
  return data;
};

/**
 * Get Stripe Account
 *
 */
const GetPaymentAccount = async () => {
  const data = await ApiGet(`auth/account-details`);

  log("☁️ GET STRIPE ACCOUNT RESPONSE: ", data);

  return data;
};

/**
 * Get Stripe previous Transactions
 *
 * @param lastTransactionId Last Transaction ID for pagination
 */
const GetPaymentTransactions = async (lastTransactionId: string = "") => {
  const data = await ApiGet(`auth/transactions?after=${lastTransactionId}`);

  log("☁️ GET STRIPE TRANSACTIONS RESPONSE: ", data);

  return data;
};

export const AuthApi = {
  Register,
  Login,
  FacebookLogin,
  GoogleLogin,

  UpdateProfile,
  GetCompanyProfile,
  UpdateCompanyProfile,
  ChangePassword,
  ResetRequest,
  ResetPassword,
  Logout,
  UploadImage,
  CreateStripeAccount,
  GetPaymentAccount,
  GetPaymentTransactions,
  ForgotPassword,
  ResetWebPassword,
};
