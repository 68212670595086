/*
 * File: BranchTable.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 16th July 2020 12:03:42 am
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Wednesday, 1st December 2021 12:50:00 am
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import * as React from "react";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";
import { IBranch } from "../../../Services/Interfaces/CompanyInterface";
import { colors, Chip, useTheme } from "@mui/material";
import { RouteKeys } from "../../../Routes/RouteKeys";
import { BranchApi } from "../../../Services/API/BranchApi";
import { ENotifyType } from "../../../Components/Notify";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { AppContext } from "../../../Services/AppContext";

interface IBranchTable {
  loading?: boolean;
  onDelete?: Function;
  companyId?: number | string;
  branches: IBranch[];
  nested?: boolean; // under another table or not
}
export const BranchTable = (props: IBranchTable) => {
  const { nested } = props;
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const { notify } = React.useContext(AppContext);

  const theme = useTheme();
  let bgColor = theme.palette.background.paper;
  if (nested) {
    bgColor =
      theme.palette.mode === "dark" ? colors.grey[900] : colors.grey[200];
  }

  const _navigate = (route: string, data?: any) => {
    history.push({ pathname: route, state: data });
  };

  // TODO : IMPLEMENT FETCH COMPANY BRANCHES HERE TO PREVENT COMPLETE RE-RENDER

  const _onDelete = async (id: number) => {
    setLoading(true);

    let message: string = "Something went wrong";
    let errorType = ENotifyType.Error;

    try {
      const response = await BranchApi.DeleteBranch(id);

      if (response.hasError) {
        message = handleApiError(response, "Branch Delete Error: ");
      } else {
        if (props.onDelete) {
          props.onDelete();
        }
        errorType = ENotifyType.Success;
        message = "Branch Deleted";
      }
    } catch (e) {
      handleCatchError(e);
    }

    // if (errorType === ENotifyType.Error) {
    notify({
      title: message,
      type: errorType,
    });
    // }

    setLoading(false);
  };

  return (
    <MaterialTable
      isLoading={props.loading}
      columns={[
        { title: "Branch Name", field: "name" },
        {
          title: "Status",
          field: "status",
          render: (rowData: IBranch) => {
            const { isActive } = rowData;
            let backgroundColor: string = colors.grey[500];
            switch (isActive) {
              case false:
                break;
              case true:
                backgroundColor = colors.green[500];
                break;
            }
            return (
              <Chip
                style={{
                  backgroundColor,
                  color: colors.grey[50],
                  fontWeight: "bold",
                }}
                label={isActive ? "Enabled" : "Disabled"}
              />
            );
          },
        },
      ]}
      data={props.branches}
      title="Branches List"
      actions={[
        {
          icon: "edit",

          tooltip: "Edit Branch",
          onClick: (event, rowData: any) =>
            _navigate(`${RouteKeys.Branches}/${rowData.id}`, {
              branch: rowData,
            }),
        },
        {
          icon: "delete",
          tooltip: "Delete Branch",
          onClick: (event, rowData: any) => {
            _onDelete(rowData.id);
          },
        },
      ]}
      style={{ backgroundColor: bgColor, paddingLeft: nested ? 50 : 0 }}
      options={{
        showTitle: true,
        toolbar: !nested,
        headerStyle: { backgroundColor: bgColor, paddingRight: 50 },
        paging: !nested,
        actionsColumnIndex: -1,
        padding: !nested ? "default" : "dense",
        filtering: false,
      }}
    />
  );
};
BranchTable.defaultProps = {
  loading: false,
  branches: [],
  nested: false,
  onDelete: () => {},
};
