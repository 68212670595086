/*
 * File: Loader.tsx
 * Project: JobApp-Admin
 * File Created: Tuesday, 18th February 2020 6:27:08 pm
 * Author: Umar Aamer (umar.u@allshorestaffing.com)
 * -----
 * Last Modified: Wednesday, 5th January 2022 7:41:01 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */
import React from "react";
import { CircularProgress, Modal } from "@mui/material";

interface ILoader {
  hidemodal?: boolean,
  style?: Object;
  size?: number;
}
export const Loader:React.FC<ILoader> = ({hidemodal, ...rest}) => {
  if (hidemodal) {
    return (
      <CircularProgress style={{color: "#2F5CF2"}} {...rest} />
    )
  }
  
  return (
    <Modal
      BackdropProps={{ style: { opacity: 0.5 } }}
      open={true}
      // hideBackdrop={true}
      style={styles.containerStyle}
    >
      <CircularProgress style={{color: "#2F5CF2"}} {...rest} />     
    </Modal>
  );
};

Loader.defaultProps = {
  hidemodal: false
}

const styles = {
  containerStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
};

export default Loader;
