/*
 * File: EmployeesTable.tsx
 * Project: JobApp-Admin
 * File Created: Thursday, 20th May 2021 6:31:32 pm
 * Author: Umar Aamer (umaraamer@gmail.com)
 * -----
 * Last Modified: Tuesday, 18th January 2022 9:25:35 pm
 * -----
 * Copyright 2020 - 2021 WhileGeek, https://umar.tech
 */

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {
  TableCell,
  TextField,
  IconButton,
  Menu,
  MenuItem,
  TextFieldProps,
  Tooltip,
  Chip,
  Pagination,
  PaginationItem,
} from "@mui/material";
import Button from "../../../Components/common/ButtonComponent/ButtonComponent";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import EditIcon from "@mui/icons-material/Edit";
import GiftIcon from "@mui/icons-material/CardGiftcard";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import UserAddIcon from "@mui/icons-material/PersonAdd";
import UserDisableIcon from "@mui/icons-material/PersonAddDisabled";
import MessageIcon from "@mui/icons-material/Message";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { PaginationComponent } from "../../../Components/common/Pagination/Pagination.component";
import {
  EEmployeeRole,
  IEmployee,
  IEmployeeBase,
} from "../../../Services/Interfaces/EmployeeInterface";
import { IPaginate, ISelect } from "../../../Services/Interfaces";
import { ImageComponent } from "../../../Components/common/image.component";
import SearchingComponent from "../../../Components/common/searching.component";
import NumberFormat from "react-number-format";
import { ConfirmModal } from "../../../Components/modals/confirm.modal";
import { ImportEmployees } from "./ImportEmployees";
import { Modal } from "react-bootstrap";
import { Typography } from "@mui/material";
import { AppColors } from "../../../Themes/AppColors";
import { TReferSaveType } from "./ReferralProgram";
import { EmployeesApi } from "../../../Services/API/EmployeesApi";
import {
  handleApiError,
  handleCatchError,
} from "../../../Services/error-handling";
import { log } from "../../../Lib/helpers";
import { RewardsApi } from "../../../Services/API/RewardApi";
import { GiftCoinModal } from "./GiftCoinModal";
import { useDollarValue } from "../../../Services/useDollarValue";
import Loader from "../../../Components/Loader";

interface HeadCell {
  id: string;
  label: string;
}

interface EnhancedTableProps {
  headCells: Array<HeadCell>;
  rowCount: number;
  numSelected: number;
  onSelectAllClick: (event: any) => void;
  classes: any;
  renderFormRow: () => any;
  showNewRow: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    classes,
    onSelectAllClick,
    numSelected,
    rowCount,
    headCells,
    renderFormRow,
    showNewRow,
  } = props;

  return (
    <TableHead className="border-1">
      <TableRow>
        <TableCell padding="checkbox" className={classes.tableHead}>
          <Checkbox
            classes={{
              root: classes.checkbox,
              checked: classes.checked,
            }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headCells?.map((headCell: HeadCell, i: number) => (
          <TableCell
            className={classes.tableHead}
            // align={"center"}
            padding="normal"
            key={headCell.label}
            id={headCell.label}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
      {showNewRow && renderFormRow()}
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const DEFAULT_DATA: IEmployeeBase = {
  fullName: "",
  title: "",
  email: "",
  phone: "",
  role: EEmployeeRole.Noob,
};
type dataType = keyof typeof DEFAULT_DATA;
interface IEnhancedTable {
  // rows: Array<Row>;
  headCells: Array<HeadCell>;
  _fetchAllEmployees: (v?: number, Keyword?: string) => void;
  orderBy: string;
  showNewRow: boolean;
  _hideFormRow: () => void;
  handleSubmit: () => void;
  employees: IPaginate<IEmployee>;
  editableEmpId: number;
  _deleteEmployee: (id: number) => void;
  _editEmployee: (id: number) => void;
  defaultInputProps: (key: dataType) => any;
  fetchEmployeeloading: boolean;
  inviteLoading: boolean;
  setSelectedEmployees: (data: Array<number> | []) => void;
  selectedEmployees: Array<number>;
  selectedJob: ISelect;
  selectedJobEmployees: Array<number>;
  _updateReferralSettings: (type?: TReferSaveType, empId?: number) => void;
  setOpenMessage: (val: boolean, id: number) => void;
  _showNewRow: () => void;
  saveLoading: boolean;
}

interface IMenuBtn {
  id: number | null;
  anchorEl: null;
}

export function EmployeesTable(props: IEnhancedTable) {
  const {
    headCells,
    _fetchAllEmployees,
    orderBy,
    showNewRow,
    _hideFormRow,
    handleSubmit,
    employees,
    editableEmpId,
    _editEmployee,
    _deleteEmployee,
    defaultInputProps,
    fetchEmployeeloading,
    inviteLoading,
    setSelectedEmployees,
    selectedEmployees,
    selectedJob,
    selectedJobEmployees,
    _updateReferralSettings,
    setOpenMessage,
    _showNewRow,
    saveLoading,
  } = props;

  // 3 dot menu button
  const [menuBtn, setMenuBtn] = useState<IMenuBtn>({
    id: null,
    anchorEl: null,
  });

  const [show, setShowConfirm] = useState(false);
  const [deleteEmpId, setDeleteEmpId] = useState(0);
  const [loading, setLoading] = useState(false);

  const [showCoinBox, setShowCoinBox] = useState(false);

  const [selectedEmpForGift, setSelectedEmpForGift] =
    useState<IEmployee | null>(null);

  const { dollarLoading, dollarValue, getCoinSettings } = useDollarValue();

  const classes = useStyles();

  const handleToggle = (e: any, id: number) => {
    setMenuBtn({
      anchorEl: e.currentTarget,
      id: id,
    });
  };

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds = employees?.data?.map((n, i) => n.id);
      setSelectedEmployees(newSelecteds);
      return;
    }
    setSelectedEmployees([]);
  };

  const handleSelectEmployee = (event: any, name: number) => {
    const selectedIndex = selectedEmployees.indexOf(name);
    let newSelected: Array<number> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedEmployees, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedEmployees.slice(1));
    } else if (selectedIndex === selectedEmployees.length - 1) {
      newSelected = newSelected.concat(selectedEmployees.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedEmployees.slice(0, selectedIndex),
        selectedEmployees.slice(selectedIndex + 1)
      );
    }

    setSelectedEmployees(newSelected);
  };

  // new employee row | edit employee row
  const renderFormRow = () => {
    return (
      <TableRow>
        <TableCell />
        <TableCell data-title="Name">
          <div className="rt-applicantimgname">
            <TextField
              variant="outlined"
              placeholder="Full Name"
              autoComplete="off"
              {...defaultInputProps("fullName")}
            />
          </div>
        </TableCell>
        <TableCell data-title="Title">
          <TextField
            variant="outlined"
            placeholder="Job Title"
            {...defaultInputProps("title")}
            required={false}
          />
        </TableCell>
        <TableCell data-title="Phone">
          <NumberFormat
            variant="outlined"
            placeholder="Mobile Number"
            customInput={TextField}
            format="###-###-####"
            // format="+## (###) ###-####"
            allowEmptyFormatting
            mask="_"
            {...defaultInputProps("phone")}
            required={false}
          />
        </TableCell>
        <TableCell data-title="Email">
          <TextField
            variant="outlined"
            placeholder="Email"
            {...defaultInputProps("email")}
            required={false}
          />
        </TableCell>
        <TableCell /> {/* status is not required in form */}
        <TableCell>
          <span
            style={{ cursor: "pointer", marginRight: 20 }}
            onClick={_hideFormRow}
          >
            <i className="icon-cross" style={{ fontSize: 20 }}></i>
          </span>
          <span style={{ cursor: "pointer" }} onClick={() => handleSubmit()}>
            <i className="icon-checkmark"></i>
          </span>
        </TableCell>
        <TableCell />
      </TableRow>
    );
  };

  const closeRowMenu = () => {
    setMenuBtn({ id: null, anchorEl: null });
  };
  const isSelected = (name: number) => selectedEmployees.indexOf(name) !== -1;
  const _handleEditDelete = (id: number, type: string) => {
    if (type === "edit") {
      _editEmployee(id);
    } else {
      setShowConfirm(true);
      setDeleteEmpId(id);
    }
    closeRowMenu();
  };

  const _onSearch = (keyword: string) => {
    _fetchAllEmployees(1, keyword);
  };
  const handleConfirm = () => {
    _deleteEmployee(deleteEmpId);
    setShowConfirm(false);
  };

  const _enableRewardsForSelectedEmps = () => {
    if (selectedEmployees.length && selectedJob.value) {
      _updateReferralSettings("save", 0);
    } else {
      alert("Please select employees to enable rewards for them.");
    }
    return false;
  };

  const _disableRewardsForSelectedEmps = () => {
    if (selectedEmployees.length && selectedJob.value) {
      _updateReferralSettings("deactivate", 0);
    } else {
      alert("Please select employees to disable rewards for them.");
    }
    return false;
  };

  const _changeEmployeeRole = async (employee: IEmployee) => {
    let newRole =
      employee.role === EEmployeeRole.Noob
        ? EEmployeeRole.Admin
        : EEmployeeRole.Noob;

    setLoading(true);
    try {
      const response = await EmployeesApi.ChangeEmployeeRole(
        employee.id,
        newRole
      );
      if (response.hasError) {
        handleApiError(response, "employee-role");
      } else {
        // ? Change Employee Role success
        log("Employees Role Change SUCCESS: ", response);

        _fetchAllEmployees(employees.page);
      }
    } catch (e) {
      handleCatchError(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedEmpForGift) {
      setShowCoinBox(true);
    } else {
      setShowCoinBox(false);
    }
  }, [selectedEmpForGift]);

  const _giftSingleEmp = (emp: IEmployee) => {
    log("showing gift box");
    closeRowMenu();

    setSelectedEmpForGift(emp);
  };

  const _closeGiftBox = () => {
    log("closing gift box");
    // setShowCoinBox(false);

    setSelectedEmpForGift(null);
    setShowCoinBox(false);
  };

  const _giftMultipleEmployees = () => {
    if (!selectedEmployees.length) {
      alert("Please select employees first to gift dymes.");
      return;
    }

    setShowCoinBox(true);
  };

  return (
    <div className={classes.root}>
      <div className="w-100" style={{ marginTop: 20 }}>
        <Typography>
          After selecting job, select employees from here to send them dyme
          invite sms and activate their rewards for selected job.
        </Typography>
      </div>
      <div className="at-jobareahead d-flex w-100 align-items-center">
        <div className="w-50">
          <SearchingComponent
            getData={(v: string) => {
              _onSearch(v);
            }}
            loading={fetchEmployeeloading}
            placeholder={"Search Employee"}
            // placeholder={"Search Employee by Name, Title, Email or Phone"}
          />
        </div>
        <div className="d-flex w-100 flex-lg-row-reverse">
          {/* <Tooltip title="Import Employees using a CSV File">
              <Button
                className={`${classes.bottomBtn} ${classes.bgBlue} mx-2`}
                onClick={_DropAndDown}
              >
                Import Employees
              </Button>
            </Tooltip> */}

          <Tooltip title="Add a new Employee in your Company">
            <Button
              className={`${classes.bottomBtn} ${classes.bgTorqtoise} ml-2`}
              onClick={_showNewRow}
            >
              Add Employee
            </Button>
          </Tooltip>

          <Tooltip title="Gift Dymes to multiple Employees">
            <Button
              className={`${classes.bottomBtn} ${classes.bgGift} ml-2`}
              onClick={_giftMultipleEmployees}
            >
              Gift Employees
            </Button>
          </Tooltip>

          {selectedJob.value ? (
            <div>
              <Tooltip title="Enable Rewards for Selected Employees">
                <Button
                  className={`${classes.bottomBtn} ${classes.bgTorqtoise} text-light mx-2`}
                  disabled={!selectedJob.value || saveLoading}
                  onClick={_enableRewardsForSelectedEmps}
                  loading={saveLoading}
                >
                  Enable Rewards
                </Button>
              </Tooltip>
              <Tooltip title="Deactivate Rewards for Selected Employees">
                <Button
                  className={`${classes.bottomBtn} ${classes.bgWarning} text-light mx-2`}
                  disabled={!selectedJob.value || saveLoading}
                  onClick={_disableRewardsForSelectedEmps}
                  loading={saveLoading}
                >
                  Disable Rewards
                </Button>
              </Tooltip>
            </div>
          ) : null}
        </div>
      </div>

      <TableContainer className={classes.mainTable}>
        <Table
          stickyHeader
          className={`${classes.table} mb-3`}
          aria-labelledby="tableTitle"
          size={"medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selectedEmployees.length}
            onSelectAllClick={handleSelectAllClick}
            rowCount={employees.count}
            headCells={headCells}
            renderFormRow={renderFormRow}
            showNewRow={showNewRow}
          />
          <TableBody>
            {employees?.data?.map((row: IEmployee, index: number) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              if (editableEmpId === row.id) {
                return renderFormRow();
              }

              const enabledForJob = selectedJobEmployees?.includes(row.id);
              const haveAccount = !!row.userId;

              // if employee have user account and enabled for enhanced rewards for selected job
              const jobIsSelected = selectedJob.value;
              const haveAccountAndEnabled =
                enabledForJob && haveAccount && jobIsSelected;

              let rewardStatus = "-";
              let statusStyles: React.CSSProperties = {
                fontWeight: "normal",
              };
              let statusTxtStyle = "text-secondary";

              // TODO: handle account pending but rewards enabled.

              if (haveAccountAndEnabled) {
                rewardStatus = "Rewards Active";
                statusStyles = {
                  fontWeight: "bold",
                };
                statusTxtStyle = "text-primary";
              } else {
                if (jobIsSelected) {
                  if (haveAccount) {
                    rewardStatus = "Rewards Disabled";
                    statusTxtStyle = "text-secondary";
                    statusStyles = {
                      fontWeight: "bold",
                    };
                  } else if (enabledForJob) {
                    rewardStatus = "Activated";
                    statusTxtStyle = "text-primary";
                  } else {
                    statusTxtStyle = "text";
                  }
                  // empStatus =
                } else if (haveAccount) {
                  rewardStatus = "Account Created";
                  statusTxtStyle = "text-primary";
                }
              }

              // {
              //   haveAccountAndEnabled ? "Rewards Enabled" : ""
              // }
              // {enabledForJob
              //   ? "Invited"
              //   : haveAccount
              //   ? jobIsSelected
              //     ? "Rewards Disabled"
              //     : ""
              //   : "Not Active"}{" "}
              // {haveAccount ? "" : "(User)"}

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                  key={row.id}
                >
                  <TableCell padding="checkbox" className="border-1 border-top">
                    <Checkbox
                      color="default"
                      classes={{
                        root: classes.checkbox,
                        checked: classes.checked,
                      }}
                      onClick={(event: any) =>
                        handleSelectEmployee(event, row.id)
                      }
                      checked={isItemSelected}
                      inputProps={{ "aria-labelledby": labelId }}
                    />
                  </TableCell>
                  <TableCell
                    className="border-1 border-top p-0"
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <ImageComponent
                        src={row.user?.photo}
                        style={{ width: 40, height: 40, borderRadius: 25 }}
                        className="mr-3"
                      />
                      <div className="mt-2">
                        {row.fullName}

                        <b>
                          {row.role !== EEmployeeRole.Noob
                            ? ` (${row.role})`
                            : ""}
                        </b>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="left" className="border-1 border-top p-0">
                    {row.title}
                  </TableCell>
                  <TableCell align="left" className="border-1 border-top p-0">
                    {/* {row.phone?.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "$1 - $2 - $3"
                      )} */}
                    <NumberFormat
                      disabled
                      // format="###-###-####"
                      // format="+1 (###) ###-####"
                      format="###-###-####"
                      value={row.phone}
                    />
                  </TableCell>
                  <TableCell align="left" className="border-1 border-top p-0">
                    {row.email}
                  </TableCell>

                  <TableCell
                    style={statusStyles}
                    className={`border-1 border-top ${statusTxtStyle} p-0`}
                  >
                    {haveAccount ? "Active" : "Pending"}
                  </TableCell>
                  <TableCell
                    style={statusStyles}
                    className={`border-1 border-top ${statusTxtStyle} p-0`}
                  >
                    {jobIsSelected ? rewardStatus : "-"}
                  </TableCell>
                  <TableCell className="border-1 border-top p-0">
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => handleToggle(e, row.id)}
                      size="large"
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      anchorEl={menuBtn.anchorEl}
                      keepMounted
                      open={menuBtn.id === row.id}
                      onClose={closeRowMenu}
                    >
                      {enabledForJob ? (
                        <MenuItem
                          onClick={() => {
                            _updateReferralSettings("deactivate", row.id);
                            closeRowMenu();
                          }}
                        >
                          <BlockIcon className="mr-3" /> Deactivate Rewards
                        </MenuItem>
                      ) : jobIsSelected ? (
                        <MenuItem
                          onClick={() => {
                            _updateReferralSettings("save", row.id);
                            closeRowMenu();
                          }}
                        >
                          <PersonAddIcon className="mr-3" /> Activate Rewards
                        </MenuItem>
                      ) : null}

                      <MenuItem onClick={() => setOpenMessage(true, row.id)}>
                        <MessageIcon className="mr-3" />{" "}
                        {!haveAccount ? "Invite to Dyme" : "Message"}
                      </MenuItem>

                      {/* do we want to reward if they don't have an account? */}
                      <MenuItem onClick={() => _giftSingleEmp(row)}>
                        <GiftIcon className="mr-3" /> Gift Dymes
                      </MenuItem>

                      <MenuItem
                        onClick={() => _handleEditDelete(row.id, "edit")}
                      >
                        <EditIcon className="mr-3" /> Edit
                      </MenuItem>

                      <MenuItem
                        onClick={() => _handleEditDelete(row.id, "delete")}
                      >
                        <DeleteIcon className="mr-3" /> Delete
                      </MenuItem>

                      {/* <MenuItem
                          onClick={() => {
                            _changeEmployeeRole(row);
                            handleClose();
                          }}
                          disabled={loading}
                        >
                          {row.role === EEmployeeRole.Noob ? (
                            <>
                              <UserAddIcon className="mr-3" /> Make Admin
                            </>
                          ) : (
                            <>
                              <UserDisableIcon className="mr-3" />
                              Remove Admin Role
                            </>
                          )}
                        </MenuItem> */}
                    </Menu>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <ConfirmModal
            message={"Are you sure you want to delete Employee?"}
            onConfirm={handleConfirm}
            show={show}
            onClose={() => setShowConfirm(false)}
          />
        </Table>
        {/* <PaginationComponent
          {...employees}
          onClick={(v: number) => {
            setSelectedEmployees([]);
            _fetchAllEmployees(v);
          }}
        /> */}
      </TableContainer>
      <div
        //className = "grid justify-items-center items-center mt-10 mb-8"
        style={{
          display: "grid",
          justifyItems: "center",
          alignItems: "center",
          marginTop: 48,
        }}
      >
        <Pagination
          count={employees.pageCount}
          page={employees.page}
          className={classes.paginationList}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              onClick={() => {
                _fetchAllEmployees(item.page);
              }}
              classes={{ selected: classes.selected }}
            />
          )}
          variant="outlined"
        />
      </div>

      {showCoinBox && !dollarLoading && (
        <GiftCoinModal
          dollarValue={dollarValue}
          employee={selectedEmpForGift}
          employees={employees}
          selectedEmpIds={selectedEmployees}
          closeGiftBox={_closeGiftBox}
        />
      )}

      {(loading || dollarLoading) && <Loader />}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  mainTable: {
    overflowY: "auto",
    // maxHeight: '600px',
  },
  topbtn: {
    marginLeft: "20%",
  },
  table: {
    minWidth: 750,
    // maxHeight: "300px",
    // position: "sticky",
    borderWidth: "1px",
    borderColor: "rgba(224, 224, 224, 1)",
    borderStyle: "solid",
    borderRadius: "4px",
  },
  inviteBtn: {
    backgroundColor: "#63d699",
    textTransform: "capitalize",
    marginRight: "5px",
    borderRadius: "2px",
  },
  smsBtn: {
    backgroundColor: AppColors.darkBlue,
    textTransform: "capitalize",
    borderRadius: "2px",
  },
  tableHead: {
    backgroundColor: "#fafafb",

    "&:first-child": {
      borderRadius: "12px 0 0 12px",
    },
    "&:last-child": {
      borderRadius: "0 12px 12px 0",
    },
  },
  checkbox: {
    color: "#93939e !important",
  },
  checked: {
    color: "#63d699 !important",
  },
  bottomBtn: {
    color: "#fff",
    textTransform: "capitalize",
    padding: "10px 0px",
    borderRadius: "10px",
    fontSize: "12px",
    minWidth: "120px",
  },
  bgWarning: {
    backgroundColor: AppColors.yellow,
    "&:hover": {
      backgroundColor: AppColors.yellow,
    },
  },
  bgDanger: {
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: "red",
    },
  },
  bgBlue: {
    backgroundColor: AppColors.blueMain,
    "&:hover": {
      backgroundColor: AppColors.blueMain,
    },
  },
  bgGift: {
    backgroundColor: AppColors.darkBlue,
    "&:hover": {
      backgroundColor: AppColors.darkBlue,
    },
  },
  bgTorqtoise: {
    backgroundColor: "#63d699",
    "&:hover": {
      backgroundColor: "#63d699",
    },
  },

  selected: {
    backgroundColor: "#047CFF",
    color: "#ddd",
    fontFamily: "'Monsterrat', sans-serif",
  },

  paginationList: {
    "& .Mui-selected": {
      backgroundColor: "#047CFF",
      color: "#fff",
      fontFamily: "'Monsterrat', sans-serif",
    },
  },
}));
